<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepage">Nos actualités : </h1>
        <h2 id="titresection">Retrouvez ici nos derniers évènements : </h2>
        <div id="sectionacc">
            <h3 id="titresection2">Notre lauréate : madame Pascale CORDE FAYOLLE - JURA - commune de MONTROND</h3>
            <h4 class="soustitresect">le 25 novembre 2023</h4>
            <p> Notre lauréate : madame Pascale CORDE FAYOLLE - JURA - commune de MONTROND
                Son parcours : d'origine bourguignonne, j'ai grandi dans la banlieue lyonnaise. J'ai suivi des études
                littéraires passionnantes. Je me suis présentée au concours de l'école normale à Bonneville en
                Haute-Savoie,
                j'ai été reçue en même temps que mon mari, et j'ai passé quarante ans dans cette merveilleuse région.
                Nous avons
                été de très jeunes parents, nos trois enfants sont nés dans les Alpes. <br>
                J'ai enseigné dans une classe de CP-CE1 dans un village voisin, pendant de nombreuses années. Puis, mon
                mari et
                moi avons décidé de travailler ensemble dans le petit village de Mégevette (Haute-Savoie), où nous
                habitions et
                dans lequel il exerçait depuis ses débuts. J’ai toujours eu ce plaisir d'apprendre à lire aux enfants.
                J'ai toujours écrit, c'est une évidence, cela fait partie de ce que je suis. Lorsque mes enfants étaient
                petits,
                j'inventais des contes pour eux. Dans mes classes, j'ai aussi amené mes élèves à créer des contes, puis
                une
                conteuse venait lire leur texte devant eux. J'adore la littérature enfantine et je partage cette passion
                avec
                mes petits-enfants.
                Depuis de nombreuses années, j'écris des nouvelles. Je participe à des concours, ce qui me permet
                parfois d'être
                publiée dans des recueils de nouvelles. J'ai été primée de nombreuses fois et j'ai rencontré des auteurs
                anonymes, comme moi ; leurs textes étaient souvent de grande qualité. Lire et écrire, m'occuper de mes
                "merveilleux" petits-enfants, randonner dans le silence des montagnes restent mes vraies passions. </p>

        </div>
        <div id="sectionacc">
            <h3 id="titresection2">Notre mention de prix : madame Sabine DUPUY - LOIRE ATLANTIQUE - NANTES</h3>
            <h4 class="soustitresect">le 25 novembre 2023</h4>
            <p> Mère de deux enfants et maintenant grand-mère. J’ai élevé mes enfants dans le XIII arrondissement de
                Paris, à
                deux pas du parc Montsouris. J’y suis restée 20 ans.
                J’ai une formation de sociologue et un doctorat d’urbanisme. J’ai exercé ma profession tout d’abord pour
                le
                ministère du logement, en réalisant des recherches et des enquêtes, principalement dans le secteur du
                logement
                social. Puis pendant 20 ans j’ai été enseignante de sociologie à l’école nationale supérieure
                d'architecture de
                Normandie, à Rouen. J’habite actuellement à Nantes, avec ma compagne, où réside ma fille et ma
                petite-fille.
                Ma fibre littéraire n’a pas toujours fait bon ménage avec le jargon scientifique et pour lui donner plus
                libre
                cours, je me suis autorisée à écrire, au gré de l’inspiration, un certain nombre de nouvelles. J’ai
                participé
                une fois à un concours de nouvelles, dans le cadre d’une association culturelle du ministère de la
                Culture, sur
                le thème du toucher. Mise à part cette expérience qui m’avait valu quelques compliments, je n’ai pas
                récidivé,
                sauf cette année 2023, au cours de laquelle j’ai envoyé une nouvelle, « la semaine des cloches » à la
                revue «
                rue saint Ambroise » qui sera sans doute publiée dans le N°53. Et bien sûr à Passion nouvelle, « le
                Cueilleur de
                plumes », qui me fait l’honneur de la récompenser.</p>

        </div>
        <div id="sectionacc">
            <h3 id="titresection2">Remise du prix 2022/2023</h3>
            <h4 class="soustitresect">le 25 novembre 2023</h4>
            <div class="galerie">
                <figure class="imactu"><img src="../../public/actu1.jpg" alt="Facebook" /></figure>
                <figure class="imactu"><img src="../../public/actu2.jpg" alt="Facebook" /></figure>
                <figure class="imactu"><img src="../../public/actu3.jpg" alt="Facebook" /></figure>

            </div>
        </div>
    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue';
import FooterCompo from '@/components/FooterCompo.vue';
export default {
    name: 'ActuVue',
    data() {
        return {
            // Your data properties go here
        };
    },
    methods: {
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    components: {
        NavbarCompo,
        FooterCompo
    }
};
</script>

<style scoped>
.titrepage {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;


}

#titresection {
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;
    margin-bottom: 1em;
    margin-left: 0.5em
}

#titresection2 {
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;

    margin-left: 0.5em
}

.soustitresect {
    font-family: 'Amatic SC', cursive;
    font-size: 1.75em;
    color: lightgrey;

}

#sectionacc {
    display: inline-block;
    border: 3px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    background: #ffffff;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    margin-bottom: 1em;
    width: 90vw;
    margin-left: 0.5em;
    padding-bottom: 1em;
}

.imactu {
    width: 30em;

}

.galerie {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}
</style>