import { createRouter, createWebHistory } from 'vue-router'
import AccueilVue from '../views/AccueilVue.vue'
import PresentationVue from '../views/PresentationVue.vue'
import ReglementVue from '@/views/ReglementVue.vue'
import NouvellesVue from '@/views/NouvellesVue.vue'
import ParticipierVue from '@/views/ParticipierVue.vue'
import ContactVue from '@/views/ContactVue.vue'
import ValidateMessage from '@/views/ValidateMessage.vue'
import MentionVue from '@/views/MentionVue.vue'
import AproposView from '@/views/AproposView.vue'
import CalendrierVue from '@/views/CalendrierVue.vue'
import ActuVue from '@/views/ActuVue.vue'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: AccueilVue
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: PresentationVue
  },
  {
    path: '/reglement',
    name: 'reglement',
    component: ReglementVue
  },
  {
    path: '/nouvelles',
    name: 'nouvelles',
    component: NouvellesVue
  },
  {
    path: '/participer',
    name: 'participer',
    component: ParticipierVue
  
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactVue
  },
  {
    path: '/validatemessage',
    name: 'validatemessage',
    component: ValidateMessage
  }
  ,
  {
    path: '/mentionlegale',
    name: 'mention',
    component: MentionVue
  },
  {
    path: '/apropos',
    name: 'apropos',
    component: AproposView
  },
  {
    path: '/calendrier',
    name: 'calendrier',
    component: CalendrierVue
  },
  {
    path: '/actu',
    name: 'actu',
    component: ActuVue
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
