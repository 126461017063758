<template>
  <section class="slidercompo" style="display: flex; justify-content: center;">
    <div class="carousel">
      <transition name="slide-fade" mode="out-in">
        <div>
          <div v-for="(slide, index) in slides" :key="index" v-show="currentIndex === index" class="slide">
            <figure class="imagecarousel"><img :src="slide.image" alt="Slide image" class="imgcarousel" /></figure>
            <div class="title">{{ slide.text }}</div>
          </div>
        </div>
      </transition>
      <div class="tourbouton">
        <button @click="prevSlide" class="buttontest">Diapo précédente</button>
        <button @click="nextSlide" class="buttontest">Diapo suivante</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CarousselCompo',
  data() {
    return {
      currentIndex: 0,
      slides: [
        {
          image: require('@/assets/actu1.jpg'),
          text: 'Remise du prix 2022/2023'
        },
        {
          image: require('@/assets/actu2.jpg'),
          text: 'Remise du prix 2022/2023'
        },
        {
          image: require('@/assets/actu3.jpg'),
          text: 'Remise du prix 2022/2023'
        },
        // Ajoutez plus de diapositives ici
      ],
    };
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.slide {
  position: relative;
}



.title {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-family: 'Dancing Script', cursive;
}

.tourbouton {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  margin-bottom: 1em;
}


.buttontest {
  background-color: #319093;
  color: white;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 1.25em;
  font-family: 'Dancing Script', cursive;
  text-decoration: none;
}

.carousel {
  width: 70em;
  
}

.imgcarousel {
  border-radius: 20px;
}
</style>