<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepage">Nous contacter :</h1>
        <div class="columns">
            <div class="column is-half">
                <h2 class="titresectioncont">Jocelyne Bour - Présidente de l'association</h2>
                <p>Par mail :<a href="mailto:associationpassionnouvelle@gmail.com" class="inline"> associationpassionnouvelle@gmail.com</a></p>
            </div>
            <div class="column is-half">
                <h2 class="titresectioncont">Lucas Maujean - Responsable du site</h2>
                <p>Par mail :<a href="lucas.maujean@gmail.com" class="inline"> lucas.maujean@gmail.com</a></p>
            </div>
        </div>
        <h1 class="titrepage">Une question ? Une remarque ?</h1>
        <h3 class="titresectioncont" style="margin-left:0.5em ;">Nous contacter directement via ce site :</h3>
        <form @submit.prevent="submitForm">
            <div class="field">
                <label class="titresoussection">Nom</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Dupond" v-model="form.lastName" required>
                </div>
            </div>
            <div class="field">
                <label class="titresoussection">Prénom</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Antoine" v-model="form.firstName" required>
                </div>
            </div>
            <div class="field">
                <label class="titresoussection">Email</label>
                <div class="control">
                    <input class="input" type="email" placeholder="antoinedupond@mail.com" v-model="form.email"
                        required>
                </div>
            </div>
            <div class="field">
                <label class="titresoussection">Votre message</label>
                <div class="control">
                    <input type="text" class="input" placeholder="votre message" v-model="form.message">
                </div>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <button id="button">Envoyer</button>
                </div>
            </div>
        </form>
    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue'
import FooterCompo from '@/components/FooterCompo.vue'
import axios from 'axios'

export default {
    name: 'ContactVue',
    components: {
        NavbarCompo,
        FooterCompo
    },
    data (){
        return {
            form: {
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            },
        }
    },
    methods: {
        submitForm() {
        let formData = new FormData();
        formData.append('firstName', this.form.firstName);
        formData.append('lastName', this.form.lastName);
        formData.append('email', this.form.email);
        formData.append('message', this.form.message);

        // Afficher l'alerte "chargement"
        alert('Chargement...');

        axios.post('https://tasteful-bramble-slicer.glitch.me/submitmessage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            // Fermer l'alerte "chargement" et afficher l'alerte de succès
            alert('Formulaire soumis avec succès');
        }).catch(() => {
            // Fermer l'alerte "chargement" et afficher l'alerte d'erreur personnalisée
            alert('Erreur lors de la soumission du formulaire, merci de contacter Lucas via lucas.maujean@gmail.com');
        });
    }
}
    
}
</script>

<style scoped>
    .titresectioncont {
        font-family: 'Dancing Script', cursive;
        font-size: 2em;
        color: grey;

    }
    .titrepage{
        font-family: 'Amatic SC', serif;
        font-size: 3em;
    }
    .inline {
        color: #319093;
    }
    .container{
        margin-top: 1em;
    }
    #button {
    background-color: #319093;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
}
.titresoussection {
    font-family: 'Dancing Script', cursive;
    font-size: 1.75em;
    color: #319093;
}

form{
    margin-left: 2em;
}

</style>