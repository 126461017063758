<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepage">A propos : </h1>
        <h2 class="titresectionpart">L'association : </h2>
        <p>L'association Passion Nouvelle est une association de droit local créée le 18 Juin 2020. Sa présidente est
            madame Jocelyne Bour. L'association est responsable de l'organisation du prix littéraire Odette Masffleder.
        </p>
        <h2 class="titresectionpart">Le prix : </h2>
        <p>Il s’agit pour les candidats de présenter conformément au présent règlement une nouvelle. Le choix du sujet
            est libre, chaque texte présenté sera rédigé en français, dactylographié en police de 12 avec un interligne
            simple, il ne devra pas excéder six pages, et être anonyme. Les pages devront être numérotées, le texte aura
            toujours un titre et les insertions de photos dans les textes sont proscrites. <br> Le jury constitué des
            membres de l'association élit chaque année son lauréat et sa mention de prix. </p>
        <h2 class="titresectionpart">Le site : </h2>
        <p>Le site web de l'association a été créé en Juillet 2021 et permet de consulter les informations liées au prix
            et d'y candidater directement.</p>

        <h3 class="titresectionpart">Une question ? Une remarque ?</h3>
        <p>Vous pouvez nous contacter via les informations de la page <a href="/contact" class="inline">Contact</a></p>

    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue'
import FooterCompo from '@/components/FooterCompo.vue'
export default {
    name: 'AproposView',
    // Add your component logic here
    components: {
        NavbarCompo,
        FooterCompo
    }
}
</script>

<style scoped>
.titrepage {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    margin-bottom: 0.5em;


}

.titresectionpart {
    font-family: 'Dancing Script', cursive;
    font-size: 2.5em;
    margin-left: 0.5em;
    color: #319093;
}

.titresectionpart2 {
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
    margin-left: 0.5em;
    color: #319093;
}

.inline {
    color: #319093;
}
</style>