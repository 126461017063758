<template>
<RouterView/>
</template>

<script>


export default {

}
</script>

<style>
</style>