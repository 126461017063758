<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepagepart">Merci !</h1>
        <p>Nous avons bien reçu votre message, nous reviendrons vers vous dans les meilleurs délais.</p>
        <div class="tourboutton">
            <router-link to="/"><button id="button">Retour à l'accueil</button></router-link>
        </div>
    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue'
import FooterCompo from '@/components/FooterCompo.vue'
export default {
    name: 'ValidateMessage',
    components : {
        NavbarCompo,
        FooterCompo
    },
    
}
</script>

<style scoped>
.titrepagepart {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;



    

}
.titresectionpart {
    font-family: 'Dancing Script', cursive;
    font-size: 2.5em;
    margin-left: -0.4em;


}
.tourboutton {
    margin-top: 2em;
    text-align: center;
}
#button {
    background-color: #319093;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
}
</style>