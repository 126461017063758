<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a role="button" class="navbar-burger" :class="{ 'is-active': isMenuOpen }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="toggleMenu">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isMenuOpen }">
    <div class="navbar-start">
      <router-link to="/presentation" class="navbar-item">
  Présentation
</router-link>

<router-link to="/reglement" class="navbar-item">
  Règlement
</router-link>

<router-link to="/" class="navbar-item" id="logo">
  Passion Nouvelle
</router-link>

<router-link to="/nouvelles" class="navbar-item">
  Nouvelles
</router-link>

<router-link to="/participer" class="navbar-item">
  Participer
</router-link>
    </div>

  </div>
</nav>
<div class="banner">
        <div class="content">
            <img src="/logo.png" alt="Logo" class="logoban">
            <h1 class="textbanner">Bienvenue sur la page de Passion Nouvelle !</h1>
        </div>
    </div>

</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  
}
</script>

<style scoped>
.navbar-start {
  display: flex;
  justify-content: space-around;
  width: 100vw;
  background-color: #319093;
}

#logo {
  font-size: 3.5em;
  color: #fff;
  font-family: 'Amatic SC', serif;
}

@media (max-width: 1024px) {
  .navbar-start {
    flex-direction: column;
  }
}

.navbar-item {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  font-family: 'Dancing Script', cursive;
}
.navbar-item:hover {
  color: blue;
}

#logo:hover{
  color: blue;
}

.navbar-brand{
  background-color: #319093;

}

.navbar-burger {
  color: #fff;
}
.banner {
    background-image: url('../../public/head.jpg');
    background-size: cover;
    background-position: center;
    height: 54vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
}

.textbanner {
    font-family: 'Roboto Slab', serif;
    font-size: 3.5em;
    color: #319093;
    font-weight: 10000;
    display: flex;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoban {
  margin-top: 35px;
}

@media (max-width: 1080px) {

    .textbanner,
    .logo {
        display: none;
    }
}



@media (max-width: 600px) {
    .texteattention {
        font-size: 0.8em;
    }
}

@media (max-width: 400px) {
    .texteattention {
        font-size: 0.6em;
    }
}

</style>
