<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepagepart">Notre planning de l'édition 2023/2024:</h1>
        <h2 class="titresectionpart" style="margin-left: 1em;">Date limite d'envoi : </h2>
        <p style="margin-left: 3em;">Pour la session 2023/2024 du prix, les participants pourront envoyer leurs écrits jusqu'au 15 Octobre 2024. </p>
        <h2 class="titresectionpart" style="margin-left: 1em;">Remise des prix : </h2>
        <p style="margin-left: 3em;">La remise des prix de la session 2023/2024 aura lieu début décembre 2024.</p>
    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue'
import FooterCompo from '@/components/FooterCompo.vue'
export default {
    name: 'CalendrierVue',
    components: {
        NavbarCompo,
        FooterCompo
    }
}
</script>

<style scoped>
.titrepagepart {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
}

.titresectionpart {
    font-family: 'Dancing Script', cursive;
    font-size: 2.5em;
    color: #319093;

}
</style>