<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepage">Retrouvez ici les nouvelles primées les années précédentes : </h1>
        <div class="deuxnouv">
            <h2 class="titresection">2023 : </h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal1 }">
                    <div class="modal-background" @click="showModal1 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Mouna</h3>
                            <p>

                                <i>Vive la joie !</i><br>

                                <i>Perle de bonheur !</i><br>

                                <i>Petite fleur ensoleillée!</i><br>

                                C'est ce que l'on disait de moi lorsque j'étais petite.<br>

                                Bonne petite bouille ronde, sourire éclatant, boucles à profusion, éclats de rire
                                contagieux, chansons
                                fredonnées, et même, deux petites fossettes au coin des lèvres.

                                « Cette enfant est notre rayon de soleil » s'émerveillaient mes parents.

                                Et moi, j 'étais une fillette très heureuse, facétieuse et joyeuse, choyée par papa et
                                maman.

                                A l'école, j'avais plein d'amis, j'étais amoureuse de Rémi, j'adorais les albums de
                                Maîtresse et les
                                cerceaux que nous lancions dans la cour, pour les poursuivre jusqu'à ce qu'ils tombent.

                                Le matin, c'était papa qui me réveillait avec de bonnes tartines avant de m'emmener à
                                l'école ; nous
                                mangions ensemble à midi et j'en profitais pour lui raconter tous les détails de la
                                matinée !

                                L'après-midi, maman venait me chercher à la sortie et c'est elle qui s'occupait de moi
                                le soir.

                                Ils s'étaient organisés pour que je sois toujours avec l'un des deux. Le paradis pour
                                une petite fille !
                                Mon papa, c'était le plus beau des papas. Il avait de magnifiques cheveux blancs qui
                                rayonnaient autour
                                de lui : c'était bien, je pouvais le voir de loin. Il parlait d'une voix très douce et
                                ne s'énervait
                                jamais. Les soirs, il fumait sa pipe en bruyère, ça sentait bon dans tout l'appartement
                                ! Maman, elle,
                                avait des cheveux très noirs et quand ils devenaient un peu blancs, elle courait vite
                                chez la coiffeuse
                                en bas de chez nous pour les renoircir. Elle était moins patiente que papa et se fâchait
                                souvent quand
                                je faisais des bêtises. Mais elle m'offrait de merveilleux câlins !

                                J'avais mon doudou chéri que j'emmenais partout avec moi, à l'école, en courses, chez
                                mamie Lucette,
                                chez le docteur ou pour dormir chez mes amis. C'était une grande écharpe bariolée, très
                                longue, très
                                douce, dans laquelle j'aimais m'envelopper, m'enfouir, m'endormir en suçant mon pouce.

                                Certains mercredis, j' invitais Lisa, Emmie ou Fleur, et Rémi, bien sûr. Il était
                                tellement beau Rémi,
                                tout blond, bouclé avec de gands yeux bleus et tellement gentil. Nos deux mamans riaient
                                de nous voir
                                ensemble, petit couple dissemblable et détonnant, entre le calme de Rémi et mon
                                exubérance.

                                Mon seul problème, à cette époque, c'est que je ne supportais pas d'êfre enfermée et que
                                je faisais des
                                convulsions dès que je me retrouvais en milieu clos. Pas question de prendre
                                l'ascenseur, nous montions
                                les cinq étages à pied, papa disait que c'était excellent pour sa santé. Je ne pouvais
                                pas fermer la
                                porte des toilettes sans être envahie d'une peur panique, alors, partout où j 'allais,
                                je laissais le
                                battant ouvert pour faire mes besoins. Maman m'avait
                                expliqué que cette maladie portait un drôle de nom : j'étais claustrophobe, depuis que
                                j'étais
                                toute petite.

                                <i>Et puis, un jour, je suis tombée du paradis.</i>

                                Une nouvelle petite fille est arrivée à l'école. Elle s'appelait Epiphanie. Elle avait
                                cinq ans, comme
                                moi. Elle était noire, vraiment toute noire. Le choc, pour moi, c'est quand j'ai vu ses
                                parents à la
                                sortie de l'école : ils étaient noirs, eux aussi, et le petit frère d'Epiphanie, noir
                                aussi ! C'est à
                                partir de ce moment-là que j'ai commencé à poser des questions... D'abord à doudou, puis
                                à Maîtresse ;
                                elle m'a dit de demander plutôt à papa et maman.
                                Comme toutes les petites filles, j'avais l'habitude de poser des milliers de questions
                                très importantes
                                : « Comment on fait les bébés ? », « Pourquoi le ciel est-il tantôt bleu, tantôt gris ?
                                », « A quoi tu
                                jouais, maman, quand tu étais petite ? », « Tu avais un papa et une maman, toi, papa,
                                quand tu avais mon
                                âge ? « Dis, mamie, tu as un mari ? », « Pourquoi la maman de Lisa elle ne sourit jamais
                                ? « Papa, tu es
                                toujours amoureux de maman ? » ...
                                Et tout le monde me répondait gentiment.
                                Mais mes nouvelles questions semblaient plus embarrassantes.
                                Epiphanie était noire car elle venait d'un pays qui s'appelait le Mali. C'était en
                                Afrique. Et, en
                                Afrique, les gens sont noirs. Le papa d'Epiphanie avait épousé du travail en France,
                                donc il était venu
                                vivre ici avec toute sa famille. Epiphanie a tout de suite été contente de me voir,
                                c'est vers moi
                                qu'elle venait tous les matins, et c'est avec moi qu'elle voulait toujours jouer.

                                Elle avait un peu peur des autres et même de Rémi, mais pas de moi. Elle me disait : «
                                Toi et moi, nous
                                sommes pareilles, nous allons bien nous entendre. » C'est vrai qu'elle était joyeuse
                                comme moi. Elle
                                avait des boucles brunes comme moi et un drôle de petit nez, comme moi.
                                Mais elle, son papa et sa maman étaient noirs.

                                Maman fuyait toute discussion, elle ne se sentait pas capable de m'expliquer. Alors,
                                papa a profité
                                d'une semaine où maman était partie pour son travail ; il m'a raconté mon histoire, du
                                moins ce que je
                                pouvais entendre à cet âge-là. C'était un soir d'hiver, la nuit était tombée très tôt.
                                Il nous avait
                                attirés sur ses genoux, mon doudou et moi, et avait parlé de sa voix douce. Longtemps.
                                Ses paroles, je
                                les ai retenues, mot à mot, elles font partie de moi, maintenant. « Tu sais, ma petite
                                Mouna, tu as la
                                chance d'avoir une très belle histoire. Je t'ai déjà expliqué comment naissent les
                                bébés. Mais papa et
                                maman, eux, n'ont pas eu d'enfants... »

                                Je me suis drapée plus fort de ma longue écharpe aux mille couleurs et j'ai ouvert bien
                                grands mes yeux
                                noirs pour manifester mon incompréhension. Papa a continué : « Tu te rappelles, je t'ai
                                déjà raconté,
                                qu'avant ta naissance, maman et moi étions grands reporters et que nous allions dans le
                                monde entier,
                                partout où il y avait la guerre, pour relater ce qui se passait dans ces pays broyés par
                                la violence.
                                Nous écrivions pour un grand journal qui relayait toutes nos
                                informations. Nous étions bien trop occupés pour songer à l'arrivée d'un bébé dans notre
                                foyer !

                                Maman travaille toujours pour eux, mais elle ne va plus rencontrer la guerre, elle reste
                                plus près de
                                toi ; et moi, depuis ton arrivée, j'ai changé de métier.

                                Cette année-là, il y a cinq ans, nous étions dans un pays où il se passait des choses
                                horribles, c'était
                                très violent, très dangereux et nous avons assisté à des drames terribles. Nous étions
                                dans un pays
                                d'Afrique qui s'appelle le Rwanda. Nous avions fini notre reportage, mais, avant de
                                rentrer en France,
                                nous voulions visiter un camp de réfugiés : c'est un endroit où vont les gens pour fuir
                                la guerre. Nous
                                avons rencontré un peuple très courageux, qui ne se
                                plaignait jamais. Et, parmi toutes ces personnes, il y avait une femme avec un bébé dans
                                les bras. Elle
                                était magnifique et elle regardait son enfant avec adoration. Elle nous a raconté le
                                massacre de toute
                                sa famille : elle et sa fille étaient les seules survivantes d'un véritable carnage.
                                Elle était blessée,
                                très fatiguée, elle n'avait plus de courage, mais sa voix restait douce. Dès qu'elle le
                                pourrait, quand
                                elle serait bien rétablie, elle fuirait à l'étranger pour élever sa petite loin de la
                                guerre. Nous avons
                                passé la nuit auprès d'elle...

                                Mais... le matin... quand nous nous sommes réveillés... cette jeune femme était morte...
                                Elle

                                ne nous avait pas dit, la veille, que ses plaies étaient aussi profondes, ni qu'elles
                                s'étaient
                                infectées. Elle s'appelait Espérance et elle avait prénommé sa petite... <i>Mouna</i>.

                                - Mouna, comme moi ? avais-je demandé, le pouce à la bouche.

                                - Oui, comme toi, ma puce, Mouna. Tu n'es pas née de nos deux corps, mais maman et moi,
                                nous t'avons
                                aimée dès que nous t'avons vue dans les bras d'Espérance.

                                Cela a été très compliqué de te ramener en France, car on n'a pas le droit de sortir un
                                enfant de son
                                pays sans autorisation spéciale. Nous t'avons cachée... En fait, nous t'avons enveloppée
                                dans l'écharpe
                                bariolée que portait Espérance et maman t'a portée, ainsi dissimulée, pendant tout le
                                voyage. Tu étais
                                si petite et tellement faible que tu es passée inaperçue. C'était le 5 juillet. Dès
                                notre arrivée, nous
                                avons couru à l'hôpital pour te donner les premiers soins, nous sommes restés avec toi,
                                nous nous sommes
                                relayés jusqu'à ce que tu
                                redeviennes une petite fille capable de vivre sans tous ces tuyaux qui te maintenaient
                                en vie. Et, nous
                                t'avons adoptée. Cela a été très très compliqué, mais nous y sommes arrivés. Et nous
                                savons qu'Espérance
                                serait contente de voir la vie que tu mènes ici. Nous pensons très souvent à elle...

                                C'est pour cela que tu es noire et que papa et maman sont blancs ; tu n'es pas sortie du
                                ventre de
                                maman, tu es sortie de cette écharpe qui t'a aidée à quitter ton pays. » <br>

                                Un autre soir, papa, m'a raconté le Rwanda. J'étais noire, car je venais du « pays aux
                                mille collines »,
                                un très beau pays, malheureusement dévasté par la guerre. Là-bas, on parle une drôle de
                                langue que papa
                                n'a jamais pu apprendre mais que maman comprenait : le Kinyarwanda. J'aime bien ce mot !
                                <br>

                                C'est comme cela que j'ai compris... On pouvait être une petite fille noire et avoir des
                                parents blancs,
                                ma vraie maman était morte, elle s'appelait Espérance, mon vrai papa était mort aussi,
                                on ne savait pas
                                comment il s'appelait, je venais de très loin, mon pays était en guerre. J'étais née au
                                mois de janvier
                                1994, mais comme on ne connaissait pas la date exacte, mes parents avaient opté pour le
                                5, leur chiffre
                                fétiche : ils s'étaient rencontrés un 5 octobre, avaient cinquante ans, nous habitions
                                au cinquième
                                étage du 5 rue des Amours. Ils
                                demandaient toujours la chambre 5 quand nous allions à l'hôtel et inventaient souvent
                                quelque chose à
                                fêter le 5 de chaque mois.

                                Les nuits suivantes, j'ai tout raconté à mon doudou ; il n'a jamais eu l'air étonné, il
                                m'enveloppait
                                délicatement et écoutait tendrement mes petites confidences. Après, j'ai pu en parler à
                                maman qui, bien
                                sûr était déjà au courant. Elle avait l'air soulagée de tout ce que papa m'avait raconté
                                et me répétait
                                souvent : « Mouna, tu es ma petite fille chérie. Papa et moi,
                                nous t'aimerons toujours. »
                                Je me suis sentie différente. J'avais cinq ans et je venais déjà de tourner une page de
                                ma vie.

                                Après, j'ai grandi. Je suis restée joyeuse, vive, bavarde, mais je suis devenue
                                indisciplinée.

                                J'étais toujours amoureuse de Rémi, mais il commençait à se fatiguer de moi. Epiphanie a
                                changé de ville
                                l'année suivante. Seule, Emmie est restée mon amie. En grandissant, nous avons cherché
                                des documents sur
                                le Rwanda, nous avons appris plein de choses que mes parents ont complétées au fur et à
                                mesure.

                                Mamie Lucette m'a beaucoup parlé de mon arrivée ici et du bonheur qu'elle avait ressenti
                                en apprenant
                                qu'elle allait enfin être grand-mère. Elle en voulait à mes parents de devenir un vieux
                                couple sans
                                enfant. C'est elle aussi qui m'a raconté l'histoire de mon doudou, telle que Maman la
                                lui avait relatée.

                                Cette écharpe légère venait du Rwanda. Elle enveloppait le corps d'Espérance et
                                l'unissait à son bébé,
                                bien serré contre son sein. C'était un châle très long, rayonnant de mille couleurs, qui
                                semblait la
                                protéger des agressions extérieures. Lorsque maman a compris qu'Espérance était morte,
                                elle m'a enroulée
                                dans cette écharpe pour que je conserve l'odeur de ma mère.

                                Elle voulait me confier à une autre maman du centre de réfugiés, mais les gens
                                croulaient sous les
                                soucis et personne ne voulait s'encombrer d'un nouveau bébé. Papa et maman ont décidé de
                                me sortir du
                                camp pour me laisser dans un hôpital, mais tout était beaucoup trop compliqué. Ils se
                                sont regardés, ne
                                se sont rien dit et sont allés prendre leur avion. Maman s'est enveloppée dans
                                l'écharpe, comme le
                                faisait Espérance, et je me suis blottie dans cette odeur maternelle. J'avais six mois,
                                mais j'étais
                                très petite, je mourrais de faim et je n'ai pas
                                bougé du trajet. Maman est allée plusieurs fois aux toilettes pour voir si j'allais
                                bien, elle me
                                sentait respirer tellement faiblement contre elle !

                                Mon doudou était donc mon seul souvenir de ma mère, il m'avait accompagnée jusqu'en
                                France et ne m'avait
                                plus quittée, unique trait d'union entre mes deux mamans. Maman avait hésité à le laver
                                au début, car
                                elle craignait de me priver des odeurs maternelles, puis, finalement, elle avait osé et
                                c'était resté
                                mon doudou chéri. <br>

                                Je vais avoir dix-huit ans dans un mois, en janvier, le 5 janvier.

                                Pendant des années, j'ai mené la vie dure à mes parents, j'étais rebelle, je leur
                                reprochais tout et
                                rien, je leur parlais mal, j'étais insolente au collège, ils étaient souvent convoqués
                                chez le
                                proviseur, devaient sévir, mais se sentaient tellement désarmés. Je les blâmais surtout
                                de m'avoir
                                arrachée à ma mère, de m'avoir maintenue en vie et offert cette existence dorée qui
                                n'aurait jamais dû être la mienne.

                                Et puis, pour mes quinze ans, maman m'a offert le manuscrit d'un livre qu'elle venait
                                d'écrire. Elle m'a
                                dit qu'elle ne pouvait le faire éditer sans mon accord et que ce serait bien si nous
                                pouvions reprendre
                                ensemble certains passages. Maman, me dire ça, à moi ? Elle m'a toujours considérée
                                comme une gamine
                                qu'il fallait sans cesse réprimander. Et voilà que, soudain, elle voulait partager ce
                                projet avec moi !
                                Elle m'a prévenue que ce serait une lecture certainement douloureuse pour moi et que si,
                                à un moment ou
                                à un autre, je souhaitais
                                l'abandonner, on n'en parlerait plus et que personne d'autre ne le lirait jamais. Papa
                                avait lu et
                                apprécié, à moi de voir ce que j'en pensais. Le livre s'appelait : « La liberté en
                                écharpe ». <br>

                                Ce n'était pas un de ces romans que me conseillait papa depuis qu'il était à la retraite
                                et que je
                                dévorais voluptueusement. Non, le titre précisait : « La liberté en écharpe - Récit »

                                Mon père ne m'avait pas tout dit. C'était la voix de maman qui racontait, mais
                                j'entendais aussi les
                                silences de papa, un peu en retrait. Je l'ai lu en une nuit, d'une traite, sans
                                reprendre mon souffle.
                                J'ai compris que maman voulait me raconter mon histoire avec ses mots à elle.
                                Ce texte, que vous avez peut-être lu, racontait papa et maman face aux horreurs de la
                                guerre civile au
                                Rwanda. Maman expliquait le contexte et tentait de comprendre comment le « pays aux
                                mille collines »
                                avait pu se retrouver dans cette situation. Elle relatait les corps massacrés, les actes
                                de tortures,
                                les gémissements des agonisants, l'impuissance des survivants.

                                Mes parents se rendaient dans un camp de réfugiés qu' ils voulaient visiter avant de
                                rentrer en France.
                                Mais, en chemin, ils étaient tombés sur un véritable massacre : des familles entières
                                qui couraient vers
                                ce camp avaient été décimées avant d'y parvenir. Le sol était jonché de cadavres, leurs
                                modestes
                                affaires éparpillées tout autour, du sang partout, plus un bruit, la mort dans toute son
                                horreur ! Ils
                                étaient restés muets, atterrés, vidés de toute pensée, hors du
                                temps. Et soudain, dans tout ce silence, un faible gémissement... Ils s'étaient
                                précipités, avaient
                                cherché, cherché, avant de découvrir une jeune femme agonisante. Ses plaies étaient très
                                profondes, elle
                                s'était vidée de son sang. Elle avait murmuré, dans un souffle, qu'elle s'appelait
                                Espérance, et avait
                                montré une valise jetée à quelques mètres d'elle. Elle avait souri, faiblement, puis
                                avait perdu
                                connaissance.

                                Mes parents s'étaient dirigés vers le petit bagage. Ils l'avaient ouvert délicatement
                                et... n'avaient
                                d'abord vu qu'une grande écharpe bariolée. Au moment où papa allait la saisir pour
                                recouvrir le corps de
                                la jeune femme, il m'avait découverte ! Toute petite fille que ma mère avait
                                soigneusement enveloppée
                                dans son châle et cachée dans sa valise lorsqu'elle avait vu
                                arriver les bourreaux. Maman m'avait prise dans ses bras et papa avait porté Espérance
                                jusqu'au camp.
                                Elle avait survécu quelques heures. Elle avait juste ouvert les yeux en m'entendant
                                pleurer faiblement.
                                Elle avait souri à maman qui me tenait bien enveloppée dans le grand châle. Elle avait
                                murmuré : «
                                ...Mouna... Puis, tout avait été fini... <br>

                                <i>« La liberté en écharpe »</i> a rencontré un grand succès. Maman a été conviée à de
                                nombreuses
                                conférences pour parler du Rwanda. Elle a du temps depuis qu'elle aussi est en retraite.
                                Mes parents
                                m'ont promis que nous irions ensemble visiter mon pays de naissance.
                                Je suis restée claustrophobe, malgré plusieurs thérapies, c'est le souvenir ancré dans
                                ma chair de la
                                terreur de ma mère lorsqu'elle m'avait enfermée dans sa toute petite valise

                                <i>En juin, quand j 'irai passer mon bac, Je serai enveloppée dans mon écharpe de
                                    liberté, je

                                    sais qu 'elle me portera bonheur et que je sentirai son souffle rwandais... </i>
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal1 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Mouna</h3>
                <h4 class="auteurnouvelle">par PASCALE CORDE FAYOLLE</h4>
                <p><i>Vive la joie !</i><br>

                    <i>Perle de bonheur !</i><br>

                    <i>Petite fleur ensoleillée!</i><br>

                    C'est ce que l'on disait de moi lorsque j'étais petite.<br>

                    Bonne petite bouille ronde, sourire éclatant, boucles à profusion, éclats de rire contagieux,
                    chansons
                    fredonnées, et même, deux petites fossettes au coin des lèvres.
                    ...
                </p>
                <div class="tourbutton">
                    <button @click="showModal1 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal2 }">
                    <div class="modal-background" @click="showModal2 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">La cueilleur de plume</h3>
                            <p>Ma douce, mon exquise, quel jour sommes-nous tous les deux ? Octobre, novembre? Je ne
                                sais plus. <br>

                                Quand je t'ai quittée hier soir, tu semblais si lasse. Oh, personne ne nous a vus,
                                rassure-toi, il n'y
                                avait qu'un Vilain pigeon boiteux, se rengorgeant à tes pieds. Quand je t'ai quittée,
                                une feuille
                                morte tombait doucement dans le couchant, l'as-tu sentie t'effleurer l'épaule ?
                                Pourquoi penches-tu la tête, songeuse, tes yeux clos ? As-tu des nouvelles de ce garçon
                                si mal en
                                point, que j'ai vu pâmé dans les bras de cet homme puissant ?
                                Est-il mourant? Son père a beau tenter de le retenir, il glisse vers la mort, n'as-tu
                                pas vu les
                                chrysanthèmes jaunes, blancs et mauves qui l'attirent?
                                Déjà sa main les frôle ; il aurait fallu dire au jardinier d'attendre encore un peu pour
                                les planter.
                                Mais tu sais bien comme les gens sont pressés ! Demain peut-être ils accrocheront les
                                guirlandes de
                                Noël dans les rues, il fait nuit si tôt maintenant.<br>

                                Quel jour sommes-nous tous les deux ? Octobre, novembre, je ne sais plus ! <br>

                                Quel âge as-tu, ma divine? Tu as des seins de toute jeune fille, non, ne détourne pas la
                                tête,
                                montre-moi tes yeux, tu n'oses pas? Ça ne fait rien, je les connais par cœur, tes
                                pupilles de velours
                                !
                                Ma farouche, as-tu déjà laissé un homme poser ses lèvres sur tes paupières en amande?
                                Non, ne me
                                raconte pas, je me souviens de ta peau si fine sous ma bouche, de tes cils drus sous ma
                                langue,
                                comment as-tu fait pour oublier?
                                J'ai froid, je sens l'humidité de la terre, oui tu vois, je ne porte plus jamais de
                                chaussettes,
                                j'aime marcher nu-pieds dans la boue, sentir les racines rouler sous mes orteils.
                                Aujourd'hui, je serais venu vers toi, chaussé de feuilles mortes, rousses et blondes,
                                ourlées du
                                dernier vert de l'été.
                                Pour toi j'aurais choisi le grand hêtre pourpre, près de la cascade, ses branches
                                ploient en berceau
                                au-dessus de la pelouse mouillée de rosée. Tu ne m'aurais pas entendu approcher dans mes
                                poulaines de
                                hêtre, à peine un imperceptible froissement, un souffle d'automne qui t'aurait peut-être
                                caressé le
                                visage. <br>
                                Mais connais-tu l'automne, ma reine sombre des pluies tropicales?
                                Rappelle-moi l'odeur de la terre rouge, ravinée par les ondées quotidiennes, j'ai soif
                                de ces senteurs
                                enfouies dans un repli de ma mémoire.
                                Parfois je descends vers la rue Mouffetard, rien que pour ça, pour me laisser surprendre
                                par des
                                parfums oubliés.
                                J'avance sans me presser vers le vaste étal, j'aperçois les formes bizarres des patates
                                douces, les
                                régimes de grosses bananes vertes, je me penche doucement, les yeux fermés, je respire
                                profondément,
                                le cœur battant.
                                Elles ne sont pas toujours à la même place, quelquefois les citrons verts me
                                chatouillent les narines,
                                il me faut patienter, laisser passer les effluves trompeuses des fruits de la passion.

                                Et puis, dans un éclair, elle me parvient cette odeur que j'appelle et qui m'enveloppe,
                                celle de la
                                goyave mûre, nichée dans son emballage de papier. <br>

                                La connais-tu déjà, ma toute belle, la morsure poignante de la nostalgie? Tu es si
                                lisse, si pleine,
                                si ferme, comment saurais-tu l'étourdissement de ce vide qui cogne dans le cœur, qui,
                                pulvérise les
                                contours.
                                Te rappelles-tu cette nuit de la Saint-Jean, j'avais alors sept ans, mais peut-être
                                étais-tu restée à
                                la maison ; mon père disait que tu recevais ton amoureux dans ta petite chambre de
                                bonne, jusqu'à ce
                                qu'il aille en prison et que tu pleures souvent.
                                C'était au bord de la baie de Guanabara, nous étions invités par des amis, dans un de
                                ces clubs
                                privés, avec piscine et terrain de tennis. Le jour s'attardait sur l'eau immobile, nappe
                                pâle et
                                tendre, posée entre les îles.

                                Quelqu'un nous a apporté des lampions, non ce n'était pas de vrais lampions, mais des
                                montgolfières
                                miniaturisées, avec une bougie en équilibre dans la minuscule nacelle. Nous avons allumé
                                les bougies
                                et toutes ces frêles sphères de papier multicolore se sont balancées lentement vers le
                                ciel, leurs
                                reflets à la surface de l'eau. Je les ai vues flotter vers le milieu de la baie,
                                attirées par le
                                collier de lumières, là bas vers le Grand Christ drapé, les bras en croix, au-dessus de
                                la ville
                                éparpillée, entre rivages et pitons. <br>

                                Pardonne-moi, je te fais mal avec le passé, donne-moi ta main que je sente battre le
                                sang dans tes
                                veines, au-dessus du poignet, que je puisses ta paume et boire la moiteur
                                de ta chair.
                                Entends-tu Le grondement des voitures sur le boulevard, sens-tu tous ces regards qui
                                traversent le
                                feuillage, tous ces gens derrière leur pare-brise qui nous regardent? J'ai froid, adieu
                                mon exquise, à
                                demain, ne me chasse pas de tes yeux clos, demain n'est-ce pas, tu me livreras l'éclat
                                de ton sourire,
                                je saurai le murmure de ton rire.
                                Quel jour sommes-nous tous les deux ? Octobre ou novembre, je ne sais plus! <br>

                                Tu es là, ma divine, montre-moi, quelle mine as-tu ? Oui le ciel est gris et bas, on
                                dirait qu'il
                                s'est posé sur tes joues, qu'il en a retiré la lumière.
                                Ils ont creusé une tranchée à la naissance du trottoir, très tôt ce matin; ils
                                installent des
                                palissades et de grandes pancartes blanches. Tu n'as pas été gênée par la poussière et
                                le vacarme des
                                marteaux piqueurs?
                                Laisse-moi couler mes doigts dans tes cheveux, javais oublié combien ils sont épais et
                                brillants. <br>

                                Ah, le jeune homme ? II a l'air bel et bien mort, peut s'est-il noyé, j'aurais aimé
                                ressembler à son
                                père, as-tu ses muscles bandés, ses fesses saillantes?
                                Moi je suis trop grand et top mince, je me suis regardé dans le miroir avant de venir te
                                retrouver,
                                mes jambes ont maigri, mes cheveux ont blanchi. J'ai mis mon pantalon préféré
                                aujourd'hui, te plaît-il
                                ? J'y ai posé des pièces avec des bouts de tissus ne trouves-tu pas que c'est triste les
                                pantalons
                                d'homme ? <br>

                                Tiens, je suis passé voir tes copines, elles papotent sans vergogne, la plus âgée a des
                                hanches aussi
                                larges qu'une amphore, elles sont belles, n'est-ce pas?
                                Comme je les admirais de loin, figure-toi que deux jeunes Japonais se sont approchés
                                doucement, sur la
                                pointe des pieds, en souriant.


                                L'un des deux a sorti un appareil de photos de son sac à dos, tandis que l'autre... mais
                                si ma toute
                                petite, il a osé, il s'est installé au milieu d'elles, il s'est assis sur leurs cuisses
                                blanches et a
                                offert son visage malicieux à l'objectif.
                                Non, non ! Elles ne se sont pas interrompues le moins du monde, elles ont continué à se
                                raconter,
                                alanguies, concentrées et moi, je me suis sauvé en souriant et me voilà. <br>

                                Ta voix est grave, modulée, ton accent me dorme des frissons, j'aime la musique de ta
                                langue, j'ai
                                envie de m'y fondre, de m'y bercer, les mots me reviennent ; parle ma belle, ma déesse,
                                chante pour
                                moi, personne ne t'entendra, je t’emmènerai sous le dôme du hêtre tortillard, au bord de
                                la pièce
                                d'eau, les mouettes s'envoleront toutes d'un coup avec des cris pressés et de doux
                                battements d'ailes,
                                je poserai mes lèvres à la racine de ton cou et ton parfum sucré me brouillera le
                                regard. <br>

                                Ah oui, je suis venu avec ce grand sac, il est un peu encombrant avec ces carreaux
                                blancs et bleus,
                                j'ai eu du mal à tout faire tenir dedans, tu sais.

                                J'y ai rangé les points et les virgules, les points-virgules, j'ai mis un élastique
                                autour des points
                                d'exclamation, mais pour ce qui est des points d'interrogation, j'ai fini par en laisser
                                la moitié à
                                la maison.

                                Tu me crois fou ? Mais non, voyons, je disais ça pour entendre ton rire, c'est drôle il
                                y a des
                                gens qui me regardent d'un air méfiant ou curieux, mais après tout ça, m'est égal, je ne
                                parle plus à
                                personne ! <br>

                                Enfin presque à personne, à part ma concierge, c'est elle qui vient faire le ménage, une
                                fois par
                                semaine. C'est demain son jour, je serai un peu en retard, tu ne m'en voudras pas, dis?
                                Parfois elle me demande conseil pour son fiston, à cause de l'orthographe. Je lui relis
                                ses
                                rédactions. La première fois il m'a fait la tête, je lui avais corrigé, comme autrefois
                                au boulot,
                                dans la marge, en rouge, il y en avait partout, il n'y comprenait rien. <br>

                                Si je regrette? Oui, ça me manque quelquefois, l'ambiance dans le cassetin, les
                                copains... le cassetin
                                ?
                                Pardonne-moi, mon ange, je parle dans mon jargon, c'est la salle de correction, tous les
                                correcteurs
                                sont rassemblés dans le même endroit. Oh, je bien que tout ça c'est terminé, ils
                                travaillent devant
                                des écrans, maintenant. Ça commençait déjà l'année où je suis parti.
                                <br>

                                Dans mon sac ? Ben, il y a les journaux, "Libé, "le Monde", celui d'hier "l'Equipe", tu
                                vois, je n'ai
                                pas changé!
                                Aujourd'hui, c'est un peu spécial, je t'ai apporté un livre, un livre avec tous ses
                                points et ses
                                virgules, ses points-virgules, ses tirets et traits d'unions, comme ça, si tu veux bien,
                                j'ai pensé
                                t'en lire un passage, te raconter l'histoire du blaireau et du criquet qui ont creusé le
                                trou de
                                l'Emergence et sauvé le Peuple sacré du déluge, puis comment Femme-Coquilie-B1anche...
                                C'est un mythe navajo, je me demande si tu n'as pas du sang indien, tes traits sont si
                                fins, ton bout
                                de nez si droit ! <br>

                                Tu boudes, tu fermes les yeux, je te fatigue? Non ! Tu voudrais que je te raconte une
                                histoire rien
                                que pour toi ! Une histoire que j'inventerais maintenant, tout de suite? <br>

                                Ma toute belle, c'est difficile, comme ça à l'improviste, ne détourne pas ton beau
                                visage, laisse-moi
                                te contempler. Je vais te dire ce que j'ai rêvé cette nuit, j'étais dans une forêt, une
                                forêt claire
                                et sombre à la fois, une forêt avec des bouleaux aux troncs d'argent, plantée de pins
                                tordus. Je
                                marchais pieds nus dans un sable très doux, presque blanc; par moments je levais les
                                yeux suivre des
                                petits nuages dans le ciel. J'ai entendu le bruit de L'eau courante et je suis arrivé au
                                bord d'un
                                torrent, encadré de grands rochers gris. Près de l'eau, sur un tapis de mousse acide,
                                debout, les
                                cornes tournées vers moi, une vache rousse, portant une tache noire sur le front. Entre
                                ses pattes, à
                                moitié dressé au-dessus du reste de son corps enroulé, un gros serpent aux reflets
                                bleutés se
                                balançait tétant ses mamelles.
                                Je me suis arrêté plein d'effroi et de stupeur et c'est alors que je t'ai vue, nue,
                                couchée sur une
                                pierre plate, tes seins levés vers le ciel, en plein milieu du torrent, les mains
                                abandonnées dans le
                                courant.
                                Je suis entré dans l'eau et je t'ai prise dans mes bras et tu étais chaude et douce et
                                mon pénis s'est
                                gonflé d'amour. Sans regarder du côté de la vache et du serpent, je t'ai portée à
                                l'intérieur de la
                                forêt, je t'ai étendue sur le sable si doux et j'ai cueilli des feuilles de fougères que
                                j'ai collées
                                sur ta peau sombre et lumineuse. Puis j'ai ramassé des poignées de sable que j'ai fait
                                couler entre
                                mes doigts, au-dessus de ton corps humide. Lentement, une par une, j'ai retiré
                                délicatement chaque
                                feuille de fougère et tu m'es apparue splendidement parée, tes bras minces poudrés de
                                sable blanc
                                entre le contour délicat des fougères, ma princesse, ma beauté, la chair ferme de tes
                                cuisses, tes
                                petits mamelons pareillement ornés. Sur ton front, j'ai posé un scarabée, et je me suis
                                couché près de
                                toi. <br>

                                N'aies pas honte, mon exquise, ouvre les yeux et regarde-moi, voilà mon histoire, mon
                                rêve, je me suis
                                réveillé le sexe ardent de désir pour toi, je suis venu, me voilà, quel jour sommes-nous
                                tous les deux
                                ? Octobre ou novembre, je ne sais plus! <br>

                                Tu m'as attendu, mon amour, ma divine ! Un rayon de soleil s'est pris dans tes cheveux,
                                est-il si
                                pesant qu'il te fasse pencher la tête ? J'ai eu le coeur serré hier après t'avoir
                                quittée, tu m'as
                                écouté sans rien dire, les yeux baissés, le temps a passé si vite! J'aurais voulu
                                revenir près de toi
                                cette nuit, te réchauffer de mes caresses, mais je me suis endormi en rentrant, épuisé.
                                Tu sais j'ai
                                perdu l'habitude de tant parler, j'ai perdu l'habitude de rêver, de désirer, d'aimer.
                                <br>

                                Non, je n'ai pas pris mon cabas ils vont poser une vraie grille autour du parc avec des
                                barreaux plus
                                haut, comme au Luxembourg. Je ne pourrais plus enjamber le grillage et marcher sur la
                                glace en hiver,
                                jusqu'à l'île aux oiseaux. <br>

                                Quel jour sommes-nous tous les deux, octobre, novembre, quelle importance, il fait beau,
                                me voilà, tu
                                es là! <br>

                                J'ai voulu éviter le jeune homme qui glisse de son linceul vers les chrysanthème, éviter
                                de voir les
                                beaux bras musclés de son père, j'ai fait un détour par la petite bergère aux seins nus,
                                je me suis
                                arrêté pour suivre les jeux des canards.
                                Je savais que tu m'attendais, ma divine, mais je ne voulais pas arriver les mains vides,
                                j'ai cherché
                                ton nom dans mon sommeil, mais je ne l'ai pas trouvé. Je l'ai cherché dans le mince
                                filet de fumée
                                bleue au-dessus des arbres, au-dessus de l'île aux oiseaux, je l'ai cherché autour de la
                                pièce d'eau,
                                sous les feuilles écarlates chassées par la brise.
                                Comment avais-je pu l'oublier, ma toute petite, ce nom-là ! J'ai ramassé une plume, un
                                duvet blanc
                                accroché à une branche du grand saule et ton prénom est revenu en moi, Léda, ma déesse.
                                <br>

                                Quel jour sommes-nous, nous deux? En venant j'ai chantonné de ces airs qui labourent ma
                                mémoire, tu le
                                connais sûrement, c'est une chanson de pêcheur, oui de Dorival Caimi : "o mar quando
                                quebra na praïa,
                                ê bounito, ê bounito ", oui ma bien-aimée, ce disque-là nous l'avons rapporté à Paris,
                                je crois bien
                                que c'est moi qui l'ai gardé .
                                Mais peut-être préférerais-tu une samba, celle qui était à la mode, te souviens-tu ?
                                Celle du
                                carnaval, "Brigitte Bardot, Bardot", ces hommes assis sur le pare-chocs des autobus le
                                rythme de leurs
                                sandales de bois, la ville enfiévrée, assourdie, cette musique inscrite dans mon sang
                                qui libère mon
                                corps de son poids, ta grande bouche rouge, ce tutu de tulle doré autour de ta taille,
                                ces mules à
                                talon, c'est la seule photo de toi que j'ai retrouvée dans l'album
                                de mon enfance. <br>

                                Je ne t'ai jamais vu danser, ma divine, te rappelles-tu ces bananes minuscules et
                                tigrées que nous
                                roulions dans la paume de nos mains jointes pour en faire de la purée, oui le dimanche !
                                Et les cerfs-volants sur la plage, comme des aigles majestueux au-dessus du fracas des
                                énormes
                                rouleaux et des grandes lames d'écume mourant sur le sable.
                                Tu baisses la tête, mon adorée, tu pleures? Non tu ne pleures pas, tu frissonnes, c'est
                                à cause du
                                serpent, je pensais que tu ne l'avais pas vu, ne crains rien, si tu savais la panthère
                                comme elle le
                                déchire! Elle le lacère de ses crocs, il se débat encore, il fouette le sol de sa queue,
                                tout près du
                                cadavre du petit qu'il vient d'empoisonner. <br>

                                Oui, c'est peu plus bas, sur la grande pelouse, le pavillon turc a brûlé, je ne t'avais
                                pas encore
                                retrouvée. C'est dommage, il était insolite ce pavillon de bois ajouré, mystérieux et
                                triste comme je
                                l'étais alors.
                                C'est comme si moi aussi j'avais été incendié, quelque chose s'est consumé. A la place,
                                il y pousse
                                maintenant des roses, toi tu vis et grandis dans mes entrailles, tu m'enflammes et me
                                guides, les
                                cendres sont douces et froides, il y pousse des souvenirs, des serpents bleus qui tètent
                                le lait des
                                vaches, des serpents magiques qui montent la garde au bord de ton royaume.
                                Comme il fait sombre déjà, j'aime la lumière chaude du réverbère entre les feuillages
                                clairsemés, je
                                voudrais m'enrouler à tes pieds, mettre ma tête sous mon aile et dormir près de
                                toi, couvrir ton corps de mes plumes, poser les fins duvets de mon cou sur tes seins et
                                en mordiller
                                le bout de mon bec. <br>

                                Peut-être rougis-tu dans le soir qui tombe, j'aperçois enfin l'éclat de tes tu voudrais
                                un bouquet, un
                                bouquet de plumes, une robe?
                                Une robe de plumes ?
                                Quelle idée gracieuse, ma divine, mon exquise, ce soir je vais la dessiner cette robe.
                                Laisse-moi te
                                regarder, ma toute petite, que tu es menue, que tu es fragile, que j'ai de la peine à te
                                quitter.
                                Non, non personne ne nous a vus, personne ne nous a entendus, j'entends le sifflet du
                                garde, vois-tu
                                les phares des voitures sur le boulevard ? Dors en paix, ma colombe. <br>

                                Quel jour sommes-nous, toi et moi? Octobre, novembre, je ne sais pas encore, peut-être
                                novembre, les
                                enfants sont en vacances, j'ai fait le tour de la pièce d'eau, ils ont installé une
                                affiche avec le
                                nom, l'origine et le dessin de chaque espèce d'oies et de canards qui peuplent l'île aux
                                oiseaux.
                                Je n'ai pas voulut tout regarder, tout lire d'un seul coup, j'ai cherché sur les images
                                l'oiseau qui
                                me ressemblerait.

                                Il y en avait un, gauche, maladroit sur ses pattes fines, un capuchon noir sur la tête,
                                une petite
                                tête au bout d'un long cou, j'ai voulu savoir son nom, pour te l'apporter, ma toute
                                belle, c'est un
                                drôle de nom, mais nom qui me va bien, un nom pour avant, pas un nom pour maintenant.

                                Comment était-ce ? Voyons, attends je l'ai noté sur un bout de papier, oui je suis venu
                                avec mon sac.
                                Aujourd'hui, je vais m'occuper de ta robe, tu vois j'en ai tout un bouquet à la main,
                                j'ai fait Le
                                tour de la pièce d'eau, en marchant sur l'étroit rebord, en dérangeant les pigeons et
                                les mouettes
                                agglutinés sur la pelouse râpée.
                                Il y a des feuilles mortes à la surface de l'eau et des marrons noircis retenus par des
                                débris de
                                branchages charriés par le vent. C'est là que j'ai trouvé ces plumes, posées sur ces
                                fragiles radeaux
                                de feuillage bruni.

                                Tu vois, y en a des longues et des courtes, des raides, blanches et grises et puis ces
                                toutes petites,
                                floconneuses, beiges et chamois. <br>

                                A un moment donné, j'ai cm que des gens me regardaient et j'ai rentré la tête dans mes
                                épaules en
                                frôlant les buissons piqués de boules rouges.

                                Je me suis enfermé dans le parfum d'eau morte qui monte de la vase, en coulant des
                                regards vers
                                l'autre berge, toute proche à cet endroit.

                                Oh! Ce n'était pas moi que les gens regardaient en poussant des exclamations attendries,
                                mais deux
                                oisillons patauds qui arrachaient de l'herbe avec leur bec, deux jeunes cygnes au
                                plumage gris sale.

                                Soulagé, je me suis dépêché de venir te retrouver. Non, personne ne m'a suivi,
                                rassure-toi, tu es
                                belle dans le soleil, ta peau brille et j'ai envie de t'embrasser, je suis un peu
                                essoufflé,
                                pardonne-moi, j'ai marché à longues enjambées dans les escaliers. <br>

                                Ah, oui, c'est vrai, je l'ai noté sur un petit bout de papier, il fait si doux près de
                                toi,
                                Dendrocygne veuf, mais je ne suis plus veuf, mon adorée! J'ai été si longtemps seul, tu
                                attends ta
                                robe pour m'épouser? <br>

                                J'irai cueillir des plumes dans tous les jardins, dans tous les parcs de Paris ! J'irai
                                au Luxembourg,
                                le long des espaliers, nous y passions les jeudis après-midi avec ma mère, j'étais
                                attiré par les
                                passants qui te ressemblaient, noirs de peau, élancés.
                                Ma mère disait que tu me manquais. J'irai à la fontaine Médicis, à l'ombre des grands

                                marronniers. Pour l'instant mes couleurs sont ternes, couleur de neige et de toit,
                                couleur de
                                poussière et de terre, mais tu verras, je trouverai le jade et améthyste des colverts,
                                la pourpre et
                                l'argent des pigeons, le jais et l'ambre des canards siffleurs du Chili. <br>

                                Non, je n'ai pas apporté de livre aujourd'hui, seulement les journaux, ils ont délogé
                                les familles
                                maliennes qui campaient face aux restaurants et aux brasseries, le long d'une avenue de
                                Vincennes.
                                J'ai vu des jeunes femmes en pagne s'arc-bouter sur des caddies emplis de jerricanes
                                d'eau, pour
                                tenter de les hisser sur le trottoir, puis disparaître derrière les bâches de plastic
                                bleu. Oui je
                                suis allé les voir, de loin! <br>

                                Cétait avant toi.
                                Tu ne savais pas ? Je voulais être ethnologue quand j'étais adolescent, j'aurais voulu
                                être capable de
                                vivre mille existences, devenir toutes sortes de peuples, un jour brésilien, un jour
                                malien, un jour
                                chinois, un jour navajo, comprendre toutes les langues, capter toutes les beautés,
                                toutes les
                                richesses, être français du bord de ce mélange, comme ça, en passant. <br>

                                Parle-moi, oui parle-moi dans ta langue, emporte-moi loin d'ici, j'étouffe, je voudrais
                                revoir la mer,
                                plonger dans l'humidité chaude et salée, revenir où je suis né! <br>

                                Parle-moi, je fermerai les yeux et tu feras fondre cette barre d'acier qui me coupe en
                                deux, oui c'est
                                là sous l'estomac, les poils de mon pubis sont emmêlés de fils blancs, tu y promèneras
                                ta petite main
                                d'ébène, tes jolis ongles roses, Léda! Léda! Répond moi, ne penche pas la tête, tu
                                parais si triste,
                                si lasse. <br>

                                Cette nuit j'ai eu envie de caresser tes hanches, puis de saisir tes fesses, de me
                                plaquer à ton
                                ventre lisse, ma toute petite, il faisait froid, un vent furieux a secoué la fenêtre de
                                la cuisine, je
                                me suis relevé, le membre raide et brûlant, je me suis jeté nu sur le divan, j'ai
                                fourragé le cuir
                                frais en cherchant le parfum aigre de sueur. <br>

                                Me laisseras-tu un jour enfouir mes lèvres à la commissure de ton sexe, j'imagine le
                                friselé serré de
                                fourrure, j'y accrocherai des points et des virgules en strass qui brilleront à la lueur
                                du réverbère.
                                Tu me laisseras Tremper mon doigt dans l'encre humide de tes cuisses entrouvertes, pour
                                écrire des
                                poèmes que personne ne corrigera, des poèmes ébouriffés de plumes, de feuilles et de
                                sang et je te
                                prendrai à en mourir et nous irons nous perdre sur l'océan.
                                Je ne sais ce que j'ai, je tremble, j'ai la fièvre, mon amour, pardonne-moi, je m'en
                                vais. Oui, je
                                sais, mariée, robe de plumes, demain, à demain ! <br>

                                IL faisait très froid en ce 1er décembre et les passants se hâtaient de monter les
                                escaliers qui, au
                                milieu des arbres dénudés et des pelouses blanchies par le gel du parc Montsouris, les
                                conduisaient à
                                la station de métro, d'où ils disparaissaient pour la journée. Derrière un massif, non
                                loin du
                                boulevard, la petite statue de bronze à la tête penchée sommeillait encore, les mains le
                                long des
                                hanches. Un escogriffe, vêtu pantalon taillé dans un patchwork bigarré, nu-pieds dans
                                des mocassins
                                avachis, tentait de se hisser sur le piédestal verdâtre. Posée sur ses cheveux gris,
                                taillés en
                                brosse, une couronne de plumes du bleu noir de la nuit. II plie sa haute taille
                                au-dessus de la
                                chevelure figée de la et, l'espace d'un soupir, il caresse les épaules nues.
                                Un sourire détend les traits creusés de son visage, ses yeux clairs, sous les sourcils
                                en bataille, se
                                plissent dans la lumière crue de l'hiver.
                                Il détache lentement le grand cabas à carreaux qu'il porte à l'épaule et le coince entre
                                ses longues
                                jambes plantées en équilibre sur le socle. II y plonge les deux mains et, avec une
                                délicatesse
                                infinie, il en extrait une parure de plumes qu'il élève vers le ciel, à bout de bras.
                                Ses lèvres
                                remuent imperceptiblement tandis qu'il passe la parure au-dessus de la tête inclinée

                                de la petite bonne femme, déhanchée sur ses lourdes cuisses de bronze.<br>

                                Deux bretelles de plumes fauve, noires et blanches retiennent la robe aux épaules de la
                                statue dont le
                                corps disparaît maintenant sous un friselis de plumage aux tons très clairs et très
                                doux.
                                A hauteur des seins, un losange plus foncé aux couleurs irisées, vert, mauve et violet
                                entouré d'un
                                liseré noir. Dans le creux des genoux, des franges superposées de plumes droites et
                                pures comme la
                                neige flottent comme une traîne
                                l'homme est descendu d'un bond piédestal et il contemple son œuvre debout dans la clarté
                                de l'hiver,
                                son grand cabas coincé sous le bras.






                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal2 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : La cueilleur de plume</h3>
                <h4 class="auteurnouvelle">par Sabine Dupuy</h4>
                <p>Ma douce, mon exquise, quel jour sommes-nous tous les deux ? Octobre, novembre? Je ne
                    sais plus. <br>

                    Quand je t'ai quittée hier soir, tu semblais si lasse. Oh, personne ne nous a vus, rassure-toi, il
                    n'y
                    avait qu'un Vilain pigeon boiteux, se rengorgeant à tes pieds. Quand je t'ai quittée, une feuille
                    morte
                    tombait doucement dans le couchant, l'as-tu sentie t'effleurer l'épaule ?

                    ...</p>
                <div class="tourbutton">
                    <button @click="showModal2 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2022 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal3 }">
                    <div class="modal-background" @click="showModal3 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Le sablier retourné</h3>
                            <p> — Non <br>
                                Le mot a claqué comme une gifle qu'Awa reçoit en pleine figure. Depuis qu'elles ont
                                quitté le
                                village, la petite n'a pas prononcé une parole, et voilà que son premier mot. <br>
                                — Non ! répète l'enfant en lâchant la main de sa mère. Nora et moi, on reste là.
                                Joignant le geste à la parole, Inaya se laisse choir sur le sable, sa poupée serrée dans
                                ses bras. Pas
                                question d'aller plus loin, Elle n'en peut plus. Des jours et des jours qu'elle marche
                                sans rechigner
                                malgré la peur, la faim, la soif, et la fatigue. Mais se retrouver là, dans la réalité
                                de cet air vif
                                qui la
                                prend à la gorge, et face à toute cette eau qui se soulève en petites dunes aussi
                                mouvantes
                                qu'éblouissantes, la terrifie. <br>
                                — Non Nora et moi on l'attendra ici. <br>
                                Awa soupire. Elle tente de s'accroupir pour raisonner sa fille, mais ses reins sont
                                brisés d'avoir
                                autant marché à son tour elle s'écroule lourdement sur le sable mouillé. <br>
                                — Tout va bien ? s'inquiète un des passeurs en les voyant serrées l'une contre l'autre,
                                sans qu'il
                                ne puisse déterminer laquelle de l'adulte ou de l'enfant soutient l'autre. <br>
                                — Ça va, ça va, s'empresse de le chasser Awa. <br>
                                Elle a incliné la tête de sa fille entre ses mains et l'a doucement posée sur ses
                                genoux. L'enfant a les
                                traits tirés. Ses joues ont perdu la rondeur de ses huit ans, et ses yeux leur lumière.
                                Hagards, ils
                                semblent hantés par des images intérieures qui les dévorent. C'est une grande violence
                                que
                                d'arTacher un enfant à sa terre, Awa le sait, qui elle aussi vit cette déchirure au plus
                                profond d'elle-
                                même. Mais avait-elle le choix ? <br>
                                — Je sais combien c'est dur, Inaya. Mais on a réussi ! Regarde : ça va aller maintenant
                                Ça va
                                aller ! Ces gens vont s'occuper de nous et bientôt tout cela ne sera plus qu'un mauvais
                                souvenir... Je sais ce que tu penses aujourd'hui, et c'est normal. Mais on n'avait pas
                                d'autre
                                choix que de partir... Il n'y a plus rien pour nous là-bas... Plus rien que la brutalité
                                de ces
                                hommes qui veulent nous imposer leur loi... Tu as été très courageuse, tu sais. Baba
                                serait si
                                fier de toi. <br>
                                À l'évocation de son père, les yeux d'Inaya se sont éclairés Baba. C'est lui qui lui a
                                fabriqué Nora
                                en taillant des morceaux de son chèche qu'il a cousus ensemble. Qui l'a vêtue d'un pagne
                                de
                                cotonnade indigo. C'est lui aussi qui a tracé sa bouche et ses yeux au charbon de bois.
                                Des yeux
                                rieurs, étirés en croissants de lune comme celui qu'elle aime à contempler le soir
                                assise au creux des
                                dunes à ses côtés, et une bouche ronde comme un soleil. « Nora est une poupée magique »
                                lui a-t-il
                                murmuré à Voreille quand il a déposé la poupée dans ses bras. « Si tu la presses contre
                                ton cœur, où
                                que tu sois, et même très loin des dunes et du village, elle te chantera la chanson du
                                sable »
                                Elle avait ri en l'embrassant très fort : qu'irait-elle faire loin des dunes orangées et
                                du village ?
                                Ce soir-là, alors qu'elle n'arrivait pas à s'endormir, trop émue de serrer la poupée sur
                                son cœur, elle
                                l'avait entendu qui discutait avec Awa, Moussa et le chef du village. Intriguée, elle
                                s'était glissée
                                sans bruit hors de la case, juste au moment où les trois hommes, armés de leurs fusils,
                                s'enfonçaient
                                dans la nuit. <br>
                                — Baba ? Tu vas où ? s'était-elle inquiétée en découvrant les armes. <br>
                                — Retourne vite te coucher, l'avait-il doucement grondé. Je serai de retour demain
                                matin, et toi,
                                Maman et moi, nous aurons une longue route à faire. <br>
                                Pourquoi Baba n'est-il pas là ? Pourquoi ce matin-là n'est-il pas revenu comme il
                                l'avait promis ? <br>
                                Comme si elle avait lu dans ses pensées, Awa presse la poupée entre ses doigts pour
                                faire chanter le
                                sable.. <br>
                                Depuis combien de temps sont-elles parties ? Ni elle ni sa mère ne sauraient le dire. Il
                                y a eu tant et
                                tant de dunes, et d'autres dunes encore. Jamais elle n'aurait pu imaginer qu'il puisse
                                en exister
                                autant. Avec sa mère, elles ont dû cheminer des nuits entières sous les étoiles pour
                                arriver ici,
                                s'abritant la journée à l'ombre d'un rocher, d'un pied d'euphorbe, ou se tapissant dans
                                le sable
                                comme Moussa leur avait montré pour se préserver des brûlures du soleil, et des soldats
                                au drapeau
                                noir. Au début, Inaya s'arTêtait souvent Jetait des regards inquiets en arrière, avant
                                de courir
                                rejoindre sa mère qui, elle, pas une fois ne se retournait Quand allait-il les rattraper
                                ? Parce qu'il
                                ne
                                tarderait pas à les rejoindre, n'est-ce pas ? Il l'avait dit Il lui avait promis. Elle
                                avait guetté sa
                                silhouette au détour de chaque dune, de chaque amas de pierres, au creux de chaque oasis
                                traversée,
                                à chacun de ses réveils... Mais toujours le désert était demeuré immobile. Aucune
                                silhouette
                                Aucune ombre. Aucun bruit autre que le murmure des grains de sable caressés par le vent
                                qui
                                venaient lui rappeler la promesse qu'elle avait faite de rester courageuse quoiqu'il
                                advienne. Alors
                                elle avait continué à marcher aux côtés de sa mère — pressant Nora contre son coeur —
                                jusqu'à ce
                                jour où elles avaient atteint ces étranges dunes mouvantes qui venaient butter sur le
                                sable. <br>
                                — Nous allons traverser, Inaya, lui avait dit Awa. <br>
                                — Traverser ? Traverser quoi ? <br>
                                — La mer. Nous allons traverser pour l'autre bord du monde. <br>
                                Cétait donc ça la mer ? La panique l'avait envahie. Et lui ? Allait-il traverser aussi ?
                                Allait-il
                                réussir
                                à les suivre jusque sur l'autre bord ? Et d'ailleurs, pourquoi n'était-il pas encore
                                arrivé ? Baba est
                                grand, et fort, et marche dix fois plus vite que le font ses petites jambes à elle,
                                alors ? Pourquoi ne
                                les a-t-il pas encore rattrapées ? Et s'il s'était perdu en route ? « Pour éloigner le
                                mauvais sort, la
                                sagesse exige de se taire, de tout garder pour soi. De tenir les présages funestes
                                enfermés derrière
                                la barrière de ses dents » lui avait enseigné le vieux Moussa. Alors elle s'était
                                contentée de lever les
                                yeux vers sa mère à la recherche des réponses aux questions qu'elle n'osait pas poser.
                                Mais sa mère
                                était loin, très loin, le regard fixe rivé à Vhorizon avec au fond des yeux une lumière
                                nouvelle.
                                Est-ce le grincement des volets qui frissonnent sous le souffle du vent ? Inaya se
                                réveille en sursaut.
                                Dans son rêve, elle était encore à bord du bateau, serrant très fort Nora contre son
                                cœur. De la
                                traversée, elle ne garde qu'un souvenir cuisant : celui d'une envie d'eau qui lui
                                gonflait la langue,
                                lui
                                faisait des lèvres de pain craquelé, et sa salive collante, impossible à avaler. Elle
                                avait détesté se
                                sentir ballotée au gré des vagues qui lui avaient donné un vilain mal au cœur. Nora non
                                plus n'avait
                                pas aimé. Elle en avait perdu son sourire, et ses beaux yeux de lune n'avaient pas
                                résisté à l'humidité
                                des embruns. Deux longues trainées lugubres sillonnaient son visage. Qu'aurait pensé
                                Baba en la
                                voyant dans un état pareil ? <br>
                                À la pensée de sa poupée, Inaya se redresse dans son lit à sa recherche. Mais Nora est
                                bien là, à
                                demi enfouie sous son oreiller, qui la regarde de ses grands yeux nouveaux. <br>
                                — Tu as vu comme elle est heureuse, avait dit la femme aux cheveux jaunes en lui brodant
                                deux grands yeux noirs bordés de cils aussi longs que ceux d'un chameau. Et sa bouche
                                couleur de cerise, tu ne la trouves pas jolie ainsi ? <br>
                                — Mais ? Baba ne va pas la reconnaître s'était-elle exclamée, partagée entre admiration
                                et
                                regret des lunes à jamais effacées. <br>
                                Awa n'avait pu retenir un soupir : <br>
                                — Baba ne viendra plus, Inaya. Je te l'ai dit cent fois. Moussa et lui ont tout fait
                                pour protéger
                                le village, mais les rebelles étaient trop nombreux.__ Tu es grande maintenant, tu peux
                                comprendre. Et même si c'est dur, il faut que tu acceptes cette idée et te tourne vers
                                l'avenir. <br>
                                Non, elle ne peut y croire Il a promis. Et Baba a toujours tenu ses promesses Sa poupée
                                dans ses
                                bras, elle s'accoude au rebord de la fenêtre et regarde tour à tour les terrasses des
                                cafés, les
                                boutiques, les balcons des immeubles voisins. La première fois qu'elle a découvert ce
                                spectacle, elle
                                en a attrapé le tournis. Tout cela était si nouveau pour elle qui jusqu'alors n'avait
                                connu que la
                                rondeur des dunes et les horizons infinis. Elle s'était étonnée de toute cette
                                agitation. Ce matin,
                                certaines des fenêtres sont encore fermées, d'autres déjà grandes ouvertes laissent
                                entrevoir des
                                scènes de la vie quotidienne des gens dici. Là, une mère de famille en robe de chambre
                                qui s'affaire
                                à ramasser du linge sec suspendu au balcon. Ici, un vieux monsieur qui arrose ses
                                jardinières Ici
                                encore, un jeune adolescent qui s'étire devant la télévision. Un homme qui avale son bol
                                de café en
                                lisant son journal. Et là encore, une fillette.qui joue à la poupée, un père qui ouvre
                                grand les volets
                                et se penche sur le lit de son fils endormi. A cette dernière vision, Inaya détourne la
                                tête, s'applique
                                à regarder ailleurs. Trop tard Comme au temps où l'harmattan soumait trop fort et
                                décoiffait les
                                dunes, des petits grains de sable s'immiscent dans ses yeux qu'elle doit noyer de
                                quelques larmes. <br>
                                À quoi ressemblera sa vie désormais ? À quoi servira-t-elle ? Elle a Vimpression davoir
                                traversé un
                                miroir. Dètre passée de l'autre côté. D'un côté, il y avait son enfance, l'âge de la
                                confiance, des
                                rires
                                joyeux, des dunes qu'elle dévalait en chantant avec Hamib, Karim, Noura, Fanta, Aminata,
                                Baba et
                                tous les autres.__ Il y avait la maison en banco, le puits, le dattier planté dans la
                                cour dont elle
                                avait
                                promis de s'occuper, le rougeoiement des braises sous la théière. Le bêlement des
                                chevreaux dont
                                elle avait la charge. Les ânes et les chameaux. Les caravanes de sel. Les buissons
                                d'acacias tout
                                autour de l'enclos qu'il fallait bien veiller à consolider tous les matins. Une vie
                                heureuse, rythmée
                                par la course du soleil et les couleurs changeantes du sable De l'autre, il y a cette
                                nouvelle vie qui
                                peu à peu se dessine loin des dunes. Un monde qui lui semble plus aride que le désert,
                                tout
                                dépouillé de rêves, et surtout inutile... Entre les deux, seuls quelques mois se sont
                                écoulés. Si peu.
                                Et pourtant tout est devenu si différent, insipide. Elle a beau regarder par la fenêtre,
                                rien ne
                                parvient
                                à la distraire, ni la lumière vive, ni le bleu intense du ciel, ni même les couleurs de
                                la ville dont la
                                foule si dense l'étourdir. Elle ne cesse de s'étonner du tourbillon incessant de ces
                                gens qui semblent
                                tous aller quelque part, avoir des choses urgentes à faire. Et le vide qu'elle ressent
                                en elle en est
                                plus
                                grand encore. Elle, ce qu'elle veut c'est du sable. Du sable et encore du sable pour y
                                enfouir ses
                                angoisses et combler cette absence qui la ronge Du haut de la tour qui domine la ville,
                                elle attend
                                l'arrivée de son père. Il lui manque tant. Son sourire, ses yeux brillants quand il lui
                                enseignait les
                                secrets du désert. Elle voudrait enfoncer ses doigts dans ses cheveux embroussaillés et
                                entendre son
                                rire, comme lorsqu'il ôtait son chèche et que sa tête s'auréolait d'un roncier noir. «
                                Quoiqu'il arrive,
                                je serai toujours avec toi, répétait-il quand ils s'asseyaient tous les deux au creux
                                des dunes pour
                                regarder le ciel. Personne, tu m'entends ? Personne ne pourra jamais te voler ni le ciel
                                ni le
                                murmure du sable. Et mot, je serai toujours là, dans le ciel, les nuages, et le sable. »
                                <br>
                                Et pourtant les jours passent. Le temps s'étire lentement comme un grand serpent mou au
                                soleil,
                                repoussant toujours plus en arrière son enfance. Peu à peu, le visage de Baba se change
                                en souvenir.
                                elle n'est plus certaine de son sourire, de sa silhouette penchée au dessus du puits.
                                Déjà, elle
                                ne sait plus réinventer sa voix. Il glisse lentement vers le fond du sablier, rêve de
                                sable que ses
                                doigts ne savent plus retenir. Seule Nora continue de chanter les dunes. <br>
                                Sur son balcon, la femme en robe de chambre a appelé son Compagnon, et les yeux levés
                                vers le
                                ciel, elle invective si fort les nuages cotonneux qu'elle extirpe Inaya de sa rêverie.
                                Comment peut-on
                                s'adresser au ciel avec autant de malveillance ? s'interroge la fillette tout en
                                repoussant de la main
                                une mouche venue lui chatouiller le front. <br>
                                Et soudain elle le voit Là, il est là... Elle n'en croit pas ses yeux... <br>
                                Sans prendre ni le temps de s'habiller ni celui d'enfiler ses chaussures, elle descend
                                quatre à quatre
                                les étages de l'immeuble, traverse en courant le hall d'entrée où Awa a déjà entamé sa
                                matinée de
                                Viens Mama, viens vite hurle-t-elle saisissant la main de sa mère. <br>
                                Mais d'où vient cette lumière soudaine qui avive le regard d'Inaya, l'illumine d'un
                                espoir soudain
                                retrouvé et irradie le petit visage depuis si longtemps chiffonné ? Sans lui donner le
                                temps de
                                ses questions, l'enfant entraine vers l'extérieur, et pieds nus, en chemise de nuit et
                                les
                                cheveux tout en broussaille, elle Sur le trottoir qu'elle caresse délicatement l'oeil
                                intrigué des passants. <br>
                                — Regarde <br>
                                ElIe redresse, et dans un sourire éclatant tend es mains vers Awa ses petites paumes
                                sont
                                colorées d'une fine pellicule orangée Du sable, Du sable du Sahara que la nuit est venue
                                déposer en
                                silence sur les trottoirs, les voitures, les balcons, les réords des fenêtres
                                — Il nmts a retrouvées, Awa !
                                L'enfant exulte ElIe recueille précieusement une pincée de sable dans le creux de sa
                                main — pour le
                                montrer à Nora restée là-haut — sous le regard attendri d'Avva qui remercie le ciel : sa
                                fille est
                                l'orée d'un nouveau départ. ElIe le sent, elle le lit dans ses d'enfant levés vers elle
                                Des yeux
                                confiants. Des d'un gris bleuté, veloutés comme l'aile d'un papillon de nuit au sortir
                                de sa
                                chrysalide. Tout les yeux de son père, songe Awa qui a repris son repoussant
                                délibérément dans les coins du passé; une poussière imaginaire. <br>

                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal3 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Le sablier retourné</h3>
                <h4 class="auteurnouvelle">par Marie-Christine QUENTIN</h4>
                <p>— Non <br>
                    Le mot a claqué comme une gifle qu'Awa reçoit en pleine figure. Depuis qu'elles ont quitté le
                    village, la petite n'a pas prononcé une parole, et voilà que son premier mot. <br>
                    — Non ! répète l'enfant en lâchant la main de sa mère. Nora et moi, on reste là.
                    Joignant le geste à la parole, Inaya se laisse choir sur le sable, sa poupée serrée dans ses bras.
                    Pas
                    question d'aller plus loin, Elle n'en peut plus. Des jours et des jours qu'elle marche sans
                    rechigner
                    ...</p>
                <div class="tourbutton">
                    <button @click="showModal3 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal4 }">
                    <div class="modal-background" @click="showModal4 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Mention de Prix : La vie radieuse</h3>
                            <p> On se lève tous les deux. Comme ça, d'un coup, en plein milieu de la nuit. Ensemble,
                                sans se causer beaucoup. <br>
                                On se couvre un peu les épaules et on sort. On marche au jugé, serrés, presqu'à
                                l'aveugle, dans la noirceur des heures. Et la moiteur montante. L'obscurité nous inonde
                                jusqu'aux épaules, tant l' air semble dense et sa matière opaque. On marche encore, le
                                pas fragile
                                et l'œil perdu dans ce qui reste à voir du paysage. Du noir, y'en a partout alentour. Du
                                noir
                                épais qui avale tout, les arbres, les bosquets, la campagne et les vieilles bâtisses du
                                coin.
                                Tout, sauf le ciel gigantesque qui bouillonne d'étoiles. <br>
                                Des grains vifs et lumineux qui ensemencent la prairie céleste comme une poussée
                                végétale subite, une éclosion démesurée de fleurs sauvages. On se pose là, à plat, les
                                mains
                                calées sous la nuque, dans l'herbe encore tiède et on regarde en l'air. Rien que ça. On
                                ne fait
                                rien que ça. Dans le silence profond du vide, rien que regarder au-dessus, sans bouger.
                                Pour
                                tout voir. Autrement. Admirer et comprendre à l'envers. L'éclat et la pâleur, la
                                distance et la
                                proximité, les nuées immobiles, le fouillis et l'alignement, l'ordre et le chaos, les
                                mondes
                                lumineux qui s' approchent. Et qui s'éloignent à mesure qu'on pense atteindre et toucher
                                toutes
                                ces foutues étoiles, juste une fois. Une dernière fois. <br>
                                On ne dit plus rien. <br>
                                Serrés l' un à l' autre dans le champ de ténèbres, le regard captif, les membres gourds.
                                Et
                                l'esprit défait. On respire bas, on somnole en duo, on s'ahurit à quatre yeux, quatre
                                lucarnes
                                grandes ouvertes, de spectateurs naïfs, éblouis et tétanisés. Parce qu'en haut, la
                                sérénité des
                                astres nous envoûte autant que nous fascine leur mouvement perpétuel. La voie lactée,
                                les nuées
                                innombrables, les festons infinis, les ribambelles sans limites et tous les mouchetis
                                lumineux
                                qui blanchissent la nuit du dessus nous aimantent la vue jusqu' à nous emporter dans des
                                regrets
                                impossibles. <br>
                                On finit par s'ensommeiller de légèreté, sans le vouloir, l'esprit ballant et le
                                palpitant en
                                pause. Le pif au ciel et le col serré. Avec des rêves sans raison et des raisons sans
                                rêves. Avec
                                des idées perdues et des virées brutales vers des retours soudains, ceux du passé,
                                proche et
                                lointain, des trajectoires insensées de notre vie qui nous ont menés ici, de la vie
                                heureuse qui se
                                barre et de toutes les sensations perdues qu'on ne retrouvera plus.
                                Les pensées des condamnés, forcément, ignorent la prospective, l'urgence les contraint
                                à voyager à reculons, du plus neuf souvenir jusqu'au plus lointain renvoi primitif. De
                                là, ils
                                déroulent, ils délient, ils dénouent. Et pleurent chaque seconde échappée autant que les
                                dizaines
                                d'années arrachées par poignées au monde d'avant. <br>
                                Ici aussi, sous le plafond de guirlandes suspendues, nos vies mouvementées, à Rose et
                                moi, défilent au ralenti, piégées cette fois dans l'immobilité de notre demi-sommeil.
                                Les
                                souvenirs flottent sous le firmament. Ils égrènent un déroulé onirique qui perd sa
                                mesure
                                linéaire. Les bribes et les lambeaux de mémoire se bousculent sans cohérence. Je revois
                                tout
                                filer, en désordre. Rose et moi, d'hier et d'autrefois. Je suis là, le premier soir,
                                tremblant, je la
                                touche avec des pognes de jeunot, des envies de gamin. Ses bras s'allongent et
                                s'accrochent
                                aux miens. L'air est doux et fluide,je l' entends siffler aux oreilles, il m'accompagne.
                                Elle sourit
                                et m'appelle d'une œillade. Son calme, sa force et sa douceur apaisent mes frissons
                                quand le
                                désir me gonfle d'audace et que mes muscles se raidissent. Je fais le dur et le fier, je
                                me tends,
                                je la prends, je la tiens, nos corps se lient, s'emboîtent et s'emmêlent, nos jambes
                                s'enchevêtrent. Je suis en elle. Je m'envole avec elle. J'oublie la gravité. J'ai la
                                tête à l'envers
                                et le cul par-dessus tête. Tout est si lent et si rapide à la fois, si mécanique et si
                                gracieux, c'est
                                l'harmonie absolue des planètes. Après, elle sourit, toujours et encore. Épanouie comme
                                personne, ensoleillée et radieuse à nouveau, sous la lumière. Elle garde le teint
                                radieux, les
                                quinquets radieux, le rire radieux. Les rides du temps, la fatigue des jours, l' usure
                                des membres
                                et toutes les saletés inventées pour abimer les corps, Rose s'en fout. Le sien porte un
                                nom de
                                fleur lumineuse qui ne se flétrirajamais, je le sais. Rose est radieuse depuis le
                                premier jour. Les
                                autres jours ne la démentent pas, ils sont I 'évidence et la suite logique, de la
                                première carambole
                                à la dernière cabriole. Ils sont à chaque fois l'émotion retrouvée, la trouille et
                                l'envie au ventre.
                                Ils sont l'instabilité et l'équilibre complet de nos existences. <br>
                                Je revois la lente grimpée des émotions. puis, le va et vient, le roulis de nos membres,
                                le rythme, la symétrie, l'enchaînement de virtuoses. Je revois l'accord tacite qui guide
                                le corps
                                à corps, le choc léger de la rencontre, la connivence tremblante du préliminaire, la
                                pulsion qui
                                nous unit, le silence qui précède le petit cri susurré, le sang au visage, la suée sur
                                les tempes, le
                                soupir qui suit la culbute et la joie, la joie entière qui vient toujours après. <br>
                                Je cligne, je palpite. Des milliers d'images s'accumulent, des fragments se collent en
                                disparate, des puzzles se défont. Rose et moi, l'un dans l'autre, si serrés, si unis à
                                ne faire qu'un
                                seul astre, qu'une seule planète en apesanteur. D'autres apparaissent en accéléré. Des
                                arabesques insensées, des perspectives grandioses, des horizons impossibles. Des tournis
                                improbables aux élans endiablés, et chaque soir, des nuits d'envols torrides, des
                                périples
                                renouvelés pour s'envoyer en l'air sans regret, sans lassitude ni fatigue, avec la même
                                envie
                                répétée, la même grâce retrouvée. Et la même passion de la démesure. <br>
                                J'ouvre les yeux et je mate encore la voûte des cieux qui ne s ' arrête plus de pétiller
                                pour
                                nous comme un champagne glacé. Je pense à celui qui nous rafraichissait la gorge quand
                                l'épuisement des chairs venait à bout de nos soirs. Ici, les éclats du ciel tissent un
                                costard de
                                paillettes, dessinent un décor de tète, entament une fanfare visuelle. <br>
                                Musique ! <br>
                                Rose papillote du regard. Je me demande si elle voit la même chose que moi. Si elle sent
                                mon désir de la saisir encore, si elle voit encore mes bras qui l'accueillent, mes
                                jambes qui
                                frémissent, mes mains qui s'ouvrent. <br>
                                Musique ! <br>
                                Oui, je sens ses doigts qui me serrent. M' attirent, me saisissent. Ses paumes vieillies
                                qui
                                mes soutiennent encore. Je sens son soume à mon cou, pareil qu'autrefois. J'entends sa
                                respiration, comme avant. <br>
                                Musique ! La musique en fanfare résonne encore dans le silence de la nuit comme un
                                écho à perpète, une persistance auditive. <br>
                                C'était elle qui nous guidait, elle qui donnait le tempo, elle qui nous soutenait
                                jusqu'au
                                bout, entre deux roulements sonores. Pas un geste sans elle. Pas un pas, pas un
                                mouvement, pas
                                un prélude sans elle. Je veux m'apprêter, me lever, bondir, gesticuler et sauter à
                                nouveau
                                puisque la zizique est là, à mes oreilles. Les cuivres, les fifres et les percussions,
                                je les entends
                                siffler, cymbaler et tambouriner comme des cœurs en chamade...
                                Oui, voilà le tambour qui m'appelle. Je veux chavirer encore, me balancer sans retenue,
                                valdinguer en artiste dans les bras de Rose. <br>
                                Mais je m'endors. <br>
                                Et puis. <br>
                                Et puis, raboule enfin le petit jour suivant, l'ennemi sournois des amants et
                                l'adversaire
                                déclaré des saltimbanques. Tous les matins du monde sont parait-il des remèdes commodes
                                à
                                l'affliction, des sédatifs de l'angoisse. Des porte-sentiments fastoches, forcés par les
                                assauts
                                malins de la nature et ses fanfaronnades rassurantes. Les décors de l'aube ont été
                                inventés pour
                                ça, soulager l'œil hagard des noctambules. Les gueulantes de piaffeux, les perlouses de
                                rosée
                                pour friser les cheveux, les rayons apaisants, les bouffées de fraicheur au menton et
                                toutes les
                                manigances diaboliques pour redonner la fadeur aux jours que les nuits précédentes
                                avaient
                                enflammés. <br>
                                Alors, quand ce maudit matin vient nous caresser les joues, qu'un soleil pâlot grimpe la
                                colline, que la cime des arbres frémit du toupet et que le tintamarre montant remplit sa
                                marmite
                                quotidienne, je comprends que la messe est dite. Et pas en latin d'église. Avec
                                l'injonction
                                définitive des causes désespérées.
                                Ici, dans la campagne retirée qui nous héberge, je m 'éveille aujour mais il n'y a plus
                                de
                                ciel. Tout me revient. Tout ce que j'ai pressenti de cette aube fatale. Tout s'éclaire,
                                cette fois
                                sans projecteur ni musique d'orchestre. Le maestro a disparu. Les sunlights sont
                                éteints. Le
                                public a déserté les bancs. J'y suis. Je suis au lendemain de notre dernière prestation.
                                De mon
                                dernier envol. Et de notre ultime voltige. <br>
                                Et je vois. <br>
                                Je vois la silhouette massive du chapiteau qui s'abat, je vois la bâche immense
                                s' écrouler, les toiles s'affaler comme la misaine d'un voilier sur une mer en bonace.
                                Je vois les
                                manutentionnaires s'activer à descendre les mâts, je les vois enrouler les cordages,
                                défaire les
                                pinces et les sangles, je vois s'abattre les derniers poteaux, les derniers filets.
                                J'entends les
                                cris,
                                les ordres, les conseils, les bruits mécaniques. Je vois la tristesse du vide et de
                                l'abandon.
                                IYici,je vois la mise à bas de mon monde, le terme résolu d'une folle aventure. Le jour
                                monte et mon cirque s'éteint. Notre espace aérien se clôt définitivement sur nos folles
                                acrobaties. L'ivresse circassienne disparait sous un jour sans clarté, dévorée par le
                                monstre
                                gourmand de la modernité. La piste aux étoiles s'étiole comme une fleur fanée. Une
                                plante
                                caduque qui ne s'éveillera plus à de nouveaux printemps. <br>
                                On nous appelait « les étoiles radieuses » , infatigables artistes du trapèze volant,
                                nous
                                étions les princes du ciel, les vedettes incontestées du barnum itinérant. Rose et moi,
                                moi et
                                Rose. Voltigeuse et porteur, porteuse et voltigeur, en alternant les places et les
                                rôles, notre duo
                                affichait, sur nos agrès d'altitude, la force solide des partageurs de danger. Nos
                                enchainements
                                frisaient l'impossible. Nous avions la passion du péril, nous avions l'ivresse sans
                                limite des
                                équilibristes du risque. Le ballant, le pendulaire, le vol et l'attrape, la
                                contre-volée, les doubles
                                vrilles, la pirouette au triple saut périlleux et toutes les figures inventées à se
                                tenir ensemble,
                                toutes nos cascades vertigineuses faisaient frissonner de plaisir un public avide, le
                                front levé, le
                                souffle coupé et les mains serrées. pour lui, nous étions des comètes égarées dans le
                                vide sidéral.
                                Nous étions la prodigieuse galaxie. Nous étions le cosmos enchanté rien qu'à nous deux.
                                <br>
                                J'ai froid. <br>
                                Ici, dans le placard de notre chambre, à la maison de retraite, Rose et moi gardons la
                                barre et les cordes de nos tournées, rangées telles des photosjaunies dans le grenier
                                poussiéreux
                                de nos souvenirs, avec nos capes de parade, nos maillots pailletés aux perles cousues et
                                nos
                                chaussons de soie. Avons enfoui le reste nos émotions dans les tiroirs sans fond de nos
                                cervelles
                                d'acrobates. <br>
                                Mais cette fois, je tremble. <br>
                                J'ai si froid. Je me redresse sur mes coudes et me lève. À peine debout, le vertige
                                m'assaille comme jamais. De si près, le sol m'attire. Il tourne, je vacille. Il
                                s'approche, je vrille,
                                je m'effondre de ma hauteur, moi le roi de la haute voltige, je suis à terre, brisé par
                                l'âge. Je
                                n'entends pas de cris d'enfants, ni de clameurs d'adultes, ni aucun bravo. J'hallucine,
                                sans
                                doute. J'écoute au mieux. Je ne capte pas d'applaudissements, pas même le souffle retenu
                                des
                                spectateurs, je reçois juste la voix de Rose qui m'appelle. <br>
                                Je chavire, je grelotte. Je ne sais plus combien de temps j'ai passé ici, dans cette
                                prairie
                                épaisse. J'entends au loin le mugissement de camions, ceux qui emportent au rebut le
                                cirque
                                ambulant, probablement. Et puis, viens l'autorité médicale qui nous admoneste :
                                Allez, Messieurs Dames, il faut rentrer, c'est dangereux, vous allez prendre froid !
                                <br>
                                Allongé à nouveau, je vois ma partenaire qui sourit, debout, en entendant ces mots. Il y
                                a des roses jaunes sur le devant de sa robe. Elle me serre des bandelettes aux poignets
                                et, de sa
                                poche, extrait une poignée de poudre qu'elle agite entre ses mains comme de la folle
                                farine.
                                Elle me refile ma part de magnésie. Comme autrefois, je frotte mes paumes longuement
                                jusqu' à
                                la sentir au creux. Je suis prêt. Elle me fait signe des yeux, nos bras se tendent l'un
                                vers l'autre.
                                S'attirent. L'attraction terrestre a disparu. Je m'élance. Je m'envole. Je la rejoins.
                                <br>
                                Musique !
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal4 = false"></button>
                </div>
                <h3 class="titrenouvelle">La vie radieuse</h3>
                <h4 class="auteurnouvelle">par Gilles Verdet</h4>
                <p>On se lève tous les deux. Comme ça, d'un coup, en plein milieu de la nuit. Ensemble,
                    sans se causer beaucoup. <br>
                    On se couvre un peu les épaules et on sort. On marche au jugé, serrés, presqu'à
                    l'aveugle, dans la noirceur des heures. Et la moiteur montante. L'obscurité nous inonde
                    jusqu'aux épaules, tant l' air semble dense et sa matière opaque. On marche encore, le pas fragile

                    ...</p>
                <div class="tourbutton">
                    <button @click="showModal4 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2021 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal5 }">
                    <div class="modal-background" @click="showModal5 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Jeannette</h3>
                            <p>
                                M'Bé Rouen se trouve à quelques encablures de Nouméa. Cette route
                                corallien est couvert de sable et de maigres buissons. À quatre-vingt-cinq ans,
                                je suis comme eux, racornie par les vents âpres et brûlants. ,J'aime cet endroit.
                                Je m'y sens bien, sans doute à cause de mes souvenir d'enfance et de l'eau
                                turquoise. Cette couleur m'apaise. Je m'absente régulièrement de l'hospice
                                pour m'évader sur la plage. Les vagues lèchent mes pieds, puis mes jambes.
                                Elles finissent par me saisirent et me reveille. Je me retrouve alors, dans cette
                                chambre que je trouve hostile. Viendra un jour où je ne sentirai plus la
                                fraîcheur de l'eau, et la marée montante m 'engloutira, comme l'oubli engloutit
                                ma mémoire. <br>
                                Je suis souvent incapable de me rappeler ce qui s'est passé une seconde
                                plus tôt, mais parfois, je me souviens de l'époque Où j'étais gamine. Avec
                                l'arrivée des premières chaleurs s'ouvrait la saison de la pêche à la traîne. Mon
                                père m 'y emmenait fréquemment. Ma panoplie de jeu, c'était les bobines de fil,
                                pochettes d'émerillons et d'agrafes en inox, les hameçons de toutes sortes.
                                Mes poupées, c'était les ieurres. J'habillais leurs jupes de plumes ou de
                                flamems en nylon pour cacher leur crochet meurtrier. J'y fixais parfois une
                                amorce en forme de petit poisson. Ce qui me fascinait le plus dans les appâts,
                                c'était leurs têtes cylindriques décorées d'un œil. Leur regard était vide comme
                                voilé, au millieu des gaffes et des dégorgeoirs. Tandis que nous voguions vers le
                                large, je scrutais la surface de à la recherche d'un banc d'exocets. Je
                                guettais leurs envolées éphémères, en gerbes argentées, au-dessus des flots.
                                Les daurades coryphènes en sont friandes, elles les suivent à faible profondeur.
                                Quand je repérais, mon père calait sa canne à l'arrière de l'embarcation. Le
                                leurre ondulait au bout de la ligne comme un poisson en fuite. J'attendais avec
                                impatience, le mornent où les stridulations du moulinet retentiraient, car mon
                                père me confierait les commandes après avoir ralenti et positionné le bateau
                                confort à la vague. J'étais heureuse. Je me sentais forte. Tandis qu'il capturait
                                Ya daurade et lui piquait la tête avec la gaffe, j'avais la responsabilité du
                                pilotage. Je ne me rappelle que ça. <br>
                                Aujourd'hui, je ne pilote plus rien, pas même mon existence. Elle
                                m'échappe. À l'hospice, on décide de tout pour moi. Je crois que c'est à cause
                                de mes crises d'amnésie ; elles me tuent à petit feu. Je n'ai pas peur de la mort.
                                Tai peur d'oublier la vie. De devenir une inconnue à moi-même. Je fouille à
                                tâtons dans ma mémoire sans trouver ce que je cherche. Je poursuis des
                                images fuyantes sans jamais réussir à les saisir. Ma tête se transforme en
                                cimetière. Mes souvenirs sont ensevelis sous des tombes anonymes. Je
                                déambule au milieu des allées sans rien reconnaître. Sur les stèles, il n'y a ni
                                épitaphes ni plaques funéraires. II n'y a pas de photos, non plus. L'oubli
                                emporte les noms et aussi les visages. C'est un vrai fossoyeur. Ses pas sont
                                toujours silencieux. Ils me terrorisent. <br>
                                J'ai consigné tous mes souvenirs dans un cahier d'écolier à lignes Seyes,
                                et une marge à gauche délimitée par un trait rouge. Je passe souvent mon doigt
                                dessus comme si je traçais un sillon dans le sable. Après notre coup de pêche,
                                nous accostions de coutume sur l'îlot M'Bé Kouen. Nous en avions fait un
                                rituel. C'est sans doute pour ça que j'ai gardé cet endroit en mémoire. Pendant
                                que mon père rangeait les lignes, je jouais avec la nature. C'est fou comme un
                                petit rien amuse les enfants. Un coquillage, une menue branche, quelques
                                feuilles de pourpier sauvage, et la réalité s'efface devant l'imaginaire. Quand je
                                consulte mon cahier, je découvre des choses que je ne me rappelle pas. J'ai la
                                sensation étrange qu'il s'agit de la vie d'une autre personne. Bientôt, je ne
                                saurai peut-être plus lire. Je divaguerai dans la brume comme un fantôme. Je
                                suis presque morte. Je m'accroche désespérément à ce cahier qui est mon
                                ultime lien avec l'existence. <br>
                                Il y a un passage à propos de mon fiancé. Je suis retournée sur l'îlot de
                                mon enfance beaucoup plus tard, avec lui. C'est là qu'il m'a demandée en
                                mariage. J'ai cru qu'on vivrait toujours ensemble, mais je suis toute seule. Où
                                est mon mari ? Pourquoi est-il absent ? Lui est-il arrivé quelque chose ? Peut-
                                être qu'il est à M'Bé Kouen ? C'est sans doute pour cette raison que j'y vais si
                                souvent. Je l'attends sur la plage, assise, les pieds dans l'eau. Elle est tiède et je
                                m'y sens bien. Les pages suivantes ont été déchirées puis recollées dans le
                                désordre. Tout est sens dessus dessous. Mes pensées sont pareilles.
                                Certains jours mon esprit est moins embrouillé. J'en profite pour classer
                                mes notes. Je veux laisser une trace écrite de ma vie avant que je ne sombre
                                dans le néant. Comme ça, j'existerai encore quelque part. Dans ce cahier
                                d'écolier. Je dois faire vite, car je risque d'oublier de le faire. J'oublie le passé,
                                et perds la mémoire du futur. Je suis prisonnière du présent. Son étau se
                                resserre sur moi, il va comprimer ma conscience jusqu'à ce qu'elle ne devienne
                                plus qu'un point égaré au milieu de nulle part. Un moment viendra où mes
                                pieds baigneront en permanence dans la même vague. <br>
                                Un article de journal est collé sur une page. II raconte qu'un homme est
                                mort en pleine mer. Ce soir-là, un violent grain a surpris un couple de
                                navigateurs. Le vent a frappé le bateau si fort que le pilote est tombé à l'eau et
                                a dérivé, emporté par le courant. Sa femme a passé la nuit à l'attendre sur l'îlot
                                M'Bé Kouen. La police maritime l'a récupérée au petit matin. Elle était assise
                                sur la plage et tenait dans la main une gaffe couverte de sang. ElIe s'appelait
                                Jeannette. <br>
                                L'infirmière trouve que je m'absente de plus en plus longtemps. La
                                Solitude me fait peur. L'incompréhension aussi. Quand je lui demande des
                                nouvelles de mon mari, elle me répond toujours la même chose : « Vous parlez
                                dé lui dans votre cahier ». Elle l'ouvre et je lis que je ne suis jamais
                                sur l'îlot... Comment s'appelle-t-il déjà ? Je n'y ai pas remis les pieds à cause
                                d'un mort. Le corps d'un homme gisait sur le rivage. Autour de sa tête, le sable
                                était rouge. Du sang avait coloré le fond d'un sillon rectiligne creusé par un
                                doigt. Assise sur la plage, lesjambes dans l'eau, une femme attendait. ElIe était
                                là depuis si longtemps qu'elle ne sentait plus la fraîcheur des vagues. Je ne sais
                                pas ce qu'elle est devenue. Après, les pages de mon cahier sont blanches.

                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal5 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Jeannette</h3>
                <h4 class="auteurnouvelle">par Christine Baille</h4>
                <p>M'Bé Rouen se trouve à quelques encablures de Nouméa. Ce récif
                    corallien est couvert de sable et de maigres buissons. À quatre-vingt-cinq ans,
                    je suis comme eux, racornie par les vents âpres et brûlants. ,J'aime cet endroit.
                    Je m'y sens bien, sans doute à cause de mes souvenir d'enfance et de l'eau
                    turquoise. Cette couleur m'apaise. Je m'absente régulièrement de l'hospice
                    pour...</p>
                <div class="tourbutton">
                    <button @click="showModal5 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal6 }">
                    <div class="modal-background" @click="showModal6 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">L'enfant</h3>
                            <p>Pourquoi ils ne m'entendent pas? J'ai beau pousser de petits cris, m'agiter en tous sens,
                                personne
                                ne s'occupe de moi. <br>
                                Apparemment je suis un beau petit garçon. J'ai 9 ans. Les photos montrent un visage
                                carré, des
                                cheveux blonds légerement ondulés, des yeux verts. Je ne suis pas très grand. <br>
                                Le jardin est tout jaune. Manque de pluie. Soleil trop chaud. Voilà un bon moment que je
                                suis sous
                                le vieux chêne. Papa, maman et leurs amis discutent fort, assis autour de la grande
                                table en bois. Ils
                                finissent le dessert et les bouteilles de rosé sont presque vides. Moi, on m'a donné à
                                manger
                                tout le monde, mis à l'ombre sur la couverture où je suis allongé <br>
                                Une jolie dame, bien habillée, se tourne vers moi et demande si on peut me faire taire.
                                <br>
                                - Comment vous supportez ça ? Dit-elle. <br>
                                Le « ça » devait sans doute me désigner. <br>
                                - On est habitués. II faut attendre qu'il finisse de piquer sa crise, à rétorqué papa
                                <br>
                                - Juliette, emmenez Victor dans sa chambre, il finira bien par se calmer. <br>
                                Ma douce Juliette, la domestique dévouée qui me prend dans ses bras et dont le contact
                                chaud me
                                rassure. <br>
                                Me voici embarqué la maison. ElIe me porte pour aller plus vite car je ne sais pas
                                marcher
                                correctement mais je ne pèse pas lourd. En fait, je ne sais pas faire grand chose tout
                                seul. Pourtant
                                je comprends tout ce qu'ils disent, même si parfois je ne m'en souviens pas. <br>
                                Il paraît que c'est une maladie que j'ai eue bébé et qui a beaucoup fait pleurer mes
                                parents.
                                Maintenant ils sont justes tristes et espèrent que je vais m'améliorer. <br>
                                Chaque matin. un monsieur en blouse blanche me malaxe bras et jambes, me force à
                                m'asseoir puis à tenir debout plusieurs fois suite. Parfois je le tape car il me fait
                                mal et je ne
                                peux
                                pas lui dire avec des mots. Alors il se fâche et je crie moi aussi. Je ne l'aime pas.
                                <br>
                                Quand il a fini, c'est une jeune femme, Céline, une ergothérapeute, qui prend le relais.
                                Elle parle
                                lentement et je dois essayer d'articuler les mots. Sans succès. Elle guide ma main
                                droite avec
                                laquelle je dois tenir un crayon et tracer traits. Difficile. <br>
                                Nous jouons avec des images. C' est le moment que je préfère. Elle me dit des mots
                                gentils quand
                                j'ai reconnu la forme ou la couleur. ElIe a toujours un bonbon ou un bout de choolat
                                qu'elle me
                                glisse discrètement dans la bouche quand la séance terminée. J'adore ça! <br>
                                Après je suis fatigué et je me repose dans mon lit en bois blanc, entouré de peluches.
                                Je me
                                recroqueville en serrant fort un éléphant gris tout que ma grande soeur Caroline a
                                raporté de
                                son voyage au Sri Lanka. <br>
                                Elle est trop belle Caroline avec ses longs cheveux châtain qu'elle attache avec une
                                grosse pince
                                quand il fait chaud. Ses yeux ont une couleur de noisette et on dirait qu'un fil les
                                tire vers le côté
                                quand elle sourit. C'est une imello. Docteur en je sais plus quoi. Elle va dans plein de
                                pays
                                étudier le comportent des animaux. <br>
                                Je repense à toutes les photcri qu 'elle m'a montrées et aux beaux paysages où
                                j'aimerais aller. Mais
                                voilà, je ne vais nulle part, hormis le parc du manoir. <br>
                                On a beaucoup de différence d'âge avec Caroline, 20 ans, et mon frère Antonin 18 ans.
                                Moi, on ne
                                m'attendait pas. Une mauvaise surprise pour des parents déjà âgés. Une erreurquoi! Je
                                n'aurais
                                pas dû m'accrocher. <br>
                                A quoi ça sert de vivre sans pouvoir parler, courir, s'amuser. Des fois je pleure et des
                                fois j'enrage
                                et
                                je décide d'arriver à être comme tous mes cousins quand ils viennent en dans notre
                                grande maison. <br>
                                L'après-midi selon le temps. je peux sortir. Juliette m' assoie dans le fauteuil roulant
                                et nous
                                parcourons les allées tout autour du manoir. Au début je n'aimais ce petit château. Trop
                                grand.
                                trop vieux, avec ses murs aux pierres marron et son toit d'ardoises grises. Mais avec
                                années la glycine en a caché une partie et la vigne vierge qui court le long de la
                                facade lui a
                                redonné des couleurs. Surtout l'automne, on dirait qu'un grand feu s'est glissé tout le
                                long, rouge.
                                jaune, orange.
                                Quand je suis dans ma chambre au troisième et dernier étage (moins bruyant), par la baie
                                vitrée qui a
                                remplacé la fenêtre à croisillons pour laisser entrer la lumière, je vois les thuyas qui
                                bordent
                                l'allée de graviers blancs. Plus loin, j'aperçois même les prés où pr beau temps, trois
                                chevaux se promènent, mangent, jouent et galopent. Je m'imagine sur leur dos. D'ailleurs
                                papa a dit
                                que ce serait bien pour moi de m'inscrire au centre équestre du village pour faire la
                                thérapie.
                                J'attends toujours. <br>
                                Lorsque nous faisons notre petit tour dehors, j'aime respier fort pour sentir odeurs de
                                la terre.
                                Surtout quand il a plu la veille mais cet été la pluie s'est faite rare. Reste le parfum
                                des roses qui
                                me
                                rappelle celui de maman. Dans le salon-bibliothèque où elle se repose dans son beau
                                fauteuil en cuir
                                garni d'une couverture toute douce, elle demande parfois à Juliette d'aller me chercher.
                                Maman me pose
                                alors sur ses genoux et je peux enfouir ma tête son cou. C'est un bonheur intense mais
                                court car je l'agace vite par mes "couinements". En fait elle supporte pas grand-chose.
                                J'aurais
                                aimé avoir un chien ou un chat. Non, trop dangereux pour moi...ou trop salissant.
                                Caroline dit qu'il
                                faut être gentil car maman aussi est malade. Elle est pleine tristesse, et prnd beaucoup
                                de
                                médicaments pour ne plus l'être et sa vie n'est pas facile. La mienne mienne non plus je
                                voudrais lui
                                dire. <br>
                                Aujourd'hui c'est la fête. On a plié mon fauteuil roulant dans la voiture. Je fais
                                partie du voyage.
                                C'est incroyable. <br>
                                On va pique-niquer au bord de la mer. Je ne plus où on habite mais je sais que ce n'est
                                pas très loin. Je suis coincé à l'arrière de la voiture (que tout le monde trouve très
                                belle) dans un
                                siège spécial à côté de Juliette. Marnan est à l'avant. <br>
                                Elle a mis une jolie robe jaune et quand elle se recoiffe dans la glace accrochée en
                                haut du
                                pare-brise, j'aperçois son beau regard doré où brille une petite inhabituelle. <br>
                                Papa conduit en fredonnant un air d'opéra que donne la radio.
                                Ils ont trouvé une petite crique tranquille. Il est midi. Parasol obligé avec ce soleil.
                                Table de camping recouverte d'une nappe et chaises assorties. Déballage du repas. C'est
                                prêt. Sauf que
                                je
                                suis toujours dans la voiture garée sur le bord de la route. <br>
                                Apparemment, le fauteuil roulant pose un problème dans le sable Où me mettre ? <br>
                                Juliette vient me chercher. Elle m 'assoit sur une couverture me cale contre elle et
                                fera mon dossier.
                                Ça me va.. Je fais partie de la famille sous ce grand parasol. Le poulet rôti sent bon.
                                Juliette me
                                donne des petits bouts de viande hachés. Un léger vent se lève et l'odeur salée la mer
                                arrive jusqu'à
                                mes
                                narines Je suis heureux. Je ferme les yeux et je tends les mains devant moi pour sentir
                                la fraîcheur
                                Un cri ! Victor, qu'est-ce que tu as ? J'ouvre les yeux. Maman est devant moi, inquiète.
                                J'esquisse un sourire pour la rassurer. Et je comprend pourquoi elle est ainsi, elle
                                porte ma
                                souffrance. <br>
                                Après manger, mes parents vont se tremper les pieds dans l'eau et se balader le long de
                                la plage.
                                On me couche la sieste sous la surveillance de Juliette. C'est bizarre sa vie. Dépendre
                                d'autres
                                personnes. Pourtant elle fait jeune. Je ne sais pas sob âge mais elle n'a les cheveux
                                grisonnants
                                comme papa. Elle est surement en bonne santé vu la grosseur de son corps. Pourquoi elle
                                n'a pas
                                d'enfants, de mari et qu'elle reste tout le au manoir ? Je ne peux même pas lui
                                demander.
                                J'espère qu'un jour, grâce à Céline, j'arriverai à écrire. <br>
                                Lorsque je réveille, est plié, rangé dans la voiture. A moi, mainteant. <br>
                                inurn6r. Pourvu au'il y en ait d'autres. Mais, j'ai parlé trop vite. <br>
                                La fin de l'été est arrivé. Mon frère Antonin est venu passer le mois de juliet à la
                                maison. C'est
                                portrait craché de papa, en plus jeune. Grand, mince, les cheveux bruns épais, avec une
                                mèche
                                tombant sur de petits yeux noirs. <br>
                                Il est étudiant à Paris. Il veut devenir avocat. Ce doit être pour ça qu'il parle tout
                                le tempe. Il me
                                soûle. Il a décidé de mon "évolution" : <br>
                                - Vous devriez le mettre dans un centre spécialisé. Il se sentirait beaucoup mieux avec
                                un entourage
                                médical approprié. <br>
                                Juliette fait les gros yeux. Ceux des parents sont remplis de points d'intarogatio... Je
                                m'agite sar
                                mon fauteuil. Non. je ne veux pas partir d'ici. <br>
                                Ils en discutent comme si je n'étais pas là. Regardez-moi ! JE COMPRENDS TOUT !
                                Je m'agite plus fort en grognant. Peine perdue. Papa hoche la tête. Il a peut-être
                                raison dit-il en se
                                tournant vers maman. Elle acquiesce timidement. Juliette me serre la main, l'air triste.
                                C'est terible de ne pas pouvoir décider de son propre sort. <br>
                                C'est fait. peut-être attendaient-ils que quelqu'un prenne la décision à leur place pour
                                ne pas
                                sentir coupables d'abandon. <br>
                                Le centre est ultra moderne. Derrière son grand bureau, la directrice consulte mon
                                dossier et lit à
                                voix haute des passages qu'elle commente avec mes parents, assis en face d'elle. Ils
                                parlent de ma
                                maladie et de mes autres problèmes, comme ma difficulté pour communiquer et marcher. A
                                voir
                                leurs airs désespérés, je commence à m'angoisser. Est-ce que c'est grave ? Si c'est le
                                cas et que
                                c'est foutu pourquoi m'avoir emmené ici ? J'ai le sentiment d'être une valise trop
                                lourde qu'on
                                dépose aux objets trouvés. Je ne peux m'empêcher de laisser couler quelques larmes.
                                L'impmante
                                dame qui doit me prendre en charge s'étonne et murmure quelque chose en se penchant vers
                                mon
                                père. Elle s'adresse ensuite à moi. <br>
                                - Ne t'inquiète pas tu va être chouchouté ici et apprendre plein de choses Tu vas voir,
                                tu auras une
                                jolie chambre et tu pourras te faire plein de copains. <br>
                                Je sanglote de plus belle et la morve coule de mon nez . Où est Juliette pmr me moucher?
                                Maman
                                cherche un mouchoir dans son sac et me le tend puis elle réalise qu'elle va devoir le
                                faire elle-
                                même. Dégoûtant, d'après son expression. <br>
                                C'est le moment des adieux. Je m'étouffe. Tant mieux ça précipite la séparation. Vite le
                                personnel
                                soignant accourt et on m'emmène. Je ferme Jes yeux. Je me réfugie dans des rêves de
                                verdure et de
                                soleil. <br>
                                La semaine est finalement vite passée. C'est vrai que je n'ai le tanps de m'ennuyer.
                                Antonin
                                avait peut-être raison. Je suis à ma place ici. Mes parents viennent me voir de temps en
                                temps.
                                J'aurais le droit de rentrer à la maison le week-end mais c'est compliqué. Maman semble
                                plus
                                épanouie. Il sortent avec moi dans le parc et papotent avec l'aide soignante qui pousse
                                mon fauteuil.
                                <br>
                                - Il mange bien ? Il s'adapte comme il faut? Pas trop de crises? <br>
                                - Non, pas d'incidents à signaler. Oui, tout va bien. Il a fait des progrès. <br>
                                Je me demande bien lesquels. Pour ma part j'apprécie toutes les activités proposées qui
                                évitent la
                                monotonie de la journée mais je sens plus spectateur que participant. Mon détachement
                                est sans
                                doute un progrès à leurs yeux. Je ne crie plus, je ne pleure plus, je suis juste là.
                                Tranquille. De
                                toutes
                                façons, j'ai de plus en plus de mal à bouger. <br>
                                Nous sommes regroupés dans la salle de loisirs...etje l'aperçois. Je sais pas ce qui se
                                passe. J'ai
                                chaud tout à coup. Mon cœur bat fort, comme lorsque j'ai peur . Elle est nouvelle. Son
                                bras et sa
                                jambe gauches sont dans le plâtre, son cou est enfermé dans un collier. Elle semble si
                                fragile dans
                                son jogging blanc. Ses yeux bleus ressemblent à deux lacs où je voudrais me noyer. <br>
                                Mon fauteuil est placé au fond de la salle. J'aimerais me rapprocher. Et c'est à nouveau
                                la colère qui
                                revient. Je m'agite, je grogne. Oui. Elle me regarde. Et semble s'étonner de mon
                                comportement. Un
                                des soignants me reprend doucement. <br>
                                - Du calme Victor. l'heure du goûter va bientôt arriver. <br>
                                Je m ' en fiche de leurs petits gâteaux et de leur chocolat. Je veux avoir une amie.
                                <br>
                                Elle me sourit. Si seulement je pouvais parler. <br>
                                Elle s'appelle Laura. Elle non plus ne parle pas. J'ai su qu'elle avait 13 ans.
                                Franchement, on ne lui
                                donne pas. Je pensais qu'on avait le même âge. Mais dans sa tête, depuis l'accident de
                                voinre, elle
                                est une petite fille et se comporte comme si elle en avait 6. C'est triste. <br>
                                Pendant toutes ces semaines, on s'est rapprochés. C'est drôle, on dirait que les
                                infirmières ont
                                compris notre échange de regard et nous sommes souvent ensemble dans la plupart des
                                activités. <br>
                                Du coup, je suis super motivé pour ma rééducation. <br>
                                Mes parents et ma sœur sont venus me voir le week-end dernier. Je suis sûr que Caroline
                                a senti un
                                changement chez moi car elle me parle sur un complice à propos de ma vie ici. Et
                                surtout, elle
                                m annonce qu'il existe une machine où je n'aurais qu'à taper sur des letres. Je pourrai
                                faire des
                                phrases et m'exprimer. Toute la famille va participer à l'achat. <br>
                                Je suis fou de joie. <br>
                                L'attente a été longue, mais ça y est, j'ai ce qu'ils appellent un ordinateur. J'ai de
                                la chance. Ils
                                l'ont
                                installé dans ma chambre. Ce n'était pas évident d'après ce que j'ai compris. Après il a
                                fallu le
                                temps d'apprendre à s'en servir. <br>
                                Et la première chose que j'ai demandée, c'est de faire venir Laura. <br>
                                Je relis tout ce que j'ai réussi à écrire au bout de gros efforts.. Je ne sais pas
                                combien de temps la
                                maladie va me laisser pour pouvoir le faire, mais ma vie est en train de changer. Je me
                                rends bien
                                compte qu'il y a plein d'autres problèmes qui s'ajoutent Des fois j 'ai du mal à
                                respirer ou mon
                                cœur trop vite, et surtout j'ai de plus en plus de mal à bouger. <br>
                                Mais pour l' instant, je suis enfin heureux. <br>
                                <br>
                                Aujourd'hui je fête mes 22 ans. C'est par hasard que j'ai rarouvé ce texte que j'ai
                                écrit grâce à mon
                                premier ordinateur, en 1977, au fond d'un tiroir de la commode Napoléon III appartenant
                                à mes
                                parents. <br>
                                Avec Caroline et Antonin. nous avons fait le partage des meubles quand le manoir a été
                                vendu. Trop
                                difficile à entretenir pour des personnes âgées. Ils ont préféré profiter d'un joli
                                appartement dans
                                un
                                lotissement résidentiel du côté de Nice. <br>
                                Nous y avons chacun notre chambre. Une pour mon frère quand il revient de ses nombreux
                                voyages
                                dus à sa carrière internationale d'avocat, une pour ma soeur, quand elle prend des
                                vacances. Tous deux sont encore célibataires. Et pas pressés de fonder une famille. <br>
                                Ce n'est pas mon cas. Avec ma femme, Laura, qui a partiellement retrouvé la mémoire,
                                nous avons
                                hâte d'être parents. Nous avons acheté un pait pavillon de plain pied dais les Yvelines,
                                avec un joli
                                jardin. <br>
                                Je suis devenu informaticien et je travaille à domicile pour une société privée. Laura
                                s'occupe de
                                notre maison et de mes soins quotidiens. <br>
                                Moi, auquel on ne donnait guère d'espérance de vie à long terme, je me suis à nouveau
                                accroché. <br>
                                Grâce aux progrès médicaux je peux espérer encore années de bonheur si mon cœur ne
                                lâche pas. Laura le sait et l'a accepté. <br>
                                Notre devise : ne jamais abandonner et se relever car l'amour est le plus fort.
                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal6 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : L'enfant</h3>
                <h4 class="auteurnouvelle">par Anne-France Gaujard</h4>
                <p>Pourquoi ils ne m'entendent pas? J'ai beau pousser de petits cris, m'agiter en tous
                    sens, personne
                    ne s'occupe de moi. <br>
                    Apparemment je suis un beau petit garçon. J'ai 9 ans. Les photos montrent un visage carré, des
                    cheveux blonds légerement ondulés, des yeux verts. Je ne suis pas très grand. <br>
                    Le...</p>
                <div class="tourbutton">
                    <button @click="showModal6 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2016 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal7 }">
                    <div class="modal-background" @click="showModal7 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Vies croisées</h3>
                            <p>Y a pas grand monde dans la rue, le dimanche, sauf pour aller au parc, Avec la
                                marchande de fleurs au coin qui reste ouverte jusqu'à cinq heures, ça fait un de
                                passage.
                                Cette habitude que j'ai prise de m'installer dans le fauteuil après déjeuner, on dirait
                                une vieille
                                qui épie tout ce qui bouge, "Vous êtes bien dans ce fauteuil, Madame Pasquier,
                                comme ça vous vous ennuyez pas" , qu'.elle me dit. Je m'ennuie jamais, j'ai plein de
                                films qui
                                repassent dans ma tète, elle peut pas comEM•endre. ElIe comprend grand-chose. Je lui dis
                                à
                                chaque fois que je déteste les flageolets, elle m'en s•a-t au moins une fois par
                                semaine. Y a
                                qu'une chose qu'elle fait bien, c'est le linge, mais franchement, je me fiche comme
                                d'une
                                guigne qu'elle repasse impeccable mes chemises de nuit ct mes torchons à vaisselle. En
                                plus,
                                y passe des au repassage. Elle a encore oublié de laisser ma canne Côté du
                                fauteuil. Non seulement c'est pas une rapide, mais en plus elle oublie tout, cette
                                Marie-
                                quelque L'autre. elle était trop mais elle oubliait rien, au moins. On peut pas se
                                fier aux services municipaux, ils vous envoient n'importe qui. Ah oui, ça me revient,
                                C'est
                                qu'elle s'appelle. C'est commun, ce nom-là. J'ai toujours détesté mon
                                prénom. je me suis rebaptisée Daisy. C'était le prénom de mon actrice préférée quand
                                j'étais
                                môme, je la trouvais follement chic. Ça veut dire la même chose. mais c'est quand plus
                                joli que Marguerite. Si seulement je pouvais sortir faire mes courses, je me gâterais un
                                peu,
                                mais pas moyen, avec mes douleurs. Le toubib me serine le même refrain depuis des années
                                :
                                "Madame Pasquier, pour l'arthrose et les rhumatismes, y a que le repos" . Et des piqûres
                                de
                                temps en temps, il oublie. On rigole un brin, il comprend l'humour, cet homme. Pas comme
                                Lucien. J'ai pas rigolé tous les jours, ça non. <br>
                                UN horizontal en sept lettres. «"Donne du fil retordre."" Je me suis mise aux mots
                                croisés avec les insomnies, y a pas mal de temps. C'est Madame Simone, notre concierge à
                                l'époque, qui m'a donne l'idée. Au début, ça marchait du tonnerre. Je me creusais
                                tellement la
                                cervelle qu'au bout d'un quart d'heure, j'étais déjà dans les bras de Morphée. Le
                                problème,
                                c'est qu'à la longue, je me suis passionnée pour la chose, et au lieu de m'endormir, je
                                restais
                                éveillée pendant des heures. Finalement, c'est desenu une drogue comme une autre. "Donne
                                du fil à retordre", avec un R, un E et L. Non. Lucien était pas du genre rigolo. Là où
                                il est,
                                je crois pas que les anges se marrent tous les Jours avec lui. REBELLE. La rebelle,
                                c'était
                                moi, avant de le rencontrer, parce qu'après, je me suis rangée des voitures, comme qui
                                dirait.
                                Faut dire que Lucien était un Monsieur. Ma mère disait qu'il avait fait les écoles, et
                                rien que
                                pour ça elle m'aurait poussée dans ses bras. ElIe était éblouie par l'instruction, ma
                                pauvre
                                mère, elle qui n'avait pas pu faire les écoles. Moi. j'avais dix-neuf ans, je le
                                trouvais beau et
                                surtout, respectueux de ma personne. Jamais un geste déplacé, même au cinéma. et un
                                bouquet de fleurs à chaque visite chez mes vieux . Avec ça, le costume impeccable et une
                                pochette de soie assortie à la cravate. Tiens, voilà les amoureux du rez-de-chaussée qui
                                traversent la rue. Sont gentils ccs mômes. C'est beau l'amour, quand c'est partagé...
                                J'étais
                                vendeuse aux Grandes Galeries, rayon vaisselle et linge de maison. Je sortais tous les
                                soirs
                                six heures tapantes pour aller boire un verre avec les copines au café, chez Morandi. Un
                                soir,
                                Lucien m'a invitée personnellement au bar du Palace Hotel. II en fallait pas plus pour
                                m'éblouir, à dix-neuf piges. On a fait connaissance, j'ai laissé les copines, les fètes
                                du parti,
                                les réunions de cellule et tout le bastringue, et j'ai pris le train avec Lucien, comme
                                on dit. Je
                                peux pas dire que je regrette, au début tout est rose. On se rend compte qu'après. <br>
                                Déjà trois heures, j'ai dû pousser un roupillon. Faut dire qu'avec cette chaleur en
                                plein
                                mois d'août et au cinquième étage. c'est la poêle frire. moins d'installer un igloo au
                                milieu
                                du salon. c'est l'expression à Henriette. Le toubib tache quand je lui dis que j'arrive
                                pas
                                boire plus de trois verres d'eau par jour. Et alors, si je buvais deux litres par jour,
                                je passerais
                                mon temps à faire des allers-retours entre le fauteuil et les toilettes. il peut pas
                                figurer ce
                                que c 'est pour une vieille femme comme moi. Au lieu de ça. je reste dans mon sauna.
                                avec un
                                éventail, les persiennes moitié fermées et une carafe d'eau, en attendant Henriette.
                                Elle vient
                                à quatre heures. après la sieste, En passant, elle prend des petits fours chez Hupin, On
                                raconte nos petites misères, On rigole comme des gamines. Faut dire qu'on connait depuis
                                un bail. On allait chercher nos gamins la même école, elle en avait quatre. moi trois.
                                Elle
                                aussi elle a épousé un Monsieur. DEUX horizontal. « On les 'Erd en tombant de haut en
                                neuf lettres. Pas trop dure, celle-ci. Enfin. façon de dire. Un jour ou l'autre, on
                                redescend sur
                                terre. Quand j'ai découvert que Lucien me trompait. mes illusions en ont pris un coup.
                                J'ai
                                rien dit, parce que quand un homme veut aller voir ailleurs, y a rien à faire. J'ai
                                encaissé et
                                puis un jour, j'ai pas pu continuer. J'ai dit à Lucien que pour les câlins, c'était
                                fini, terminé. Je
                                lui ai fait croire que j'étais déprimée. A vingt-neuf ans. j'étais loin d'être déprimée,
                                c'est venu
                                plus tard. J'ai continué à faire bonne figure, surtout avec la txlle•famille parce que
                                devant
                                eux. pas question de flancher. Chez Ces gens-là. fallait tout le temps rester dans les
                                bonnes
                                manières et tenir son rang. comme disait la belle-maman. ravais aucun rang à tenir.
                                j'avais
                                juste qu'à être une bonne petite fée du logis, une bonne mère et une épouse docile,
                                prendre
                                l'argent qu'on me donnait et pas broncher. Mais ça. je l'avais compris dès le départ.
                                que
                                j'étais pas tout à fait à leur goût. Une gentille petite. qu'elle disait la belle-mère
                                avec
                                sourire faux-jeton, et qui sait rendre mon Lucien heureux, n'est-ce pas, Daisy ? Mon
                                prénom
                                l'a toujours fait sourire, comme si c'était un caprice de gamine d'avoir voulu un prénom
                                américain. C'est Lucien qui m'a pas rendu heureuse. <br>
                                DEUX vertical. en huit lettres : "Après l'orage" E au début et B en troisième.
                                Embellie. Un joli mot. Mon embellie, elle venue avec Robert. C'est arrivé au moment où
                                je commençais à broyer du noir Il tenait l' atelier de couture où je faisais faire les
                                costumes de
                                Lucien et mes toilettes deux fois par an. On pas eu beaucoup à se dire, le courant
                                passait
                                plus que bien. Il était pas marié, j'avais déjà les gamins. Il voulait que je plaque
                                tout pour le
                                suivre, il était prêt à prendre le tout, moi et les enfants. Il a dit qu'il attendrait
                                le temps qu'il
                                faudrait. J'aurais dû le rencontrer avant, la vie est mal faite. Entre Lucien qu'était
                                jamais là et
                                qui se donnait même plus la peine de mentir, et Robert qu'attendait que je me décide,
                                j'étais là
                                au milieu, avec les gamins, et je devenais maboule. J'ai dit à Robert de pas m'attendre.
                                J'en ai
                                eu le cœur crevé. <br>
                                Tiens, la petite du quatrième s'en va se promener. Elle a bien raison, faut en profiter
                                tant
                                qu'on peut. Si j 'étais partie avec Robert, où que je serais maintenant ? Va savoir. On
                                peut se
                                faire plein de films, le résultat, c'est que je suis là, coincée dans mon fauteuil avec
                                mes
                                douleurs et Lucien qui mange les pissenlits par la racine depuis deux ans. Le HUIT
                                horizontal. « De préférence avec une concession » en dix lettres, avec un E, un P et un
                                U.
                                PERPÉTUITÉ. Ce mot... Moi, je dis que quand on vit ensemble et qu'on n'a plus rien se
                                dire. on en prend pour perpét'. Quarante ans de silence, c'est long. J'ai jamais
                                pardonné à
                                Lucien, il a jamais compris ou cherché à comprendre. On n'était pas faits pour vivre
                                ensemble, voilà tout. Je suis jamais allée sur sa tombe. J'ai donné mon corps la
                                médecine.
                                D'accord c'est peut-être pas un cadeau mais au moins, j'irai pas dormir à côté de lui
                                pour
                                l'éternité. Et puis j'aime pas les cimetières, quel gâchis toutes ces belles pierres,
                                ces fleurs
                                coupées, ces grands terrains qu'on pourrait y construire des maisons, et l'argent que ça
                                coûte.
                                Henriette dit que ça fait un peu de verdure au milieu des villes, c'est un point de
                                vue... Ce
                                ramdam dans l'escalier, c'est la famille du quatrième qui descend au parc. C'est comme
                                ça
                                tous les dimanches à la même heure, après le poulet-frites et la tarte aux pommes.
                                J'allais au
                                parc avec les gamins, tous les jeudis et parfois le dimanche. C'est des bons souvenirs,
                                mais
                                une fois sortis du poulailler, on les revoit plus, les mômes, L'erreur, c'est de les
                                élever pour
                                soi, mais y a pas une mère qui comprend ça. Ils viennent me voir quand y ont envie,
                                c'est
                                moins hypocrite. Ceux qui me manquent le plus, c'est les petits-enfants, avec leurs
                                petites
                                bouilles et leurs gambettes qui courent partout, c'est du soleil dans la maison. <br>
                                SIX horizontal, en huit lettres, « pour prendre de la hauteur » avec un L, un T, un U.
                                ALTITUDE. J'allais à la montagne avec les gamins en hiver, on louait toujours le même
                                chalet. Lucien restait travailler à l'étude, enfin c'est ce qu'il disait. Une fois,
                                Robert a loué un
                                chalet pas loin, on se voyait en cachette pendant que les enfants prenaient leurs leçons
                                de ski.
                                Quelle affaire, quand j'y repense... Aujourd'hui, les femmes travaillent et quittent
                                leur
                                bonhomme quand elles en ont marre, mariées ou pas. C'est plus simple. Quoique,
                                savoir si c'est plus simple d'être seule à travailler en élevant des gamins. Moi, j'en
                                ai eu trois
                                et ça m'a suffi. Comme c'est sur nous que les hommes se reposent pour s'occuper des
                                mômes,
                                y a intérêt à poser des limites. J'ai comme qui dirait limité les naissances, mais c'est
                                pas sûr
                                que Lucien ait fait la même chose de son côté. Je saurai jamais s'il a semé des graines
                                ailleurs, j 'ai jamais voulu savoir. J'avais déjà mon lot. <br>
                                SEPT vertical en six lettres, « Vous mènent par le bout du nez Un D, un U, et un S au
                                bout. Parlant d'odeurs, j'ai envie d'un bon café. Henriette devrait pas tarder. Quatre
                                heures
                                dix, elle qui est toujours à l'heure, .. Peut-être bien qu'elle fait la queue chez
                                Hupin, mais à
                                cette heure et un dimanche d'août, ça m'étonnerait. Fait une chaleur, dans cet
                                appartement !
                                Faudra que je dise à Marie-Modeste de sortir le ventilateur. Si je me lève pas pour me
                                rafraichir, je vais fondre comme une bougie. Henriette m'inquiète, elle répond pas au
                                téléphone... <br>
                                On a bien ri et s'en est mis plein les trous de nez. On a siroté deux petits verres de
                                Chartreuse après le café, y a pas de mal à se faire du bien. Elle m'a quand même fichu
                                la
                                trouille en arrivant avec une demi heure de retard. Hupin était fermé, elle a couru à
                                l'autre
                                bout de la ville pour les petits fours. Elle avait encore une nouvelle toilette, achetée
                                Au Chic
                                de Paris, tout en rose et mandarine, quelle coquette ! Elle prétend qu'elle pourra
                                jamais me
                                surpasser en élégance, taratata, l'âge est là et j'engraisse de partout à rester assise
                                sur ce
                                fauteuil. Mais pas question de se laisser aller avec des cheveux blancs et pas de
                                maquillage, y
                                manquerait plus que ça pour prendre encore dix ans ! C'est pas maintenant que je vais
                                commencer à me laisser aller. Elle m'a fait bien rire avec son histoire de voisin, un
                                vieux pas
                                appétissant qui lui tournait autour depuis des mois. Elle lui a cloué le bec en lui
                                disant qu'elle
                                avait le Sida. Le pauvre vieux, il a failli avoir une attaque en direct, mais depuis,
                                elle est
                                tranquille. <br>
                                HUIT vertical, en cinq lettres, « Religieux au pluriel », avec un R, un D, un E.
                                ORDRES. La seule chose que je supporte pas, c'est qu'on m'en donne, des ordres. Déjà
                                toute
                                gosse, je supportais pas. J'en ai reçu des claques, à cause de mon mauvais caractère,
                                j'étais
                                une cabocharde, disait ma pauvre mère. Je marche à la douceur, mais ça non plus, Lucien
                                l'a
                                Jamais compris. Y fallait qu'il Se sente le patron. l'étude et la maison. Je lui disais
                                que
                                c'est pas en criant qu'on a plus d•autorité sur les mômes. Au fond, je crois qu'il se
                                rattrapait
                                de jamais être là pour les mômes en leur criant dessus. La patronne de la maison,
                                c•était moi.
                                mais j'avais qu'à me taire parce que j'étais une bonne femme. Rien que d'entendre ça.
                                j'étais
                                hors de moi, Avec les années. le mur entre nous est devenu plus dur que du béton, Quand
                                il a
                                arrêté l'étude, il avait soixante-huit ans. Il avait espéré que Jean-Luc reprenne
                                derrière lui.
                                mais il a jamais Voulu, le gamin. Alors il s'est installé dans le fauteuil près de la
                                fenêtre. et il a
                                plus bougé. Il restait là toute la journée à fumer et à regarder la vie à travers les
                                carreaux. Les
                                rideaux et le papier peint étaient tout jaunes. on les changeait tous les deux ans. Il
                                disait pas
                                un mot. S'il sortait acheter le journal et la baguette de pain, faire un loto au café du
                                coin,
                                c'était bien tout. On n'a jamais su se reparler après toutes ces années de mensonges et
                                de
                                silence. Je l'ai vu mourir à petit feu jour après jour, dans sa fumée de cigarette.
                                C'était trop
                                tard pour nous deux. On passait notre temps à s'éviter. même les repas. Il mangeait au
                                salon devant la télé et moi, dans la cuisine avec la radio, chacun son territoire. On
                                était
                                devenus des bêtes l'un pour l'autre, quand j'y pense. Quand Lucien est mort. j'ai
                                ressenti un
                                grand poids en moins dans l'estomac. Et un vide. <br>
                                Suite du UN horizontal, « Elles peuvent tout mener à la baguette ». FEES. Tout ça. c'est
                                la faute aux contes de fées qu'on nous raconte dès qu'on est gosse. La Belle au Bois
                                Dormant,
                                Cendrillon et Blanche-neige, toutes ces bêtises. On n'a jamais rencontré le Prince
                                Charmant
                                chez le boulanger Au bout du compte. c'est une énorme peau de banane, et tout le monde
                                glisse dessus. L'homme idéal, on doit le rencontrer une fois sur un million, et encore.
                                Qui sait
                                st ça aurait marché avec ? Avec des si, comme dit Henriette... Les gamines
                                d'aujourd'hui, elles s'enquiquinent moins que nous, elles ont un boulot, la pilule et
                                elles
                                disent bye bye à leur bonhomme quand elles veulent. En TROIS vertical, sept lettres,
                                "Doux
                                pour les amoureux Commence par un B". Les petits billets doux de Robert, c'est Henriette
                                qui les recevait dans sa boite aux lettres, discrètement. C'était pas un grand poète,
                                mon
                                Robert, mais il savait tourner les phrases et trouver les mots pour me chavirer. Quand
                                il a
                                compris que je le suivrais pas. il a arrêté d'écrire. Il a fini par se marier, mais on
                                pensait
                                toujours l'un à l'autre, c'était plus fort que nous. Une fois, on a fait une folie. Les
                                enfants
                                étaient partis en vacances dans la famille, je devais les rejoindre quelques jours
                                après. J'ai
                                inventé une amie à visiter sur le parcours. On a passé deux jours dans un petit hôtel en
                                Normandie. je me rappelle même plus le nom. Y avait une rivière, un pont en bois et des
                                maisons rayées, des vaches et des pommiers en fleurs, mais je crois qu'on n'a pas mis
                                les
                                pieds dehors pendant deux jours. J'ai même pas honte d'y repenser. Je pourrais pas dire
                                s'il
                                est encore en vie, j'ai jamais cherché à le revoir. Je trouvais qu'il ressemblait à
                                Gérard
                                Philipe, c'est tout dire. Je crois que j'ai vraiment descendu la pente après son
                                mariage. La vie
                                avait plus beaucoup d'importance. Si les enfants avaient pas été là et Henriette pour
                                m'écouter, je crois que j'aurais atterri à Sainte-Anne. C'est le docteur Jouve qui m'a
                                sauvée,
                                avec des cachets. plus d'une fois j'ai eu envie d'en prendre une bonne dose pour en
                                finir, vaut
                                mieux plus y penser. Ces mots croisés me donnent le bourdon. Même si ça Sert à rien de
                                remuer le passé, je peux pas m'empêcher, assise dans ce foutu fauteuil à regarder les
                                autres
                                vivre, s'aimer, s'agiter, se promener, comme si je regardais un film. Le mien de film,
                                il arrête
                                pas de défiler dans ma tête. A la longue, je supportais plus de voir ce fauteuil vide
                                contre la
                                fenêtre. C'est vrai qu'il est confortable. D'ici, on voit les couchers de soleil, c'est
                                spectacle
                                gratuit tous les soirs. Lucien voyait la même chose. <br>
                                Il reste un petit dernier pour finir la grille. DIX horizontal en sept lettres, « Fleur
                                ou
                                fruit. elle fait aussi «'uffrir avec un Pau début et un N la fin. Bon sang. il fait au
                                moins
                                quarante degrés dans cette fournaise. pas étonnant qu'on ait envie de piquer un rytit
                                sorn...
                                Oh. ce foutu téléphone, faut pas être cardiaque Je dirai Marie-Célestine de régler la
                                sonnerie... Allô, qui est à l'appareil... <br>
                                J'ai pas reconnu sa voix de suite. je croyais que c'était une erreur. Pourtant c'était
                                bien
                                lui. cette voix chaude, un Fu plus enrouée qu'autrefois, ces profonds qui me faisaient
                                devenir toute chose... J'ai dû bafouiller comme une gamine. Laisse-moi le temps de
                                m'asseoir, je lui ai dit. J'avais le cœur tout affolé. Tout ça, c'est trop d'un coup,
                                entendre
                                voix après toutes ces années ! Je sais même plus ce qu'il m'a dit, je sais plus où je
                                suis... Je
                                vais boire un petit remontant et je terminerai la grille de mots-croisés Wur me calrner.
                                Je
                                retrouve pas mes lunettes, elles ont dû rester près du téléphone. Mais non, je suis
                                nouille. elles
                                sont sur mon nez. C'est quand même flou, de plus en plus brouillé. C'était bien sa voix,
                                presque pas changée. Ce qu'il m'a dit. je m'en souviens déjà plus... Il va me rappeler
                                dans
                                quelques jours, je crois. J'aurais pas parié qu'il était encore vivant... Mèrne avec les
                                lunettes,
                                j'y vois tout brouillé, et ce coeur qui n'arrête pas de cogner... Le demier mot de la
                                grille, c'était
                                quoi encore ? Ah oui, j'y suis. PASSION. </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal7 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Vies croisées</h3>
                <h4 class="auteurnouvelle">par Fabienne Pacory</h4>
                <p>Y a pas grand monde dans la rue, le dimanche, sauf pour aller au parc, Avec la
                    marchande de fleurs au coin qui reste ouverte jusqu'à cinq heures, ça fait un de passage.
                    Cette habitude que j'ai prise de m'installer dans le fauteuil après déjeuner, on dirait une vieille
                    qui épie tout ce qui bouge, "Vous êtes bien dans ce fauteuil, Madame Pasquier,
                    comme ça vous vous ennuyez pas" , qu'.elle me dit. Je m'ennuie jamais, j'ai plein de films qui
                    repassent...</p>
                <div class="tourbutton">
                    <button @click="showModal7 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal8 }">
                    <div class="modal-background" @click="showModal8 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">La lune de sang</h3>
                            <p>Elle ne fait pas son âge, Sue. Quand je l'ai accueillie au pied de la passerelle, au
                                Caire, je me suis surpris à penser : <br>
                                « Il ne manquait plus que cela ! Une gamine pour ce genre de job, ils sont fous
                                ces Yankees... ! » <br>
                                Il ne m'a pas fallu des mois pour réviser mon jugement. D'abord, Sue est plus
                                âgée qu'elle ne le laisse paraitre : vingt-sept ans, alors qu'on lui en donnerait dix-
                                huit. Et, surtout, elle connait son affaire, avec cette façon d'être des gens de son
                                pays, décontractée, peu soucieuse de la hiérarchie mais terriblement efficace. <br>
                                Les premiers temps, je dois avouer que ses « O.K, boss... » m'énervaient tout
                                autant que le chewing-gum mastiqué à longueur de journée, puis j'ai constaté, un
                                beau matin, que j'aimais bien être appelé « boss >> que la mastication mettait en
                                valeur ses pommettes hautes, trahissant peut-être une lointaine ascendance slave,
                                que Sue était, ma foi, fort jolie et que la vie était belle.
                                Je dis « était » car tout cela ne survit qu'en ma mémoire et celle de Sue, si elle
                                est toujours de ce monde. <br>
                                Nous étions en 1958 et Nasser, poussé par des impératifs pressait le
                                mouvement pour l'édification du grand barrage qui devait révolutionner
                                l'économie du pays. L 'U.N.E.S.C.O s'était inquiété, à juste titre, des con-
                                séquences de cette construction sur les sites archéologiques en vue. C'est pour
                                cette raison que je me retrouvais près des rives du Nil, à une centaine de
                                kilomètres en amont d' Assouan, en charge de répertorier des vestiges dont On ne
                                savait s'il convenait de les sauver des eaux à venir. <br>
                                Les premières fouilles s'avéraient décevantes. Quelques sépultures de notables,
                                visitées depuis bien des lustres par des pillards locaux, des babioles sans valeur.
                                Une seule trouvaille méritait d'être signalée : une collection d'une douzaine de
                                scarabées sacrés. en terre cuite, de taille décroissante que le délégué de
                                l' administration égyptienne qui me suivait pas à pas s'empressa de soustraire à ma
                                garde pour les confier au musée du Caire. <br>
                                Je disposais d'une équipe de cinq ouvriers, des fellahs recrutés dans le
                                pleins de bonne volonté et d'ardeur. Trop pleins même car ils maniaient la pelle et
                                la pioche avec un enthousiasme tel qu'on eût cru qu'ils s'attaquaient à un chantier
                                d'autoroute. J'en arrivais à me réjouir que nos champs de prospection fussent sans
                                grand intérêt n'osant imaginer le camage dont mes séides eussent été coupables,
                                dans I 'innocence et la bonne humeur les plus totales. Leur mine épanouie lorsque,
                                muni d'une petite bros.se, je m'efforçais d'épousseter un fragment de poterie
                                minuscule me laissait subodorer leurs propos rigolards que j'étais bien incapable
                                de traduire. Ils devaient me considérer comme un fou, victime sans doute du dieu
                                Ra qui régnait sans partage en ces lieux. Un fou sympathique qu'il importait de ne
                                point froisser tant qu'il distribuerait les quelques livres hebdomadaires récom-
                                pensant leurs efforts. Et ils attaquaient de nouveau les monceaux d'éboulis en
                                priant Allah de prêter longue vie à cet original maniant les cailloux avec des
                                pinces à sucre. <br>
                                L'arrivée de Sue troubla quelque peu la convivialité de nos rapports. Très vite,
                                je constatai, à mon grand déplaisir, que ma position de commandant en chef pesait
                                peu au regard de la blondeur de Sue. Sic transit gloria mundi ! Ma collaboratrice
                                pouvait passer des heures à brosser les échantillons découverts, nul sourire sur la
                                face de mes fellahs. Bien au contraire, une admiration béate illuminait leur regard
                                comme si la technique de Sue, en tous points semblable à la mienne, les eût
                                envoûtés. Peut-être pensaient-ils que ces tâches ménagères relevaient de la
                                compétence féminine alors qu'elles ne pouvaient que dévaloriser l'image de
                                l'homme éternel ? <br>
                                Dès son arrivée, la tente de Sue fut mise en place dans les plus brefs délais,
                                avec un luxe de précautions dont il ne me semblait pas qu'on eût usé pour ma
                                propre installation. Quand je demandai pourquoi l'entrée de sa tente était orientée
                                plein nord alors que la mienne s'ouvrait au sud, on m'expliqua doctement qu'ainsi
                                la dame serait mieux abritée des ardeurs du soleil et à l'abri des vents de sable
                                lesquels, comme chacun sait ou devrait le savoir, viennent toujours du Soudan.
                                J'en conclus que je devais avoir une constitution propre à supporter les coups de
                                soleil et les assauts de l'harmattan. A bien réfléchir, c'était plutôt flatteur, mais je
                                me serais volontiers passé de cette forme d'hommage. <br>
                                Sue accueillait ces empressements avec une simplicité, un naturel désarmants.
                                Nulle afféterie dans son attitude ou ses propos. Rien qui pût prêter à confusion
                                dans son maintien. Elle était là, voilà tout, et les choses s'organisaient autour
                                d'elle comme les pièces d'un puzzle. Il me fallut bien l'admettre quoi qu'il m'en
                                coûtât. <br>
                                Ahmed, le chef de ma brigade d'excavateurs, proposa à Sue l'assistance de son
                                neveu Salim, un adolescent d'une quinzaine d'années susceptible, selon lui, de lui
                                rendre de nombreux services dans les travaux domestiques, voire même, en l'ins-
                                truisant quelque peu, de la suppléer dans ses activités de dépoussiérage.
                                Quand je fis remarquer à Ahmed qu'il s'était bien gardé de m'informer de cette
                                opportunité, il m'expliqua suavement qu'il n'avait point osé, me jugeant apte à me
                                débrouiller tout seul. Cette surestimation de mes capacités commençait à me
                                chatouiller l'épiderme, d'autant plus que j'avais l'intime conviction que mon chef
                                de chantier se payait ma tête plus qu'il n'eût convenu s'il avait été aussi soucieux
                                de la hiérarchie qu'il le prétendait. <br>
                                Bref, Salim débarqua dans notre campement sans crier gare, comme surgi de
                                derrière une dune. En moins d'une semaine, il devint le personnage le plus en vue
                                de notre communauté, si l'on excepte Sue dont l'aura ne souffrit aucunement de
                                cette nouvelle présence. Mieux, elle s'amplifia, servie qu'elle était par un zélateur
                                dont l'incessante activité ne cessait de m'étonner. Une mouche - l'impudente ! -
                                se posait-elle sur le bras de Sue, la main brune de Salim chassait l'importune
                                aussitôt. A peine ma collaboratrice avait-elle remarqué que le soleil était vraiment
                                très chaud ce jour qu'un verre de thé glacé était apporté avec moult salamalecs. Je
                                dis bien « un » verre car, en vertu des aptitudes qui m'étaient conférées, j'étais
                                censé ne point souffrir de la soif, pas plus que du soleil ou du vent.
                                Je recouvrais un peu de mon autorité sur le chantier. Un peu. Très et pas
                                longtemps car le bougre, sans que je m'en rendisse compte, parvint à se rendre
                                utile, puis indispensable. Sue recourait à lui pour de multiples tâches dans
                                lesquelles sa célérité, la finesse de ses mains, son accoutumance au rude climat de
                                la vallée, faisaient merveille. Comme je lui objectais qu'il convenait de garder
                                plus de distance avec ce vibrion, Sue parut décontenancée. Pourquoi diable Se
                                priver d'une aide si précieuse ? Elle raisonnait en termes d'efficacité et mes
                                arguties de patron jaloux heurtaient à son incompréhension0 <br>.
                                Femme certes, mais Américaine avant tout, Sue ne comprenait pas. Elle ne
                                percevait pas les épiphénomènes concomitants de sa venue. Pourquoi, depuis lors,
                                l'air le matin semblait plus léger ? Pourquoi le vrombissement des moustiques le
                                soir, autour de la lampe-tempête, se parait d'une musicalité nouvelle et insoup-
                                çonnée ? Par quel miracle les boulettes de viande les sempiternelles kôftas
                                retrouvaient-elles la saveur de l'inattendu lorsque nous les dégustions ensemble ?
                                J'aurais eu mauvaise grâce à lui en tenir rigueur car, moi-même, je ne m'ex-
                                pliquais pas ces métamorphoses. Jusqu'au jour où je me surpris, devant mon
                                miroir, à tenter longuement de mater un épi rebelle. <br>
                                J'aimais Sue. <br>
                                La réciproque n'était pas évidente. Sue, pour charmante qu'elle fut, se
                                comportait comme un être asexué, ne se souciant que de remplir correctement sa
                                tâche. J'appréciais son enthousiasme de néophyte mais déplorais qu'il se limitât
                                aux ombres du passé alors que le présent était là. <br>
                                Mes tentatives d'effraction de sa vie privée échouèrent piteusement. Les quel-
                                ques confidences m'apprirent qu'elle était divorcée depuis trois ans.
                                puis le couvercle se referma. <br>
                                Nous disposions encore d'un délai de detLX mois mener nos recherches à
                                leur terme et Sue m'engagea à presser le pas, persuadée qu'elle était de la richesse
                                du gisement sur lequel nous travaillions. Avec mon accord. elle délaissa le
                                chantier principal pour ouvrir une fouille annexe quelques hectomètres de la rive
                                du fleuve, suivie par l'indispensable Salim auquel elle enseignait les premiers
                                rudiments d'archéologie. <br>
                                Si je m'efforçais de dissimuler mon attirance pour Sue, il n'en allait pas de
                                même de Salim qui ne la quittait pas du regard. Jamais, à cette époque, je n'osai
                                reconnaitre que j'étais sinon jaloux - ce qui aurait frôlé le grotesque - du moins
                                envieux de cette permanente proximité que le nouveau chantier favorisait.
                                Je proposai à Sue une journée "en ville", c'est à dire à Assouan, afin de
                                rompre la monotonie des jours de travail mais elle refusa avec obstination,
                                désireuse avant tout de voir aboutir nos investigations. A diverses reprises je
                                l'emmenai dans des promenades nocturnes le long du fleuve, convaincu qu'elle
                                succomberait à la magie du lieu. Nul endroit ne pouvait se montrer plus
                                romantique que la vallée du grand fleuve encore sauvage en ce temps-là. près de
                                notre campement, verdoyait un petit ilot planté de papyrus dans lesquels la brise
                                vespérale chuchotait des choses qui vous ouvraient le cœur et l'âme. par nuit de
                                pleine lune, on pouvait surprendre un couple d'hippopotames, peut-être le dernier,
                                dont les ombres se détachaient à contre-jour alors même que de grandes bouffées
                                de senteurs mêlées, odeurs d'herbes aquatiques et de limon fertile, vous montaient
                                à la tète. Oui, je vous le jure, nul endroit au monde n'aurait su Se montrer plus
                                propice au rapprcxkxment de deux êtres soumis à la solitude. <br>
                                Sue n'était pas étrangère à cet environnement, mais son émoi n'affectait que sa
                                sensibilité professionnelle. <br>
                                — Voyez-vous André, concédait-elle, il faut avoir vécu ces instants pour
                                percevoir ce que fut la vie jadis sous le règne de Pharaon. <br>
                                J'en étais conscient certes, mais il y a une vie après Ramsès et j'eusse aimé que
                                ma compagne partageât cette opinion. <br>
                                La mise au jour, par Ahmed, des fondations d'une antique demeure me fournit
                                le prétexte recherché pour le retour de Sue sur mon chantier personnel. J'avais
                                besoin de renforts afin d'exploiter cette découverte dans les délais impartis.
                                J'ordonnai à Salim de rester sur le chantier secondaire pour aider les deux
                                ouvriers qui poursuivaient les fouilles. Si je m'étais interrogé, il m'eût bien fallu
                                reconnaitre que j'avais saisi l'occasion de séparer Sue de son chevalier servant et
                                que tout cela n'était guère glorieux. Aussi évitai-je de m'interroger. <br>
                                Sue était de nouveau auprès de moi et sa tranquille présence gommait la
                                pénibilité de nos travaux. Maintes fois, elle suggéra que Salim nous rejoigne,
                                arguant qu'il Serait plus efficace placé sous son contrôle mais, avec une mauvaise
                                foi insigne, je justifiais son éloignement. Sue, après être revenue à la charge,
                                n'insista plus ; cependant je percevais son mécontentement. <br>
                                Nous étions en plein mois d'août et la chaleur devenait insupportable. Force nous
                                était d'interrompre les fouilles dès dix heures du matin pour ne les reprendre que
                                vers seize heures. Il nous restait trois semaines avant la fin de notre délégation
                                mais le butin s'avérait maigrelet. Trois semaines et nous abandonnerions cette
                                vallée perdue. Trois semaines et Sue regagnerait son Kansas natal. Cette
                                perspective occultait toutes les autres, m 'emplissant de mélancolie. <br>
                                Sue, au plus fort de la canicule, me proposa une nouvelle fois de faire revenir
                                Salim sur le chantier principal où les conditions de travail étaient moins pénibles.
                                Sans doute refusai-je un peu vivement car Sue parut surprise et m'examina
                                longuement avant de se détourner en haussant les épaules dans un mouvement
                                d'impuissance. Je n'ai pus oublié cette scène précédant de quelques heures le
                                dramatique accident qui devait marquer notre séjour. <br>
                                Les travaux venaient de reprendre quand, sur le coup de dix-sept heures, je vis
                                accourir un des deux ouvriers du chantier secondaire. De très loin, ses gestes
                                désordonnés nous laissèrent deviner qu'un événement grave venait de survenir.
                                - Salim ! Salim !
                                Ahmed se précipita et s'efforça de nous traduire le discours véhément de son
                                compagnon. Salim s'était fait mordre par une vipère cornue en retournant une
                                pierre et il gisait là-bas. <br>
                                Ahmed n'avait pas achevé que Sue s'élançait à grandes enjambées et gagnait
                                le lieu du drame. Quant à moi, je fis un saut jusqu'à ma tente pour m'emparer du
                                nécessaire à pharmacie et tentai de la rattraper. <br>
                                Quand je la rejoignis, elle était déjà au côté de Salim allongé sur le sol, agité de
                                spzLsmes convulsifs. Le venin se répandait dans ses veines, commençant son
                                œuvre destructrice. <br>
                                — Laissez-moi faire...
                                Sue m'arracha la trousse des mains. Nous disposions de doses d'un sérum anti-
                                venimeux destiné à combattre les effets des piqûres de scorpions, mais serait-il
                                efficace pour une morsure de serpent ? Nous n'avions pas le choix. Sue s'affairait,
                                soucieuse de calmer les angoisses de Salim. Je demeurais-là, stupide, avec une
                                énorme boule que je sentais gonfler en moi. <br>
                                — Ne restez pas là, il faut chercher du secours... <br>
                                Elle avait pris la direction des opérations sans que j'aie songé à m'en offusquer
                                tant le spectacle de notre petit compagnon haletant, les yeux révulsés, me
                                tétanisait. <br>
                                Sortant de mon hébétude, je revins à notre base. Par chance, je pus établir un
                                contact radio assez rapidement avec notre Q.G d'Assouan qui me promit l'envoi
                                d'un hélicoptère dans les plus brefs délais. <br>
                                Pendant ce temps, Salim ramené dans l'installation de Sue, avait été étendu sur
                                son lit de camp. Les convulsions s'étaient apaisées mais, et ce n'était pas pour me
                                rasséréner, il semblait éprouver de plus en plus de difflcultés à respirer. Je sava1S
                                que certains venins entraînent une paralysie progressive des muscles du thorax et
                                je craignais de me trouver en présence des premiers symptômes. <br>
                                Sue soutenait d'une main la nuque de Salim et, de l'autre, lui bassinait le front
                                avec un linge humide sans cesser de lui parler comme si elle eût craint qu'il ne
                                s'endormit d'un sommeil irréparable. Pas une fois son regard ne s'arrêta sur mol
                                et je sentais une mort lente monter dans tout mon être, comme un venin. <br>
                                Lorsque l'hélicoptère se posa, après une interminable attente. Salim vivait tou-
                                jours mais sa respiration spasmodique nous laissait à tout instant redouter une
                                issue fatale. <br>
                                Les premières ombres du soir s'étendirent sur le sol dès que l'appareil disparut
                                dans le ciel. Sue avait renoncé à accompagner Salim, faute d'un moyen de trans-
                                port pour le retour. <br>
                                - Sue, je suis désolé... <br>
                                Je ne savais que dire. pour la première fois depuis l'accident, elle me regarda
                                mais ses yeux étaient emplis d'étonnement comme si elle découvrait un inconnu
                                qu' elle aurait côtoyé pendant des semaines sans deviner sa présence. <br>
                                Je n'étais donc que « cela ». Et le monde s'écroulait autour de moi. <br>
                                Le diner, cet instant privilégié que j'attendais tout au long du jour, me fut une
                                épreuve difficilement soutenable. Sue ne répondait que par monosyllabes aux
                                ébauches de conversation que je tentais. Cependant, à ma grande surprise, elle
                                accepta la promenade nocturne que je lui proposai sur la rive du fleuve. Il faisait
                                nuit noire lorsque nous partîmes mais une faible clarté à l'horizon annonçait la
                                lune montante. <br>
                                Après l'atroce chaleur diurne, la relative fraîcheur venue du fleuve calmait nos
                                fièvres. Sue semblait s'être ressaisie et aspirait à pleins poumons l'air empreint
                                d'effluves puissants dispensés par la brise naissante. La lune apparut, pleine,
                                ronde, posée sur la crête d'une dune. <br>
                                Jamais, me parut-il, la magie de l'astre n'avait atteint ce paroxysme. Une sorte
                                d'ivresse me gagnait dont je n'aurais su dire si elle était faite d'allégresse ou
                                d'une angoisse inexprimée. <br>
                                Nous étions, au terme d'une longue marche, sur le point de revenir sur nos pas
                                quand Sue saisit mon bras. <br>
                                - Regardez, André ! <br>
                                Son bras tendu désignait la lune qui peu à peu se teintait de rouge. Je sentais les
                                doigts de ma comparse se crisper sur mon poignet. Elle tremblait de tous ses
                                membres. Décontenancé par cette panique soudaine, je tentai de la rassurer. <br>
                                — C'est un phénomène connu, Sue. Cela se produit lorsqu'une tempête de
                                sable se lève dans une région de terres rouges, formant un filtre devant la lune.
                                Les anciens Egyptiens l'appelaient « la lune de sang »... <br>
                                Sue m'interrompit. <br>
                                Je connais cela, mais savez-vous la signification qu'ils attachaient à ce
                                phénomène ? Il précédait la mort de Pharaon... <br>
                                La phrase s'était achevée dans un murmure. Sans nous être concertés, nous
                                firmes demi-tour et hâtâmes le pas en direction du campement. <br>
                                Ahmed nous attendait, les bras croisés sur la poitrine. II avait, en notre
                                absence, reçu le message radio : Salim était mort. <br>
                                Sue partit le lendemain. Je ne devais jamais plus la revoir. </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal8 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : La lune de sang</h3>
                <h4 class="auteurnouvelle">par Guy Vielfault</h4>
                <p>Elle ne fait pas son âge, Sue. Quand je l'ai accueillie au pied de la passerelle, au
                    Caire, je me suis surpris à penser : <br>
                    « Il ne manquait plus que cela ! Une gamine pour ce genre de job, ils sont fous
                    ces Yankees... ! »</p>
                <div class="tourbutton">
                    <button @click="showModal8 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2015 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal9 }">
                    <div class="modal-background" @click="showModal9 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">La fresque de la vie d'un enfant du siècle</h3>
                            <p>Lorenzo, la main tremblante, repose le pinceau sur la palette. Beaucoup de noir, de gris,
                                un
                                de bleu et quelques taches de rouge, Trois longues années de labeur s'achèvent. La
                                fresque se-
                                rait livrée en temps voulu. Le Musée National des Invalides lui en avait passé commande.
                                Son
                                œuvre, le chef-d'œuvre de Sa Vie, doit recouvrir les murs de l'intérieur des arcades
                                ceinturant la
                                grande cours du musée de l'armée. <br>
                                Ce 31 décembre n'est pas un jour comme les autres : à la fois son anniversaire et celui
                                de la
                                fin d'un siècle. Lorenzo est né un premier janvier 1914, à zéro heure une. Ce siècle qui
                                s'achève,
                                n'est-il pas un le sien ? <br>
                                Satisfait, mais fatigué, il s'assoit au milieu de son vaste atelier pendant trois ans,
                                son seul
                                décor a été cette pièce tendue d'une imposante toile blanche. Jour après jour, ses
                                pinceaux l'ont
                                couverte Ses yeux la balayent, lentement. <br>
                                <i>Sur un fond de brume du chemin des Dames. se promène un jeune couple endimanché.
                                    Épars sur le sol, un casque, un masque à gaz. une baïonnette, un jeune soldat. les
                                    yeux vireur row-
                                    nés vers le ciel mort. Sur son front laiteux, sans ride, <strong>le point rouge du «
                                        i » d'un point
                                        d'interrogation</strong>. La jeune femme porte une ombrelle rose. Le ciel
                                    d'encre reste barbouillé
                                    par les
                                    fumées noires des bouches béantes des canons. Certains crachent encore la mort.
                                    Partout, la déso-
                                    latton. Dans l'entrelacs des barbelés, les victrmes de la barbarie humaine, les
                                    mains tendues vers
                                    un je-ne-sais-quoi. Leurs fixent un point au-delà de l'horizon. Entre les lourds
                                    nuages teintés
                                    de rouge sang : quelques touches de bleu. </i> <br>
                                Son regard abandonne ces terribles images de guerre pour se poser sur le couple du
                                premier
                                plan. <br>
                                <i>Le jeune homme, la tête bandée, porte sur son habit de poilu la poussière des
                                    tranchées et
                                    les taches de sang de ses compagnons fauchés par la mitraille. Sur le visage de la
                                    jeune femme, en
                                    rides à peine esquissées : l'attente angoissée. Elle tient une ombrelle. Derrière
                                    eut, de blanc vêtue.
                                    une nurse pousse un landau. Tout donne ù pemer que I 'enfant, qu'on ne voit pas, est
                                    celui de ce
                                    couple. La vre reprend son cours. Sur le côté, en toile de fond, les cheminées des
                                    hauts-fourneaur
                                    de Pompey fument à nouveau.</i> <br>
                                L'enfant, c'est lui, Lorenzo. Trop petit, comme souvenir de la Grande Guerre il ne
                                gardait
                                que les histoires rapportées par son père, Agostino. Après la démobilisation, ce dernier
                                avait quitté
                                la Lorraine pour reprendre son ancien emploi près de Toulouse. Au début du siècle, la
                                misère de son
                                pays, le Portugal, l'avait conduit en France. Habile mécanicien, il avait d'abord
                                travaille à Muret,
                                dans les ateliers d'aviation de Clément Adder puis dans ceux de Latécoère. <br>
                                Ses yeux passèrent l'étape suivante. <br>
                                <i>Le ciel gris de la guerre cède le pas à un azur limpide où Éole, I 'aéroplane
                                    d'Adder. s 'ar-
                                    rache à la pesanteur</i><br>
                                Il lui semble entendre sourdre les hourras de la toile. <br>
                                <i>Dans un coin. le pionnier de l'aviation sourit. Bien que son père n'ait pas assisté
                                    aux pre-
                                    miers essars. Lorenzo I 'a représenté en compagnie de sa mère. Elle tient la méme
                                    ombrelle : rose
                                    blanche s'épanouissant au milieu de I 'étendue verte du champ témoin de I 'exploit.
                                    Accroché sa
                                    robe, un petit garçon en costume de marin. Ses longues anglaises retombent
                                    négligemment sur un
                                    col blancc bordé d'un double liseré, bleu comme le ciel.</i><br>
                                Lorenzo sent se raviver la peite flamme d'une enfance joyeuse et insouciante. <br>
                                <i>Le doigt pointé Vers l''appareil, le garçon crie son enthousiasme, « Maman, quand je
                                    serais
                                    grand, je veux voler Comme le monsieur. Sur le visage de sa mère, en plus appuyé,
                                    Lorenzo a
                                    peint les rides de l'inquiétude soulevée par la déclaration impromptue du bambin.
                                    Les lèvres res-
                                    tent closes.</i>
                                Il lui semble entendre résonner à ses oreilles le « non » qui lui fut opposé quelques
                                jours plus
                                tard <br>
                                <i>Puis la famille quitte Toulouse pour Paris. pour Agostino, c'est I'emploie chez
                                    Dassault,
                                    à Saint-Cloud, en bord de Seine, face au Bois de Boulogne et... pour Lorenzo la
                                    pension. chez les
                                    Jésuites.<br>
                                    Le blanc de la toile et le bleu du ciel disparaissent sous le gris monastique d'une
                                    salle de
                                    classe. Un religieur, sévère, la férule à la main, dispense un cours de physique. Au
                                    mur, est suspen-
                                    du le parangon offert en exemple aur potaches : un crucifix. Sur le visage du
                                    supplicié toute la
                                    souffrance du monde. Au tableau des formules ? des hiéroglyphes ? Sur I'un d'eux,
                                    s'est posé une
                                    mouche prête à l'envol.. par la fenêtre entrouverte, bousculant les nuages gris de I
                                    'ennui : un petit
                                    coin de ciel bleu. Les bras croisés sur le pupitre, engoncé dans son tablier gris
                                    silence, l'enfant
                                    rêve d'espaces infinis. Dans le petit coin de bleu, une colombe blanche. Ses rêves
                                    d'enfant bafoués
                                    par le cadre rigide de l'éducation s'envolent par la fenêtre entrouverte. Est-ce
                                    l'oiseau qui le suit
                                    ou l'inverse ? </i> <br>
                                Nerveux, presque angoissé, assailli par les images d'une adolescence embastillée,
                                Lorenzo
                                grille une cigarette. <br>
                                <i>Dans la suite de la fresque, une variété infinie de tons chauds remplace le gris de
                                    la pen-
                                    sion. Dans un froufroutement. les robes des élégantes ondulent toutes les couleurs
                                    de I "arc-en-ciel.
                                    En armère plan, sur le lapis du ciel, le Sacré-Cœur resplendit de blancheur. La
                                    place du Tertre.
                                    s'animent les habituels badauds des beaux dimanches de printemps. Comme des tulipes
                                    au soleil,
                                    s'ouvrent les parasols. Sur l'un d'eur, chante un moineau. Déjà, les premiers
                                    consommateurs. De
                                    la toile, monte à peine un murmure. Des silhouettes éthérées vont et viennent. Dans
                                    un coin. un ac-
                                    cordëon accompagne une chanteuse de rue. Piaf? Au centre, une casquette de poulbot
                                    sur la tête,
                                    un jeune homme en pantalon de toile beige, un peu trop large, est arrêté devant un
                                    chevalet. Il
                                    porte une longue blouse blanche, serrée à la taille par une écharpe rouge de la même
                                    couleur qu
                                    son foulard. C'est Lorenzo. Le regard fixe, il suit les gestes d'un peintre. Un peu
                                    en retrait, les
                                    traits
                                    à peine esquissés d'une femme. Peut-être sa mère ? En toile de fond, jaillissant de
                                    la pénombre, un
                                    soleil encore pâle. </i> <br>
                                De retour à Saint-Cloud, il déclare « maman, je serais peintre». Agostino s'y oppose vi-
                                goureusement. «Mon fils, tu seras pilote ou mécanicien !» Son père cultivait de grandes
                                ambitions
                                pour son garçon. Il les lui avait expliquées. Sa mère avait gardé le silence. Il n'avait
                                pas insisté.
                                Comment leur avouer qu'il préférait avoir les mains maculées de peinture plutôt que de
                                cambouis ? A cette époque, un enfant le pouvait-il ? Le coeur lourd, il avait longé les
                                berges de la
                                Seine. En se couchant, le soleil emportait aux abysses ses rêves d'enfant. <br>
                                Lorenzo, après un temps d'hésitation, reprend son pinceau. En mélangeant du rouge et du
                                bleu, il cherche le violet du deuil. <br>
                                <i>Le blanc assombri de Montmartre se couvre de reflets parme.</i>
                                Puis, il retourne s'asseoir. Son regard se fige, absent. Une étrange sensation, mélange
                                de mo-
                                rosité et d'enthousiasme l'envahit. <br>
                                [l avait bataillé dur avant que son père accèpte qu'il s'inscrive aux Beaux-Arts. Dans
                                un
                                vieux pavillon d'Argenteuil, il installe son premier atelier. Paris restait hors de
                                prix. Nombre de ses
                                premières toiles sont des copies de maitres. Les autres, les siennes, laissent deviner
                                ses rèves et ses
                                désillusions. Peintre visionnaire, comment pouvait-il être compris ? Dépourvu de
                                ressources, il
                                connait la vie difficile de l'artiste à ses débuts. De longues soirées, avec seuls
                                compagnons ses
                                pinceaux et sa palette ponctuent sa solitude. Ses peintures ne trouvent d'acheteurs. Des
                                copies et
                                encore des copies. Avec acharnement, il praufine sa technique, cherche sa voie. Ses
                                œuvres s'em-
                                pilent. On lui passe des commandes. Hélas ! pas de ses tableaux. De vrais faux
                                remboursent ses
                                dettes. Goya, Rembrandt, Chardin, Michel-Ange, Monet, Cézanne, Picasso... Tous
                                retrouvent vie
                                sous la main du copiste. <br>
                                <i>La fresque nous plonge ensuite dans la guerre de 40. Influencée par Guernica. en
                                    arrière
                                    plan, la pemture de Lorenzo étale des corps disloqués, des arbres déchiquetés, des
                                    villes rasées.
                                    Dans un ciel gainé de plomb. les bombardiers sèment la mort. Là, c 'est I 'appel
                                    désespéré d'une
                                    mère. Plus loin, le cri d'effroi d'un enfant. A ses pieds, agonisant, une colombe
                                    blanche. Sur son
                                    poitrail, <strong> un point rouge, comme le « i » du point d'interrogation</strong>.
                                    Un homme sort des
                                    ténèbres et
                                    ramasse le rameau d'olivier. Il le met en terre. Un peu plus loin, un camp de
                                    concentration blesse
                                    I horizon. Les hautes cheminées des fours crématoires scarifient le ciel avec leurs
                                    volutes d'une fu-
                                    mée noire, nauséabonde. Au travers de l'entrelacs de barbelés... la tête rasée d'un
                                    enfant tendant
                                    une main décharnée. A ses pieds, un rat achève un squelette. <br>
                                    Plus loin encore, au milieu du champignon atomique, sur l'horizon d'où s élèvent les
                                    cla-
                                    meurs d'Hiroshima. un petit coin de ciel bleu. Au milieu : un oiseau blessé. une
                                    colombe ? laisse
                                    tomber son rameau d'olivier. Dans un angle du panneau : un couple, femme est
                                    toujours
                                    aussi jeune et gracieuse. Elle ne tient plus d'ombrelle mais le bras d'un homme aux
                                    cheveux longs.
                                    Sur son visage, toutes les craintes et tous les espoirs du monde. C est lui, Lorenzo
                                    avec Ilda, la mu-
                                    nichoise. Il l'avait connue alors que, prisonnier en Allemagne, on l'avait affecté
                                    travaur des
                                    champs dans une petite ferme. Malgré la haine et la guerre, ils s'étalent aimés.
                                    <br>
                                    Sur la toile, le ciel gris laisse à nouveau place au bleu et au vert de la Bavière.
                                    Sous un so-
                                    leil radieux, les sapins escaladent les pentes abruptes des sommets couverts d'une
                                    neige immacu-
                                    lée. Au pied de la montagne, joue un petit garçon en culotte de cuir : Lorenzino,
                                    son fils. Le soleil
                                    l'enveloppe d'une mandorle. Dans les champs, rutilent toutes les couleurs des fleurs
                                    du printemps.
                                    La colombe a retrouvé son rameau d'olivier. Le panneau tout entier respire le calme
                                    et le bonheur.</i>
                                <br>
                                Lorenzo consulte sa montre. Vingt-trois heures 45 Inexorablement, le siècle s' enfonce
                                dans
                                le puits de la mémoire. Ses amis ne vont pas tarder. Il allume une nouvelle cigarette,
                                Plus loin, le
                                décor change Une larme perle au coin de Ses yeux noirs. <br>
                                <i>Le relief aride de l'Atlas se substitue aux vertes forêts bavaroises. Le ciel se
                                    teinte d'un mé-
                                    lange de gris et d'incarnat. Dans un coin, les palmes bruissent, bercées par le
                                    sirocco. Sous l'ombre
                                    diaphane d'un olivier, dort un jeune soldat. De son cou béant s'épanche un mince
                                    filet de sang, cou-
                                    leur de la terre de ce pays de larmes. Les yeux du jeune homme fixent pour
                                    1'éternité un petit coin
                                    de ciel bleu, au-delà du réel, au-delà des nuages teintés du sang d'inutiles
                                    victimes. </i> <br>
                                Sa poitrine s'oppresse. Lorenzo jette à terre sa cigarette. Nerveux, il l'écrase au sol.
                                Sur son
                                visage décomposé, les larmes ruissellent comme une pluie d'orage sur la vitre fermée
                                d'un cœur. A
                                l'abri du temps, reposent ses peines et ses pleurs enveloppés dans le linceul de
                                l'oubli. Tout au
                                moins, le croit-il. Trop longtemps contenues, ce soir, elles coulent à flots. Alors que
                                son regard se
                                pose avec tendresse sur le jeune soldat allongé sous l'olivier, un mot s'échappe de sa
                                poitrine : Lo-
                                renzino ! puis... Mon fils ! Il ne peut regarder la suite de la fresque. Ses mains se
                                mettent à
                                trembler.
                                Des rides de douleur sillonnent son front blême. Sa respiration se fait haletante. Sur
                                le côté droit de
                                la poitrine... <strong>un point rouge, comme le « i » du point d'interrogation
                                </strong>. L'émotion
                                passée, ses
                                yeux effleurent la suite de l'œuvre. <br>
                                Enfin, la reconnaissance et la gloire Les musées se le disputent. Paris, Londres,
                                Berlin, New-
                                York, Tokyo... Les plus grandes galeries du monde l'exposent. On s'arrache ses tableaux.
                                L'argent
                                ne lui fait plus défaut. <br>
                                <i>Les murs gris d'Argenteuil sont remplacés par les baies vitrées de son nouvel atelier
                                    de I'île
                                    de la Cité. La fenêtre entrouverte laisse pénétrer à grands flots une lumière d'or.
                                    Le pont Marie col-
                                    porte les murmures du temps rapportés par les eaux apaisées. Dans le coin droit de
                                    la pièce, plus
                                    de tableaux attendant le client. En revanche, dans un apparent désordre, plusieurs
                                    chevalets avec
                                    une toile en voie d'achèvement. Sur l'une, le portrait de son fils. Sur une autre,
                                    Ilda devant une fe-
                                    nêtre ouverte. A son côté, posé sur une table, un vase de cristal avec un bouquet de
                                    roses rouges.
                                    Sur la nappe blanche et brodée bleu, quelques pétales rouges, <strong>comme des « i
                                        » de points
                                        d'interro-
                                        gation.</strong> A I 'arrière plan, le froid d'un cimetière, un petit matin
                                    brumeux de novembre.
                                    Plus loin en-
                                    core, la plume des cyprès raconte au vent le bruit des guerres. Sur d'autres toiles,
                                    la vie facile,
                                    les
                                    réceptions, les flagorneurs... </i> <br>
                                Pour ses quatre-vingt-trois ans, c'est la rétrospective de son œuvre au Grand Palais. On
                                s'y
                                presse. On s'y extasie. La presse s'enthousiasme. C'était un génie ! Le plus grand du
                                siècle. Le
                                Louvre passe commande. <br>
                                Depuis, Lorenzo travaille à cette gigantesque fresque, la plus grande qu'un homme n'ait
                                ja-
                                mais peinte. <br>
                                <i>Les périodes d'euphorie succèdent à celles plus sombres du doute, des interrogations.
                                    Sur
                                    les visages, parfois, l'espoir et l'amour cèdent le pas au désespoir ou à la haine.
                                    Après la guerre,
                                    vient la paix. Après la mort : la vie. Après les ciels d'encre : les ciels d'azur.
                                    Tout n 'est que
                                    recom-
                                    mencement. </i> <br>
                                Lorenzo se lève, gagne la table de bois blanc où s'étalent ses couleurs. Beaucoup de
                                noir, de
                                gris, un peu de bleu et quelques taches de rouge. Décidée, la main saisit le pot de
                                blanc et un rou-
                                leau. Oppressé, il se dirige vers le premier panneau de la fresque. Dans un opéra
                                wagnérien de
                                gestes angoissés, la main va et vient, frénétique. Vingt-trois heures cinquante-cinq. Le
                                temps presse.
                                Le siècle va mourir. Le travail commencé doit être achevé. A ses amis et à la presse, il
                                avait donné
                                rendez-vous le premier janvier, à zéro heure une. Une sueur froide perle sur son front.
                                Un frisson lui
                                descend le long du dos. La main se presse. Le blanc s'achève. Il prend du noir et met la
                                &dernière
                                touche à Son chef-d'œuvre : <strong>la fresque de la vie d'un enfant du siècle</strong>
                                Quelques instant plus tard, on sonne. Les invités, stupéfaits. se trouvent face à une
                                toile
                                blanche. Les flashs ne crépitent pas. Un silence pesant écrase le plafond sur les
                                visages ahuris. Seul.
                                un point d'interrogation noir, inachevé, côtoie une colombe dont on ne voit que le
                                rameau
                                d'olivier sur toile. S'arrachant du blanc de l'oubli, par la porte entrouverte de
                                l'atelier, l'oiseau
                                s'envole vers le ciel vierge du futur Le rameau tombe sur le sol ou git Lorenzo.
                                Sur sa poitrine : un point rouge, comme le « i » du point d'interrogation.
                            </p>>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal9 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : La fresque de la vie d'un enfant du siècle</h3>
                <h4 class="auteurnouvelle">par Jean-Christophe Vertheuil</h4>
                <p>Lorenzo, la main tremblante, repose le pinceau sur la palette. Beaucoup de noir, de
                    gris, un
                    de bleu et quelques taches de rouge, Trois longues années de labeur s'achèvent. La fresque se-
                    rait livrée en temps voulu. Le Musée National des Invalides lui en avait passé commande. Son
                    œuvre, le chef-d'œuvre de Sa Vie, doit recouvrir les murs de l'intérieur des arcades ceinturant la
                    grande cours du musée de l'armée.
                    Ce...</p>
                <div class="tourbutton">
                    <button @click="showModal9 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal10 }">
                    <div class="modal-background" @click="showModal4 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">L'Azuré du Serpolet</h3>
                            <p>
                                <strong>290717</strong> <br>
                                Depuis combien de temps vivons-nous dans cette obscurité, ventilés par des moteurs. avec
                                comme seule lumière le soleil dans notre souvenir. Avons-nous mérité de devenir ces
                                hommes-
                                taupes qui fouissent dans des galeries obscures, tantôt couchés, tantôt accroupis,
                                creusant avec
                                ces pics mal emmanchés, puis évacuant vers l'arrière un mélange de terre et de rocaille
                                noyé
                                dans une poussière âcre ? <br>
                                On nous avait pourtant promis que nous serions relevés au bout de quelques jours, que
                                d'autres
                                viendraient vivre dans ces galeries. Mais non, rien ni personne. Et ce silence ! Cette
                                interdiction
                                de parler. Chuchoter à la rigueur, pour demander une pioche, expliquer que la hotte est
                                remplie,
                                en réclamer une vide... <br>
                                Faire le moins de bruit possible car «les autres » pourraient entendre. Ils nous
                                écoutent là-haut.
                                ils ont posé sur le sol des stéthoscopes géants comme pour ausculter les entrailles
                                d'une vieille
                                femme agonisante, écouter ses artères, prendre son pouls. Se demander quel rythme bat
                                son
                                cœur, savoir si elle respire encore, tenter d'interpréter un feulement ou un râle...
                                <br>
                                Ils font comme nous, après tout, C'est à celui qui dénichera le premier un ronflement,
                                un hoquet,
                                le cliquetis d'un outil dans cette sape qui court, court, sur des centaines de mètres
                                sous terre. Un
                                réseau de galeries qui se croisent, serpentent, montent, descendent, bifurquent. Et,
                                quand nous
                                aurons atteint la cote fixée comme objectif, chacun devra se replier, toujours en
                                silence,
                                remporter ses outils, ramper vers la sortie pour retrouver, enfin, la lumière du jour.
                                Une cohorte
                                de fourmis cheminant le long des galeries, en file indienne, silencieuse, Une
                                gigantesque armée.
                                docile et soumise. <br>
                                Puis interviendront d'autres camarades, les artificiers, les spécialistes de l'explosif,
                                les poseurs
                                de mines, ceux qui savent aligner les tresses de fils et disposer les engins de mort
                                dans les
                                souterrains. Ceux qui diront quand et comment appuyer sur le détonateur pour provoquer
                                la
                                gerbe finale. Alors, le sol s'ouvrira en un gigantesque cratère pour éventrer cette
                                terre de Meuse.
                                Jadis belle et fertile, elle ne sera plus qu'un désert lunaire, inerte, inanimé. <br>
                                <strong>110817</strong> <br>
                                Enfin relevés après cet enfer souterrain de plusieurs semaines à Vauquois. En quittant
                                le site,
                                sous une faible pluie d'été, je me suis retourné vers notre passé. Du village, il ne
                                restait pour
                                ainsi dire plus rien, sauf le vague éperon du clocher dominant un champ de pierres
                                effondrées. Je
                                supposais que les habitants avaient été évacués mais je ne pus m'empêcher de penser mes
                                deux
                                petits, chez moi, au bord de la Moselle, à leur mère, à ma famille. Je crus sentir des
                                larmes se
                                mêler à la pluie qui ruisselait sur mes joues tandis que nos pas nous emmenaient dans la
                                boue.
                                Notre compagnie se dirigea ensuite vers Pont-à-Mousson et le train nous déchargea dans
                                les
                                parages de Thiaucourt Il s'agissait de renforcer la 7è et la 8è compagnie venues de
                                Mars-la-Tour
                                pour contenir les assauts lancés par les Français depuis les flancs du Bois-le-Prêtre.
                                Après de
                                longues heures de marche, notre nouveau cantonnement fut atteint près de Regniéville.
                                Ici, tout semble calme. La forêt nous entoure, dense par son couvert, généreuse pour Son
                                ombre.
                                Notre camp est installé près d'une clairière, d'où nous avons repris tout un système de
                                tranchées
                                à demi détruites. Les mines tripodes à ailettes des Français ont atteint leur objectif.
                                Notre tâche
                                principale sera de consolider les tranchées de roche et de marne, de tresser de
                                nouvelles claies Où
                                elles Sont effondrées, de les renforcer avec de la terre, On dit que le village de R, a
                                été détruit à
                                force d'attaques répétées. <br>
                                <strong>150817</strong> <br>
                                Messe du 15 août ce matin. Temps chaud. Tout est calme. Dans Ie camp d'en face, les
                                Franzmänner ne bougent pas. Alors, l'aumônier catholique nous a proposé une marche en
                                guise
                                de procession vers l'église de Regniéville cet après-midi. Quelques longs kilomètres
                                mais
                                l'ardeur au cœur être en communion avec toute ma famille et tous les paroissiens de
                                Contz qui
                                célèbrent eux aussi le Leiffrawëschdag. Alors, tandis que nous cheminons, je cueille de
                                quoi faire
                                un bouquet pour Notre-Dame : fleurs des champs, épis sauvages, chèvrefeuille, humbles
                                graminées. Quelques tiges tressées me serviront à nouer l'ensemble. <br>
                                Un camarade s'est approché de moi, visiblement intrigué par mon assemblage. II cueille
                                trois
                                bleuets, me les tend en souriant et se présente : <br>
                                <i>- Lieutenant Jünger. Ernst Jünger. Ses yeux semblent avoir emprunté la couleur de la
                                    fleur
                                    Et tu peux me dire ce que tu es en tain de faire ? <br>
                                    - Ee Léiffrawësch, dis-je en prenant un air mystérieux, tu comprends ?
                                    - Non, mais je devine. Quelle langue parles-tu et d'où viens-tu ?
                                    - C'est ma langue régionale, mon Plattdeustch. Je viens du pays de Sireck, sur la
                                    Moselle.
                                    près du Luxembourg, Et ceci un bouquet, un "wësch" en l'honneur de notre Léif
                                    Fra, la Sainte Vierge, puisque c'est elle que nous célébrons en ce jour le
                                    "Léiffrawëschdag"
                                    « Je m 'appelle Jean Beauregard, les camarades m'appellent Jheng. <br>
                                    Enchanté de te connaitre. Dans ma région, nous portons la statue de Notre-Dame en
                                    procession pour le 5 août, et nous lui offrons un bouquet garni de fruits, de
                                    légumes ou
                                    de fleurs. Alors, je prends ce que je trouve dans la nature... <br>
                                    - Là-bas, tu as des cynorhodons d'églantier, des clématites ou des baies de
                                    prunellier, si tu
                                    veux Je vais te chercher ça. <br>
                                    -Merci mais tu vas te piquer. Tu as l'air de bien connaitre les plantes ? <br>
                                    - Disons que je m 'intéresse à la nature, à la botanique, aux insectes aussi.
                                </i><br>
                                Puis nous sommes arrivés à l'église, ou plutôt à ce qu'il en reste. Clocher sans toit,
                                cloche de
                                guingois. Grand portail disloqué. Fenêtres éventrées, vitraux détruits, éclats à terre.
                                Statues
                                brisées, décapitées Sol jonché de gravats, pierres, bancs, poussière. Nous nous avançons
                                dans ce
                                silence artificiel, imposé par les circonstances. Dans une chapelle latérale, une grande
                                Vierge,
                                intacte. Au centre du chœur : le crucifié. Il n'a jamais si bien porté son nom. Son bras
                                gauche est
                                sectionné à l'épaule, il pend dans le vide, exsangue, retenu par le clou du gibet. Sa
                                tète inclinée
                                porte les traces de sang infligées par la couronne d'épines. Un rayon de soleil
                                inattendu
                                transperce son Cœur une deuxième fois. Notre aumônier s'est agenouillé : il se signe,
                                Nous
                                faisons de même. Sauf Ernst, qui est resté un peu en retrait. <br>
                                Puis le groupe fait halte devant l'autel de la Vierge. L'aumônier m'invite à déposer mon
                                bouquet. II entonne le « Magnificar» dont les échos vont se perdre au-dehors, à travers
                                les
                                vitraux vidés. Temps de silence. «Sainte Marie. mère de Dieu. priez pour nous. pauvres
                                pécheurs... » Oui, priez pour nous, prions pour nous, pour ces désastres, ces massacres,
                                dont nous
                                sommes responsables. <br>
                                Sur le chemin du retour, Ernst et moi faisons connaissance. Il a un parcours étrange,
                                famille
                                prussienne aisée, baroudeur dans la Légion étrangère, militaire dans l'âme, blessures
                                multiples,
                                médailles. Je lui explique qui je suis, Lothringer d'origine modeste, instituteur,
                                marié, deux
                                enfants. Au fond de l'église, il m'a vu écrire dans mon petit carnet ; je lui ai
                                expliqué pour les
                                haïkus, ces petits poèmes japonais sur 3 lignes... Il connaît. Lui aussi tient un
                                journal. <br>
                                Mon haïku du jour : Aujourd'hui Quinze août / Comme un champ d'actions de grâces / Pour
                                louer Marie. <br>
                                <strong>180817</strong><br>
                                <i>Silence rompu dès 6 heures, le canon a repris sa rengaine. Pluies d'été, orageuses,
                                    drues. Après
                                    l'inspection des tranchées, la vérification des lignes téléphoniques et des
                                    barbelés, Ernst m'a
                                    invité dans sa cagna, un abri de branches couvert d'une toile qui lutte contre le
                                    ruissellement des
                                    pluies. Installation rudimentaire : quelques caisses de munitions vides superposées
                                    servent
                                    d'étagères dans un recoin. Sur l'une d'elles, des objets. Une boîte ouverte : un
                                    herbier, fleurs et
                                    insectes. Une Croix de fer. Des pierres, coquillages, ammonites qui calent quelques
                                    ouvrages.
                                    Sur la tranche, j'essaye de déchiffrer leur titre : Thomas Mann « La Mort à Venise
                                    », « Les
                                    Buddenbrooks », A.Dumas « Le Comte de Monte Cristo » ; un dictionnaire
                                    français-allemand ;
                                    une vieille Flore d'Europe défraichie. Il m'a proposé un schluck de schnaps. Un peu
                                    tôt en
                                    journée, mais c'est si rare, alors ça ne se refuse pas. <br>
                                    -Tu penses remporter toutes ces pierres avec toi, Ernst ? dis-je en souriant. <br>
                                    -Pourquoi pas ? La guerre sera bientôt finie : ce seront mes trophées avec mes
                                    médailles
                                    et ma Croix defer. Tu te rends compte : des fossiles qui ont deux cent millions
                                    d'années
                                    et qui ont connu la mer en Lorraine ? Quelles plus précieuses traces de la vie sur
                                    terre ? <br>
                                    -Ici, chez toi, Jheng, in Lothringen, chez nous, in Deutschland ? Tu te rends
                                    compte, au
                                    moins ? <br>
                                    -C'est curieux, Ernst. Parfois j 'ai l'impression que nous sommes frères, parce que
                                    frères
                                    d'armes, membres du même Deutsch-land ; et parfois, j'ai l'impression que nous
                                    sommes
                                    adversaires ... <br>
                                    -Et pourquoi donc ? me demande-t-il d'une moue suspicieuse. <br>
                                    -A cause de votre méfiance à notre égard. Vous n 'aimez pas les soldats d'Elsa/-
                                    Lothringen. Vous vous méfiez de nous, et parfois même... <br>
                                    -Qu'est-ce qui te permet de dire ça ? <br>
                                    -Quand j'étais caserné à Metz, avec les Bavarois et les Saxons, je le remarquais
                                    bien. J'ai
                                    entendu des propos désagréables, surtout de la part de camarades prussiens ... <br>
                                    -Halte, n 'oublie pas que je suis prussien, Jheng ! Oui, je sais, beaucoup de gens n
                                    'aiment
                                    pas la Prusse. C'est de la jalousie, parce que nous avons une histoire plus riche,
                                    le
                                    royaume de Prusse, des traditions, des valeurs de travail, de discipline ... <br>
                                    -Mais regarde, Ernst : ne dit-on pas que beaucoup d'Alsaciens-Lorrains sont affectés
                                    automatiquement sur le front russe ? Parce que l'Etat-major allemand ne leur fait
                                    pas
                                    confiance, et croit que nos soldats pourraient déserter, rejoindre les Français
                                    -C'est vrai, Jheng, je l'ai entendu moi aussi. Et toi, que ferais-tu si tu en avais
                                    la
                                    possibilité ? Tu n 'irais pas te rendre prisonnier chez les Français ? <br>
                                    -Je ne sais pas, à vrai dire. Je ne connais personne en France, à part un vague
                                    petit-
                                    cousin éloigné qui est parti pour Nancy en 1872. Tu sais, ceux qu'on appelle les
                                    « optants », ceux qui ont opté pour la France parce qu 'ils ne voulaient pas devenir
                                    Allemands. Ce fut un choix difficile, tu sais : mes parents sont nés Français et
                                    parlent le
                                    français, que tu connais bien, d'ailleurs. Et toi, Ernst, que ferais-tu si tu étais
                                    dans la
                                    même situation ? Tirerais-tu sur tes cousins d'en face ?</i> <br>
                                Jünger marqua un silence. Il flaira le piège dans ma question, alluma une cigarette et
                                planta
                                son regard dans le mien en expulsant une longue fumerolle bleuâtre. <br>
                                <i>-Je ferais mon devoir, Monsieur Schang. dit-il en prononçant mon prénom français avec
                                    un accent germanique appuyé. Même si j'aime beaucoup la France, je ferais mon devoir
                                    de soldat, je serais fidèle à mon uniforme et à mon Kaiser. Nous avons juré fidélité
                                    à
                                    Guillaume II, souviens-toi. Nous sommes des soldats. Jheng. Faire la guerre est
                                    notre
                                    honneur, parce que... </i> <br>
                                Une canonnade vint nous interrompre, ponctuée par le sifflement sinistre d'un fusant.
                                Explosion
                                au-dessus de nos têtes. Ernst m'avait projeté à terre. Un tronc d'arbre s'abattit près
                                de nous. L'un
                                des rares survivants de la forêt, démembré, orphelin depuis tant d'assauts. Ses
                                cannelures de
                                bronze révélaient son identité : un chêne, l'écorce déchiquetée par de nombreux impacts,
                                cicatrices claires de métal ternies par le temps et les flaques de boue dans lesquelles
                                il gisait à
                                présent, non loin de nous. Sous le moignon d'une branche, l'uniforme d'un camarade
                                écrasé,
                                bras en croix, face contre terre, semblait rappeler une guerre inégale, L'homme foudroyé
                                tenait
                                encore son fusil, et son Stalhelm bien ajusté sur sa tête le protégeait des éclats
                                ennemis..
                                Dérisoire, sous ce tronc ! <br>
                                Haïku pour ce valeureux Hic et nunc miles / Sic transit gloria tua / In Mosella valle
                                <strong>200817 </strong>
                                Hier, nous avons fait route vers la Moselle, entre Thiaucourt et Jaulny, Il s'agit de
                                consolider nos
                                positions de crainte d'un assaut des Français. Pendant une pause, nous nous sommes assis
                                sur un
                                coteau dominant la vallée. Au loin, je devine ma chère rivière qui coule vers vous, Une
                                petite
                                cinquantaine de kilomètres, par Metz et Thionville, et ce serait Contz, la belle vallée
                                de Sierck.
                                Une simple barque, quelques coups de rame, un courant généreux.,_ près de moi, penché
                                au-
                                dessus des herbes qu'il inspecte avec sa loupe, Ernst m'a sorti de ma rêverie insensée.
                                <br>
                                <i>- Och Mensch, ein Schwarzflecken Blauling... Phantastisch !
                                    - Qu'est-ce qui t 'arrive. Ernst, tu as trouvé un trésor ? dis-je en sursautant.
                                    - Oui mon ami. tu ne crois pas si hien dire. Il s 'agit d'un papillon très rare.
                                    Komm mal
                                    hier et regarde bien bleu lumineux comme un ciel d'été, taches noires circulaires au
                                    bout des ailes. Jheng Beauregard, je présente Maculinea Arion, le bel azuré du
                                    serpolet.
                                    -Mais qu 'est-ce qu 'il a de spécial, Ernst, c 'est juste un papillon, comme les
                                    autres ; il y en
                                    a des dizaines, non ?
                                    -Alors là, tu te trompes gravement, Monsieur I 'instituteur. Celui-ci fait partie
                                    d'un cycle
                                    de vie qui lui permet d'assurer sa reproduction et sa descendance d'une manière très
                                    spé cialisée.
                                    -Ah bon ? Et tu peux m'expliquer ça ?
                                    -Avec plaisir. Tu vois cette herbe avec des fleurs en bouquets violets ? C'est du
                                    thym, du
                                    thym serpolet. Et là-dessus, regarde bien, le papillon va pondre ses œufs. Il a
                                    choisi
                                    exprès cette fleur-là, ou parfois de l'origan. Viens voir avec ma loupe, regarde !
                                    -Ah ! des petites billes blanches. Mais pourquoi choisit-il cette plante en
                                    particulier ?
                                    -C'est là que ça devient intéressant. Quand les œufs vont éclore, chacun va donner
                                    une
                                    chenille. Et cette chenille, dans quelquesjours, va se nourrir de cettefleur. Et
                                    attends, ce
                                    n'est pas fini, La chenille va ensuite se laisser tomber de la plante. et des
                                    fourmis se
                                    rapprocher d'elle. Là, ça devient passionnant.
                                    - Et pourquoi donc ? Quel rapport entre une chenille et des fourmis ?
                                    - Ha ha ! Justement, ce ne sont pas les petites fourmis ordinaires que tu connais,
                                    mais
                                    mirmica sabuleti. Plus grosse, un peu rouge. Elle a déjà senti une Odeur émise par
                                    la
                                    chenille et va Se rapprocher d'elle pour la flatter. La chenille va alors sécréter
                                    un miellat
                                    que les fourmis adorent Sucer. Elles vont ensuite transporter la chenille dans leur
                                    fourmilière Où tout ce petit monde va passer l''hiver. Les fourmis prennent soin de
                                    la
                                    chenille qui. en retour, leur donne son miellat. Echange et don solidaire !
                                    - Impressionnant ! Et ensuite, que se passe-t-il au printemps ?
                                    - La chenille va se transformer en chrysalide, cesser d'émettre son miellat et les
                                    fourmis
                                    vont se désintéresser d'elle. Puis elle va se métamorphoser en un beau papillon bleu
                                    aux
                                    ocelles noires, qui ira pondre ses œufs sur le thym serpolet. Et tu connais
                                    maintenant la
                                    suite...
                                    - Œufs, éclosion, chenille, miellat, fourmi, chrysalide. Pourquoi cet univers
                                    t'intéresse-t-il
                                    tant, Ernst ?
                                    - Je trouve tout cela si fascinant. Ce cycle de la nature si bien organisé, ces
                                    fourmis qui
                                    prennent en charge les chenilles, qui travaillent en obéissant à leur instinct, dans
                                    la plus
                                    grande solidarité. et qui permettent à la chrysalide de devenir un papillon, qui va
                                    nous
                                    Offrir sa beauté puis perpétuer son cycle naturel. Un peu comme une grande armée.
                                    faite
                                    de milliers de soldats au service d'un chef ou d'un idéal qui les dépasse. Chacun a
                                    sa
                                    place et son rôle à jouer, sa mission à accomplir. et il le fait sans poser de
                                    questions,
                                    comme un fier soldat qui devient du même coup un héros. </i>
                                Je suis resté silencieux. Une telle logique me laissait sans voix. Le fonctionnement
                                d'un groupe
                                humain calqué sur celui d'un cycle animal naturel ? J'ai repris la parole peu après,
                                tandis que sa
                                loupe continuait d'explorer d'autres plantes. <br>
                                <i>- D'accord Ernst, accomplir sa mission, à condition de ne pas y perdre son âme !
                                    - Quelle âme, Jheng ? Les chevaliers des croisades qui partaient défendre le temple
                                    de
                                    Jérusalem n 'étaient-ils pas engagés pour une noble cause qui les dépassait ?
                                    Librement,
                                    au service d'un juste idéal ? </i>
                                Notre conversation fut interrompue par le rappel à l'ordre d'un bombardement lointain.
                                Et notre
                                repli nous éloigna de cet étrange papillon bleu et de Jérusalem. <br>
                                <strong>110917</strong>
                                ...une gerbe de teme et de boue mêlées s'éleva vers le ciel en une gigantesque salve de
                                libération. Comme un vrai feu d'artifice, avec le tonnerre des explosions, mais sans les
                                couleurs,
                                excepté une pauvre palette bicolore de gris et de grenat ! Rouge carmin, touches
                                vermeil, giclées
                                généreuses vers le ciel et trainées fluides qui retombaient en tachant le paysage et nos
                                uniformes.
                                <br>
                                <strong>04 octobre 1917 — Verdun (Meuse) </strong>
                                A Madame Beauregard, Niederkontz (bei Sierck) <br>
                                J'ai le triste devoir de vous apprendre que votre mari, le sous-lieutenant Jean
                                Beauregard, est
                                tombé au champ d'honneur le 18 septembre 1917 à Fleury-devant-Douaumont, alors que nous
                                subissions un féroce assaut de la part des armées françaises. Je tiens à préciser qu'il
                                est mort en
                                héros parmi sa compagnie de Pionier qui a longuement célébré son souvenir et l'excellent
                                camarade qu'il fut. Il était animé d'un réel esprit de patriotisme et nous espérons que
                                son
                                sacrifice ne sera pas vain pour mettre fin à cette guerre qui a déjà pris trop de vies.
                                Vous
                                trouverez, avec ce courrier, quelques effets ayant appartenu votre mari. Je vous
                                transmets, pour
                                conclure, l'expression de ma vive sympathie. <br>
                                Signé : Feldwebel Langweiler <br>
                                <i>Lentement. avec minutie. en luttant contre le not de larmes qui voilaient ses yeux et
                                    noyaient ses
                                    joues. Louise défit la ficelle de jute qui enserrait une liasse de documents, Avec
                                    précaution, elle
                                    les disposa Sur la vieille table en bois de la cuisine. après avoir repoussé une
                                    miche de pain et
                                    un pot de confiture puis déployé un linge propre : leur image de mariage, une photo
                                    des enfants.
                                    les cartes et lettres reçues par son mari, des « Feldpostkarten » encore vierges.
                                    une petite bible
                                    Offerte par Sa marraine, un livre La Mort à Venise de Thomas Mann, ainsi que trois
                                    cahiers
                                    numérotés, Dans le dernier. titré de I 'écriture régulière de son mari. elle se
                                    reporta
                                    machinalement à I 'ultime page manuscrite. datée du 16 septembre 1917. Pourquoi
                                    avait-il ainsi
                                    codé ses dates : 160917 pour économiser I 'encre ? Pour leurrer un lecteur indiscret
                                    ? Pour se
                                    donner un genre ? « Mon tendre amour. pensa-t-elle. ru adorais écrire par énigmes,
                                    surtout dans
                                    nos lettres infimes... Etait-ce ta façon de penser à moi ? de conjurer le temps qui
                                    nous
                                    éloignait ?»</i> <br>
                                <strong>160917 </strong>
                                J'ai finalement décidé d'appeler ce troisième cahier Maculinea Arion Un peu en hommage à
                                ce camarade venu d'un ailleurs qui m 'échappait, à ce soldat aux étoiles inaccessibles,
                                à ce
                                chasseur... de papillons, à ce chevalier d'un Walhalla si personnel, que je n'ai pas
                                toujours su
                                bien comprendre. <br>
                                Voilà notre guerrier idéaliste parti pour une autre affectation de guerre en France,
                                tandis que ma
                                compagnie se retrouve ici près de Verdun, Fleury, un si joli nom. Mais je ne peux
                                toujours pas
                                adhérer à sa construction du monde, qui a jeté sur ces terres laminées tant de vies
                                désormais
                                détruites. Et qui ruinera pour toujours les idéaux qui nous ont animés, et qui devaient
                                instaurer la
                                paix entre les nations. « Si vis pacem, para hellum» comme ils disent ! <br>
                                A l'heure de monter au combat ou de retrouver mon cher cahier, je ne peux oublier Ernst,
                                car
                                l'écho de nos conversations résonne toujours en moi. Malgré moi, comme l'aiguille d'un
                                aimant
                                que l'on tente de leurrer et qui finit toujours par se cabrer en direction du nord.
                                Indomptable.
                                Irrésistible. Comme lui. <br>
                                « Que notre Dieu "élaire cet intrépide en lui donnant la lumière de la sagesse, à jamais
                                !
                                <span style="justify-content: center ;">Dona ei
                                    Lux sapientiae
                                    Domine semper ! » </span>
                                Vota Bene. Au-dessous de ce texte, en le protégeant tant bien que mal par un voile de
                                gaze, j'ai
                                fixé un « Maculinea Arion - Schwarzflecken Blauling - Azuré du serpolet » qu'il m'avait
                                offert.
                                J'espère que son éclat bleu, aussi dense que celui de ses yeux, continuera d'illuminer
                                nos regards
                                malgré les épreuves.

                            </p>

                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal10 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : l'Azuré du Serpolet</h3>
                <h4 class="auteurnouvelle">par Armand Bemer</h4>
                <p>290717
                    Depuis combien de temps vivons-nous dans cette obscurité, ventilés par des moteurs. avec
                    comme seule lumière le soleil dans notre souvenir. Avons-nous mérité de devenir ces hommes-
                    taupes qui fouissent dans des galeries obscures, tantôt couchés, tantôt accroupis, creusant avec
                    ces pics mal emmanchés, puis évacuant vers l'arrière un mélange de terre et de rocaille noyé
                    dans...</p>
                <div class="tourbutton">
                    <button @click="showModal10 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2014 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal11 }">
                    <div class="modal-background" @click="showModal11 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Un si long voyage</h3>
                            <p>Ma vie a commencé dans une biscuiterie à Nantes. J'étais toute belle, fraichement
                                sortie de l'usine dons une robe argentée décorée d'une image sereine : une façade de
                                maison
                                eurie d'hortensias et un chat qui dort sur le rebord d'une fenêtre : le reflet de la
                                paix sans
                                mauvaises promesses. <br>
                                Remplie de gâteaux secs, je me suis reposée quelques mois sur l'étagère d'une petite
                                épicerie de campagne avant de migrer dans une famille ordinaire : le père besogneux,
                                quatre
                                enfants échelonnés entre 20 et 8 ans et leur mère qui élevait la voix, bizarrement après
                                le passage
                                de petites mains qui me vidaient de mon contenu. J'étais la boite à gâteaux, la boite à
                                gâter. Vide
                                et froide, j'ai fait le bonheur d'Emile qui rangeait ses billes en sortant de l'école.
                                Un jour, les
                                billes ont déménagé. J'allais tenir mon rôle d'étui du kilo de sucre justement tassé à
                                mon gabarit.
                                Eh non ! Sur la table de la cuisine, j'ai repris ma fonction de boite nourricière,
                                bourrée à craquer
                                de cochonnailles, de pâtés, de saucissons et dans le coin gauche, d'un paquet de tabac
                                gris, le tout
                                posé sur une enveloppe adressée à Lucien, l'ainé des enfants stationné à Douaumont. Bien
                                emballée dans un journal et un papier de récupération, le service de la poste m'a
                                emportée sans
                                précaution vers l'Est, un voyage de chocs violents avec d'autres colis qui suivaient le
                                même rail.
                                Des arrêts et des départs, des séjours dans des lieux humides, le voyage m'a semblé
                                long, très
                                long. <br>
                                Arrivée enfin, des mains boueuses m'ont à mise à nu. Le déshabillage a amené
                                autour de moi des uniformes de guerre portés par des soldats amaigris. Que dire de la
                                joie
                                débordante de Lucien à la lecture de la lettre écrite par sa mère lui disant à mots
                                couverts tout son
                                amour et sa peur de le savoir au front, aux lignes violettes de ses frères et au petit
                                gribouillis de
                                Simone, la petite dernière. Du haut de ses huit ans, elle avait dessiné la chatte «
                                Mounette », le
                                ventre arrondi par les petits pois volés autour du poulet rôti. Les mots de sa mère et
                                ceux de son
                                père « Courage mon fils, on les aura » ont fait couler des larmes de joie et de peine
                                confondues.
                                Les victuailles ont été appréciées par les copains groupés autour de moi qui
                                retrouvaient le goût
                                de leurs produits : un instant unique avec une cigarette, roulée avec tendresse et
                                précaution de ce
                                gris, économisé au brin près et chanté sur tous les tons quelques années plus tard. <br>
                                - Garde la boite Lucien, on y rangera nos faits de guerre et nos souvenirs.
                                Mon parcours n'était donc pas fini, Je faisais partie du barda. Je suis devenue
                                l'amie fidèle, la confidente, la Compagne à protéger : une sorte de coffre-fort, de
                                boite à secrets
                                digne de recevoir la plus petite parcelle de la vie de ces soldats pour garder en
                                mémoire l'instant
                                qu'ils ne pourraient oublier, une fois la victoire gagnée, la peur au ventre, les tripes
                                labourées par
                                la trouille. La victoire, ils y pensaient sans y croire. <br>
                                Je me suis alourdie de babioles rattachées à un mot, une situation, un instant, un
                                souvenir à retenir. <br>
                                - la coquille égarée d'un bulot des Côtes Normandes. Louis qui connaissait ces côtes
                                la mettait contre son oreille pour entendre la mer. Pendant quelques secondes il
                                oubliait l'horreur
                                du moment. <br>
                                - une balle de mitraillette qui s'était fichée dans la guêtre de Joseph. Récupérée avec
                                précaution, il la remerciait presque de ne pas avoir achevé sa tâche. <br>
                                - un briquet à pierre qui a repris du service avec les cigarettes roulées. <br>
                                - un rameau de cerisier fané. La rouille avait chassé le rose des fleurs, mais Lucien
                                ne la voyait pas, Il ne voyait que la douceur des pétales qui le renvoyaient au teint de
                                Louise, la
                                voisine de la ferme de Clairette qu'il croisait en revenant des champs, alors qu'elle
                                pédalait sec
                                sur son vélo. Juste quelques mots sans importance, bafouillés timidement sous la
                                pression de deux
                                cœurs qui battent vite et trop fort. Penser à elle lui redonnait du courage et de
                                l'espoir. Sans
                                promesse formelle, il la devinait inquiète à son sujet. La veille de son départ, il
                                s'était posté sur
                                son chemin pour la voir une dernière fois et s'emplir les yeux, le coeur de son âme
                                discrète qu'il
                                avait découverte dans l'éclat triste de son regard. En silence, ils s' étaient donné un
                                baiser chaste et
                                tiède, un baiser presque naïf d'enfants qui se séparent avant les grandes vacances. A
                                défaut de
                                mots, les yeux ne trompent pas. <br>
                                -un éclat d'obus tordu, sans histoire. Chacun des hommes lui avait trouvé une
                                ressemblance : un oiseau privé de son aile droite, ou un lion au cou de girafe, une
                                série d'éclopés
                                qui les faisait rire jaune. <br>
                                - le muselet d'une bouteille de champagne tout aplati. Redressé, il pourrait remplacer
                                un lacet ou servir de cure-dent. Le système « D » prenait toute sa place dans cette
                                époque
                                guerrière. <br>
                                - le rasoir coupe-chou qui ne coupait guère. Il n'y avait plus de savon pour le
                                blaireau et le miroir avait perdu par écailles, sa couche de tain. Et puis, à quoi bon
                                Se raser ? On
                                ne nait pas poilu, on le devient contre son gré et faute de moyens. <br>
                                - Tout au fond, l'harmonica du père de Lucien. Compagnon plaintif des moments
                                tristes, jusqu'ici rangé dans la poche de la vareuse du soldat, il faisait partie des
                                trésors de guerre
                                à protéger. <br>
                                Et surtout, le bien le plus précieux, un carnet noir serré par un élastique souvent
                                manipulé. Chaque jour, au creux de la mitraille, les mêmes mains parfois mouillées
                                griffonnaient
                                quelques lignes serrées, voire quelques pages avec un crayon de bois qui commençait à
                                avoir
                                "mauvaise mine". Lucien abrégeait certains mots, une sorte de code secret (sait-on
                                jamais ! ) et
                                par économie. Où trouver un crayon après le trépas de celui-ci ? <br>
                                Lucien consignait les détails de l'avancée du régiment, donnait ses impressions sur
                                la popote froide et sur le moral des troupes : Joseph a peur. II ne dort plus et maigit
                                de jour en
                                jour. Aujourd'hui l'anniversaire d'Emile, gâté par les plus grands, tant mieux pour lui.
                                Sur
                                certains feuillets, c'est Louise qui prenait toute la place. Anticipant sur l'avenir,
                                Lucien écrivait
                                leur histoire. De rencontres anodines aux rendez-vous plus intimes, Il déclarait son
                                amour avec
                                des mots qu'il n'aurait su lui dire de vive voix. Taiseux comme son père, sa pudeur l'en
                                aurait
                                empêcjé, mais seul, face à son petit carnet, les mots lui venaient du fond du coeur : un
                                cri
                                d'amour que rien n'arrête, pas même la mitraille, fond sonore de tous les instants, qui
                                le ramenait
                                violemment à la réalité. II oubliait ces boyaux de boue, la vertmine, les rats, - ah !
                                les sales bêtes -
                                qui le bouffaient de partout, et s'isolait dans le réve lointain de sa vie avec Louise.
                                Il serait peut-
                                être trop tard à son retour, mais batir cet avenir le faisait tenir debout et lui
                                donnait
                                l'envie de s'en sortir. Ragaillardi, plein d'espoir, il remettait crayon et carnet toute
                                vitesse dans
                                la boite, la boite dans la musette et se sauvait. <br>
                                Certains jours, fatigué et désespéré, Lucien prenait son temps, (advienne que pourra !)
                                pour relire la lettre de sa mère. II la connaissait par cœur mais la relecture
                                réveillait
                                l'émotion d'une première fois. Le papier usé reprenait sa place dans le carnet noir et
                                la gorge
                                serrée, il se posait toujours la méme question : « Est-ce que je les reverrai un jour ?
                                <br>
                                - Lucien, qu'est-ce que tu fous ? Allez cours ! <br>
                                Alors, il rejoignait les autres, s'échappait avec eux, au-devant du danger en croyant
                                l'éviter. <br>
                                Après une mitraille infernale, tout semblait redevenu calme. Serrés dans une
                                tranchée, ils étaient tous là. débordant de joie d'avoir échappé au pire. « Ce n'est pas
                                encore pour
                                aujourd'hui». Lucien a sorti son harmonica et s'est mis à jouer la chanson qu'il
                                connaissait bien.
                                En choeur, ils ont repris « Le Temps des cerises » , chacun gardant pour soi le poids
                                des mots, leur
                                poésie, l'espoir de jours meilleurs, de printemps et de paix. Emportés par leur
                                allégresse, leurs
                                voix ont couvert le canardage qui faisait rage autour d'eux; un retour de feu imprévu.
                                Trop tard
                                pour s'échapper, Le tir était ajusté. Joseph et Armand, blessés ont été secourus. Lucien
                                et les
                                autres sont morts en chantant, et moi, orpheline, cercueil de leurs secrets j'étais là,
                                enterrée
                                vivante avec mes trésors dans une terre devenue mon cimetière. <br>
                                J'ai perdu la mémoire. Le bruit était infernal et il a duré...une éternité. J'ai fini
                                enfouie dans la dans un silence de mort. Plus rien ne s'agitait autour de moi. La paix
                                était-
                                elle enfin revenue ? <br>
                                Les dégradations successives du sol ont contribué ma destruction. La belle image
                                utopique de mon couvercle, s'est effacée et la rouille a entamé mes flancs jusqu'au jour
                                Où j'ai
                                revu la couleur du ciel. J'ai refait surface et d'autres mains m'ont recueillie et
                                gardée en secret. Je
                                n'ai pas reconnu l'uniforme de mon sauveur, ni l'accent de ses mots étrangers. Il m'a
                                protégée
                                comme une relique pour que le temps cicatrise les plaies de son âme. L'attente a duré au
                                fond
                                d'une malle dans un grenier mais je ne me sentais pas chez moi. Je me languissais.
                                désespérée, attendant l'irrémédiable. Mon histoire s'arrêterait là sans mémoire et sans
                                postérité,
                                pour la transmettre. Le carnet noir garderait ses déclarations inutiles. Un déménagement
                                m'a
                                sauvée. l'homme qui m'avait trouvée était grave et indécis. Avec des gestes pudiques, et
                                des
                                yeux tristes comme une caresse, il n'osait aller au bout de son envie. Je lui faisais
                                peur. Le passé
                                faisait encore mal, mais je savais qu 'un jour il aurait la volonté de vouloir connaitre
                                de l'autre ce
                                qu'il avait lui-même vécu parce que les hommes sont amis ou ennemis selon la phobie
                                meurtrière
                                de leur chef. <br>
                                J'ai livré mes secrets à l'homme enfin prèt à explorer mes tripes. Un soir au coin du
                                feu. avec précaution et hésitation, il a tout regardé en silence. La mèche du briquet
                                était moisie et
                                le rasoir rouillé. Il a reconnu la balle de mitraillette. Le Camet noir était intact et
                                sa lecture a
                                pris
                                beaucoup de temps. La dernière page datait du 17 Avril 1917. Il a refermé le livret, je
                                crois qu'il
                                pleurait. Livide et accablé, il a tout remis en ordre et mon couvercle s'est refermé une
                                dernière
                                fois. <br>
                                Pendant la nuit, j'ai entendu l'homme se retourner dans son lit, soucieux, chaviré
                                par les révélations de la veille et dès le jour levé, j'ai senti le poids de son
                                indécision. Ses yeux
                                braqués sur moi quémandaient la solution. Que faire de moi ? Me laisser mourir ou me
                                renvoyer
                                vers ceux qui ne m'attendaient plus. Rouvrir les plaies à peine refermées et troubler
                                leur vie
                                gratuitement. Où étaient le bien ou le mal, le droit ou le devoir, parler ou se taire à
                                jamais, des
                                sentiments difficiles à analyser et un choix impossible. Il s'est résigné à laisser le
                                monde en paix,
                                ne pas le peuplé de morts et de gueules cassées. Apaisé, l' homme m'a remise à
                                l'abri entre les plis de son uniforme et ses souvenirs conservés avec le même respect.
                                <br>
                                La longue maladie, l'émiettement me guettaient jusqu'à la mort au fond
                                d'une poubelle allemande, une mort sans gloire, celle des boîtes à sucre, objet banal,
                                capital par
                                intermittences voué à la destruction sans oraison funèbre : une parmi tant d'autres. Les
                                générations futures ignoreraient toute la tragédie intime de ces milliers de petits
                                carnets noirs. La
                                peur, la faim. le découragement, la crasse. il fallait les subir pour les écrire. Seuls,
                                les livres
                                d'histoire mentionneraient froidement cette portion de vie de ces combattants rivaux qui
                                croyaient
                                à leur supériorité militaire, à leur ivresse du pouvoir, à la qualité de leur matériel,
                                obéissant aux
                                ordres imbéciles pour sauver la patrie, la mère patrie, torturée, labourée, gorgée de
                                leurs sangs
                                mélangés. Quant un jour, un autre miracle : le jour a chassé la nuit, la porte du cachot
                                s'est
                                rouverte. Le soleil existe donc encore et la terre tourne toujours. <br>
                                Deux jeunes ont exploré ces vieilleries et leurs voix, d'abord moqueuses se sont tues
                                par I'émotion, Ils se sont souvenus de la douleur de leur père parlant de cette époque
                                tragique. Les
                                preuves de ces souffrances de part et d'autre de la frontière ont ramené la même
                                question
                                (décidément héréditaire) : Que faire de moi ? A cause ou grâce au recul du temps, la
                                décision a été
                                vite prise ; ma place était en France. J'allais reprendre le rail, accompagnée cette
                                fois par ces deux
                                imprudents, pressés d'agir, d'en savoir plus sur celui qui avait perdu la vie par l'un
                                des leurs,
                                avant que la nargue menacente ramène l'épouvante et trouble la paix éphémère. <br>
                                A destination, personne n'attendait de visiteurs, fussent-ils français, encore moins
                                allemands. L'accueil a été glacial. Aux uns et aux autres, tout leur est passé dans la
                                tète : la colère,
                                l'audace, la rancune. Chez les visiteurs, plutôt de la douceur, comme une excuse à
                                l'intrusion.
                                Faute de mots d'échange, les jeunes ont ouvert la valise et ma vue, les visages ont
                                blêmi.
                                incrédules à ce qu'ils voyaient. <br>
                                Personne ne paraissait pressé d'en savoir plus. Déposée sur la table d'autrefois,
                                Simone, grandie a coupé ficelle et papier. Les autres se sont approchés. Je les avais
                                quittés enfant,
                                devenus des hommes, je ne les ai pas reconnus. <br>
                                A peine ouverte, sitôt refermée ! Beaucoup d'émotion autour de moi, larmes de
                                Simone, la crainte dans les yeux d'Emile dans un silence pesant qui a plombé l'espace.
                                Ils ont
                                compris subitement tout ce que je représentais. L'inconnu les effrayait, les inconnus ne
                                leur
                                faisaient plus De chaque côté de la frontière, ils avaient eu la même jeunesse inquiète,
                                une
                                partie de leur Vie en commun. <br>
                                Une femme au regard gris s'est approchée. « Je reconnais cette boite, c'est celle de
                                votre frère. Je l'ai envoyée sur le front en 1915 pour son anniversaire et depuis... on
                                sait. Je
                                n'aurais jamais pensé qu'un jour le passé nous reviendrait de cette façon et vous avez
                                eu le
                                courage de venir jusqu'à nous ! <br>
                                Mon contenu a soulevé des questions sans réponses, supposé des états d'âme propres
                                à chaque objet, imaginé les peines et les joies durant ces années de malheur.
                                Marguerite, la
                                maman a lu et relu la petite écriture serrée du carnet souffrant après coup de ces
                                récits écrits avec
                                des mots simples. L'ombre de Lucien rôdait autour d'elle et lui soufflait tout ce qu'il
                                ne lui avait
                                jamais dit. Elle savait tout cela, mais aujourd'hui, qui mieux qu'elle pouvait ressentir
                                au plus
                                profond d'elle le calvaire vécu par son « grand». Elle a gardé le carnet et l'a recopié
                                en entier
                                sauf les pages destinées à Louise qu'elle s'est interdit de lire. Les secrets d'amour de
                                son fils ne la
                                regardaient pas. Elle savait pour Louise avant le départ pour cette maudite guerre ; une
                                mère sent
                                ces choses-là. Après, sans regret, elle a porté le Camet à Louise qui l'a reçu comme un
                                cadeau. Le
                                reste, sauf l'harmonica qui a fini dans les tranchées de la Meuse, a été distribué aux
                                enfants,
                                propre à eux d'inventer l'histoire qui leur manquait. <br>
                                Que sont devenues mes copines d'usine ? Ont-elles vécu de belles histoires plus
                                gaies que la mienne et qui les racontera ? Le sucre a t'il servi d'antirouille à celles
                                simplement
                                chargées de le tenir au sec sur l'ai de la cheminée ? D'autres sont peut-être le
                                cimetière des faire-
                                part de décès ou la boite à couture lourde de boutons qui ne serviront jamais, mais trop
                                beaux pour
                                être jetés. d'autres encore, banales et sans intérêt crèvent comme moi, pleines de
                                clous, vis et
                                pointes au fond d'un cellier. J'ai vécu une belle et triste histoire, rare pour une
                                boite gâteaux.
                                Mon destin est lié à des évènements douloureux et témoins de la folie des hommes avides
                                de
                                conquêtes et de domination. Les pions sur l'échiquier y sont restés en héros innocents.
                                Blessés et
                                meurtris dans leur coeur et dans leur chair, mais vivants, nous aurions dû, Lucien et
                                moi, rentrer
                                en paix comme des copains d'infortune. La guerre n'a pas voulu.
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal11 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : un si long voyage</h3>
                <h4 class="auteurnouvelle">par Janine Chenu</h4>
                <p>Ma vie a commencé dans une biscuiterie à Nantes. J'étais toute belle, fraichement
                    sortie de l'usine dons une robe argentée décorée d'une image sereine : une façade de maison
                    eurie d'hortensias et un chat qui dort sur le rebord d'une fenêtre : le reflet de la paix sans
                    mauvaises promesses.
                    Remplie...</p>
                <div class="tourbutton">
                    <button @click="showModal11 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal12 }">
                    <div class="modal-background" @click="showModal12 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Fatum</h3>
                            <p><i>Le haut chapeau noir frémit dans le vent. Les pans du long manteau rouge vif claquent
                                    comme des coups de fusil. Les bras écartés, I étrange personnage oscille, menaçant.
                                    Tous les autres oiseaux n 'approchent plus du champ fraichement labouré malgré les
                                    beaux
                                    grains d'or si tentants à portée de bec. <br>
                                    Mais, lui, le petit oiseau craintif est fasciné. Il tend les ailes et se laisse
                                    porter par le
                                    souffle
                                    du vent. S'approche. S'affole. S'enfuit sous la protection des feuillages du bois.
                                    <br>
                                    Bien à I'abri, il tend la tête. Observe. <br>
                                    Le haut chapeau noir frémit dans le vent. Les pans du long manteau rouge vif
                                    claquent
                                    comme des coups de fusil. Les bras écartés, I 'étrange personnage oscille. Mais il
                                    ne bouge
                                    pas. Les bras écartés, I 'étrange personnage oscille. Mais il n'avance pas. Il
                                    attend . <br>
                                    L'oiseau ose quelques coups d'aile, par cercles successifs, de plus en plus courts,
                                    de moins
                                    en moins hauts, il s approche à nouveau. Sous le haut chapeau noir frémissant, sous
                                    le long
                                    manteau rouge flottant, il aperçoit le jaune pale, doux et chaud que les futurs
                                    parents
                                    accumulent avec précaution et tendresse au fond des nids pour le confort de leurs
                                    oisillons.
                                    L'homme de paille n 'a rien de menaçant. <br>
                                    L'oiseau se pose tout en haut du chapeau noir. Siffle victorieusement. Seul le
                                    silence répond.
                                    L 'oiseau s 'enhardit. Se glisse sous le manteau rouge, juste au creux du cou. Rien
                                    ne bouge.
                                    Il se sent bien. Protégé de la bise qui peut faire claquer les longs pans de tissu.
                                    Protégé des
                                    füsils. Protégé de la peur. Un abri Un abri doux. Un abri chaleureux. Un abri comme
                                    un
                                    nid. L'homme de paille lui offre confort et douceur. <br>
                                    C'est ainsi que le petit oiseau est revenu souvent vers son ami immobile. Se nicher
                                    dans la
                                    cachette offerte. Se pelotonner dans la paille chaude. Oubliés le vent, le froid, la
                                    faim, la
                                    solitude, la crainte. Tout est plus facile à deux. Tout est plus facile quand on est
                                    amoureux.</i>
                                <br>
                                Laure pose son stylo. Relit ce qu'elle vient d'écrire. Oui. La vie paraît plus facile
                                quand on est
                                deux pour l'affronter, plus douce quand on est deux pour la goûter. Que c'est bon d'être
                                amoureux ! <br>
                                <i>Laure soupire. Sent les larmes venir. Reprend son stylo. <br>
                                    L'oiseau connaît le bonheur. L'oiseau connait I'amour. L'homme de paille lui donne
                                    sa
                                    protection, sa chaleur. Mais ne répond jamais aux cui cui quémandeurs. C'est tout de
                                    même
                                    un peu triste un amour silencieux. <br>
                                    « Ami, ami, je t 'aime. Mais toi, que penses-tu ? » <br>
                                    L'automne, la grisaille et les tempêtes de novembre ont passé. L'hiver, le froid et
                                    la neige de
                                    février ont coulé. Le printemps a fait bourgeonner les arbres, germer les graines.
                                    Les
                                    pommiers et les cerisiers éclatent de blancheur. Les lilas préparent leur courte et
                                    odorante
                                    floraison mauve. <br>
                                    Mais l'oiseau ne s'enivre pas de parfums et de chants comme tous ses amis. Il pleure
                                    son ami
                                    disparu. <br>
                                    Un matin, il n'était plus là. Plus aucune trace de son existence dans l'océan vert
                                    du blé en
                                    pleine croissance. L'oiseau a volé des jours et des jours au-dessus des champs qu'il
                                    connaissait et même au-delà. Mais pas de chapeau noir parmi les coquelicots
                                    disséminés le
                                    long des chemins. Pas de manteau rouge surgissant du jaune éblouissant des étendues
                                    de
                                    colza. <br>
                                    L'homme de paille est parti. <br>
                                    Plus d'ami. Plus d'amour... <br>
                                    L'oiseau ne sait plus siffler. Il ne comprend pas. Il est seul. Abandonné. <br> </i>
                                Laure sait qu'elle n'ira pas plus loin pour l'instant. Inutile de rester devant son
                                cahier. La page
                                restera blanche. <br>
                                Six mois déjà ! Et la douleur est toujours là. Aussi lancinante. Aussi poignante. Aussi
                                déchirante. Laure ne comprend toujours pas ce qui est arrivé. Ce qu'elle a fait. Ou pas
                                fait. Ce
                                qu'il lui a reproché. Ce qui l'a éloigné. <br>
                                Elle l'aimait. Il l'aimait. Du moins le croyait-elle. Et puis... Ces quelques mots : «
                                Ne m'en
                                veux pas, Laure. Je ne peux plus poursuivre plus loin notre liaison. Je gâcherai ta vie.
                                Cherche
                                quelqu'un de plus solide et de plus fiable que moi. Il faut que tu me quittes. » <br>
                                Laure n'a pas compris ce jour-là. Ne comprend toujours pas, six mois plus tard. Après
                                leur
                                rupture, il s'est montré quelques semaines avec des femmes, toujours rieuses, toujours
                                jolies,
                                jamais la même. Le style évaporé. Elle croyait que ce n'était pas ce genre de femmes qui
                                l'attirait. Et pourtant... Le connaissait-elle finalement ? Connaissait-elle ses goûts ?
                                L'avait-il
                                trompée à ce point ? Avait-elle aimé un homme né de son imagination ? Un autre homme que
                                celui qu'il était vraiment.
                                Comment était-ce possible ? <br>
                                La naissance de leur amour a été si inattendue, si soudaine. Un coup de foudre ? Peut
                                être pas.
                                Ils se sont vus plusieurs fois après la première rencontre. Mais à chaque rendez-vous,
                                la
                                découverte de goûts communs, de plaisirs semblables. Laure chavirait sous le regard
                                caressant de Miguel, ses yeux de velours d'hidalgo. Elle était séduite par son élégance.
                                Elégance de style et d'allure. Mais surtout élégance de pensée. <br>
                                Plusieurs mois de pur bonheur. Quelques froissements, bien sûr. Parfois. De légers
                                heurts.
                                Mais jamais de querelles. De mots durs. Un sentiment d'harmonie. <br>
                                Elle se sentait plus sûre d'elle. Plus solide. Plus chaleureuse avec son entourage. Un
                                amour de
                                soleil. Un embrasement contenu de tout son être. <br>
                                Et lui ? Il l'aimait alors. Elle ne pouvait pas s'être trompée à ses gestes tendres, ses
                                regards de
                                feu. Il n'était pas homme à jouer avec le sentiment clair et désintéressé qu'elle lui
                                offrait.
                                D'ailleurs pourquoi aurait-il joué ? <br>
                                Alors, que s'était-il passé ? <br>
                                Il avait paru plus froid, plus distant. Doucement. Peu à peu. Elle avait senti ses
                                réticences. Ses
                                dérobades. Comme s'il se protégeait. Comme s'il la protégeait. Mais de quoi ?
                                Jusqu'au jour où il s'était éloigné. L'avait éloignée. « Il faut que tu me quittes »
                                Elle n'y avait pas cru. Mais il s'était affiché dans les jours suivants avec toutes ces
                                jolies
                                femmes. Elle s'était enfuie et pleurait depuis six mois ce bel amour feu de paille. <br>
                                Son conte le disait bien. Pauvre petit oiseau fidèle amoureux d'un épouvantail qui donne
                                sa
                                chaleur sans distinction, à tous les oiseaux qu'il n'effraie pas. Un être de paille
                                indifférent. <br>
                                Le conte plaira à Flavie. Elle saura comme toujours laisser courir son imagination et
                                ses
                                pinceaux. Elle métamorphosera le récit de Laure en éventail de dessins poétiques et
                                colorés.
                                Leur éditeur pressera le travail pour une parution de l'album dans les bons mois de
                                vente.
                                Mais Laure ignore la suite de son conte. N'a encore aucune idée de la fin qu'elle veut
                                lui
                                donner. <br>
                                Flavie a comme toujours bien travaillé. Ses dessins sont délicats. touchants.
                                L'épouvantail est
                                imposant dans son long manteau rouge flottant, rassurant aussi. On devine sa chaleur
                                offerte à
                                l•oiseau minuscule. <br>
                                Laure la félicite en cherchant à dissimuler son émotion le mieux possible. Mais Flavie
                                est son
                                amie et la connait parfaitement. Elle ne peut se tromper sur le sens de ce conte. <br>
                                Aujourd'hui, Flavie est un peu étrange, hésitante. Plutôt silencieuse comme si elle
                                réfléchissait à la portée de chaque mot, comme si elle ne savait comment exprimer sa
                                pensée.
                                C'est en rangeant précautionneusement ses dessins, le regard détourné de Laure qu'elle
                                dit :br
                                « J'ai rencontré Charles Destrez hier. » <br>
                                Laure sursaute. Charles est le meilleur ami de Miguel. « Le gentil Charles aux yeux de
                                cocker
                                triste » l'avait-elle d'abord décrit à Flavie. Mais Charles n'était pas triste. Elle
                                avait beaucoup
                                apprécié cet homme doux et chaleureux. Mais elle n'avait pas eu l'occasion- et sans
                                doute pas
                                l'envie- de le voir depuis sa rupture d'avec Miguel. <br>
                                Le cœur serré, Laure attend que Flavie poursuive. Mais seul un lourd silence pèse sur
                                les deux
                                amies. Flavie continue à trier ses dessins. En pose un. Le reprend. Ses gestes sont
                                désordonnés. Ce n'est pas habituel. Pourquoi se tait-elle si longtemps ? Quelle si
                                grande
                                importance que sa rencontre de la veille ? Qu'a-t-elle de si pénible à dire ? <br>
                                Oppressée, Laure interroge d'une voix tremblante : <br>
                                « Comment va —t-il? » <br>
                                La question est à double sens. Elles le savent bien toutes les deux. Laure aurait pu
                                directement
                                poser la question qui la hante : « Et Miguel, que devient-il ? » <br>
                                Flavie se tourne enfin vers elle. Ses beaux yeux clairs sont embués. Elle fait signe à
                                Laure de
                                s'asseoir. Lui prend les deux mains entre les siennes. Elle serre très fort. <br>
                                « Laure, Miguel est mort le mois demier. » <br>
                                La terre ne s'ouvre pas. Le plafond ne s'effondre pas. Laure ne bouge plus. Pas un
                                souffle.
                                Pas un frémissement. Même ses yeux n'ont pas cillé. Une statue de glace. <br>
                                Flavie rapproche sa chaise à côté d'elle. L'entoure de ses bras. Se tait. Attend. Attend
                                la
                                question qui vient enfin. Une voix blanche : <br>
                                « Comment est-ce possible ? Que s'est-il passé ? » <br>
                                Un accident sans doute. Miguel conduit vite. Et puis, il y a tant de chauffards sur les
                                routes.
                                Un incendie, peut-être. Miguel est si imprudent avec ses cigarettes. Elle lui a si
                                souvent
                                reproché de fumer dans le lit. Une noyade ? Il nage tellement loin du rivage sans se
                                soucier de
                                surveillance de la baignade. Il aura été emporté par les courants de la marée basse.
                                <br>
                                « Il était très malade. Tumeur au cerveau inopérable. Il n'était plus que l'Ombre de
                                lui-même.
                                Toi-même, tu aurais hésité à le reconnaitre, tellement il était changé » <br>
                                Charles est assis en face d'elle. Il la fixe avec ses yeux de cocker triste. Et cette
                                fois, il est si
                                triste, hélas ! <br>
                                C'est Flavie qui a conseillé de lui fixer ce rendez-vous dans le café où ils ont passé
                                de si bons
                                moments tous ensemble. « Ce serait mieux que tu rencontres Charles. Il m'a raconté la
                                maladie de Miguel. Mais il vaut mieux que tu apprennes la vérité de sa bouche. Il espère
                                que
                                tu accepteras de l'écouter. Je crois qu'il tient à te confier certains secrets qu'il ne
                                voulait pas
                                me transmettre. C'est à toi de décider. Quand tu le souhaiteras. » <br>
                                Laure a attendu plusieurs jours. D'abord se relever. Se sentir prête. <br>
                                Flavie l'a accompagnée au cimetière. Elles ont déposé un bouquet de violettes sur la
                                tombe.
                                Miguel aimait cette fleur symbole d'humilité et de discrétion. <br>
                                Laure n'est pas sûre d'être croyante. Mais elle a prié. Prié longtemps. A senti un soume
                                près
                                d'elle. La présence de Miguel. S'est sentie plus forte. <br>
                                Et elle est là. Face Charles. Le doux Charles qui parle lentement. Une voix grave.
                                Enveloppante. Tellement affectueuse. Une voix qui fait du bien, Une voix qui dit : <br>
                                « Il t'aimait tellement, Je ne sais pas s'il aurait voulu que je te dise la vérité. Je
                                crois que je me
                                serais tenu silencieux si le hasard n'avait pas mis Flavie sur mon chemin. Mais c'est
                                mieux
                                ainsi. Tu as choisi de venir. De toute façon, tu étais dans la souffrance. Je ne me
                                trompe pxs ?
                                Savoir que tu es restée dans son cœur et sa pensée jusqu'au bout atténuera ta douleur
                                comme
                                ta présence en lui a adouci ses moments les plus pénibles. » <br>
                                Et Charles parle. <br>
                                Il n'y avait même pas un an que Miguel vivait avec Laure quand sa tumeur avait été
                                diagnostiquée. « Impossible d'opérer à l'endroit où elle se trouve». Terrible épée de
                                Damoclès soudainement suspendue pour trancher le grand bonheur à peine effleuré. A ses
                                questions insistantes, une réponse inexorable : « Six mois peut-être. Pas plus d'un an.
                                »
                                Miguel avait choisi. Impossible d'infliger à sa Laure si peu de temps aimée le spectacle
                                de la
                                dégradation de son corps et de son cerveau assurée, inéluctable. Leur rencontre était
                                trop
                                récente pour lui imposer le rôle pesant d'infirmière. Il ne voulait pas qu'elle le vit
                                souffrir. Il
                                ne voulait pas la regarder souffrir. <br>
                                Il désirait qu'elle gardât de lui l'image de ce qu'il avait été réellement et non celle
                                d'un
                                homme diminué au physique comme au mental. <br>
                                « Je ne partageais pas son choix. Je le trouvais cruel. Pour toi qui te croyais trompée.
                                Pour lui
                                qui se privait de ton amour pour le soutenir. Mais c'était à lui de décider. Vous vous
                                connaissiez depuis si peu de temps. Il cherchait à t'épargner ce trop lourd fardeau. »
                                <br>
                            <p style="justify-content: center;">*</p> <br>
                            Charles a eu raison de lui parler. Sa douleur est plus supportable que de savoir que Miguel
                            l'a
                            toujours aimée. <br>
                            Miguel a-t-il eu tort ou raison ? Peut-être n'aurait-elle pas supporté les longs mois de sa
                            maladie ? Comment le savoir à présent ? Elle pense qu'elle aurait su être forte. Il aurait
                            pu
                            s'appuyer sur elle. Il aurait dû se laisser aimer. <br>
                            <i>L'été est venu Juillet inonde de lumière et de chaleur toure la campagne. Et l'oiseau
                                poursuit
                                sa quête. [l survole chaque jour le vaste champ d'or. Dans le blé qui ondule sous les
                                frissons
                                du vent, il cherche le haut chapeau noir e' le long manteau rouge vif Il cherche I 'être
                                de
                                paille qui l'a si chaudement accueilli et si bien protégé du rude hiver. [l cherche
                                I 'énigmatique personnage dont il est encore si follement amoureux.
                                Une tache écarlate attire son regard Il plonge vers les vagues des épis. Un morceau de
                                tissu
                                noir. Un pan de tissu rouge. Un bout de bois brisé Quelques touffes de paille. Son amour
                                disloqué tout au fond d'un fossé asséché
                                Pas d'abandon. Pas de trahison.
                                Le destin</i></p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal12 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Fatum</h3>
                <h4 class="auteurnouvelle">par Christine Pruvot</h4>
                <p><i>Le haut chapeau noir frémit dans le vent. Les pans du long manteau rouge vif
                        claquent
                        comme des coups de fusil. Les bras écartés, I étrange personnage oscille, menaçant.
                        Tous les autres oiseaux n 'approchent plus du champ fraichement labouré malgré les beaux
                        grains d'or si tentants à portée de bec. <br>
                        Mais, lui, le petit oiseau craintif est fasciné. Il tend les ailes et se laisse porter par le
                        souffle
                        du vent. S'approche. S'affole. S'enfuit sous la protection des feuillages du bois. <br>
                        Bien...</i></p>
                <div class="tourbutton">
                    <button @click="showModal12 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2013 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal13 }">
                    <div class="modal-background" @click="showModal13 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Le cerisier de Sakura</h3>
                            <p>Sakura marqua un temps d'arrêt, et lâcha la main de sa camarade de jeux. L'air était
                                délicieusement pur
                                en
                                cette journée de mars. Elle se tenait tout prés de la sortie de l'école et contemplait
                                les cercles que
                                le
                                concierge venait de tracer au râteau sur le sable humide. Le cerisier du jardinet de
                                l'école tendait ses
                                branches vers le ciel, en promesse de bourgeons. Les rires et les clameurs enfantines
                                semblaient faire
                                écho
                                au bleu de ce printemps précoce. La fillette regarda autour d'elle, et eut simplement le
                                temps de lire
                                la
                                surprise dans le regard des adultes qui l'entouraient : Yoko, la jeune institutrice de
                                la classe des
                                petits, était
                                déjà en train de marcher vers les enfants, en leur demandant doucement de rentrer
                                s'abriter à nouveau.
                                Pourtant, presque une heure s'était écoulée depuis les premières secousses, et tous
                                pensaient que le
                                danger
                                majeur était écarté. On avait enfilé les anoraks par-dessus les kimonos de tëte, et même
                                répété la danse
                                des
                                cerisiers en fleurs sous le préau, faisant contre mauvaise fortune bon cœur, malgré
                                l'absence de
                                musique...Les enfants jouaient à présent, regroupés dehors, sous la houlette
                                bienveillante des adultes
                                rassurants. La directrice, Mei, avait maintenant saisi son téléphone et regardait
                                nerveusement l'écran,
                                tandis
                                que le concierge leur faisait de grands gestes, montrant la mer, là- bas, de l'autre
                                côté de la route.
                                <br>
                                Soudain, une autre vibration, bien plus forte, se fit sentir, et tous se figèrent. Le
                                visage de
                                l'institutrice se
                                durcit, mais elle demeura calme et maitresse d'elle-même, réussissant même à sourire en
                                frappant dans
                                ses
                                mains. <br>
                                - Les enfants, venez, rentrons vite prendre nos petits sacs et nous mettre sous les
                                tables ! Nous allons
                                chanter la chanson de Totoro ! <br>
                                Mais la vibration reprit de plus belle, se muant en un grondement, en un mugissement.
                                Yoko regarda ses
                                deux collègues, qui, les yeux écarquillés, fixaient un point vers l'horizon. Elle aussi
                                se mit à
                                trembler, puis à
                                crier. Elle se précipita vers les enfants en leur hurlant de la suivre, et ouvrit en
                                grand le portail de
                                la petite
                                école maternelle. Elle avait compris. Ce n'était pas simplement un tremblement de terre.
                                <br>
                                C'était un tsunami. Et la vague arrivait, là, elle était toute proche, on entendait les
                                flots qui
                                mugissaient dans
                                les rues de Sendai, et les cris, et les klaxons, et soudain l'eau s'engouffra dans la
                                cour, en un seul
                                bloc,
                                écrasant le muret qui entourait l' école, et la fresque dessinée pour la tète de fin
                                d'année toute
                                proche explosa
                                un millier de bulles. <br>
                                Le toit de la classe de Yoko fut arraché, des dizaines de petits corps se mirent à
                                surnager, à couler, à
                                Se
                                disloquer, et les petites bouches n'eurent même pas le temps de crier « maman » ; un
                                enchevêtrement
                                atroce
                                de menottes mutilées, de pierres, de jouets, de mangas et d'eau boueuse se mit à danser
                                devant Sakura,
                                qui,
                                immédiatement, s'était accrochée au pilier central du portaik et avait commencé grimper
                                les marches vers
                                la terrasse de sable, juste avant l'arrivée du torrent de flots dévastateurs. <br>
                                Puis, soudain, très vite, le silence se fit. <br>
                                C'était épouvantable. Comme si la vie s'était arrêtée, alors même que, çà et là,
                                quelques enfants
                                luttaient
                                encore pour tenter de refaire surface et de s'extirper des débris de bois ou de ciment
                                qui les
                                retenaient
                                coincés sous l'eau. Yoko, se tenant d'une seule main au pilier, elle aussi, revit ces
                                images du film
                                qu'elle
                                avait tant aimé, Titanic. lorsque l'eau envahit les ponts et les cabines, brise les
                                portes, emporte
                                tout, et cette
                                idée lui donna une force infinie. Elle était Rose. et elle allait survivre. Il le
                                fallait. Son « Jack »
                                l'attendait. et
                                elle ne voulait pas mourir. Abasourdie, elle voyait les décorations florales dériver
                                dans les
                                tourbillons, et les
                                cahiers, et puis des chaussures, des kimonos, tous ces préparatifs de tète, si incongrus
                                dans ce monde à
                                l'envers, et aussi des corps démantibulés, ces petits corps en uniforme, sa collègue et
                                amie Mei,
                                décapitée, et
                                ces regards, immenses, pleins d'horreur et de peur, qui ne reverraient jamais le soleil.
                                La tète
                                presqu'entièrement sous l'eau, elle sentit soudain deux autres mains qui tenaient le
                                pilier, et réussit
                                à se
                                saisir de l'enfant. <br>
                                En donnant un immense coup de pied vers la surface, et en retenant fermement le petit
                                corps si léger de
                                Sakura, Yoko remonta vers la lumière, ne lâchant pas la fillette, ni le pilier,
                                miraculeusement encore
                                ancré
                                au sol, et c'est ainsi qu'elles demeurèrent, hagardes, crachant et s'étouffant à moitié,
                                comme deux
                                statues
                                surplombant un enfer, comme ces anges de pierre qui restent parfois, seuls vestiges
                                intacts, au vantail
                                d'une
                                cathédrale bombardée... <br>
                                Pierre riait de bon cœur d'une plaisanterie d'un de ses collègues au moment où le
                                tremblement de terre
                                frappa la Centrale. Éberlué. il senti le sol tanguer sous ses pieds et comprit
                                immédiatement ; au bout
                                de trois
                                ans passés au Japon, il se considérait presque comme un vieux de la vieille, et avait
                                senti des
                                centaines de
                                fois le sol de son appartement de Tokyo onduler comme un serpent.. .ll venait d'arriver
                                à Fukushima,
                                pour
                                une mission ponctuelle d'inspection et de collaboration entre son groupe français et les
                                autorités
                                nucléaires
                                nippones. <br>
                                Soudain, tout s'accéléra. Une autre vibration. un texto d'alerte immédiate du consulat,
                                l'Ordre aboyé
                                par le
                                contremaitre hurlant aux étrangers de prendre leur véhicule et de foncer vers les terres
                                : il prit ses
                                jambes à
                                cou tout en essayant de joindre Yoko. Sa Yoko, sa fleur de cerisier adorée, la princesse
                                de ses nuits.
                                celle dont le prénom signifiait « enfant du soleil » et pour laquelle il avait quitté
                                Bordeaux et le
                                domaine
                                viticole de sa famille. Se précipitant avec ses collègues américains vers la route qui
                                remontait vers la
                                montagne, il comprit, en se retournant, qu'il était sans doute en train de fuir devant
                                les cavaliers de
                                l'apocalypse. Il en avait souvent discuté avec les cadres de l'entreprise de maintenance
                                Tepco : que se
                                passerait-il en cas de tsunami, si non seulement un tremblement de terre majeur frappait
                                la côte, mais
                                si un
                                raz de marée venait ensuite compliquer la donne ? <br>
                                Près d'une heure plus tard, Pierre était à l'abri ; il avait roulé sans s'arrêter sur la
                                vieille route
                                sinueuse de
                                Bansei Tairo. en direction du mont Kuriko, puis s'était garé au milieu de la foret et
                                contemplait les
                                érables et
                                les mélèzes blottis les uns contre les autres... Cette nature grandiose, au sein de
                                laquelle il se
                                sentait abrité
                                comme dans un antre tutélaire, contrastait tellement avec l'agitation de sa vie dans la
                                capitale nippone
                                et
                                avec les tressaillements des entrailles de la terre. . Encore une fois. il tenta de
                                connecter son
                                portable. et fut
                                abasourdi en découvrant qu'un tsunami venait effectivement de frapper la côte : Sendai
                                était anéantie,
                                et un
                                des cœurs du réacteur de la Centrale avait subi des dommages majeurs. Seul, adossé
                                contre le tronc blanc
                                d'un bouleau, il hurla son désespoir face à la montagne silencieuse ; sa vie aussi
                                venait d'exploser.
                                <br>
                                Sur la côte, à Sendai, la fin du monde avait déjà eu lieu. Yoko avait désespérément
                                tenté de sauver
                                d'autres
                                élèves, mais les quelques corps d'enfants qu' elle avait réussi arracher aux décombres
                                étaient dépourvus
                                du
                                moindre souffle de vie. Épuisée, tentant de fuir en direction des hauteurs et de
                                s'éloigner du désastre,
                                marchant, titubant, nageant parfois entre les décombres, elle n'avait pas lâché la main
                                de Sakura. La
                                fillette.
                                indemne, semblait être en état de sidération. Elle jetait autour d'elle des regards
                                vides, se contentant
                                de
                                respirer faiblement et de suivre la jeune femme. Et puis soudain, en voyant une dame
                                âgée assise à même
                                le
                                toit de ce qui restait d'une maison, hagarde, Sakura sembla se réveiller d'un rêve, et
                                se tourna vers
                                Yoko en
                                criant : <br>
                                - Obaasan Je dois retrouver Obaasan !
                                Yoko, qui, de son côté, se demandait où elles pourraient s'abriter, se souvint alors que
                                la fillette,
                                qui n'avait
                                jamais été son élève, avait perdu ses parents dans un grave accident de train, et
                                qu'elle était élevée
                                par
                                Misaki, sa grand-mère, une personnalité très importante de Sendai. La vieille dame était
                                une ancienne
                                geisha, très cultivée, férue de textes anciens et d'estampes, qui avait aussi survécu
                                l'explosion
                                nucléaire
                                d'Hiroshima, et qui, inlassablement, témoignait, dans les écoles, sur les plateaux
                                télévisés, et au
                                travers de
                                son art... Mais Yoko, qui avait déjà participé à des sorties éducatives avec Misaki,
                                savait aussi que la
                                maison
                                de Yoko et de la vieille dame se trouvait non loin de la jetée. .. Et là, elles étaient
                                en train de se
                                diriger vers la
                                montagne, à l'exact opposé de la digue meurtrière... Elle s'agenouilla et sourit à
                                l'enfant : <br>
                                - Je te promets que nous la Mais d•abord, nous devons chercher un abri pour la nuit...
                                <br>
                                - Tu sais, Yoko, Obaasan m'avait toujours dit que si une catastrophe arrivait, nous nous
                                retrouverions
                                au
                                Mont Kuriko, à l'observatoire, là où l'on célèbre le Hamami la fin du Sakura Zensen,
                                dans le jardin
                                japonais... <br>
                                La jeune institutrice sourit presque, malgré les larmes qui lui montaient aux yeux, en
                                entendant
                                l'enfant
                                évpquer ces merveilleuses journées où un pays tout entier suivait la progression des
                                fleurs de cerisier.
                                comme d'autres suivent des finales de foot... Et elle comprit aussi toute la symbolique
                                du prénom de
                                cette
                                fillette, qui soudain lui sembla comme un heureux présage.... « Sakura le cerisier, cet
                                arbre
                                emblématique
                                de l'empire du soleil levant, de cette nation qui toujours se relève, forte comme le
                                soleil, belle et
                                fragile
                                comme la vie, comme les fleurs éphémères des cerisiers. ..EIle serra l'enfant dans ses
                                bras détrempés,
                                et lui
                                murmura à nouveau qu' elle retrouverait sa grand-mère. <br>
                                Pierre conduisait comme un fou, roulant seul sur la route cabossée, croisant les files
                                ininterrompues de
                                voitures fuyant la côte. Il roulait droit vers l'enfer. Et vers Yoko. Il était certain
                                qu'elle était
                                encore en vie. Il
                                était tout simplement hors de question qu'elle soit morte, ou même blessée. Elle allait
                                bien, il le
                                sentait. il le
                                savait. et ils allaient se marier. avoir traucoup d'enfants. et ils allaient enfin
                                réaliser leur rêve
                                commun, au
                                lieu de travailler comme des fous en se croisant simplement le dimanche : ils allaient
                                acheter un
                                domaine
                                viticole au pied du mont Fuji et ils produiraient ce délicieux vin japonais, et ses
                                parents seraient
                                fiers de voir
                                leur fils comme un ambassadeur des traditions françaises, et sa Yoko cuisinerait des
                                sushis et du en
                                devenant une merveilleuse maman. Vivre, ils allaient vivre ! <br>
                                Cependant, son optimisme fut mis à rude épreuve au fur et à mesure qu'il redescendait
                                vers Et
                                lorsqu'il arriva au premier barrage, où le sens de l'organisation nipponne avait déjà
                                repris le dessus
                                sur la
                                fatalité, et où les réfugiés étaient orientés vers les quelques abris de fortune, il ne
                                put s'em*cher de
                                frémir
                                en apprenant les premiers chiffres de la bouche du policier...ll se disait que sur la
                                centaine d'enfants
                                de
                                l'école d'Ishinomaki, plus des deux tiers étaient déjà Byrtés disparus...Enfin. en
                                prenant la mesure du
                                spectacle de désolation qui régnait au loin, et de cette eau saumâtre qui recouvrait
                                tout, et surtout
                                lorsqu'il
                                commença à se repasser en boucle les images de la vague sur son téléphone, il comprit
                                que ce serait un
                                miracle si sa princesse. dont l'école était située, elle aussi, non loin de la jetée,
                                était encore en
                                vie... <br>
                                Pierre passa les trois journées suivantes arpenter les villages. les villes. les rues
                                dévastées. Il
                                avait rassure
                                sa famille et le consulat. mais refusé catégoriquement de quitter les lieux, et ce
                                malgré les risques
                                liés à
                                l'explosion de la Centrale. Méthodiquement, il fouillait les décombres, tentant de
                                retrouver
                                l'emplacement
                                de la maison de Yoko. mais cherchant surtout son nom sur les listes qui commençaient à
                                s'afficher un peu
                                parlait couramment jarxynais. et s'épuisait à répéter toujours les mêmes phrases en
                                montrant la
                                photo de Yoko, celle qu'il conservait toujours dans son portefeuille. Sur le cliché, la
                                jeune femme
                                souriait.
                                face à un autre océan, assise sur le promontoire du Rocher de la Vierge, à Biarritz...
                                Pierre avait son
                                téléphone le premier soir, en se penchant inlassablement sur des amas de poutrelles et
                                de
                                meubles.. .ll avait pu joindre ses proches depuis un cybercafé, mais n'avait plus aucun
                                moyen d'entrer
                                en
                                contact avec Yoko.. .tl ne mangeait plus, dormant dans sa voiture. se nourrissant de
                                quelques de riz, et
                                croyait reconnaitre son amie à chaque coin de rue dévastée, dans chaque centre
                                d'hébergement. Personne
                                n 'avait revu la jeune femme, Elle faisait partie des centaines de disparus <br>
                                A quelques kilomètres de là, Yoko, elle aussi. allait de centre en centre. La petite
                                Sakura blottie
                                contre elle.
                                Elle avait pu rassurer sa famille, Tokyo, mais n'avait pas réussi à joindre Pierre. Son
                                téléphone ne
                                répondait plus, et elle ne se souvenait plus du numéro de ses parents, si loin, à
                                Bordeaux. Elle
                                imaginait sans
                                cesse le pire, même si elle connaissait les procédures d'évacuation de la Centrale...
                                Elle espérait
                                qu'il avait
                                été mis l'abri au plus vite, avec les autres étrangers. Et puis sa priorité était de
                                retrouver Misaki.
                                Chaque
                                fois qu'elle évoquait son nom. un beau sourire dessinait sur les visages des survivants
                                ; c'était comme
                                si
                                cette seule évocation suffisait à faire renaitre l'espoir. Mais la vieille dame
                                demeurait introuvable.
                                Au troisième jour, et sur l'insistance de Sakura, Yoko se mit la recherche d'un moyen de
                                transport. Elle
                                voulait tenter de gagner la montagne. Sakuru était tellement persuadée que sa grand-mère
                                avait réussi à
                                se
                                rendre à leur point de ralliement.. Elle chantonnait, assise dans le bus qui avait
                                repris la liaison
                                vers les
                                hauteurs, fixant, comme sans les voir, les terribles vestiges de la catastrophe. Elle
                                chantonnait une
                                très
                                ancienne mélodie, où il était question de grues cendrées et de cerisiers en fleurs.
                                Yoko, elle aussi,
                                regardait
                                par la vitre du bus et admirait malgré elle cette nature qui venait pourtant de ravir
                                des milliers de
                                vies aux
                                hommes. Au fur et à mesure que le véhicule s'engageait vers le col, la forêt se
                                densifiait, et les
                                mélèzes,
                                ployant sous le poids de la neige fraichement tombée, semblaient s'agenouiller au
                                passage du bus, comme
                                des gardes s'inclinant devant l'Empereur.. <br>
                                Une équipe de télévision française était du voyage, et Yoko entendait des bribes de
                                conversation, le
                                cœur
                                meurtri à l'écoute de cette langue française qui lui était si chère... Un poème de
                                Victor Hugo lui
                                revint en
                                mémoire, ce poème où il va vers la tombe de sa chère Léopoldine... <br>
                                <i>Demain, dès l' aube, à l'heure Où blanchit la campagne, <br>
                                    Je partirai. Vois-tu, je sais que tu m'attends. <br>
                                    J'irai par la forêt, j 'irai par la montagne. <br>
                                    Je ne puis demeurer loin de toi plus longtemps. <br>
                                    Je marcherai les yeux fixés sur mes pensées, <br>
                                    Sans rien voir au dehors, sans entendre aucun bruit, <br>
                                    Seul, inconnu, le dos courbé, les mains croisées. <br>
                                    Triste, et le jour pour moi sera comme la nuit. <br> </i>
                                Le véhicule se gara juste devant l'entrée du jardin japonais. La neige avait recommencé
                                à tomber, de
                                gros
                                flocons tournoyaient autour des cerisiers.. .On se serait cru au dernier jour du Hanami,
                                lorsque le «
                                front des
                                cerisiers en fleurs » a atteint les montagnes, et que les demiers cerisiers du Japon se
                                parent de leurs
                                fleurs
                                délicatement irisées.. Comme la neige ce jour-là, les pétales tourbillonnent alors
                                joyeusement, tandis
                                que
                                des milliers de Japonais se promènent dans les campagnes, en hommage au rituel
                                millénaire... <br>
                                Les journalistes français avaient déjà allumé leurs caméras et filmaient cette scène
                                surréaliste de
                                beauté,
                                admirant les brumes se mêlant à la neige et les bourgeons prêts à fleurir, comme un
                                improbable défi
                                après
                                l'horreur sans nom du tsunami... Un guide leur expliquait l'histoire du Sakura zensen.
                                Et il leur disait
                                aussi
                                qu'une grande artiste japonaise immortalisait cette scène printemps après printemps,
                                mais que cette
                                année
                                elle était en avance et refusait de quitter le site. C'est lui qui avait alerté les
                                chaines de
                                télévision, se disant
                                qu'un tel sujet remettrait un peu de baume au cœur de tous les survivants... Sakura, la
                                bien nommée,
                                courait
                                de toutes ses jambes vers la fin du promontoire, là ou le plus gros cerisier élevait ses
                                branches vers
                                l'infini. <br>
                                Misaki était là. Droite comme un i, enveloppée dans trois couvertures, elle peignait.
                                Face à la
                                montagne,
                                Face à cette nature insupportablement belle. Elle ne pleura pas en se retournant et en
                                faisant tournoyer
                                sa
                                petite-fille dans les airs. elle ne pleura pas. ni de joie, ni d'émotion. Elle lui
                                murmura simplement
                                qu'elle
                                était fière d'elle. qu•elle savait qu'elles se retrouveraient, et qu'elle aussi,
                                lorsqu'elle était
                                enfant. elle avait
                                retrouvé sa devant l'aloé Véra qui l'avait sauvée de ses brûlures et de ses
                                irradiations. Et Sakura
                                riait, riait, riait à gorge déployée, ses petites nattes volant dans le vent, en criant
                                qu'elle savait
                                que son
                                prénom la protégerait. <br>
                                En bas, dans la vallée, ayant enfin trouvé refuge chez un ancien collègue disposant
                                d'une télévision
                                câblée,
                                Pierre regardait distraitement une chaine française. Et c'est là qu'il aperçut soudain
                                une jeune femme
                                d'une
                                incroyable beauté. Elle marchait derrière une enfant qui courait travers les allées d'un
                                jardin
                                japonais,
                                entourée de mélèzes, de bouleaux, de brumes et de neige. Et surtout de cerisiers, de
                                centaines de
                                cerisiers
                                enveloppés d'une aura de flocons duveteux comme des fleurs de printemps. Tout au bout du
                                promontoire se
                                tenait un peintre. Entouré de couvertures. <br>
                                Et lorsque l'enfant se jeta dans les bras de cette vieille dame en houppelande, et que
                                la jeune femme se
                                mit à
                                sourire, face à la caméra, le visage inondé de larmes, Pierre tomba à genoux. Lui aussi
                                souriait. De
                                toute son âme
                                Notes :
                                <i>** Chaque année, l'Agence météorologique du Japon et l'ensemble de la population
                                    suivent le sakura
                                    zensen
                                    (front des fleurs de cerisier). Tous les soirs, les prévisions à ce sujet suivent le
                                    bulletin
                                    météorologique du journal télévisé. La floraison commence dans l'archipel Okinawa en
                                    janvier et
                                    atteint
                                    généralement Kyôto et Tôkyô à la fin du mois de mars ou en début d'avril. Puis, elle
                                    progresse vers le
                                    nord
                                    pour atteindre Hokkaidô quelques semaines plus tard Les Japonais prêtent une grande
                                    attention à ces
                                    prévisions. Ils pourront ainsi aller dans les parcs, tes autels et les temples en
                                    famille ou entre
                                    amis pour «
                                    contempler les fleurs » 花見 / はなみ, hanami?),. manger et boire. Les festivals du
                                    hanami célèbrent la
                                    beauté des
                                    cerisiers en fleurs et sont, pour beaucoup, une occasion de se reposer et de
                                    profiter du paysage.
                                    (source : http://fr, wikipedia_ orgiwiki'Sakura)
                                    Des arbres morts et des tonnes de boue entravent toujours la cour de récréation de
                                    l'une des écoles
                                    élémentaires d'Ishinomaki, une ville située dans la préfecture sinistrée de Miyagi.
                                    Sur les 108
                                    enfants de
                                    cette école, 74 sont décédés et seul un professeur a survécu.
                                    C'est dans un climat sinistre que les enfants vont reprendre les cours cette
                                    semaine. Cette école
                                    élémentaire
                                    étant trop endommagée pour les accueillir, une école épargnée des environs leur
                                    prête quatre salles de
                                    cours.
                                    (source : http://downtowntokyo, canalhlog_ com]archives/2011/04/19/20929687. html)
                                </i>
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal13 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Le cerisier de Sakura</h3>
                <h4 class="auteurnouvelle">par Sabine Aussenac</h4>
                <p>Sakura marqua un temps d'arrêt, et lâcha la main de sa camarade de jeux. L'air était
                    délicieusement pur en
                    cette journée de mars. Elle se tenait tout prés de la sortie de l'école et contemplait les cercles
                    que le
                    concierge venait de tracer au râteau sur le sable humide. Le cerisier du jardinet de l'école tendait
                    ses
                    branches vers le ciel, en promesse de bourgeons. Les rires et les clameurs enfantines semblaient
                    faire
                    écho
                    au bleu de ce printemps précoce. La fillette regarda autour d'elle, et eut simplement le temps de
                    lire la
                    surprise...</p>
                <div class="tourbutton">
                    <button @click="showModal13 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal14 }">
                    <div class="modal-background" @click="showModal14 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Le coffret aux 5 sens</h3>
                            <p>Mon frère et moi avions juste un an de différence. Avant mes quatre ans, je ne me
                                souviens de rien, ou de peu de choses : à part un gros poupon noir que j'appelais
                                Gérard, allez
                                savoir pourquoi. Ensuite, lorsque j'eus cinq ans, mon frère eut six ans, quand i'cus à
                                mon tour six
                                ans, il en eut sept, etc.. nous étions nés le même iour exactement, une année
                                d'intervalle
                                Incroyable mais vrai. Ma mère ne se lassait pas de me raconter l'événement de ma
                                naissance un
                                12 juillet à 10 heures du matin (tandis que mon frère avait vu le jour, si on peut dire,
                                à 23 heures).
                                Dans le fond, c'était assez pratique, car le jour J, mes parents organisaient une fête
                                unique avec la
                                famille. Nous recevions nos cadeaux chacun de notre côté mais nous soufflions les
                                bougies du
                                gâteau d'anniversaire ensemble, dans une commune et longue expiration : le gâteau était
                                partagé
                                en deux parties égales, avec d'un le nombre de bougies pour mon frère et de l'autre le
                                nombre de bougies pour moi. <br>
                                ...jusqu'au iour où mon frère eut pour toujours dix ans cause d'une voiture qui le
                                heurta et l'envoya au cimetière. C'était donc un 12 juillet, nous avions soufflé les
                                bougies. D'un
                                seul Coup. Unc belle réussite. Mon frère avait reçu un beau vélo bleu en cadeau et moi
                                un non
                                moins superbe landau pour promener ma progéniture de celluloid, dont Gérard faisait
                                encore
                                partie. DéÀ, neuf ans, j'avais ressenti certains aspects néfastes de la vie. Par
                                exemple. les
                                vacances ne durent pas, tout a une fin, même les meilleurs moments, et ce jour-là. ce
                                fut le cas.
                                Tout le monde était heureux. il faisait beau. Tom, car il s'appelait ainsi, traversa en
                                courant la
                                petite route départernentale, qui séparait notre maison du petit bois où nous avions la
                                permission
                                de jouer, sans regarder de chaque côté, comme nos parents nous l'avaient si souvent
                                répété.
                                Pourquoi ? Si les adultes ne se sont pas posé la question mille fois... Personne ne sait
                                pourquoi et
                                ne saura jamais. D'ailleurs, il n'y a pas d'explication à ce genre de malheur. <br>
                                Tom traversa et la seconde près une voiture passa. Le choc fut inévitable, pas vraiment
                                violent, mais sans retour. Je veux dire par là, sans retour pour la vie, en ce qui
                                concerne mon
                                frère. C'est inimaginable ce que l'existence peut changer tout coup. Il me sembla un
                                instant que
                                c'était moi qui avais fait ce bond en l'air et qui étais retombée sur la tête, tant
                                celle-ci me
                                faisait
                                mal. Mal cause de ma mère qui hurlait. Tout à coup, je fus bannie de son champ de
                                vision, mon
                                père aussi d'ailleurs sembla ne plus me reconnaitre. Je fus conduite dans ma chambre par
                                une
                                main inconnue mais ferme, tandis qu'un peu plus haut, au-dessus de ma tète, une voix
                                sembla
                                vouloir me consoler, moi qui ne pleurais pas. <br>
                                Les jours, les semaines passèrent. Mon père s'occupait de moi comme il pouvait. Le
                                mercredi, il nous emmenait à pied au cimetière, moi et mon landau. Ce dernier faisait
                                partie
                                intégrante de notre malheur. L'école avait repris. Depuis ce jour maudit où mon frère
                                n'eut même
                                pas le temps de profiter de son beau vélo bleu, je n'avais pas revu ma mère : " Elle est
                                l'hôpital, m'expliqua enfin mon père, dans un service où l'on essaie de lui redonner le
                                goût de
                                vivre..." Et devant mon insistance. il poursuivit : <br>
                                - "Depuis que ton frère n'est plus là, elle n'a plus envie de rien. Elle ne mange plus,
                                dort
                                toute la journée à Cause des médicaments et elle pleure beaucoup..."<br>
                                Elle devait aussi pleurer pour moi car, étrangement, depuis l'accident, aucune larme ne
                                parvenait à couler de dessous mes paupières. La violence de ce 12 juillet m'avait comme
                                asséchée. <br>
                                Le vélo bleu de Tom était resté dans rentrée, personne sans doute n'osait y toucher...
                                Sauf moi
                                Chaque fois que je passais devant, je l'effleurais du doigt, d'un petit coup de pied je
                                faisais
                                tourner
                                la pédale dans le vide, j'actionnais le frein, je faisais tinter la sonnette lorsque
                                j'étais certaine
                                que
                                personne n'entendrait. C 'était un vélo mixte qui pouvait aller aussi bien à un garçon
                                qu'à une fille.
                                Le bleu n'était pas vraiment ma couleur favorite, mais enfin... Dans la famille, il y
                                avait une
                                tradition: il fallait attendre dix ans pour avoir une bicyclette le jour de son
                                anniversaire. Il en
                                avait
                                été ainsi pour mes cousins, cousines et plus récemment pour mon frère. Cela ne veut pas
                                dire que
                                nous ne savions pas en faire, car il y avait bien longtemps que nous enfourchions déjà
                                les deux
                                roues de nos ainés. <br>
                            <p style="justify-content: center;">*</p>
                            C'est un samedi de septembre que je fus autorisée rendre visite ma mère.
                            Curieusement, la semaine précédente. à l'école nous avions étudié les sens. « les 5 sens le
                            goût,
                            l'odorat. le toucher, l'ouie et la vue. Cette leçon m'avait particulièrement intéressée. Je
                            me
                            souviens encore du regard étonné de la maitresse devant mes questions pertinentes. <br>
                            Connaissant tout du drame familial qui m'affectait, elle devait sentir jusqu'où, du haut de
                            mes neuf ans, voulais aller. Finement et avec beaucoup de tact, laissant un peu de côté le
                            reste
                            de la classe, elle m'emmcnait doucement, m'accompagnait dans mes interrogations, devinait ce
                            que mon Jeune âge ne savait pas exprimer avec de vraies phrases- Elle traduisait mes
                            pensées, mes
                            silences. trouvait les réponses adéquates. Ainsi. elle m'expliqua, sans jamais évoquer ma
                            mère, que
                            quelqu'un. adulte. enfant, homme ou femme, pouvait perdre l'envie de vivre, c'est-à-dire
                            l'envie
                            de faire fonctionner ses 5 sens. Cette envie, poursuivit-elle. pouvait Se comparer aux
                            parfums des
                            fleurs pour l'odorat, la douceur d'une caresse pour le toucher, une jolie chanson pour
                            l'ouie
                            Ou un petit morceau de chocolat pour Ic goût. Et la vue... elle hésita car ses yeux elle
                            s'embuaient de larmes. Elle finit par dire « ah la vue. Il y a tellement de choses voir...
                            tenez...
                            de jolies images... n'est-ce pas les enfants » Et toute la classe acquiesça en riant. y
                            compris moi
                            d'ailleurs. L'un d'entre nous, profitant de cet instant de détente et de fou-rire, lança :
                            <br>
                            - "Il y a aussi le sens unique et le sens interdit ! " <br>
                            Elle sourit : <br>
                            -"Oui, et aussi le sens giratoire, le double sens.. Et puis plus tard, vous apprendrez le
                            sens
                            propre. le sens figuré... mais là n'est pas l'objet de la leçon " répondit-elle, en nous
                            demandant,
                            d'un geste, de nous calmer. <br>
                            C'est ainsi que, forte de ce que j'avais appris en classe, je pénétrai dans la chambre où ma
                            mère était allongée, blanche et amaigrie. Je ne me souviens pas des détails de notre
                            arrivée. Si ma
                            mère nous a souri ou embrassés ou fait un geste quelconque ou eu un regard particulier pour
                            moi.
                            Je ne sais plus rien de tout cela parce que je pense ne l'avoir jamais su. Je suis entrée,
                            'e n'ai rien
                            vu d'autre que cette blancheur dans la lumière du jour : une chambre blanche, des draps
                            blancs,
                            un visage et des bras qui n'avaient plus envie de rien, des yeux qui dormaient trop à cause
                            des
                            médicaments et qui pleuraient tout le temps. Je m'approchai du lit, posai une petite boite
                            en bois
                            tout près de son visage. Les pensées de mon père devaient être ailleurs car il ne broncha
                            pas et
                            semblait me regarder mécaniquement. <br>
                            De ma boite secrète, que j'appelais très vite « mon coffret aux 5 sens je sortis un petit
                            carré de chocolat que je posai sur les lèvres de ma mère qui tourna la tète comme écœurée
                            par ce
                            qu'elle avait adoré. Le second test consista à lui faire écouter la ritournelle de ma petite
                            boite à
                            musique « frère Jacques J'eus la nette impression qu'un orage éclatait dans sa tète Du coup,
                            mon père fit mine de se lever vers moi. mais je stoppai la ritournelle avant même que sa
                            maln
                            n'eut quitté Ic bras du fauteuil Où il s'était affaissé. Je continuai ma quête,
                            imperturbable, et posai
                            délicatement, sous son nez, un petit morceau de coton imbibé de son parfum préféré. Sa
                            réaction
                            fut sans appel. elle n'aimait plus ou elle ne sentait plus vint le tour du joli dessin fait
                            uniquement
                            pour elle qui ne déclencha aucune réaction, quant mon baiser sur sa joue, il sonna comme un
                            gong la fin de la visite. Mon père, tel un robot. me prit par la main et nous sortimes de la
                            chambre. Le couloir était immense, très large, très haut et très long. J'entendis ma voix
                            d'enfant
                            raisonner : <br>
                            - " Maman a perdu le sens du goût, le sens de l'ouie, le Sens de l'odorat, le sens de la vue
                            et le sens du toucher... " <br>
                            Dans une autre vie, mon père aurait été étonné, mais dans cette vie-là, il ne le fut pas.
                            Peut-être que lui aussi. il avait perdu le sens de . Il me regarda, fronçant les sourcils.
                            Je voyais
                            bien que quelque chose ne marchait plus en lui. Cela ne m'empêcha pas de vérifier, dès notre
                            arnvée la maison que, pour ma part, je n'avais perdu aucun de mes 5 sens : je fonçai sur la
                            bonbonnière pour en extraire un bonbon la framboise que je suçai avec délectation, allumai
                            mon petit poste de radio qui diffusa un air inconnu qui ma foi me plut, courus sentir le
                            bouquet
                            de chrysanthèmes qui était destiné notre prochaine visite au cimetière (la voisine nous en
                            déposait un pot chaque semaine) ; ce n'était pas ma fleur préférée quant au parfum, mais
                            quant
                            aux variétés de couleurs, une merveille ! (c'était notre voisine qui parlait ainsj. Je
                            n'avais donc pas
                            perdu mon sens du goût, de l'ouie. de l'odorat ni de la vue. pour le toucher, il me suffit
                            de
                            caresser le doux guidon en caoutchouc bleu foncé du vélo de Tom qui était toujours dans
                            l'entrée. J'étais rassurée. <br>
                            <p style="justify-content: center;">*</p> <br>
                            La Vie coulait doucement. J'évitais de faire trop de bruit. Mon père rentrait encore plus
                            tard du travail car il passait par l'hôpital pour prendre des nouvelles. Gérard me tenait
                            compagnie
                            et puis ma voisine Noémie était gentille. C'est elle qui venait me chercher la sortie de
                            l'école. Un
                            jour, je lui ai même demandé s'il lui était déjà arrivé de perdre un de Ses sens ou les cinq
                            la fois.
                            Elle me scruta d'un drôle d'œil et je n'tnsistai pas. Tout ça c'était un secret entre la
                            maitresse et
                            moi... A ce propos, la leçon sur les 5 sens se poursuivit toute la semaine. Je la
                            soupçonnais
                            longtemps d'avoir légèrement modifié son programme à cause de moi. Ainsi nous apprit-elle
                            que
                            certaines personnes naissaient avec un sens plus développé et s'en faisaient un métier : il
                            y avait
                            les goûteurs d'huile, de chocolat. de vin et de plein d'autres choses encore. Il y avait
                            ceux et celles
                            qui avaient l'oreille musicienne. Elle esquissa une timide explication sur le sixième sens,
                            mais
                            devant nos regards ahuris, elle abandonna et revint aux sens qui captaient le plus notre
                            attention,
                            celui du goût et celui de l'odorat. Toute cette semaine-là, elle nous fit fermer les yeux
                            pour goûter,
                            sentir et reconnaitre : le sucré, le salé, l'amer, l'âcre, l'acide, le piquant, le doux, le
                            poivré...
                            Nous
                            dûmes aussi deviner l'odeur du vinaigre, de la cannelle, de la menthe, de la lavande, de la
                            vanille,
                            du citron, de l'orange et j'en oublie sûrement. Nous poursuivions ce jeu entre nous à la
                            récréation <br>
                            Pour Noël, ma mère eut la permission de rentrer à la maison. Je n'ai gardé aucun souvenir
                            de ces trois jours, hormis que le vélo de Tom avait disparu de l'entrée. Après une courte
                            enquête,
                            je constatai que mon père l'avait relégué dans la petite cabane au fond de la cour, posé
                            contre les
                            outils de jardin. Sans nul doute pour épargner ma mère. Son absence faisait un vide dans
                            l'entrée
                            et ce vide que je côtoyais plusieurs fois par jour attisait mon ardeur à vouloir le
                            posséder. Depuis
                            cc 12 juillet, je menais des combats pour garder mes 5 sens, voire même les développer, hair
                            la
                            tristesse, aller de l'avant jusqu'à mes dix. Ce j'attendais tant. Plus les semaines
                            passaient et plus j'avais du mal ne pas me révolter: ce vélo, mais enfin ce vélo qui ne
                            demande
                            qu'à rouler. Je rongeais mon frein si je peux m'exprimer ainsi, car sentais bien que ce
                            désir
                            intense aurait été mal perçu par mon père et les adultes qui m'entouraient. Seul Gérard
                            partageait
                            mes secrets. Avec lui, blotti contre moi, comme un prétexte, j'allai rendre visite au vélo
                            de Tom.
                            Inévitablement, comme un rite, d'un petit coup de pied, je faisais tourner la pédale,
                            j'actionnais le
                            frein puis la sonnette, caressai le guidon ou la selle, constatant, avec une pointe de
                            reproche
                            envers mon père, que la poussière s'y déposait. <br>
                            <p style="justify-content: center;">*</p> <br>
                            Je revis ma mère. dans sa chambre blanche, courant janvier, puis en mars mais sans mon
                            coffret. D'ailleurs, j'avais mangé Ie chocolat depuis longtemps, le parfum s'était évaporé,
                            le
                            dessin n'était plus au goût du jour et la petite boite musique était cassée.
                            Quant au baiser. j'en avais plein en réserve sur mes lèvres, il suffisait de le demander
                            elle n'en
                            réclamait aucun.
                            Depuis l'accident, j'avais remarqué que mon père l'embrassait tendrement sur le front, alors
                            qu'auparavant, il lui faisait des trucs dans l'oreille et dans le cou. ce qui la faisait
                            rire aux
                            éclats.
                            Cette image me rendait nostalgique mais heureusement Noémie était là pour me rassurer. "Ta
                            maman va déjà mieux. disait-elle, un jour, elle va revenir guérie.. bientôt tu verras.."
                            <br>
                            L'été donna raison à Noémie. Alors qu'il pointait timidement son nez, n'osant franchir
                            porte de notre maison, il se décida enfin entrer, le même jour que ma mère. Voici plus de
                            huit
                            jours que l'on m'y préparait : elle va mieux me répétait mon père et c'était sûrement vrai
                            car, par
                            la même occasion, je le trouvai comme rajeuni et un brin souriant. Dès qu'elle arriva, je me
                            plaçai
                            dans son champ de vision pour voir si elle allait m'y laisser ou passer comme si je
                            n'existais pas.
                            Noémie qui avait aidé à faire le grand ménage. se tenait à l'écart, Elle descendit de
                            voiture avec
                            une élégance qui me ravit. Mon père coupa le moteur, fit le tour du véhicule et l'enlaça
                            d'un bras
                            protecteur. Je suis sûre qu'il aurait voulu, à cet instant, que son bras soit deux fois, dix
                            fois plus
                            long. Le moment fut inoubliable, comme ce baiser qu'elle me fit sur la bouche. Puis mon père
                            se
                            pencha sur son oreille, y enfouit ses lèvres et elle eut ce rire que j'aimais tant. <br>
                            <p style="justify-content: center;">*</p> <br>
                            La vie reprit doucement. Je fus autorisée à ne pas terminer l'année scolaire. Bien sûr, dans
                            cette affaire, on se preoccupa de mon avis et ce fut l'amour que je portais ma mère qui
                            l'emporta sur ma reconnaissance et mon affection envers la maitresse. Nous allâmes souvent
                            au
                            cimetière, avec de gros bouquets des fleurs des champs posés sur le landau. La saison des
                            chrysanthèmes était terminée. A observer ma mère, je pouvais aisément dire qu'elle avait
                            retrouvé
                            ses 5 sens ; elle regardait autour d'elle, humait l'air, écoutait les oiseaux, ne refusait
                            pas une
                            friandise et nous embrassait volontiers. Certes, ce n'était pas du 100%, c'était comme un
                            balbutiement, mais si j'avais pu en parler la maitresse cet instant, je suis certaine
                            qu'elle aurait
                            été contente, elle aussi. Sur la tombe de Tom, chacun évitait le regard de l'autre, chacun
                            se
                            concentrant sur son acnvité : mes parents s'occupaient des fleurs, quant moi je berçais mon
                            landau Où s'entassaient pêle-mêle Gérard et tout le reste de ma marmaille. <br>
                            <p style="justify-content: center;">*</p> <br>
                            Le 12 juillet approchait. Autour de moi, j'entendais les messes basses des adultes : « Tom
                            aurait eu 11 ans... faire une fête pour Emma certes, mais c'est aussi l'anniversaire de la
                            mort de
                            Tom... et puis Emma va avoir dix ans, c'est l'année de son vélo. ...". Je me réfugiais dans
                            ma
                            chambre pour fuire ces conciliabules. Tous ces chuchotements, ces secrets entre mes
                            parents et le reste de ma famille semblaient me monter à la tête comme un accès de fièvre.
                            Plus
                            Ie jour approchait et plus je me sentais devenir liquide, je m'amollissais comme un morceau
                            de
                            beurre sur le feu. J'avais peur que cette fragile fenêtre ouverte sur ce nouveau bonheur non
                            moins
                            fragile, n'éclate en morceaux sous l'effet d'une bombe. Cette bombe, j'en devinais la mêche.
                            cette
                            mèche sous-jacente qui avait la forme du vélo bleu de Tom. A cette peur d'une explosion de
                            tout
                            mon être, se mélait celle d'y perdre mes 5 sens. Prévoyante, j'avais réactualisé mon coffret
                            (comme d'autres vérifieraient leur mallette de survie) ; j'y avais rangé des bonbons
                            multicolores
                            pareils à des confettis en chocolat, un minuscule xylophone avec ses deux petits maillets
                            trouvés
                            dans une pochette surprise, des échantillons de parfum, un livre d'images, et des duvets de
                            plumes d'oiseaux ramassés dans le petit bois d'en face. Ainsi, de temps autres, dans mes
                            moments de doute extrême, je venais vérifier la réalité, la capacité, la vivacité de mes 5
                            sens. J'en
                            revenais alors plus forte, ma peur d'une explosion s'éloignait et si elle tentait de
                            s'approcher de
                            nouveau, je m'emparai de mon coffret, comme on se saisit d'une arme, pour m'en faire un
                            rempart. Mon coffret aux 5 sens était devenu un peu mon armure, mon refuge aussi d'où je
                            ressortais aguerrie. <br>
                            <p style="justify-content: center;">*</p> <br>
                            Dans la nuit du II au 12 juillet, je dormis très mal. J'échafaudai, entre rêves et
                            cauchemars, des mises en scènes diverses et improbables nous passions la journée de mon
                            anniversaire sur la tombe de Tom, tous assis en rond autour d'un énorme bouquet de
                            chrysanthèmes bleus, la main dans la main, dans une communion faite de ferveur et de
                            tristesse.
                            Ou bien mon père m'annonçait, au petit matin. qu'à la suite d'un conseil de famille, il
                            avait été
                            décidé de passer outre ce 12 juillet qui désormais ne pouvait plus être un jour de fête
                            puisqu'il
                            était un jour de deuil. Il y avait aussi le scénario du repas familial qui se terminait par
                            un gros
                            gâteau sans bougie, sans cadeau, surtout sans vélo, Je me vis sauter sur la table telle une
                            furie,
                            habillée d'une longue robe de satin bleu, portant au bout du bras mon coffret aux 5 sens que
                            le
                            brandissais comme un glaive. J'entamis une incroyable tirade, hurlant, réclamant mon dû,
                            menaçant d'allumer la mèche de cette bombe qui m'obsédait. <br>
                            La nuit dur être longue car mon imagination ne faiblissait pas. D'un scénario tari en
                            émergeait un autre, encore et encore. dont je tairai l'invraisemblance, le rocambolesque, et
                            le côté
                            injuste envers mes proches qui m 'aimaient. Car ils m'aimaient.. <br>
                            <p style="justify-content: center;">*</p> <br>
                            Il faisait jour depuis longtemps lorsque mon père me sortit de ma torpeur nocturne. Tout
                            de suite, il s'aperçut que j'avais de la fièvre. Ma mère accourut, aimante, inquiète. Elle
                            me serra
                            contre elle. Je me mis pleurer. Enfin, je pleurais et ma mère pleurait avec moi, tandis que
                            mon
                            père nous berçait dans ses bras. Tous ces mois de solitude sans Tom s'abattaient sur moi
                            comme
                            une pluie diluvienne et glaciale. Mon trop plein de colère s'en allait doucement, emporté
                            par ce
                            déluge et le ruisseau de nos larmes et, comme par magie, je sentis que ma fièvre
                            s'évanouissait tel
                            un volcan qui s'éteint. Nos trois corps enlacés eurent du mal à se démêler et pour cela mon
                            père
                            prononça un « bon anniversaire Emma » avec une douceur infinie. Ma mère renchérit par un
                            sourire et un baiser puis souffla ; <br>
                            - "Tu as dix ans !" <br>
                            Alors seulement nos bras, nos mains acceptèrent de se dénouer les uns des autres.
                            Chacun se moucha, essuya ses larmes. J'avais dix ans et ce n'était pas un rêve. <br>
                            Tandis que mes parents quittaient ma chambre sur la pointe des pieds, je revenais à la
                            raison. D'un geste nerveux, je repoussai l'armure qui semblait vouloir se recoller sur moi.
                            Un
                            sentiment d'impatience m'envahit toute entière. Gérard, couché dans le landau parmi ses
                            congénères, me lançait un regard redoutable. Assise au bord du lit, je n'osai aller
                            interroger mon
                            coffret aux 5 sens. Il était midi et ic n'entendais rien qui ressemblât à une quelconque
                            fête. A
                            quoi avaient abouti toutes Ces messes basses, ccs conciliabules, ces murmures, ces
                            secrets...?
                            Quelle mise cn scène allaient-ils tous me réserver.. Etaient-ils seulement là au grand
                            rendez-
                            vous du 12 juillet ? Je m'habillai à la hâte, sans chichi, alors qu'un tel jour aurait
                            demandé plus
                            d'attention, une jolie robe, un noeud dans mes cheveux. Il n'en fut rien. Encore chiffonnée
                            par
                            trop de sommeil, trop d'inquiétude, trop de questionnement, encore moite d'une fièvre tout
                            juste
                            passée, je franchis la porte de la salle manger, les yeux écarquillés : Mon père et ma mère
                            se
                            tenaient debout devant moi, graves mais sereins. Entre mon père et ma mère, appuyée sur sa
                            béquille, se tenait le vélo bleu de Tom et, sur la petite desserte, sous la fenêtre de la
                            cuisine,
                            trônait un gâteau planté de dix bougies. Sur le pas de la porte, notre voisine Noémie
                            souriait-
                            Certes, il n'y avait pas d'autres membres de la famille, pas de fioritures, pas de ballons
                            multicolores le front collé au plafond, mais que pouvait m'importer le reste du monde
                            puisqu'il y
                            avait le vélo de Tom. Et s'il avait été sorti de sa cachette, ramené jusqu'ici, dépoussiéré,
                            rajeuni
                            en quelque sorte, c'est qu'il était pour moi, moi qui avais dix ans aujourd'hui. <br>
                            Sans même songer à dire un seul mot, ne fut-ce qu'un simple merci, je m'emparai de cette
                            chose qui m'avait obsédée pendant un an. Accrochée au guidon, redoutant sans doute que l'on
                            vint me reprendre ce qui était dorénavant mon bien, tellement convoité, tellement mérité
                            (allez
                            savoir pourquoi...), nous sortimes dans la cour, lui et moi. Il faisait beau. La chaleur de
                            l'été sur
                            ma peau, le parfum des derniers lilas qui planait dans l'air, le chant des oiseaux qui
                            arrivait jusqu'à
                            moi du petit bois d'en face, tout me semblait magnifique, presque irréel, comme la saveur du
                            gâteau (forcément extraordinaire) que je n'avais pas encore goûté. Mes 5 Sens étaient bien
                            là, bien
                            vivants et me rendaient invincible. Sans la moindre hésitation, dans une lueur éblouissante,
                            je
                            grimpai sur ma monture, donnai quelques coups de pédales qui s'avérèrent difficiles et
                            maladroits, Je tanguai, retrouvai mon équilibre, tanguai de nouveau, furieuse d'être si mal
                            habile
                            devant ces trois paires d'yeux qui, sans aucun doute, me regardaient. Je m'entétai,
                            poursuivant
                            mon périple tout autour de la cour, sur un sol chaotique que je connaissais pourtant. Au
                            passage,
                            je pouvais voir les trois silhouettes dressées sous la véranda. Voulant faire un dernier
                            tour de
                            piste, je tentai un demi-tour à haut risque, histoire peut-être que l'on m'applaudisse. Et,
                            tandis
                            que j'entendis des voix me crier de faire attention, la roue avant Se bloqua dans un trou...
                            la
                            magie des 5 sens n'avait pas fonctionné. <br>
                            Du fond de mon lit, le genou douloureux, le cœur et l'orgueil ulcérés, je vis mon père et
                            ma mère au-dessus de moi, rassurants, indulgents, amusés... ? Je ne sais plus, J'entendis
                            que la
                            roue du vélo était voilée et qu'ils venaient de prendre la décision de ne pas le faire
                            réparer.
                            J'aurai bientôt une bicyclette, à moi, de la couleur de mon choix. pour Noël certainement. A
                            cet
                            instant, je ne pus m'empêcher de songer, avec une pointe de satisfaction, que la tradition
                            du vélo
                            d'anniversaire était rompue / <br>
                            Plus tard dans la soirée, ma mère et moi nous nous retrouvâmes au-dessus du coffret aux
                            5 sens : heureuse, je lui en dévoilai tous les secrets. Elle m'écouta. Nous parlâmes
                            longtemps. Elle
                            évoqua aussi d'autres sens, comme le sens du partage, le sens du devoir, le sens de
                            l'humour, le
                            sens de l'écoute... tous ces sens qui donnent du sens à la vie. </p>

                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal14 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Le coffret aux 5 sens</h3>
                <h4 class="auteurnouvelle">par Monique Gendrault</h4>
                <p>LMon frère et moi avions juste un an de différence. Avant mes quatre ans, je ne me
                    souviens de rien, ou de peu de choses : à part un gros poupon noir que j'appelais Gérard, allez
                    savoir pourquoi. Ensuite, lorsque j'eus cinq ans, mon frère eut six ans, quand i'cus à mon tour six
                    ans, il en eut sept, etc.. nous étions nés le même iour exactement, une année d'intervalle
                    Incroyable mais vrai. Ma mère ne se lassait pas de me raconter l'événement de ma naissance un
                    12...</p>
                <div class="tourbutton">
                    <button @click="showModal14 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2012 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal15 }">
                    <div class="modal-background" @click="showModal11 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">La petiote</h3>
                            <p>« Oui, je t'installe à l'avant, je préfère. Je sais bien que tu l'aimes
                                pas cette place du mort... » <br>
                                Mais là, je pouvais davantage la surveiller, et je pense encore qu'il
                                valait mieux. Je lui ai donc avec soin accroché sa ceinture, bien vérifié,
                                avant d'aller de l'autre côté me caler derrière mon volant. <br>
                                Avec cette circulation déjà chargée, jusqu'à la sortie de la ville on
                                n'a pas dit un mot. Simplement, aux feux, d'un petit coup d'œil, je
                                m'assurais que tout allait bien. Pas trop tranquille, en fait. J'appréhendais
                                le coup de frein brutal, la voir valdinguer dans le pare-brise, elle en était
                                capable, et j'ai bien cru, à un carrefour, qu'on y était quand j'ai dû piler sec
                                pour éviter un jeune suicidaire Sur son scooter. Pas passé loin ! Par miracle,
                                elle n'a pas bronché. C'est après les interminables zones industrielles, sur
                                la petite départementale quasi déserte, que, dans les dernières brumes du
                                matin, enfin j'ai pu souffler et là, au milieu de tout ce vert, ce jaune, tous
                                ces champs à perte de vue, d'un seul coup ça m'a fait vraiment drôle. <br>
                                "Combien de temps qu'on s'était pas retrouvées comme ça routes
                                les deux ? " <br>
                                Je n'en savais même plus rien. Bien longtemps en tout cas. L'œil sur
                                la route, au-delà d'un gros camion blanc à dépasser, je la distinguais
                                vaguement à mes côtés. <br>
                                « Toutes ces années perdues, tu trouves pas que c'est béte, non ?»
                                Ma question est restée en suspens, le temps que je finisse de
                                doubler le long véhicule, mais les idées suivaient leur fil, tournaient,
                                tournaient. J'ai fini par lâcher d'une petite voix : <br>
                                — Je voudrais pas être désagréable aujourd'hui mais je sais bien ce
                                tu penses au fond, ce que tu as finalement toujours pensé. Que ce gâchis
                                est de ma faute, et à moi seule. Parce que, disons-le, de toute éternité.
                                pour toi, tout a toujours été de ma faute. Je me trompe ? Ce matin, on
                                enfin l'occasion de s'expuquer, on ne la retrouvera pas, alors pour une fois,
                                eest moi qui parle : s'il te plait, écoute-moi. <br>
                                Combien de fois je me l'étais tenue dans ma tête, cette discussion
                                On pénétrait dans un petit village que j'avais oublié, je ralentis. par quel
                                bout reprendre tout cela ? <br>
                                — Du plus loin que je remonte, en fait, j'ai le souvenir qu'entre nous
                                deux rien n'a jamais vraiment cadré.Ppas de câlins, pas de douceur. Ce qui
                                me revient c'est que des engueulades, des redresse-toi, va te laver, tu
                                pourrais dire merci... A croire — et je sais que je vais te choquer — que j'avais
                                pas été vraiment désirée. Ou alors pas comme ça. A ta décharge, il faut
                                reconnaitre que j'y mettais pas tellement du mien non plus.
                                Des images remontaient en vrac, les dimanches, l'école, des jours
                                de fête... <br>
                                — Cest vrai, à côté de ma charmante ainée, gracieuse, docile,
                                studieuse, moi avec ma tronche renfrognée et mon sale caractère — le tien
                                en fait fagotée le plus souvent comme l'as de pique (rien ne me va,
                                qu'est-ce que j'y peux ?), je faisais un peu tâche dans le beau tableau de
                                famille. Et râleuse avec ça, rebelle même, jusqu'à t'affronter un dimanche,
                                pour Pâques je crois, en refusant tout net et définitivement de remettre
                                les pieds à l'église parce que je n'y croyais plus. Ouah Le drame ! Ca, tu ne
                                l'as jamais digéré. Tu me l'as même ressorti ce sinistre matin où je suis
                                partie, comme tu m'as en même ternps reproché, et ça m'a fait plus mal,
                                de n'avoir pas versé la moindre larme à la mort de Papa. Ah ! Hélène en
                                fille éplorée était sublime, pas comme son ingrate cadette. le regard
                                mauvais, sec. mais qui te dit que dans mon coin. loin des autres, je rfai
                                pas pleuré moi aussi Qui te dit que je n'ai pas eu de peine autant que
                                vous ? La pudeur, c'est refuser de se donner en spectacle. Moi je ne veux
                                pas en rajouter, jouer la comédie, je me suis toujours refusée à faire
                                semblant. <br>
                                D'uun seul coup j'ai retrouvé la grande ligne droite bordée de
                                peupliers. Déjà ! Tout au bout, sur la gauche, il devait y avoir une grosse
                                ferme carrée flanquée d'un silo bleu. Elle n'avait pas changé, sauf peut-être
                                ce grand hangar disgracieux dont je ne me souvenais pas. <br>
                                — Cest vrai que la mort de papa, ça n'a rien arrangé entre nous
                                deux. Tu dois admettre que c'était devenu votre chagrin avec Hélène.
                                Chasse gardée, la mauvaise en était exclue. Le silence aussitôt que
                                j'arrivais. Pas étonnant que le dialogue ait viré au minimum, bonjour,
                                bonsoir et terminé. Tu te réservais quand même les remarques
                                désagréables sur mon désordre, ma prétendue fainéantise ou mon allure,
                                sans compter les regards appuyés à la pendule si par malheur je rentrais un
                                peu en retard... <br>
                                On passait le pont sur le chemin de fer. J'ai retrouvé l'inévitable
                                blague de mon père qui nous demandait de regarder en bas si ça mordait.
                                Le pire c'est que, des années durant, à chaque fois, mon obéissante sœur
                                s'est dévissé le cou à chercher d'improbables pêcheurs pour hausser
                                finalement les épaules de dépit, à la grande joie de Papa. <br>
                                — Un moment, juste avant qu'Hélène épouse son grand benêt
                                friqué, j'ai naïvement cru que les choses allaient changer : tu souriais, je t'ai
                                même surprise un matin à fredonner.. Mais au lendemain des noces, on
                                s'est retrouvées toutes les deux. Seules. Mortel ! Heureusement, pour faire
                                diversion, on avait maintenant les dimanches, le déjeuner de notre dinde
                                énamourée avec son paon. On a les spectacles que l'on peut ! Toi, tu te
                                mettais en quatre, cuisine, toilette, conversation, l'élu de ton aînée avait
                                droit à toutes tes attentions, tous tes soins. Le mien, quelque temps plus
                                tard, n'a pas eu vraiment droit aux mêmes égards. <br>
                                II faut dire que, cet après-midi glacial d'hiver, ma mère avait rendez-
                                vous chez le gynéco. Trop confiante dans le retard bien connu du praticien,
                                j'avais eu la mauvaise idée de ramener la maison mon petit copain, un
                                garçon dont je concède qu'il était un peu coloré, mais où est le mal ? On
                                s'apprêtait à repartir. La surprise quand la porte s'est ouverte ! Là, elle
                                nous l'a joué très sobre : l'index impératif, elle a montré la sortie. « Pas de
                                ça chez moi » pour le principe. J'ai raccompagné jusqu'en bas mon beau
                                métis mais le lendemain il n'y avait plus personne au rendez-vous.
                                — La goutte de trop. Cest ce jour-là que j'ai vraiment décidé de
                                partir. Je t'ai jamais raconté combien j'en ai bavé là-bas, les petits boulots
                                pour survivre, la faim, le froid, toutes les humiliations. La solitude surtout.
                                Les coups de tête ça se paie cher, et je t'assure que j'ai bien donné. Tu m'as
                                reproché depuis de t'avoir laissée sans nouvelles à cette époque. Pour te
                                dire quoi ? Que je pleurais tous les soirs. Plutôt crever ! <br>
                                L'usine à l'entrée du village semblait abandonnée et la grand-rue
                                dormait encore. Dans la maison natale de mon père, les volets étaient clos.
                                Des gens qu'on ne connaissait plus. <br>
                                — Nos vies se sont organisées, chacune de son côté, et c'est peut-
                                être mieux comme ça. On se surveille de loin. Je t"accorde que je me suis
                                pas ruinée pour toi en téléphone, mais toi non plus, faut le dire. Tu
                                admettras aussi que, les rares fois où j'ai dû t'appeler, ton « allô » était si
                                réfrigérant qu'il donnait tout de suite renvie de raccrocher. Et je ne te
                                parle pas des visites, de ton regard quand je débarquais, ces yeux qui
                                éternellement me jugeaient et me condamnaient. Il faut me rendre cette
                                justice pourtant que, si j'ai soigneusement évité les baptêmes et autres
                                communions des prétentieux rejetons de ma sœur, pour les coups durs,
                                ton opération, ta fracture, j'ai toujours été là alors que d'autres, comme
                                par hasard, se bichonnaient à Djerba en thalasso ou se faisaient dorer les
                                bourrelets sur les plages blanches des Seychelles. Du coup, c'est sur moi
                                qu'est retombée la décision de cette maison de retraite que t'as jamais
                                acceptée. Et, bien sûr, c'est à moi que tu en as voulu ! Qu'est-ce que je
                                pouvais faire d'autre ? <br>
                                Deux vieilles femmes, le pain sous le bras, bavardaient sur la place
                                de la mairie déserte. Le village se meurt doucement. Au pont, rai pris le
                                chemin de terre cahoteux, le long de la rivière. <br>
                                — Pourtant, l'autre jour, je dois avouer que tu m'as bien eue. En
                                arrivant. je suis tombée sur l'infirmière. Elle m'a raconté ton malaise de la
                                semaine dernière, comme tu te remettais tout doucement et elle a rajouté
                                que tu te réjouissais parce que ta « petiote » allait venir. « Ta petiote ! » Tu
                                peux pas savoir comme ce mot m'a touchée. C'est tout nous ça, de pas
                                savoir se dire. J'arrivais dans ta chambre, tu reposais sur ton lit dans la
                                pénombre, les yeux fermés. Je t'ai regardée, toute petite, abandonnée,
                                fragile, et j'ai alors pris conscience que, moi ta fille, je ne t'avais même
                                jamais vue dormir. Il m'est monté comme une boule de tendresse dans la
                                gorge. Je ne sais pas si tu l'as senti mais je me suis approchée, je t'ai bien
                                regardée pour une fois et je t'ai embrassée. Tout doucement. Et cette fois,
                                je n'en ai pas honte, j'avais bien les larmes aux yeux. <br>
                                On arrivait. J'ai retrouvé la prairie des parties de pêche de mon
                                enfance. <br>
                                — Cest toi qui as voulu revenir ici, on y est. Finalement, tu as de la
                                chance, il fait beau et, regarde, les vaches viennent même nous accueillir.
                                J'ai coupé le moteur, fait le tour de la voiture et défait sa ceinture. <br>
                                — Allez viens. <br>
                                Je l'ai prise contre mon cœur, rai serrée longuement.
                                — Hélène aurait voulu être avec nous, elle pouvait pas soi-disant,
                                trop dur...Ta « petiote » la remplacera une fois de plus. Viens. Je me doute
                                qu'il y a eu dans ces prés quelque chose entre Papa et toi. Je veux surtout
                                pas savoir mais l'idée me fait du bien... <br>
                                J'ai ouvert la clôture, les bovins se sont approchés. <br>
                                — Où tu veux qu'on se mette ? Là au millieu ? Pourquoi pas ? <br>
                                On profitera du vent. Bon, se lance, on y va ? Allez ouste, poussez vous, les
                                vaches ! Le temps de m'orienter et maintenant attention, je préfère pas regarder,
                                j'ouvre l'urne...
                                Au revoir Maman. </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal15 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : la petiote</h3>
                <h4 class="auteurnouvelle">par André Fanet</h4>
                <p>« Oui, je t'installe à l'avant, je préfère. Je sais bien que tu l'aimes
                    pas cette place du mort... » <br>
                    Mais là, je pouvais davantage la surveiller, et je pense encore qu'il
                    valait mieux. Je lui ai donc avec soin accroché sa ceinture, bien vérifié,
                    avant d'aller de l'autre côté me caler derrière mon volant. <br>
                    Avec...</p>
                <div class="tourbutton">
                    <button @click="showModal15 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal16 }">
                    <div class="modal-background" @click="showModal16 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Un juste</h3>
                            <p>L'orage éclata en soirée, emportant vers l'Allemagne l'édredon de chaleur qui
                                emmitouflait
                                la vallée depuis des jours. La bourrasque fondit sur le village, le ciel se chargea de
                                ténèbres et
                                avant
                                même que tombe la première goutte de pluie, l'installation électrique de la maison
                                rendit l'âme.
                                Comme tirés d'un songe, nous accueillîmes avec lassitude cette nuit sonore et
                                aveuglante. et nous
                                levâmes sans hâte à la recherche de lampes-torches ou de bougies. <br>
                                En d'autres temps et d'autres circonstances, nous nous serions égaillés dans la vaste
                                demeure, prêts aux fausses terreurs, préférant parfois fermer les yeux et se fier à nos
                                mains plutôt
                                que de risquer d'entrevoir dans un éclair l'un de nous, silhouette horriblement tapie
                                dans un recoin.
                                ou, silencieuse et inattendue, juste à nos côtés, Mais l'heure n'était plus au jeu, Nous
                                devions, à la
                                chandelle, éclairer le corps de mon grand-père. <br>
                                Il était mort la veille, noyé dans un oedème aigu du poumon, alors que son jardin
                                s'étiolait
                                sous un soleil enragé. Il avait tardé à appeler les secours. À son arrivée, le médecin
                                n'avait pu
                                qu'établir de décès, avant de poursuivre ses visites désolantes : cette année-là, l'été
                                faisait des
                                ravages dans la vallée vieillissante. Les enfants et petits-enfants, contraints par la
                                raréfaction du
                                travail, abandonnaient les grandes bâtisses familiales à leur solitude ou aux soins d'un
                                ultime
                                gardien décidé à y demeurer vaille que vaille, tant que le corps tiendrait. <br>
                                Plus qu'un troupeau, nous étions une meute, que les murs du jardin ne retenaient guère à
                                l'appel de la montagne, nous redevenions sauvages, imprévisibles, affamés sans relâche.
                                Ma grand-
                                mère supportait cela très bien et satisfaisait de bonne grâce nos besoins impérieux. Sa
                                mort mit fin à
                                nos ardeurs ; du reste, nous nous étions calmés en grandissant. Sagement, absorbés par
                                d'autres
                                tâches, nous espaçâmes nos visites : la villégiature estivale devint un devoir et mon
                                grand-père un
                                ermite. Là-haut dans sa vallée, on avait l'impression de le déranger. Il semblait
                                repousser la vie au-
                                delà des murs, comme si nous n'étions plus grand-chose depuis la mort de sa femme, comme
                                s'il
                                n'attendait que ça, que son coeur lâche d'un coup. Cela avait fini par arriver, et le
                                lendemain
                                s'était
                                produit ce que depuis des années la vieille maison n'avait pas connu : le regroupement
                                sous son toit
                                de l'intégralité de la famille, blottie autour du lit de mes grands-parents pour la
                                veillée funèbre.
                                <br>
                                La nuit avait assombri l'orage. Dans l'obscurité lardée d'éclairs, nous avancions
                                toujours à
                                tâtons, appelant à la rescousse la mémoire de nos mains. Nous passions d'une pièce à
                                l'autre, nous
                                lançant pour éviter de nous cogner, de nous surprendre, pour nous rassurer, des appels
                                brefs qui
                                attendaient l'écho d'une réponse. Nous avions rayonnés depuis le lit de mon grand-père
                                et il me
                                semblait que chacun, arrivé au bout de son chemin, y resterait, hagard, se balançant
                                comme les
                                bêtes qu'on enferme. <br>
                                Je progressais derrière mes mains. Mes paumes glissaient sur les murs, la rencontre de
                                l'arête d'un meuble du chambranle d'une porte. Elles reconnaissaient le grain d'un
                                papier peint, la
                                résistance d'une poignée, la moulure buffet. Elles sollicitaient machinalement un
                                interrupteur,
                                exploraient un tiroir, hésitaient à s'y perdre en toute confiance, craignant d'y
                                rencontrer la lame
                                dun
                                couteau ou une pelote d'aiguilles. Les matières et les volumes convoquaient pour moi les
                                odeurs et
                                les bruits de la maison, disparus depuis longtemps et je me laissais aller à la noria de
                                mes
                                souvenirs. <br>
                                C'est moi qui trouvai les lettres. <br>
                                affale dans un voltaire, la tête appuyée au dossier, la nuque douloureuse, je m'étais
                                abandonnée à mes tristes rêveries et un début de migraine. Tout près, la voix de mes
                                cousins
                                éclata : « Mais il les planquait où ses piles et ses bougies, le Pépé ? »
                                Je me levai instantanément, mue par la logique qui avait dû être celle de notre
                                grand-père.
                                Je me rendis dans la chambre où il reposait. Dans l'obscurité, les draps blafards
                                dessinaient en
                                négatifs les contours de corps. Sans plus le regarder, j'entrepris de fouiller sa table
                                de nuit dans
                                cette mison humide, éleve au creux d'une vallée où bon an, mal an s'amonscelaient et
                                crevaient
                                les nuages du couchant, mon grand-père avait dû plus d'une fois se découvrir aveugle au
                                sortir d'un cauchemar, l'installation électrique ayant succombé aux intempéries. À
                                portée de sa main
                                devait se trouver de quoi le rassurer.
                                Dans le tiroir du chevet, je découvris effectivement une lampe de poche que j'allumai.
                                Son
                                faisceau fit miroiter dans le tiroir un bout de chaine dorée sous une épaisse enveloppe
                                que je
                                soulevai avec précaution, se lovait la chaine de baptême de ma grand-mère. Ma gorge se
                                noua. Mes
                                doigts se crispèrent sur l'enveloppe, qui atira mon attention. J'en extirpai le contenu.
                                <br>
                                À l'instant je dépliai les feuillets jaunis de ce courrier suffisamment précieux pour
                                que
                                mon grand-père le conservât au pied de son lit, j'aurais voulu y découvrir les mots
                                d'amcur qu'il
                                avait pu y coucher du temps où ces choses se faisaient, de mots polis et désuets
                                adressés à ma
                                grand-mère, et peut-être aussi sa réponse à elle, l'origine de tout, l'origine de moi.
                                Je rêvai tout
                                cela.
                                moi qui avais dix-sept ans, en ces quelques minutes passées seule aux côtés d'un mort
                                dont je
                                violais les souvenirs avec l'espoir d'étoffer de romantisme l'histoire familiale que je
                                raconterais un
                                jour à mes propres enfants. <br>
                                Le courrier portait l'en-tête de la préfecture des Vosges. Il était daté de 1943. Il y
                                était mentionné
                                l'arrestation d'une famille juive à l'adresse de la même maison où je me trouvais. Parmi
                                d'autres papiers de la même époque, une missive destinée mon grand-père attira mon
                                attention :
                                l'auteur y avait couché des remerciements sans objet et faisait discrètement allusion à
                                la
                                récompense laquelle ils étaient convenus. Le ton était amical, presque badin. et
                                signature illisible — bien sûr. <br>
                                Je lus et relus, incrédule me demandant par quelles voies obscures ce document tupéfiant
                                avait pu arriver là et il pouvait porter le nom de mon grand-père. La pénombre de la
                                chambre se peuplait peu à peu de chasseurs de lumière. Ma mère fut soudain à mes cotés.
                                Sans un mot
                                je lui donnai les papiers que ses frères vinrent lire par-dessus épaule. Je vis son
                                visage
                                dans la lueur de la flamme courbée par son souffle. Alors, je me souvins. <br>
                                Comme dans toutes les familles, la notre était riche d'anecdotes plus ou moins
                                pittoresque,
                                qui, passant d'une génération l'autre, s'enjolivaient ou finissaient par perdre leur
                                intérêt.
                                C'étaient,
                                au gré des humeurs et des demandes, les épopées de l'enfance, le souvenir des biens et
                                des amis
                                perdus, le récit des joies et peines du travail, le fourre-tout des existences, un
                                échaveau que num
                                n'avait jamais démêler, car chacun savait que la vie est comme ça, inextricable. <br>
                                Pendant la demière guerre, ma mère était à l'âge où les souvenirs deviennent précis et
                                foissonnants.
                                Mes grands-parents exploitaient alors une petite ferme et leurs quelques vaches
                                contribuaient à la survie du village. Ils vivaient à quelques pas de cette maison qui
                                serait un jour
                                la
                                nôtre, celle médecin venu s'installer dans le pays une dizaine d'année plus tôt et dont
                                ma mère
                                Se rappelait le français ciselé et l'accent indéfinissable. Mon grand-père effectuait
                                pour lui des
                                travaux d'entretien dans le jardin et la maison. Ma mère ne perdait une occasion de
                                l'accompagner et rejoindre ainsi les filles médecin, doubles portaits de leur mère : des
                                jumelles
                                exactes, aux de cheveux corbeau et aux yeux de jais. <br>
                                Ma mère m'avait jour avoué la fascination qu'exerçaient sur elle les deux fillettes qui
                                avaient à peu près son age, mais avec lesquelles elle n'avait jamais eu l'impression
                                d'être autre
                                chose
                                qu'une observatrice affectueusement tolérée de leur monde singulier.
                                "Elles étaient gracieuses et enjouées, me racontait-elle et m'accueillaient gentiment.
                                Je me
                                rappelle qu'elles faisaient des dessins, des paysages chatoyants, avec dans le ciel des
                                personnages qui flottaient. Je m'évertuais à les copier. J'avais l'impression de pénéter
                                ainsi
                                univers.. Ma mère. me voyant dessiner avec une telle obsession ces choses étranges en
                                avait parlé à
                                la femme du médecin. qui lui avait répondu que ses filles ne faisaient que reproduire un
                                tableau
                                qu'ils avaient à la maison, et qu'elles aimaient beaucoup. Du coup, j'avais cessé de
                                dessiner Je
                                n'avais pas envie d'entrer dans le monde d'un inconnu "
                                Puis la guerre avait pris un tour auquel ma mère de huit ans n'avait pas compris
                                grand-chose
                                Ses souvenirs se heurtaient au secret des conversations des adultes.
                                « Un jour, la maison s'est retrouvée. porte et volets clos. Il s'est dit dans le village
                                que le
                                docteur était parti en zone libre, en Amérique, peut-être. Mon père continuait toutefois
                                à s'occuper
                                du jardin, mais me décourageait de le suivre, puisque mes amies n'y étaient plus. Il
                                avait en fait
                                converti la plus grande partie du jardin en potager, ce qui améliorait l'ordinaire de
                                nombre
                                d'habitants village, et surtout le nôtre. Je me disais qu'avant le retour des jumelles,
                                il faudrait
                                replanter des fleurs.. Et puis une nuit j'ai entendu du bruit dans la rue. Je me suis
                                levée. jai
                                rgardé par la fenêtre, et j'ai vu une grosse voiture noire engloutir deux chemises de
                                nuit blanches,
                                extirpées de l'ombre du jardin. J'ai cru voir des fantômes, Pour moi, toute la famille
                                du docteur
                                était
                                partie depuis longtemps. » <br>
                                Au matin, Maman ne savait plus si ce qu'elle avait entreaperçu durant la nuit était un
                                réve ou
                                la réalité, et, en bonne gamine qu'une idée tarabuste, elle n'avait cessé d'en parler à
                                ses parents,
                                jusqu'à ce qu'on lui explique que les Allemands prenaient les Juifs, que ses amies
                                étaient juives et
                                qu'elles et leurs parents s'étaient cachés dans la grande maison pendant des mois, sans
                                sortir, pour
                                qu'on les crois partis, et que c'était son père à elle qui leur amenait manger, mais ça,
                                personne ne
                                le savait ni ne devait le savoir tant que les Allemands seraient là. <br>
                                « J'ai posé les mêmes questions toi : c'est quoi un Juif ? Pourquoi et où les Allemands
                                les emmènent-ils ? Qu'est-ce-qu'on leur fait ? Est-ce qu'ils reviennent ? Je n'ai obtenu
                                aucune
                                réponse. Ta grand-mère m'a vaguement expliqué que les Juifs n'étaient pas chrétiens et
                                qu'il y avait
                                des gens qui leur en voulaient parce qu'ils avaient tué le Christ. Bien que troublée,
                                j'ai décidé que
                                mes amies n'étaient pour rien dans ces vieilles histoires, ce qui ne m'a pas permis de
                                comprendre
                                pwrquoi les Allemands les avaient arrêtées. En revanche, je mourrais de savoir comment
                                mon père
                                avait pu les aider sans que personne ne s'en rende compte. Mais c'était un grand et
                                grave secret,
                                m'avait-on dit, car si on apprenait qu'il avait été le complice de Juifs, on remmènerait
                                aussi, et
                                peut-
                                être toute notre famille avec. Non loin de là, à Natzweiler. se trouvait un camp
                                allemand où
                                travaillaient des prisonniers, et de ce camp comme de tous les autres endroits où les
                                Allemands
                                enfremaient des hornmes, des femmes et des enfants, se disaient des choses terribles
                                Alors. je n'ai
                                ien demandé ni rien su de plus tant qu'a duré la guerre » <br>
                                Nul n'avait jamais revu le docteur, sa femme et ses filles. La paix revenue, Maman avait
                                appris des noms qui étaient devenus pour elle le comble de l'effroi : Auschwitz,
                                Bergen-Belsen.
                                obibor, Struthof... Et son père, qui s'était dressé contre cette monstruosité. qui avait
                                aidé au péril
                                de
                                vie une famille juive, avait été, au sortir de la guerre, auréolé du statut de héros, de
                                Juste. <br>
                                La paix revenue. mon grand-père, lassé de ses maigres vaches, était descendu dans la
                                plaine
                                pour y trouver du travail. Il rentrait chez lui deux trois fois par mois, donnait de
                                l'argent à ma
                                grand-mère et repartait. Il y eut, au fil du temps, de plus en plus d'argent. Et un
                                jour. mon grand-
                                père annonça qu'il s'associait avec un ami pour reprendre une scierie. <br>
                                La vie changea. La France avait besoin de bois. mon grand-père sciait- Ma grand-mère
                                devint une dame, ma mère eut des soulliers, des robes et des chapeaux, et fut envoyée,
                                comme ses
                                frères, dans un lycée catholique d'Epinal. Elle se consacra aux études avec passion. au
                                savoir avec
                                avidité, et sa reconnaissance pour son père n'eut plus de limite. Elle ne songeait plus
                                aux jumelles.
                                jusqu'au jour où ses parents lui annoncèrent une grande nouvelle : les affaires étaient
                                florissantes.
                                déménageait. Où ça ? Pas loin. <br>
                                " Mon père avait acheté la maison du docteur. Elle fut restaurée, lavée, curée.
                                blanchie.
                                avant que nous emménagions, C'était comme si on avait essayé d'en ôter la moindre trace
                                de ses
                                précédents occupants. Mais pour moi, la maison portait en elle mes amies. Mon père dut
                                me
                                montrer le recoin de la cave qu'il avait muré sur elles et leurs parents. Le seul accès
                                en était un
                                soupirail dans l'appentis où il entreposait ses outils. Je ne me sentais pas à l'aise
                                dans cette
                                bâtisse
                                et je finis par trouver du plaisir à l'internat. Je ne me suis réconciliée avec notre
                                maison que
                                lorsque
                                je vous y ai vus si heureux, toi, tes frères et tes cousins. » <br>
                                Maman lisait maintenant les derniers papiers : l'acte d'achat de la maison, et un reçu
                                de
                                1948, signé par mon grand-père. Il déclarait avoir cédé à un marchand d'art, pour une
                                somme
                                astronomique, une huile sur toile. Maman lut à voix haute. <br>
                                « Un Chagall... » <br>
                                Elle posa les yeux sur le corps de son père : ils étaient vides de tout. Il me sembla
                                cet
                                instant que mon grand-père avait voulu ce moment, qu'il n'avait pas eu le courage
                                d'affronter de son
                                vivant, comme une justice rendue à deux petites filles. Nul ne saurait jamais qui
                                d'autre que lui.
                                dans la vallée, devait sa bonne fortune à celle du médecin juif, mais à mon grand-père
                                je ne pouvais
                                prêter le mtindre cynisme : je lui cherchais des excuses - on l'a obligé, c'était une
                                tentation trop
                                forte, ils étaient si pauvres. Pourquoi, s'il n'avait éprouvé le moindre remords,
                                aurait-il conservé
                                ces
                                papiers, les preuves de sa faute, avec autant de soin que la chaine de baptême de sa
                                femme ? Et ce
                                reproche de pierre. la maison où il avait passé seul la fin de sa vie. ne l'avait-il pas
                                voulu comme
                                une punition, d'autant plus terrible qu'il y voyait ses enfants vivre et se nourrir en
                                toute innocence
                                de
                                son forfait ? <br>
                                Puis la culpabilité fondit sur moi. <br>
                                Cest alors que je vis ma mère rouler les papiers qu'elle n'avait pas lichés en une
                                baguette Elle
                                approcha une bougie, l'enflamma. avec cette torche. calmement. sans qu'aucun de
                                nous ne protestat elle mit le aux ridaux, aux tapis, aux draps du lit, avant de nous
                                pousser hors
                                de la maisons. Nous sortimes docilement. Ma mère referma la grille sans se retourner,
                                tandis que
                                derrière elle, la maispn brûlait
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal16 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Un juste</h3>
                <h4 class="auteurnouvelle">par Laura Kuster</h4>
                <p>Le résumé de la nouvelle</p>
                <div class="tourbutton">
                    <button @click="showModal16 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2011 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal17 }">
                    <div class="modal-background" @click="showModal17 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Ad patrem</h3>
                            <p>Le feuillage du grand chêne filtre les derniers rayons du
                                soleil. Combien de fois s'est-il couché depuis que je vous ai quit-
                                tés mes anges ? Votre image est restée à tout jamais fixée sur ma
                                rétine. <br>
                                Alicia portait une robe bleue, de la même nuance que ses
                                yeux et sa tignasse boucléé flottait autour de son visage poupin.
                                Et toi, Bertrand, tu venais de faire tes tout premiers pas et tu ap-
                                préciais fort l'indépendance de ce nouveau statut de marcheur.
                                Ce jour-là, à ce moment précis, cette toute dernière fois donc, tu
                                m'as souri mon fils, de cet air qui me faisait fondre ; tu m'as en-
                                suite tendu tes petits bras potelés et, de ta démarche hésitante, tu
                                as couru vers moi, tout excité. Mais je n'ai pas répondu à ton at-
                                tente, pas plus que je n'ai renoncé à partir. Non, je ne l'ai pas
                                fait... Je n'ai pas vu non plus ta mine déçue. Je me suis contenté
                                de briser l'innocence d'un coup sec, de tout balayer d'un revers
                                de la main, de vous tourner brutalement le dos, à ta sœur et à toi.
                                J'ai commis l'irréparable ; je me suis enfui, sans un mot, sans un
                                geste de tendresse, sans la moindre hésitation, un soir d'été...
                                cette dernière fois. <br>
                                Et après ? Après, je me suis efforcé de vous oublier. Oh, je
                                n'ai eu aucun mal à y parvenir dans les premiers temps. Force est
                                de le reconnaitre, malgré la honte qui m'accable. Mon nouvel
                                amour m'accaparait tellement ! Passion dévorante que j'étais in-
                                capable de maitriser. Tout pour elle et plus rien pour vous, pour
                                mes petits, pour la chair de ma chair, subitement rayée de ma vie.
                                Abandonnés et de surcroit chassés de mon esprit, les deux êtres
                                que j'avais tant chéris. <br>
                                Et puis, lorsque le sentiment amoureux a commencé à dé-
                                cliner, vous êtes réapparus. Vos tendres frimousses ont commen-
                                cé à me hanter. Les deux bouilles rondes surgissaient sans crier
                                gare : au détour d'une conversation avec la femme qui partageait
                                ma vie, au cours d'une promenade en sa compagnie et jusque
                                sous les draps, au cœur de notre intimité. Ou encore, au travail,
                                lorsque le matin, par la fenêtre de mon atelier d'artiste,
                                j'apercevais de jeunes mamans, à demi étranglées par le bambin
                                qui pendait à leur cou et pressant le pas en direction de la garde-
                                rie toute proche. Arrivaient ensuite des ribambelles de gosses en
                                route vers la piscine du quartier. Au milieu d'un groupe de pe-
                                tites filles virevoltantes, je croyais parfois te voir, Alicia. Mais
                                comment aurais-je pu te reconnaître parmi elles alors que des
                                milliers de kilomètres nous séparaient ? Alors, pour combler le
                                manque, je recouvrais mes toiles de ton visage et de celui de ton
                                petit frère ; je m'évertuais à retrouver l'expression de votre re-
                                gard, le délicat tracé de vos fossettes, la carnation parfaite de
                                votre peau. Des heures durant, je vous inventais. <br>
                                Un jour, « la raison de votre abandon » s'est fendue d'un
                                large sourire pour m'annoncer qu'elle portait notre enfant. Mon
                                cœur s'est serré. A travers vos portraits qui tapissaient désormais
                                tous les murs de mon atelier, j'ai cru déceler des grimaces de
                                douleur. J'ai souri à la future mère alors que j'aurais voulu hurler
                                de désespoir ; je l'ai enlacée alors que je mourrais d'envie de la
                                repousser. Contenant la colère que je sentais sourdre en moi, j'ai
                                fait semblant d'être heureux. <br>
                                Les semaines ont passé. Ma terreur s'amplifiait à la vue de
                                ce maudit ventre qui s'arrondissait ! Je ne savais plus dormir.
                                Vos deux silhouettes jouaient sans relâche dans ma tête. Moi,
                                père à nouveau alors que j'avais failli à ce rôle une première
                                fois ! Comment pouvais-je décemment m'octroyer ce droit ? Le
                                tourment me rongeait quand soudain le foetus a lâché prise,
                                comme s'il avait fini par réaliser l'inutilité de son existence face
                                à un géniteur qui le rejetait. Je me souviens de ses larmes à elle et
                                de mon indifférence, de ma joie même lorsque j'ai réalisé que
                                personne d 'autre ne viendrait prendre votre place. <br>
                                Notre couple n'a pas résisté longtemps après l'épreuve.
                                Mais devrais-je plutôt dire "son épreuve". Elle est partie, je ne
                                l'ai pas retenue. Je me suis senti apaisé, délivré d'un fardeau et
                                me suis mis à peindre avec une frénésie redoublée, mon âme tout
                                entière tournée vers vous. Des petits garçons, des petites filles et
                                puis, les années passant, des adolescents, comme ceux que vous
                                étiez devenus. Ma créativité se nourrissait de votre souvenir et
                                aussi de votre vie actuelle que j'imaginais et que je plaquais sur
                                mes toiles à grands coups d'aplats colorés. Car je la voyais belle,
                                insouciante et gaie votre vie. Des enfants heureux, même sans
                                leur père. <br>
                                L'avez-vous été ? Avez-vous su grandir sans moi ?
                                Qu'avez-vous gardé des brefs moments partagés ensemble ?
                                Images fugaces engluées dans les méandres du passé, englouties
                                par des années de silence, balayées par l'abandon de votre papa. <br>
                                Et puis cette lettre est arrivée. J'ai aussitôt reconnu
                                l'écriture fine et élancée de votre mère. C'est alors qu'une pensée
                                des plus délicieuses m'a traversé l'esprit : vous souhaitiez me
                                revoir et elle allait m'en faire part. Oui, après tout, c'était fort
                                possible ! La démarche était commune à de nombreux enfants de
                                divorcés, privés depuis longtemps d'un de leurs parents. Vous
                                aviez grandi, vous étiez presque des adultes et votre papa vous
                                manquait. Sans doute vouliez-vous aussi qu'il justifie son départ
                                et les années de silence. Quoi de plus normal ? Ce passage obligé
                                ne m'effrayait pas, c'était le prix à payer pour vous revoir.
                                J'attendais depuis si longtemps de faire mon mea culpa. <br>
                                Bientôt, songeai-je, j'écouterai vos reproches et assumerai
                                ma faute. Je m'efforcerai ensuite de redevenir le père que vous
                                méritez en me jetant à corps perdu dans la reconquête de votre
                                amour et j'y parviendrai. Oui, j'y parviendrai... <br>
                                Mon cœur s'est gonflé de joie à l'idée de retrouvailles fa-
                                miliales des plus émouvantes et... a cessé de battre dès la lecture
                                des premières phrases de la missive. <br>
                                Un accident de la route, c'est ce que votre maman men-
                                tionnait, causé par un chauffard. L'ignoble individu vous a fau-
                                chés en pleine jeunesse, vous mes petits. Vous n'êtes plus de ce
                                monde, vous m'avez quitté à votre tour. Jamais je ne vous rever-
                                J'aurais voulu pouvoir continuer à vous faire vivre à tra-
                                vers ma peinture mais je n'en ai plus la force. Un voile sombre
                                recouvre mon cerveau et paralyse mes gestes. <br>
                                J'ai vendu mes toiles et remis la somme d'argent récoltée à
                                une association de lutte contre les tueurs au volant. Et ce matin,
                                j'aurais dû vous rejoindre mes amours, car plus rien ne me rete-
                                nait sur cette terre. J'avais suspendu une corde à la plus grosse
                                branche du chêne, celui-là même sous lequel je me trouve, et
                                j'étais sur le point de commettre l'irréparable lorsque la sonnette
                                a retenti. Pourquoi ne l'ai-je pas ignorée pour accomplir mon
                                geste fatal ? J'aime à croire que de là-haut, mes anges, votre
                                doux regard m'a guidé vers la porte d'entrée. <br>
                                Un jeune homme à la frêle stature se tenait dans
                                l'embrasure ; il m'a fixé durant quelques secondes d'un air mi-
                                embarrassé mi-féroce avant de balbutier : "Je suis votre troi-
                                sième enfant, celui qui était dans le ventre de votre femme lors-
                                que vous l'avez quittée" <br>
                                Je suis resté sans voix. <br>
                                Le vent bruisse à travers les feuilles tandis que le soleil
                                poursuit son inévitable déclin. Les tourments de mon âme se sont
                                apaisés, un soume de vie anime à nouveau mon être. Demain se-
                                ra un nouveau jour. br
                                Votre frère, MON ENFANT, dont jamais je n'avais pres-
                                senti l'existence, se repose dans la chambre d'amis. A travers son
                                visage, j'ai retrouvé vos traits, votre regard, votre sourire. Je veil-
                                lerai sur lui, je vous le promets ; je voulais vous le dire à travers
                                ces quelques mots que je vous adresse par-delà la mort. Il est
                                temps en effet que j'assume enfin mon rôle de père.
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal17 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Ad patrem</h3>
                <h4 class="auteurnouvelle">par Nadine Groenecke</h4>
                <p>Le feuillage du grand chêne filtre les derniers rayons du
                    soleil. Combien de fois s'est-il couché depuis que je vous ai quit-
                    tés mes anges ? Votre image est restée à tout jamais fixée sur ma
                    rétine...</p>
                <div class="tourbutton">
                    <button @click="showModal17 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal18 }">
                    <div class="modal-background" @click="showModal18 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">L'hiver ou Hyper Cut</h3>
                            <p><i>Autoroute info vous souhaite la bienvenue sur le réseau des routes du grand sud en ce
                                    vendredi de départ vacances. Rien à signaler à part un léger ralentissement sur le
                                    périphérique intérieur à la sortie de Paris.</i> <br>
                                Autoroute A6. Route des vacances. Direction Chamonix. Fin d'après midi. Le soleil se
                                couche. Peu de circulation Presque pas. La route. Les champs. Les Barrières de sécurité.
                                Les
                                lignes sur le béton. Les panneaux de signalisation. Des aires d'autoroute. <br>
                                II a pris la route directement après le travail. II voulait être le plus vite possible
                                en vacances.
                                Réaction normale chez tout homme. Il a quitté le travail tôt. II voulait arriver au
                                chalet avant
                                la nuit. Pour se réveiller demain en vraies vacances. II sait que ce n'est pas possible.
                                Beaucoup trop de kilomètres à parcourir. Mais il Il a pris ses enfants à l'école et il a
                                pris la route. II avait chargé la voiture la veille Toute la journée il n'a pensé qu'à
                                ce moment
                                Où il serait Sur la route. Avec sa nouvelle voiture familiale. Un Ford Galaxie. A
                                bouffer les
                                kilomètres. A songer à ses vacances. Les pistes. la neige, les restaurants d'altitude,
                                l'air de la
                                montagne, le froid, le vin chaud, la fondue, la tartiflette. Enfin tout ce qui pour lui
                                et sa
                                famille signifie de bonnes vacances de février. De vraies vacances de février. II sourit
                                sans
                                arrêt, Face à la route. II calcule le temps qu'il reste avant l'arrivée. <br>
                                Sa femme côté essaye de se souvenir si elle a tout pris, si elle n'a rien oublié. Les
                                combinaisons de ski. le casque pour le petit, les skis, les clés du chalet, les crèmes
                                hydratantes, les crèmes solaires. Est-ce que elle a bien fermé la maison de Paris. Et
                                l'eau, elle
                                l'a coupée? Et les volets? Et les lumières? Bien toutes éteintes? Elle le harcèle de
                                questions
                                sans attendre les réponses, Elle fait la liste des gens à qui il faut envoyer des cartes
                                postales.
                                Ses parents, sa sœur, son patron qu'elle regarde avec admiration depuis 4 ans,qui lui a
                                donné
                                ses vacances sans rien dire. Lui a juste demandé une petite carte postale pour sa
                                collection de
                                paysages de France qui décorent son bureau. Ses amies proches, qui l'envient car elles
                                doivent bosser, elles. Et sa belle famille. Elle ne l'aime pas trop. mais elle pas le
                                choix.
                                Obligée. sinon jalousie, cris, disputes. Elle va passer tout le voyage le nez dans son
                                sac et son
                                agenda pour être sure d'avoir toutes les adresses. <br>
                                A l'arrière ses deux enfants. Son fils de 5 ans et sa fille de 16 ans. Tous les deux
                                côte côte.
                                Ils ne se regardent pas. Ne se parlent pas. Tous les deux plongés, l'un dans sa gameboy,
                                l'autre dans son portable. <br>
                                Sa fille habillée comme aller en boite en été. Petit débardeur, un slim. et ses
                                converses
                                roses. Maquillage au visage rimmel et tout. Le sourire aux lèvres d'avoir son amoureux
                                par
                                texto. Ca fait 4 semaines. La séparation les vacances a été dure. Mais ils se sont
                                résignés
                                ElIe n'arrête pas de penser à lui, Elle s'est promis de lui ramener un cadeau de
                                vacances. ElIe
                                hésite encore entre un tee-shirt ou un pull. Elle a prévu ça dans son budget vacances
                                qui
                                compte les produits de beauté, les boules de neige, les pâtisseries sur les pistes, un
                                teeshirt
                                « Spirit » pour sa meilleure amie, un ensemble pour elle et ce fameux cadeau. II vient
                                de lui
                                écrire qu'il l'aime. Elle sourit. Elle est heureuLse. Ca lui fait drôle de penser à lui
                                alors qu'il
                                est
                                encore à Paris où il va passer toutes ses vacances à penser à elle. ElIe espère. <br>
                                A sa droite, son frère, plongé dans son jeu vidéo. Au troisième niveau de Maricxenter 2.
                                [l
                                s'est promis de finir le jeu avant la fin des vacances. A ses pieds son sac avec son
                                cahier de
                                coloriage, son doudou et ses crayons de couleur. Attention un monstre. Saute Mario !
                                Saute !
                                Oui c'est bien. Prends le champignon ! Vas-y, vas-y ! Pause. II sourit. II est fier de
                                lui.I[I se
                                sent puissant. II regarde le paysage. Les lignes blanches défilent devant lui. II les
                                compte mais
                                elles vont trop vite pour ses yeux. Dehors le paysage défile aussi. Toujours le même.
                                Des
                                arbres, des arbres. des arbres. Et un panneau SENNECY LE GRAND avec un château dessiné
                                en dessous, Et puis les arbres, les arbres. <br>
                                La voiture avance dans ce paysage singulier, monotone, hypnotique. Encore 3h de route
                                pour
                                la petite famille pressée d'être au chalet, de voir la neige. Pour eux ils ne seront en
                                vacances
                                qu'une fois là-bas. Pour le moment ils sont dans un entre-deux. C'est ça qu'inspire
                                l'autoroute
                                Un entre-deux, un no man' s land entre le travail et le repos, un espace pour faire le
                                vide dans
                                sa tête, repartir à oublier le stress, le travail, les collègues, les embouteillages. la
                                pollution, Paris. Tout oublier. Tout qui rappelle la vie active. Ne plus se souvenir de
                                ça. C'est
                                pour ça qu'il a posé ses vacances, ses congés payés. Pour oublier, prendre la route,
                                repartir à
                                zéro. prendre un nouveau départ, se rel»ser pendant deux semaines. Et après reprendre la
                                vie
                                comme avant ce départ. <br>
                                Le rituel. Métro boulot dodo pour lui. Vaisselle travail ménage pour elle. Lycée pour sa
                                fille
                                et maternelle pour le petit. La même chose pour tout le monde. Rien. rien ne change
                                après les
                                vacances. Ils se sentiront mieux, reposés, c'est tout. C'est ça qu'ils cherchent tous.
                                Le repos
                                Se sentir mieux. Même s'ils savent qu'au retour rien ne change. Rien du tout. Il roule
                                toujours. Encore. <br>
                                Ils se sont arrêtés il y a 30 minutes. Pause pipi. Pause diner. A la station service il
                                a pris de
                                l'essence. Au cas où, puis il est allé à la "cafét". Un parisien pour lui. Un américain
                                pour les
                                trois autres. Des frites. Les enfants un coca. De l'eau pour elle. Et lui, une bière. Il
                                se dit
                                qu'une petite ne peut pas lui faire de mal. De plus, il n y a personne sur la route. Il
                                a confiance
                                en lui. Les accidents arrivent toujours aux autres. Jamais à lui. Alors pourquoi
                                aujourd'hui. Il
                                n'y a pas de raison. Puis un café pour tenir éveillé. Son portable sonne. C'est Bernard
                                son
                                collègue. II est tard. mais Bernard appelle toujours tard. Problème de dossier pour le
                                nouveau
                                chantier. II est directeur de chantier pour le nouveau stade. Beaucoup de travail même
                                en
                                vacances. En tout cas c'est ce que lui dit Bernard. Lui, précise les choses. Oui
                                d'accord,
                                demande aux autres. Toujours les mêmes soucis quelque soit le chantier, le travail
                                d'équipe, le
                                type de matériaux ou le budget. On le sait tous. Et il demande à ne plus être dérangé.
                                En tout
                                cas pendant une semaine, Mais par acquis de conscience il précise sauf en cas de gros
                                problème. Mort sur le chantier, problème budgétaire, problème de livraison. etc.. .
                                II reprend une bière pour relancer la mise à zéro. Pour ne pas être dérangé par le
                                travail
                                pendant le trajet. Pour être sur d'être en vraies vacances l'arrivée. Etre vierge de
                                sensation
                                de sentiment, de tout. <br>
                                Il est temps de reprendre la route. La A40 cette fois. II reste des kilomètres à
                                bouffer. Des
                                chansons idiotes à écouter jusqu'à ce que le gosse dorme. Dorothée, Petit loup, Henri
                                Dès.
                                Apres il mettre autoroute info pour connaitre la situation de la route. II se doute
                                qu'il
                                n'y aura rien de spécial. Vue l'heure tardive et le peu de voiture sur la route. Mais on
                                ne sait
                                jamais. On n'est jamais assez prudent. C'est ce qu'il pense. Lui le père de famille
                                précautionneux, pret à tout pour le bien-être de sa petite troupe. Soucieux de l'avenir
                                de ses
                                enfants. II a fait des économies pour leurs études, Sa fille veut être avocate et le
                                petit super
                                héros ou pompier. Mais ça lui passera il le sait. Jamais de dispute avec sa femme, trop
                                peur
                                du divorce, trop peur de la perdre. II aime sa femme. C'est pour elle qu'il a acheté ce
                                chalet
                                Près des pistes. Ils y vont l'hiver, 2 semaines en été pour faire des travaux et
                                profiter de l'air
                                de la montagne. II aime la montagne. C'est son petit bonheur à lui. Le silence dans les
                                plaines,
                                les levés de soleil sur ta neige. II se sent bien là-bas, il se sent vivant, grand,
                                puissant même.
                                Comme son fils face à sa console de jeu. Mais cette sensation est nonnale, humaine,
                                primaire.
                                Cette parcelle de terre qu'il a achetée, c'est à lui, sa propriété, son bien. II en
                                prend soin. Il
                                paye des impôts n'y être qu•au maximum I mois par an. Principe capitaliste. Avoir
                                quelque part une seconde maison dite de vacances, pour n'en profiter qu'un mois par an.
                                Question d'image, marque de richesse. C'est ça qu'il accélère, retrouver son bien.
                                être chez lui. Ca y est tout le monde dort, Il est 23h et tout le monde dort. Sa femme.
                                son fils,
                                sa fille, sauf lui. Le paysage n'a toujours pas changé. Il roule, avance, s'enfonce dans
                                la nuit-
                                de voitures autour de lui. II le savait. C'est pour ça qu'il est parti le soir. Pour
                                être seul sur
                                la route. II se sent très bien, Seul. Sur la route, au milieu de la campagne. Toujours
                                la même
                                campagne. Encore et encore. II pense à tout, à rien. Il écoute la radio, la musique qui
                                passe. II
                                se concentre sur la route. II continue de s'enfoncer sur cette route. Il ne voit pas
                                très loin. Mais
                                il avance sans peur. II sait sans savoir où il va. Pas de cartes. Toujours dans la nuit.
                                C'est là
                                qu'il aime conduire. Dans la nuit. Personne autour de lui. L'obscurité, le silence. De
                                temps en
                                temps, une autre voiture. un panneau, une station service. II accélère encore et encore.
                                Il
                                dépasse la limite de vitesse. Un peu. Comme d'habitude, toujours un pas trop non plus
                                pour se sentir bon citoyen, C'est ça la conscience de l'homme. Allez contre la loi, mais
                                ne pas
                                culpabiliser, jamais car on ne fait rien de mal ni de bien au final. Alors il accélère.
                                Toujours. <br>
                                Chamonix 160 kilomètres. Il se rapproche. Sa mise à zéro est presque finie. Son portable
                                éteint. Au cas où il le rallumera dans trois jours, quand il sera bien immergé dans les
                                vacances. Pour votre sécurité. faites une pause toutes les deux heures. Ca fait
                                2heures30 qu'il
                                roule. Mais il est presque arrivé. II dormira au chalet. II est bientôt arrivé. Pourquoi
                                se ralentir,
                                pourquoi ? Aucune raison. Il ne sent pas la fatigue. Autour de lui ils dorment, donc eux
                                ils
                                s'en foutent. rien à foutre. ça ne changera rien pour eux. rien de plus. rien de moins.
                                Alors il
                                continue, il accélère encore et encore, toujours. Cette fois il a largement dépassé les
                                130. mais
                                il ne fait attention. Il ne regarde pas le cadran. Il avance l'instinct. Son GPS le
                                préviendra s'il y a un radar, et il ralentira. Il fait confiance à cette machine, il y
                                aura un bip et
                                ensuite il ralentira, passera le radar et reprendra sa vitesse d'avant le radar. Face à
                                lui. la route
                                toujours droite, il la balaye avec son véhicule, avec sa vitesse. Il la sent s'enfoncer
                                dans son
                                regard. Elle le pénètre, elle se rétrécit devant ses yeux. Ou bien ses yeux se ferment.
                                Ca doit
                                être ça. Il lutte pour les maintenir ouverts. Mais après une journée de travail, une
                                dure journée,
                                réunions, papiers signer, problème avec sa secrétaire. Il ne veut plus que dormir
                                maintenant,
                                juste un peu. Il ferme les yeux. Juste quelques secondes. De toute façon il n'y a pas
                                d'obstacle
                                devant lui, Personne. Il va les rouvrir. Mais pas tout de suite. A la fin de cette
                                chanson. Elle
                                est bientôt finie, Il le sait. Il la connait trés bien. Il l'aime. « Dans le port
                                d'Amsterdam y a des
                                marins qui dansent en se frottant la panse contre la panse des femmes Il la fredonne
                                tout
                                bas. Il va rouvrir les yeux. Boum! Crash ! Boum! <br>
                                Un simple boum. La voiture quitte la route, percute la barrière dite de sécurité,
                                décolle,
                                s'envole. L'espace d'une seconde, elle se transforme en avion et retombe. Elle glisse
                                sur le
                                bitume. Se raye. Se racle. Bruit sourd du métal sur la route, du verre qui casse, puis
                                du
                                silence. <br>
                                Un long silence. Que du silence. Plus de mouvements. Plus aucun mouvement. La voiture
                                n'avance plus. D'ailleurs il n'y a plus de voiture, en tout cas plus la forme. Juste un
                                morceau
                                de métal, de taule mélangée à la chair et au bitume. Une odeur d'essence. Les corps sont
                                immobiles, compressés, enfoncés, défoncés, mélangés au métal. <br>
                                Lui, sa tête a explose contre le pare brise. Son visage est méconnaissable plein de
                                verre et de
                                sang. Son visage est enfoncé, plat. Seul le verre fait le relief. Son thorax écrasé par
                                la ceinture
                                dite de sécurité qui n'apas cédé. Elle le maintient toujours. Ses jambes broyées,
                                crispées,
                                explosées entre le siège et le volant. entre le tissu et le métal. Ses mains encore sur
                                le volant,
                                prêtes à toumer. Ses pieds prêts à appuyer sur le frein. <br>
                                A sa droite sa femme. Enfin ce qu'il en reste. A moitié déchiquetée par le bitume,
                                transpercée
                                par un bout de métal. Sur son visage encore l'expression de l'effroi, de la douleur
                                suite à cette
                                pénétration. Le métal dans la chair, le métal dans le corps, contre les os, broyés,
                                cassés,
                                fendus. Sur son Cœur elle sert son sac, sa vie, sa maison. Tout ce qui résume son être
                                est
                                dedans. Papiers d'identité, téléphone, argent, photos de famille, clés de la maison.
                                Tout ca
                                contre son cœur, entaché de sang de son sang. <br>
                                Derrière son fils encore endormi au moment de l'impact, alors qu'il se serait cru dans
                                un jeu
                                vidéo. Il aurait eu peur, mais aurait trouvé ça cool. Le temps d'une seconde, une
                                seconde
                                avant de crier, avant d'être terrorisé- Mais là non, il ne criera plus, plus du tout.
                                Son visage est
                                calme, très calme, serein. tl n'a pas peur. Il n'a rien sentit. Il n'a pas crié. Un vrai
                                petit homme
                                face à la catastrophe. Un héros comme dans ses jeux. A trois mètres de lui son bras
                                droit,
                                serrant encore son doudou, un petit ours brun avec un large sourire et des yeux rieurs.
                                Même
                                Maintenant alors que le bras de l'enfant l'écrase, le serre de toutes ses forces comme
                                pour être
                                sauvé. Il sourit toujours, encore. Il lui manque le pouce, surement coupé par ses dents
                                au
                                moment de l'impact. Si on le cherche, on le trouverait surement dans sa bouche ou dans
                                son
                                estomac. Son ventre est ouvert, découpé. Entre ses intestins et son foie, il y a de la
                                tôle, du
                                tissu, du verre et du sang. Beaucoup de sang qui coule sur les chairs, le tissu et le
                                bitume. Les
                                lignes blanches entachées de ce liquide rouge, signe de la vie qui se répand dans la
                                nuit, sur la
                                route. Seul le sang continue sa route son chemin vers les vacances. Les corps eux
                                n'avancent
                                plus ils ne peuvent plus. Le métal les bloque, les encercle. Marre de sang, océan rouge,
                                écarlate où naviguent des bateaux de chair, des radeaux de peau, de métal, de verre. Les
                                rochers sont ces corps morts, explosés. <br>
                                La fille, comme sa mère, raclée, rayée. déchiquetée. Aucun maquillage ne pourra masquer
                                ses
                                cicatrices, vestige de l'accident. La chair à vif. L'envelople humaine a cédé, s'est
                                déchirée
                                entièrement. Son visage est à vif, rouge. Elle respire, faiblement, mais elle respire.
                                Ses jambes
                                ne répondent plus. Elle a peur. Elle voit autour d'elle son frère, sa mère, son père
                                immobiles,
                                silencieux. Elle veut crier. mais ne peut pas. Aucun son ne sort, aucun son ne peut,
                                aucun son
                                ne veut, Elle est seule, sans peau, sans visage, sans voix. Et elle ne peut pas bouger.
                                ElIe
                                mourra surement là, de douleur, de perte de sang. Elle ne peut pas prévenir, ni les
                                secours, ni
                                personne. Elle ne peut pas. Elle a peur. Elle veut pleurer mais impossible. Aucune
                                larme, rien.
                                Elle voudrait que tout s'arrête. Elle repense à lui, son amoureux. Une dernière pensée.
                                Elle se
                                sent femme tout d'un coup parce qu'elle pense à lui alors qu'elle va mourir. Elle pense
                                à lui.
                                Son cœur bat. Toujours. Elle cherche à l'arrêter. Elle veut le stopper. Elle, seule au
                                milieu de
                                cette mer de Sang. Elle, morceaux encore vivants, vestige du malheur, monument naturel
                                taillé par la nature, Son souffle se fait rare. Ca y est elle part. ElIe meurt enfin.
                                ElIe. victime
                                de cette vision. Avant de mourir, elle n'a pas vu sa vie défiler. mais elle a vu la
                                cause de sa
                                mort. Le sang, la tôle, le bitume. Au milieu de campagne, au milieu de la nuit, seule,
                                elle part
                                elle meurt enfin. <br>
                                Plus rien ne vit dans la mer rouge. Plus rien ne respire, tableau sanguinaire. Le métal,
                                le verre,
                                le tissu, le plastique, tout se mêle, se confond. La nuit profonde, bercée par le vent.
                                Le sang
                                qui coule lentement sur la route. Remise remise à niveau réussie, terminée. Plus
                                d'idées, plus de problèmes, enfin en vacances. Mise à jour réussie. esprit vierge,
                                primaire,
                                corps immobiles, inutiles. sans fonction, sans raison. Viande humaine, mélange de chairs
                                et
                                d'os, de peaux et de muscles, d'artères et d'organes. Tous pareils, homme, femme,
                                enfant,
                                vieux, noir, blanc, vivant mort. Retour aux origines. Le père mélangé dans la mère. Leur
                                chair
                                en symbiose. Les enfants vides ressemblent à leurs parents. Morceaux par morceaux, rien
                                ne
                                les différentie. Sur la route des vacances, sur la route dans la nuit. Sous le vent,
                                dans la
                                campagne du silence. Plus un bruit, plus un mouvement. Partir plus tôt, pour être plus
                                vite en
                                vacances, Réflexe humain, éviter les embouteillages, dépasser la limite de vitesse.
                                arriver plus
                                rapidement. Se reposer, dormir en vacances. Ca y est, ils sont arrivés. Mais ils ne
                                repartiront
                                pas, plus. jamais. Juste de la vitesse. Arriver vite, sans retour. Plus maintenant, un
                                hyper cut_
                                Coupure nette au visage, dans la gueule. Un chaos sans bavure. Fin du round. Fin du
                                combat.
                                Fin du trajet. <br>
                                Ils sont arrivés au bout. Vacances, tranquillité, repos jamais. Normalement ça n'arrive
                                qu'aux autres. Alors pourquoi c'est tombé sur lui ce soir. Il voulait juste être en
                                vacances
                                Avec sa famille, réunis. Et ils le sont, là, ensemble, pour toujours dans la nuit à
                                jamais. <br>
                                <i>Autoroute info flash spécial nous informons nos automobilistes qui se trouve sur la
                                    A40 en
                                    direction de Chamonix Mont Blanc que aux kilomètre 1 73 entre Thonex et Bonneville
                                    une
                                    Ford galaxie est accidentée les secours se rendent sur place soyez vigilants car la
                                    circulation
                                    s'effectuera sur une seule voie rien d'autre à signaler sur les autres grands axes.
                                    Bonne
                                    route</i>
                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal18 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : L'hiver ou Hyper Cut</h3>
                <h4 class="auteurnouvelle">par Laurent Cazanave</h4>
                <p><i>Autoroute info vous souhaite la bienvenue sur le réseau des routes du grand sud
                        en ce
                        vendredi de départ vacances. Rien à signaler à part un léger ralentissement sur le
                        périphérique intérieur à la sortie de Paris.</i> <br>
                    Autoroute A6. Route des vacances. Direction Chamonix. Fin d'après midi. Le soleil se
                    couche. Peu de circulation Presque pas. La route. Les champs. Les Barrières de sécurité. Les
                    lignes...</p>
                <div class="tourbutton">
                    <button @click="showModal18 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2010 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal19 }">
                    <div class="modal-background" @click="showModal19 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Bonne Chance</h3>
                            <p>Ariane a aligné méticuleusement les dossiers sur l'étagère, rangé son bureau, épousseté
                                le clavier de son ordinateur, et déposé le contenu de sa corbeille à papiers dans le bac
                                « à recycler». Elle n'a pas oublié d'arroser la plante verte, le papyrus du Nil censé
                                apporter une touche d'exotisme dans l'univers momifié de cet office notarial, et
                                destiné,
                                comme l'a souligné son patron soucieux de « préserver la santé au travail de ses
                                salariés », à lutter contre la pollution intérieure. <br>
                                Cet ordre impeccable n'est en fait qu'un verrou contre son agitation intérieure A partir
                                de ce midi elle est en vacances, pour quatre longues semaines, et n'aucun projet. Elle
                                est
                                terrifiée par l'idée de se réveiller le lendemain et les jours suivants sans aucun
                                objectif
                                pour baliser ses journées. <br>
                                Elle rentre chez elle, retrouve ce deux-pièces banal et vide, où personne ne l'attend,
                                pas
                                même un chat. Antoine, son compagnon depuis cinq ans, a emmené avec lui leur matou
                                lorsqu'il est parti, il y a six mois. Assise dans un fauteuil, elle somnole, songe à
                                toutes
                                ces années qui passent et s'enlisent en bégayant toujours la même histoire, à ce métier
                                qui a bâillonné ses rêves, à cette chape de grisaille qui de manière insidieuse a
                                empaqueté peu à peu sa vie, ratatiné ses désirs, l'a dessaisie de l'élan vital qui la
                                caractérisait. Elle a bien tenté parfois de réagir — je veux changer de vie, partir,
                                loin ! —
                                consulté les offres d'emploi sur internet, n'a pu s' empêcher de fantasmer parfois,
                                comme ce jour où elle a découvert un plan de recrutement de l' Australie méridionale
                                proposant des postes de goûteur de bières, de chasseur de koalas, de décorateur pour
                                abris à pingouins, de profileur analyste de la personnalité des requins ou de ramasseur
                                de crottes de kangourous dans une des plus belles régions du monde. Mais bon, elle n'a
                                plus vingt ans <br>
                                Dix sept heures trente, Elle a encore le temps d'aller flâner un peu dans les rues
                                commerçantes avant la fermeture des boutiques, plutôt que de déprimer autour d'une
                                tasse de thé. Avant de sortir, elle s'est arrêtée devant le miroir de l'entrée : la
                                quarantaine alourdie, tenue classique, chignon strict, regard terne... Elle hausse les
                                épaules. Quelconque, décidément quelconque. <br>
                                Ariane débouche sur la Grand-place au moment où un convoi funéraire s'apprête
                                quitter l'église. Derrière le corbillard, des musiciens en costumes blancs, cravates de
                                couleurs et lunettes noires, l'allure compassée, précèdent les membres de la famille du
                                défunt. Elle répertorie les instruments : deux trompettes et deux clarinettes, un saxo,
                                un
                                trombone, un banjo, une guitare, un accordéon et même un harmonica. <br>
                                Lui revient tout à coup en mémoire le visage de sa professeur de musique au collège, en
                                classe de cinquième, une jeune femme énergique, à l'accent du midi, qu'elle admirait
                                beaucoup Pour trouver grâce à ses yeux, elle apprenait par coeur les rébarbatives leçons
                                d'histoire de la musique et s'était même inscrite à la chorale, où d'ailleurs elle avait
                                brillé, interprétant souvent la partition soliste. Des souvenirs oubliés, enfouis jusque
                                là,
                                refont surface : à son grand désespoir, cette enseignante n'était restée qu'une année
                                scolaire dans l'établissement, elle habitait un hôtel situé dans la même rue que la
                                maison
                                d' Ariane, qui épiait par la fenêtre de la cuisine ses allées et venues, la présence ou
                                non
                                de sa voiture... Interloquée, Ariane voit soudain se révéler sous son regard d'adulte la
                                passion amoureuse de la préadolescente qu'elle était alors pour son professeur, mais
                                elle
                                n'a pas le temps d'approfondir sa réflexion, de disséquer cette évidence tardive, le
                                convoi démarre. <br>
                                Tandis que sonne le glas, le jazz band entonne avec lenteur et solennité un poignant
                                Chant des adieux Des passants émus se sont arrêtés, comme elle, pour regarder cette
                                scène insolite. Elle se met fredonner les paroles du refrain : « Ce n'est qu'un au
                                revoir,
                                mes frères, ce n'est qu'un au revoir, oui nous nous reverrons. » et machinalement se
                                joint au cortège. Dés que celui-ci emprunte la rue Carnot, le son des cloches s'amenuise
                                et soudain une musique pleine de rythme et de vitalité succéde à la mélodie nostalgique.
                                La spontanéité reprend ses droits, les musiciens se déhanchent au fil des
                                improvisations.
                                Des arrangements pétillants de « Oh happy day », « When the Saints go marching in »,
                                et des plus grands standards du jazz émaillent le parcours vers le cimetière. Si
                                quelques
                                signes de surprise ou de réprobation manifeste se lisent sur les visages des personnes
                                rencontrées, les participants aux obsèques semblent en parfaite harmonie avec cette
                                déambulation festive et Ariane comprend peu à peu au fil des conversations, au gré de
                                ses déplacements dans le cortège, qu'il s'agit là d'un dernier hommage de la part de ses
                                potes à un pianiste de jazz décédé, un hommage joyeux conforme à ses dernières
                                volontés. <br>
                                Entre la sortie de la ville et le cimetière, la route serpente au milieu des champs
                                fécondés par la Loire et la procession s'étire, A la lisière des blés, un épouvantail
                                sans
                                âge mâche sa solitude ; les corbeaux perchés sur ses bras s'égaillent avec les volées de
                                doubles croches à l'approche du fourgon mortuaire, colportent dans l'infini du ciel la
                                couleur du deuil. <br>
                                Devant l'entrée du cimetière, une tente est dressée et abrite une longue table nappée de
                                blan. Sans que quiconque manifeste la moindre surprise, le cercueil, surmonté d'un
                                gros bouquet de fleurs des champs et d'une photo de l'artiste, a été installé sur des
                                tréteaux au bout de la table, et des petits pains ronds, les fouées, farcis de rillettes
                                ou de
                                mogettes et accompagnés de vin blanc de Saumur, sont servis aux nombreux
                                participants. <br>
                                Ariane voudrait garder à jamais dans les méandres de sa mémoire cette fin d'après-midi
                                inattendue, baroque et déconcertante, ce paysage lumineux et cette musique gaie qui la
                                renvoie à ses soirées animées d'étudiante. <br>
                                Sous ses yeux ébahis, les gens vont et viennent, un verre à la main, entourent la veuve,
                                une belle femme aux longs cheveux roux, échangent des souvenirs détaillés et des
                                anecdotes mémorables ayant trait au défunt. Quelques musiciens continuent à jouer, des
                                enfants courent autour de la table. <br>
                                Personne n'affecte un air triste ou éploré bien que les propos échangés laissent
                                transparaitre l'amour et l'amitié qui entouraient le dispam. Il souhaitait que la joie
                                de
                                vivre demeure, envers et contre tout. <br>
                                Personne non plus ne s'étonne de la présence d'Ariane, tout le monde discute
                                simplement avec elle comme si elle faisait partie des proches, des amis. Au cours de ces
                                évocations emplies de tendresse, elle apprend donc à connaitre André, le pianiste
                                décédé, sa vie, ses goûts, ses habitudes, ses qualités. ses défauts aussi, et les
                                circonstances accidentelles de sa mort Elle croit comprendre également qu'il était en
                                instance de divorce. Les morceaux de puzzle s'assemblent pour représenter non
                                seulement un pianiste doué et passionné. mais un homme gai, simple et cultivé, qui
                                refaisait le monde avec ses copains jusqu'au bout de la nuit en appréciant les vins de
                                Loire, qui aimait aller à la pèche, avait pour ticheuse habitude d'être toujours en
                                retard,
                                n'aurait manqué pour rien au monde une édition du festival de Marciac, photographiait
                                au printemps les clochettes en damier des fritillaires pintades, se désolait de leur
                                disparition progressive des prairies, et affectionnait les longues randonnées solitaires
                                en
                                vélcy C'est ce dernier loisir qui lui a d'ailleurs coûté la vie. <br>
                                Elle s'arrête un instant devant la photo une allure décontractée, une silhouette aussi
                                longiligne qu'un peuplier, une tignasse si épaisse et bouclée que des oiseaux pourraient
                                y faire leur nid, un regard bleu de baroudeur, appellent d'instinct la sympathie. <br>
                                Une vie bien remplie et de belles obsèques Une pincée de jalousie la titille, Si elle
                                mourait aujourd'hui, qui viendrait à son enterrement ? Ses parents, ses deux vieilles
                                tantes, ses cousins peut-être, Antoine pas sûr, les deux clercs de l'étude et sa voisine
                                de
                                palier, voilà tout, et cet inventaire rapide suffit à résumer la médiocrité de sa vie,
                                Un homme annonce d'une voix forte que la cérémonie au cimetière se déroulera dans
                                l'intimité familiale, la détournant de ses pensées. Ariane se joint donc aux amis et aux
                                musiciens qui commencent à se retirer Au moment du départ, elle se sent observée, se
                                retourne. Les prunelles vertes de la veuve, dont elle a retenu le prénom, Victoire, la
                                fixent d'un regard insistant et interrogateur. Gênée, Ariane détourne les yeux. pour la
                                première fois, elle se sent une intruse dans cette foule et se dépêche de quitter les
                                lieux. <br>
                                Les deux jours suivants, Ariane passe son temps, allongée sur son lit, à écouter en
                                boucle ses disques de jazz, qui dormaient au fond d'un placard depuis des années
                                puisque Antoine n'aimait pas la musique et laissait sans cesse en marche le poste de
                                télévision, Elle pense sans cesse à André comme à un vieil ami, brode sur sa vie à
                                partir
                                des éléments qu'elle connait. <br>
                                Curieusement elle se sent mieux, apaisée, plus sereine. <br>
                                L'aube maraude les dernières étoiles. Ariane est réveillée depuis un moment déjà, elle
                                n'arrive pas se rendormir Elle enfile un jean, un pull et des baskets et sort pour une
                                promenade matinale sur les sentiers sauvages de son enfance, à fleur de Loire Le
                                chemin du retour passe devant le cimetière Poussée par la curiosité, Ariane y entre et
                                trouve sans difficulté la tombe d' André Sur la pierre tombale, à côté de sa photo, un
                                grand vase rouge accueille un bouquet frais de marguerites et de graminées qu'ébouriffe
                                une légère brise. Ariane y dépose l'ancolie mauve glanée distraitement en chemin. Elle
                                entend des pas derrière elle Elle n'a pas besoin de se retourner, elle sait qui
                                s'approche.
                                Elle n'est donc pas surprise d'entendre la voix de Victoire : <br>
                                « Vous étiez sa maitresse, n'est ce pas ? » <br>
                                Elle s'entend répondre sans hésitation « Oui », surprise elle-même par ce mensonge
                                spontané qu'elle confessera et cherchera à comprendre plus tard. Se valoriser, vivre par
                                procuration, s'auréoler un peu du prestige du pianiste peut être. Pour l'heure, elle
                                n'en
                                mène pas large tout d'un coup. Paralysée par cet aveu, elle s'attend au pire, à un coup
                                de
                                feu dans son dos, tiré d'une main gantée par une belle rousse, comme dans un film.
                                Mais non. Un long silence tendu immobilise les deux femmes, Jusqu'à l'incroyable
                                proposition de Victoire. <br>
                                « Demain soir, à partir de vingt heures, nous lui consacrons une soirée musicale, chez
                                moi, entre amis. Venez nous rejoindre. » <br>
                                Ariane se retourne enfin et prend la carte de visite que lui tend la veuve du pianiste.
                                Après un échange de regards d'une grande intensité. deux sourires timides finissent par
                                s'esquisser. Sans un mot, Ariane s'éloigne en hâte. <br>
                                Tout d'abord, elle s'inquiète. Dans quelle situation s'est-elle mise ? Que cherche
                                Victoire ? Elle n'ira pas bien sûr. Pourtant, peu peu. l'idée fait son chemin. Et
                                pourquoi pas ? Au fil des heures, elle entre dans la peau de la maitresse du pianiste,
                                sent
                                sa hardiesse grandir comme si ce statut la distinguait, lui rendait un peu de sa
                                séduction
                                perdue. C'est donc une Ariane nouvelle, vêtue d'un pantalon gris et d'une chemise
                                blanche, qui le lendemain soir arpente l'artère principale de la ville, l'avenue Jean
                                Jaurès, s'arrête au numéro huit devant une belle maison bourgeoise et sonne d'un geste
                                assuré, décidée toutefois à rétablir la vérité. <br>
                                Elle n'en n'aura pas l'occasion ce soir là. La réception a déjà commencé lorsque
                                Victoire lui ouvre la porte, la prend par le bras et l'accompagne dans une grande salle
                                pleine de monde. La musique rend difficile toute conversation sérieuse. Les morceaux
                                se succèdent en hommage à André. L'émotion est au rendez-vous, la convivialité aussi.
                                Ariane a déjà bu plusieurs verres de vin en plaisantant avec ses voisins de table. Aussi
                                lorsque le trompettiste entonne les premières mesures de « The man I love qu'elle a
                                écouté à plusieurs reprises les jours derniers, elle ne peut s'empêcher de se lever et
                                de se
                                mettre à chanter, avec une intensité rauque. Concentrée, les yeux fermés, elle
                                apprivoise
                                lentement sa voix, puis lâche prise et chante, de plus en plus fort, comme si un barrage
                                venait de céder en elle- Elle ne voit pas Victoire lui ôter les épingles de son chignon.
                                Elle ne voit pas l'air stupéfait se propager sur les traits de son auditoire. Tous les
                                regards sont fixés sur elle, incrédules et admiratifs à la fois. La dernière note se
                                noie
                                dans un déluge d'applaudissements, Elle ouvre les yeux, comme délivrée. <br>
                                Il est quatre heures du matin lorsqu'elle rentre chez elle, les cordes vocales en feu,
                                étonnée et fière de son succès, grisée par le vin et les applaudissements. Et par le
                                baiser
                                de Victoire, Elle s'endort en souriant avec des sentiments, de bonheur et de liberté,
                                qu'elle n'a pas éprouvés depuis longtemps. Vivre, il s'agit de vivre. <br>
                                Dimanche soir, son mois de vacances s'est écoulé. Elle a vendu une partie de ses
                                meubles. Restent le lit, le réfrigérateur et la table de la cuisine sur laquelle elle
                                est
                                occupée à écrire une lettre Sa lettre de démission, Avant de cacheter l'enveloppe, elle
                                y
                                insère également une invitation pour le concert du mois prochain. son premier concert,
                                déjà programmé même si de nombreuses répétitions l'attendent encore. Elle regarde en
                                souriant le billet, sur lequel figurent la photo et les noms des membres du groupe de
                                jazz
                                et de sa chanteuse, Adriana. Elle n'a pas eu à modifier beaucoup son prénom pour
                                trouver son nom de scène !
                                Demain, les compagnons d'Emmaüs viendront récupérer le mobilier restant, les lustres
                                et la vaisselle Elle a déjà emmené dans la grande maison de la rue principale toutes ses
                                affaires personnelles puisque désormais elle vit chez Victoire. Elle n'arrive pas encore
                                à
                                dire <strong>avec</strong> Victoire. <br>
                                Elle abandonne cet appartement comme un serpent abandonne sa mue.
                                Avant de partir. elle s'arrête devant le miroir de l'entrée la quarantaine épanouie.
                                robe
                                décolletée. crinière de lionne, le désir en bandoulière. Elle sourit. Charmante,
                                vraiment charmante. <br>
                                Un bref instant. l'image tremble, Elle devine un double. en retrait de son reflet, la
                                silhouette fugace d'une Ariane terne en tailleur beige, coiffée d'un chignon tiré
                                Adriana
                                a juste le temps. avant que ce fantôme ne s'efface, de lire deux mots sur ses lèvres .
                                « Bonne chance » </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal19 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Bonne Chance</h3>
                <h4 class="auteurnouvelle">par Catherine Ligeon</h4>
                <p>Ariane a aligné méticuleusement les dossiers sur l'étagère, rangé son bureau, épousseté
                    le clavier de son ordinateur, et déposé le contenu de sa corbeille à papiers dans le bac
                    « à recycler ». Elle n'a pas oublié d'arroser la plante verte, le papyrus du Nil censé
                    apporter une touche d'exotisme dans l'univers momifié de cet office notarial, et destiné,
                    comme l'a souligné son patron soucieux de « préserver la santé au travail de ses
                    salariés...</p>
                <div class="tourbutton">
                    <button @click="showModal19 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal20 }">
                    <div class="modal-background" @click="showModal20 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">(mal)Heureux Evenement</h3>
                            <p>Assise sous un lampadaire, Léa contemplait avec fierté les bottes d'équitation que sa
                                mère lui avait achetées lundi dernier. Si son père ne le lui avait interdit, elle les
                                porterait
                                tous les jours les tellement aimait. Malheureusement, elle n'avait le droit de les
                                chausser
                                que les mercredi après-midi pour son cours d'équitation hebdomadaire, ElIe aimait à
                                penser
                                que ces bottes possédaient des pouvoirs magiques. Grâce à elles, Léa se sentait
                                invincible. <br>
                                C'est peut-être pour cette raison, que ce mercredi là, bien qu'assise toute seule le
                                long
                                d'une départementale quasi déserte dans la pénombre d'une fin d'après midi hivernale,
                                Léa
                                n'avait peur. Le moniteur d'équitation en quittant le club toute à l'heure avait bien
                                de la raccompagner mais la petite fille avait refusé poliment, assurant que son père
                                n'allait pas tarder. Celui-ci, réputé pour son mauqne de ponctualité, avait plus d'une
                                heure de
                                et Léa n'agonnisait pas pas. Certes, jamais encore il n'avait, comme aujourd'hui, laissé
                                la
                                nuit arriver avant lui, mais la fillette mettait cet incident sur le compte de la
                                naissance encore
                                récente du bébé. <br>
                                Avoir un petit ou une petite sœur, elle en rêvait depuis tellement longtemps
                                qu'elle acceptait sans peine de supporter l'arrivée t'udivc de son père. II est vrai que
                                depuis
                                qu'il était né. tes tranquilles habitudes de la maison se trouvaient un peu chamboulées.
                                Du
                                haut de Ses dix Léa savait que d'un bousculerait la routine mais jamais elle
                                n'avait imaginé que cela prendrait une telle ampleur Et surtout, elle se posait de plus
                                en plus
                                questions. Normalement quand un nourrisson pointe son nez dans foyer, tout le monde
                                est la logique des choses veut que la sourit même si le bébé pleure. <br>
                                Mais à la maison, Thibaud était né et ce sont les larmes de Maman qui coulaient... Léa
                                avait d'abord pensé que peut être sa maman regrettait son gros ventre. En effet, elle ne
                                s'était
                                jamais aussi heureuse que lorsqu 'elle était enceinte. ElIe s'était arrêtée de
                                travailler et
                                souvent, Léa et elle allaient se promener toutes les deux, discutant de l'arrivée
                                prochaine du bé
                                La petite fille peinait donc à présent à comprendre pourquoi sa maman était si triste.
                                Une fois le bébé tant attendu venu au monde, les choses ne s'étaient pas non plus
                                déroulées exactement comme prévues. Maman ne lui donnait pas le sein. Papa n'avait
                                finalement pas offert la belle peluche qu'ils avaient choisie ensemble et Mamie n'était
                                pas
                                restée garder Léa pendant que Maman se reposait à la maternité. C'est à peine si elle
                                était
                                venue voir Thibaud. Sa visite s'était résumée à quelques fleurs, un baiser sur le front
                                de
                                Maman et un rapide coup d'œil au bébé. D'ailleurs, personne d'autre n'avait rendu visite
                                à
                                Maman à l'hôpital alors que Léa se souvenait avec précision que ces visites à venir
                                réjouissaient beaucoup sa mère avant l'accouchement. <br>
                                La nuit était vraiment noire à présent et Léa reboutonna son anorak. Elle Commençait
                                penser que son père l'avait tout bêtement oubliée. Quelque chose d'important devait le
                                retenir à
                                la maison. De temps en temps une voiture passait, éblouissant la petite fille sur son
                                passage
                                mais ne ralentissant jamais, Elle demeurait véritablement seule devant la grille du
                                poney club
                                mais elle n'avait pas peur. D'aussi loin qu'elle se souvienne, Léa s'était toujours
                                sentie
                                étrangère à toutes les peurs enfantines, qu'elle qualifiait volontiers de ridicules. Dès
                                son plus
                                jeune âge, elle avait dormi portes fermées, volets clos dans le noir le plus complet,
                                sans jamais
                                s'en plaindre. Les insectes ne l'effrayaient guère, au contraire ils l'amusaient. Quant
                                à la peur
                                de l'abandon, Léa ignorait tout bonnement ce sentiment. Sachant cela et connaissant la
                                débrouillardise de la petite fille, les adultes ne s'inquiétaient que rarement de sa
                                solitude.
                                Ainsi, Léa restait calme et sereine sous son lampadaire alors que n'importe quel autre
                                enfant
                                de son âge aurait commencé à sangloter depuis longtemps. <br>
                                ElIe pensait à son petit frère. Depuis sa venue au monde, il ne s'écoulait pas une heure
                                sans
                                qu'elle ne pense à ce petit être qui faisait d'elle une soeur heureuse. Elle ne l'avait
                                quitté que depuis quelques heures mais déjà il lui manquait, déjà elle avait hâte de
                                rentrer et
                                de l'embrasser, déjà elle pensait au biberon qu'elle lui donnerrait ce soir. Ses parents
                                la
                                laissaient en effet très régulièrement s'occuper de Thibaud. Elle le baignait, le
                                changeait, le
                                berçait. <br>
                                II y avait cependant quelque chose qui froissait Léa. Elle aurait aimé faire connaitre
                                son petit frère à tout le monde, malheureusement, Maman ne sortait jamais avec le bébé.
                                Le
                                magnifique landau acheté quelques semaines avant l'accouchement patientait à la cave et,
                                chaque fois que Léa émettait l'idée de s'en servir pour promener Thibaud, Maman refusait
                                catégoriquement, prétextant la fragilité du bébé. Léa se demandait jusque quand son
                                petit frère
                                allait rester fragile car Maman employait toujours cet argument pour interdire à
                                Léa de montrer le bébé à ses copines lorsqu'elle invitait celles-ci à goûter la maison.
                                <br>
                                A la longue, Léa vraiment à se demander s'il n'y avait pas un problème
                                avec ce nouveau qui rendait sa maman si malheureuse. Elle ne comprenait plus très bien
                                pourquoi
                                on appelait communément la naissance d'un bébé un "heureux événement" En effet, la
                                naissance
                                de Thibaud ne semblait pas embellir franchement la vie de ses parents. A
                                force de contempler son petit frère, Léa devait admettre qu'il ne ressemblait pas aux
                                autres
                                bébés qu'elle avait eu l'occasion de voir auparavant. Mais pour Léa, il restait le plus
                                beau de
                                tous. Oui, Thibaud était vraiment différent du cousin Paul qui avait quelques mois de
                                plus. Il avait
                                de tous petits yeux, des cheveux blonds et fins, une moitié de crâne complètement
                                chauve et une drôle de façon bouger, mais après tout, chaque bébé n'était-il pas unique
                                ?
                                D'ailleurs, à la réflexion, le cousin Paul n'était même pas beau. II faisait partie de
                                ces bébés
                                bouffis à la peau tendue, Léa préférait de loin son Thibaud blond, tout petit, tout
                                rigolo. <br>
                                Alors que son papa se faisait toujours attendre, Léa repansa aux faires-parts de
                                naissance qui n'avaient été envoyés. Elle avait passé des heures à aider Maman avant la
                                naissance
                                à les confectionner avec du Canson et des brins de rafia, Il ne manquait plus qu'une
                                photo du bébé. Mais le photographe n'était jamais venu et les enveloppes préparées
                                prenaient
                                la poussiére depuis trois mois sur le buffet de l'entrée. <br>
                                La fraicheur de la nuit commençait réellement à se faire sentir et Léa espérait qu'il ne
                                soit rien arrivé de grave à la maison. Depuis la naissance de Thibaud, ses parents se
                                disputaient quotidiennement. La nuit, les pleurs du bébé qui réclamait à manger
                                s'avéraient
                                très souvent couverts par les cris des parents. Léa se blottissait alors dans son lit,
                                les mains sur
                                les oreilles, en attendant que ça passe. Parfois, le bébé s'épuisait à pleurer avant
                                qu'on vienne
                                s'occuper de lui. Léa se sentait triste pour Thibaud. Une fois la maitresse, qui avait
                                appris la
                                naissance du petit, lui avait demandé si elle n'était pas trop jalouse de ce petit frère
                                qui devait
                                occuper sa maman. Mais Léa n'avait aucune raison de se montrer jalouse pour la simple
                                raison que le bébé n'accaparait en rien Maman. A la maison, personne ne s'attardait trop
                                sur le
                                nouveau né. Les câlins qu'il recevait se limitaient aux seuls destinés à l'endormir
                                après son
                                biberon et, bien que très sage et volontiers rieur, il n'était l'objet d'aucune
                                quelconque extase
                                de la part des parents. Léa se doutait de l'normalité de ce manque d'affection. Elle
                                avait si
                                souvent vu sa mère faire des papouilles à son cousin Paul l'année dernière à sa
                                naissance,
                                qu'elle s'étonnait qu'elle ne se comporte pas pareillement avec son propre fils. <br>
                                Mais Léa adorait son petit frère. Souvent, la nuit, lorsque ses parents rejoignaient
                                enfin
                                leur chambre, elle se levait sans bruit pour aller le regarder dormir. Il était si beau
                                Il lui
                                arrivait méme de grimper les barreaux du lit et de se blottir contre lui Elle lui
                                racontait des
                                histoires même si elle n'était pas sans savoir qu'il ne comprenait pas encore tout. <br>
                                Devant le poney club, Léa s'était levée et sautillait sur place afin de ne pas laisser
                                le
                                froid engourdir ses orteils. Elle repensa à son après midi à dos de poney, à ses
                                bottes, au saut et tout ce qu'elle aimait faire. Un jour, elle ferait découvrir cette
                                passion à son petit frère. Mais un drôle de sentiment la sumbergea. Une espèce de
                                certitude
                                intérieure lui disait que ne serait capable de faire toutes ces choses. Elle se
                                rassit. Elle, qui répétait si souvent qu'elle n'avait de rien, frissonnait à présent,
                                Elle
                                regrettait presque d'avoir laissé le moniteur partir en refusant qu'il la ramène la
                                maison.
                                Pourquoi son père n'arrivait-il pas ? <br>
                                A ce moment là, elle aurait rêvé d'avoir un protecteur à ses côtés ? Thibaud se
                                montrerait-il un jour capable de la protéger ? Certes ce n'était encore qu'un petit mais
                                deviendrait-il jour grand et fort ? Léa ne savait plus quoi penser. Quelque chose lui
                                échappait. ElIe pouvait maintenant dire avec certitude que Thibaud était un bébé
                                différent. Les
                                autres bébés suscitent l'admiration même lorsqu'ils se vident de bave et qu'ils ont la
                                couche pleine. Les autres bébés sont promenés en landau, sont câlines, reçoivent des
                                cadeaux... Pourquoi Thibaud n'avait-il pas tout cela ?
                                Léa se souvint alors d'un drôle de mot commençant par T que son père avait un jour
                                prononcé à propos de son petit frère. Malheureusement, même avec de grands efforts, elle
                                ne
                                parvenait pas à ce souvenir de ce mot.. "Trilogique ? Trigonométrique ? Tri...". Elle
                                avait
                                d'abord cru qu'il s'agissait signe astrologique. Mais alors qu'elle se trouvait seule à
                                réfléchir au bord de la ce départementale, ce mot ne correspondait plus banale histoire
                                d'horoscope, il prenait un autre sens dans la tête de Léa. Elle se fit alors la promesse
                                de ne
                                jamais voir son frère comme un être anormal. Peu importe le sens de ce mot en T inconnu,
                                jamais il ne devrait signifier le rejet d'un si gentil petit frère. D'ailleurs Léa avait
                                déjà par le
                                passé croisé des enfants un peu différents, un peu bizarres mais très gentils. Et elle
                                ne
                                comprenait vraiment pas pourquoi ses parents semblaient ne pas aimer Thibaud. Elle
                                songea à
                                leur demander le soir même. <br>
                                De puissants phares éblouirent tout à coup Léa. Elle était tellement occupée à penser
                                qu'elle en avait arrêté de guetter au loin la voiture de son père. ElIe reconnu
                                rapidement la
                                voiture familiale qui s'arrêta à sa hauteur. Papa lui fit signe de monter. Sans
                                attendre. elle
                                grimpa à l'arrière, mais dès qu'elle eut claquée la portière, elle remarqua quelque
                                chose
                                d etrange <br>
                                «Il est Où le siège-auto de Thibaud ? » interrogea-t-elle son père. Celui-ci,
                                redémarrant la voiture, lui jeta juste un regard travers le rétroviseur mais resta
                                silencieux.
                                Oui? Léa n'angoissait jamais. mais aujourd'hui pourtant elle avait peur ; peur de
                                comprendre
                                pourquoi les affaires de son petit frére avait disparu.
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal20 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : (mal)Heureux Evenement</h3>
                <h4 class="auteurnouvelle">par Bertille Bluche</h4>
                <p>Assise sous un lampadaire, Léa contemplait avec fierté les bottes d'équitation que sa
                    mère lui avait achetées lundi dernier. Si son père ne le lui avait interdit, elle les porterait
                    tous les jours les tellement aimait. Malheureusement, elle n'avait le droit de les chausser
                    que les mercredi après-midi pour son cours d'équitation hebdomadaire, ElIe aimait à penser
                    que ces bottes possédaient des pouvoirs magiques. Grâce à elles, Léa se sentait invincible. <br>
                    C'est...</p>
                <div class="tourbutton">
                    <button @click="showModal20 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2009 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal21 }">
                    <div class="modal-background" @click="showModal21 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Un dimanche dans la vie de Louise</h3>
                            <p>Le calendrier est posé sur la petite table. Il y reste en permanence. Un calendrier
                                banal,
                                douze feuilles agrafées sur un rectangle de carton, cadeau d'un commerçant. Cette année,
                                elle
                                a choisi celui du pharmacien: une clématite d'un bleu profond sur un fond vert pâle.
                                <br>
                                C'est là, face au jardin, que Louise s'installe chaque soir pour l'annoter
                                scrupuleusement. En face de chaque jour, une croix, en face des dimanches, une barre
                                dressée
                                comme une borne, comme un obstacle à franchir. Les mêmes activités qui se répètent,
                                rassurantes: mardi à 19h, chorale - jeudi à 20h, club de broderie - vendredi à 14h,
                                IMmanence
                                à la Bibliothèque... <br>
                                Derrière la fenêtre, le jardin change au fil des saisons, devient invisible en hiver Ou
                                resplendit après les averses du printemps. <br>
                                Vendredi 8 mai: Louise trace une croix plus épaisse que d'habitude, s'attarde à la
                                crayonner, à la surcharger de fleurs et de feuilles minuscules. Qu'importe sa place dans
                                la
                                semaine, le 8 mai n'est pas un jour comme les autres; c'est un jour de cauchemar et de
                                larmes.
                                Pierre est mort le 8 mai, il y a seize ans. Dans le tiroir de la table, quinze
                                calendriers sont
                                soigneusement rangés, des pages de petites croix et dannotations infimes, des poussières
                                de
                                temps. <br>
                                La fenêtre est entrouverte, l'air est doux; un avion a laissé une traînée blanche et
                                floconneuse dans le ciel qui pâlit à peine. Mais Louise est auprès de Pierre. Elle lui
                                tient la
                                main, caresse son visage émacié, guette sa respiration douloureuse. Plus de cheveux...
                                Dès le
                                début de la maladie, il a demandé qu'on les lui rase, pour ne pas les voir tomber par
                                poignées.. -Lèvres desséchées sur des gencives sanguinolentes. De temps en temps,
                                l'infirmière entre et vérifie la perfusion. Son regard qui glisse, dérive vers la
                                fenêtre, la
                                lenteur de ses gestes disent assez qu'il ne faut plus rien espérer. Mais Louise a glissé
                                son bras
                                sous les épaules de Pierre et le front contre sa tempe où le sang bat obstinément, elle
                                chante, à
                                bouche fermée. Elle le berce, lui murmure qu'il ne faut pas avoir peur et qu'elle sera
                                là,
                                toujours. <br>
                                Cette fois, l'infirmière contemple la scène sans entrer. La perfusion n'a plus
                                d'importance. En refermant la porte, elle emporte l'image du mourant, blotti entre deux
                                bras
                                arrondis qui font autour de lui comme un berceau d'enfant. <br>
                                Pierre est mort le samedi 8 mai 1993 à 17h35. Lorsque Louise a levé les yeux vers la
                                fenêtre, le ciel était bleu, le vent agitait légèrement les feuilles des arbres de
                                l'avenue. Le
                                médecin avait posé son stéthoscope sur le bord du lit et signait une liasse de papiers.
                                L'infirmière murmurait des mots dont elle ne saisissait que des bribes Aussi longtemps
                                que vous voudrez... Vêtements... Une belle chemise... Si vous avez besoin de quelque
                                chose..." <br>
                                Louise a enfermé dans un coin de sa mémoire les souvenirs des heures et des jours qui
                                ont suivi. Elle ne leur permet jamais de se manifester. Ils sont tapis à l'écart,
                                muselés comme
                                des bêtes malfaisantes. Seule l'image d'Aurore a le droit de remonter à la surface. Elle
                                revoit
                                sa petite fille, ses gestes si calmes lorsqu'elle avait déposé dans le cercueil, près de
                                la joue de
                                son père, le jouet en peluche - un husky gris et blanc - qu'il lui avait offert pour son
                                dernier
                                anniversaire. Pas un mot, pas une larme. Elle avait, comme à son habitude, soigneusement
                                lissé ses cheveux blonds qui lui tombaient plus bas que la taille. Dans son visage au
                                teint
                                cireux, ses yeux bleus paraissaient encore plus grands. A l'église, au cimetière, elle
                                avait
                                conservé la même attitude, perdue dans un étrange rêve dont personne n'osait la tirer.
                                <br>
                                Louise émerge avec peine de ses souvenirs. La nuit commence tomber, il faut... Il
                                faut faire les gestes qu'elle fait tous les soirs... les gestes paisibles qui tiennent
                                le passé à
                                distance... les gestes que font les vivants. Elle a fleuri la tombe au début de
                                l'après-midi et
                                passé un long moment assise sur la dalle chauffée par le soleil à écouter les enfants
                                rire et
                                crier dans la cour de l'école. Le village, cerné de forêts, somnolait dans la tiédeur
                                printanière
                                Samedi 9 mai: Il est presque 22h30; Louise a depuis longtemps mis à jour son
                                calendrier, diné et lavé le peu de vaisselle qu'elle a utilisé. La sonnerie de la porte
                                d'entrée
                                suspend son geste; elle reste là, stupidement, sa télécommande à la main. Une visite
                                imprévue? C'est impossible: il n'y a plus jamais d'imprévu dans la vie de Louise. Elle
                                se
                                décide quand même à aller ouvrir. Une jeune femme se tient sur le seuil, portant dans
                                ses bras
                                une couverture d'où pend une toute petite main ouverte. Aurore ! <br>
                                "Maman, où puis-je la poser pour qu'elle ne s'éveille pas ?" <br>
                                Louise se contente de pousser la porte du salon et d'indiquer le canapé. Aurore y
                                installe précautionneusement son fardeau, arrange le tissu autour du pâle visage. <br>
                                "Il faut que je cache la voiture. Est-ce le garage extérieur est vide ? <br>
                                - Bien sûr! Tu veux que je t'aide ? <br>
                                - Non, je vais me débrouiller. Reste avec elle!" <br>
                                Louise ne pose pas de questions et va s'asseoir sur la table basse en face de cette
                                petite-
                                fille qui lui tombe du ciel. L'enfant dort paisiblement, mais elle parait si frêle...Et
                                cette vilaine
                                plaie toute fraiche qui lui meurtrit le front et se perd dans les boucles blondes! Elle
                                se
                                rappelle avoir ainsi longuement contemplé sa propre fille endormie, émerveillée de
                                l'arrondi
                                des joues, de la courbe parfaite des paupières et des lèvres. Pierre venait à pas de
                                loup poser
                                son menton contre son épaule et ils restaient ainsi enlacés, heureux. <br>
                                Le retour d'Aurore la tire de sa rêverie. Le couloir s'est peuplé de valises, de sacs
                                disparates d'où s'échappent des vêtements mal pliés. <br>
                                "Maman, viens, laissons-la dormir. Je voudrais un peu de café s'il te plait!" <br>
                                Malgré sa maigreur, son teint terne et les rides amères qui marquent déjà les coins de
                                sa bouche, Louise remarque chez sa fille une autorité et une détermination qu'elle ne
                                lui
                                connaissait pas. Il ne reste plus rien de l'enfant timide et silencieuse, perdue dans un
                                monde
                                intérieur dont elle barrait l'accès à tous ceux qui l'entouraient. <br>
                                Le café s'écoule goutte à goutte, embaumant la pièce, et, sans que Louise ait besoin de
                                poser une seule question, Aurore commence, lentement, les yeux fixes, un terrible récit.
                                <br>
                                Quand j'ai quitté la maison. il m'a emmenée sur la côte. près de Cannes. NotLS avons
                                fait la route d'une seule traite, en roulant toute la nuit. Au matin. j'ai ouvert les
                                yeux sur le
                                monde qu'il m'offrait: le soleil. la plage. l'hôtel luxueux. les cadeaux. Le conte de
                                fées sur
                                papier glacé! Il m'étourdissait... Je devais vivre enfin... Me libérer de toi. de ta
                                manie de
                                vouloir me protéger jusqu'à m'étouffer... Et je le croyais' J'ai accepté qu'il tout'
                                Cest lui qui m'a dicté la lettre. <br>
                                Louise écoute en silence et se souvient aussi. L'angoisse de la disparition. les propos
                                rassurants des policiers et cette affirmation définitive " De toutes façons, votre fille
                                est
                                majeure et rien ne prouve qu'elle soit en danger. D'ailleurs la lettre qu'elle vous a
                                laissée
                                montre bien qu'elle avait simplement envie de s'en aller! Dans quelque temps, vous
                                recevrez
                                de ses nouvelles." Effectivement, Louise avait reçu des nouvelles! De courtes lettres,
                                des
                                propos rassurants. Les cachets postaux étaient en général ceux de petits villages, un
                                peu
                                partout en France. Au début. elle téléphonait, à la gendarmerie s'il y en avait une, ou
                                à la
                                mairie. Mais elle avait compris rapidement que ces courriers épisodiques n'étaient
                                destinés
                                qu'à la leurrer et à brouiller les traces. Alors elle avait cessé ses appels et commencé
                                à
                                attendre. <br>
                                "Au bout de quelques semaines, nous nous sommes installés dans un coin perdu de
                                Dordogne... Une maison confortable mais isolée au milieu d'un grand parc. en lisière de
                                foret.
                                Une propriété que ses grands-parents lui avaient léguée, parait-il. On y accédait par un
                                chernin de terre. Il n'y passait jamais C'est là qu'il a commencé à me frapper." <br>
                                Louise fronce les sourcils et ouvre la bouche pour protester. Un geste d'Aurore
                                l'empêche d'aller plus loin. <br>
                                "Pourquoi ne suis-je pas partie? C'est la question que l'on pose à toutes les femmes qui
                                se sont trouvées dans la méme situation que moi' Après les coups, il me demandait
                                pardon,
                                me couvrait de cadeaux et de promesses, me suppliait de l'aider à vaincre ses
                                démons...Et que
                                sais-je encore! <br>
                                Nous manquions pas d'argent. même si je n'ai jamais su exactement quel métier il
                                exerçait. Il s'absentait rarement et passait de longues heures au téléphone sur lequel
                                il posait
                                ensuite un cadenas pour que je ne puisse pas lutiliser. Parfois, il me dictait une
                                lettre, pour te
                                rassurer, disait-il. <br>
                                Lorsque je lui ai annoncé que j'étais enceinte, j'ai cru avoir enfin gagné la partie. Il
                                semblait fou de joie et ne me battait plus. Nous avons préparé ensemble la chambœ du
                                bébé.
                                Il a commencé à quitter la maison un peu plus souvent et rapportait sans cesse des
                                articles de
                                puériculture, de la layette, des jouets. Je n'avais même plus de place pour tout ranger!
                                <br>
                                Quelques jours avant la date prévue de la naissance. pour la première fois. vu
                                quelqu'un franchir le seuil de la maison: il avait engagé une sage-femme qui s'est
                                occupée de
                                moi pendant plusieurs semaines et est repartie aussi discrètement qu'elle était venue.
                                Il a
                                décidé que notre fille s'appellerait Noélie. <br>
                                Mais cette embellie n'a pas duré. Il ne supportait pas les cris de la petite, me
                                reprochait
                                de ne pas savoir m'en occuper. Il me répétait que j'étais devenue laide, que je le
                                dégoûtais et
                                les coups ont recommencé à pleuvoir. Mais il ne me demandait plus pardon, il me frappait
                                puis disparaissait, quelques heures... ou quelques jours." <br>
                                Louise se revoit enceinte, le visage radieux de Pierre flotte entre elle et sa fille. Il
                                était
                                si heureux. La tête posée contre son ventre à peine arrondi, il rêvait: ce serait une
                                petite fille,
                                toute blonde; elle ferait entre eux ses premiers pas dans le jardin. Il allait séance
                                tenante
                                entourer le bassin d'un grillage et réparer la serrure du portail! Louise riait et le
                                traitait de fou
                                en le serrant dans ses bras. <br>
                                "Mais puisqu'il s'en allait, toi aussi tu aurais pu..." <br>
                                Aurore esquisse un pauvre sourire. <br>
                                Je n'avais rien, pas d'argent, pas de voiture et surtout pas le courage de le fuir. Il
                                répétait sans arrêt qu'il me tuerait, qu'il nous tuerait toutes les deux. J'étais sans
                                espoir et sans
                                volonté, une morte vivante. Un jour, Noélie devait avoir 16 mois, elle commençait juste
                                à
                                marcher, il m'a frappée si fort que je suis tombée à la renverse. J'ai perdu
                                connaissance
                                pendant plusieurs heures. Lorsque j'ai rouvert les yeux, il était parti. La petite était
                                pelotonnée
                                contre moi. Ses joues étaient toutes mouillées, elle avait dû pleurer longtemps avant de
                                s'endormir. J'ai simplement attendu qu'il revienne en préparant le dîner. Je voyais
                                bien,
                                pourtant, que son état s'aggravait: il devenait de plus en plus violent, un rien le
                                mettait en
                                rage... Une fourchette posée de travers... Une serviette mal pliée... Un livre que
                                j'avais
                                changé de place... La nuit, il se levait pour ranger par ordre de taille les coussins du
                                canapé; il
                                dépliait et repliait, au millimètre près, tout le linge des armoires. Au matin, ses yeux
                                de
                                dément me remplissaient d'épouvante. Surtout, il s'en allait des semaines entières. Au
                                début,
                                je n'ai pas compris que sa surveillance se relâchait peu à peu; j'étais comme un chien
                                habitué
                                à sa chaîne! Mais j'avais le temps de fouiller et j'ai trouvé un peu partout dans la
                                maison de
                                l'argent, le double des clefs de la voiture. Je n'y touchais pas, persuadée qu'il me
                                tendait des
                                pièges. Mais je crois qu'il avait tout simplement oublié ces cachettes saugrenues. <br>
                                Ce matin, très tôt, il m'a réveillée sans ménagements pour que je prépare sa valise. Il
                                voulait partir "se détendre un peu pendant le week-end, loin d'une femme insupportable
                                et
                                d'une gamine geignarde!" Mais il s'est aperçu qu'une de ses chemises avait, paraît-il,
                                un pli au
                                col... Un prétexte habituel pour me gifler et me traiter de bonne à rien. Et à ce
                                moment, la
                                petite est arrivée, à moitié endormie. Elle a trébuché et renversé le reste de la pile
                                de linge.
                                Cest alors qu'il est entré dans une rage folle et a levé la main sur elle, ce qu'il
                                n'avait encore
                                jamais fait. La plaie qu'elle a au front, c'est à cause du rebord de la table contre
                                lequel elle est
                                tombée. [l n'a pas eu un geste pour la secourir, s'est contenté de dire qu'il était trop
                                fatigué
                                pour conduire et qu'il allait appeler un taxi. Il a ajouté que si j'avais le malheur de
                                bouger
                                pendant son absence... Le regard qu'il m'a jeté en sortant m'a glacée. Ce n'est qu'en
                                voyant ma
                                petite fille hurlant sur le sol, le visage couvert de sang que j'ai enfin réalisé qu'il
                                fallait fuir.
                                J'ai agi comme une somnambule, ramassé ce que j'ai pu, pris les clés et l'argent. J'ai
                                conduit
                                presque sans m'arrêter, pendant des heures." <br>
                                Aurore se tait, le regard perdu. Louise allonge le bras pour effleurer doucement la main
                                de sa fille. Elle ne dit rien, elle sent qu'il est trop iôt, que ce récit fait d'une
                                traite est une
                                blessure à vif et qu'elles ont bien mérité un peu de repos. <br>
                                Dimanche 10 mai: La journée s'est déroulée paisiblement. Avec précaution mère et
                                fille ont refait connaissance. L'enfant, habituée sans doute se rendre quasi invisible,
                                s'occupait seule, en silence et sans jamais les quitter des yeux. Mais elle avait
                                esquissé une
                                ébauche de sourire lorsque Louise avait sorti du grenier les boites où dormaient tous
                                les
                                jouets de sa mère. Vers la tin de l'après-midi. après avoir défait les bagages et
                                préparé
                                l'ancienne chambre d'Aurore, elles se sont assises sur la terrasse. Le jardin ruisselait
                                de
                                lumière, les hirondelles striaient le ciel de leur vol imprévisible. Mais mesure que le
                                soir
                                s'approchait, la jeune femme paraissait de plus en plus nerveuse. Elle jetait autour
                                d'elle des
                                regards inquiets, en triturant entre ses doigts une de ses longues mèches. <br>
                                "Il va arriver...Je le sens...ll ne nous laissera jamais en paix! <br>
                                - Tu m'as dit quil ne comptait pas revenir avant mardi après-midi. Cela nous laisse le
                                temps d'aviser. <br>
                                - Je ne crois pas. J'ai un mauvais pressentiment' <br>
                                - Rentrons alors. Nous allons tout fermer et je veillerai cette nuit. De toutes façons,
                                je
                                dors si peu!" <br>
                                Une fois seule, après avoir une dernière fois fait le tour de la maison et vérifié
                                portes et
                                fenêtres, Louise s'est assise dans le salon pour boire une deuxième tasse de café. Elle
                                a éteint
                                les lampes; la lumière du réverbère filtrant à travers les fentes des volets lui permet
                                vaguement de distinguer ce qui l'entoure. Seuls des bruits familiers lui parviennent:
                                quelques
                                rares voitures, des aboiements lointains... Aurore et la petite dorment à l'étage. <br>
                                Malgré le café, elle a dû s'assoupir. C'est un frôlement étrange contre le mur extérieur
                                qui la ramène à la réalité... Un frôlement suivi d'un léger bruit d'eau qui s'écoule. Il
                                ne pleut
                                pas pourtant. Elle se demande un instant si elle n'a pas rêvé. Mais le bruit se
                                renouvelle et
                                semble se déplacer le long de la maison. Alors elle se lève, se rend dans le bureau et
                                tire, du
                                fond d'un placard, la carabine de Pierre. La boite de cartouches est rangée à côté, sur
                                la
                                droite. Elle charge l'arme, regagne le salon et essaie de distinguer, par les
                                interstices du volet,
                                ce qu'il se passe dans la rue. A une centaine de mètres sur la gauche, une voiture est
                                garée;
                                elle n'y était pas au début de la soirée. Soudain une silhouette apparait dans son champ
                                de
                                vision avec, au bout du bras, un objet vaguement rectangulaire qu'elle n'arrive pas à
                                identifier. Mais sa réaction est immédiate: en silence, elle descend au sous-sol,
                                déverrouille la
                                porte du jardin et se glisse jusqu'à l'angle du mur qui lui permettra, sans être vue, de
                                surveiller
                                la rue. Dans le même temps, une forte odeur d'essence la prend à la gorge, sous ses
                                pieds nus
                                le sol est mouillé...Elle vacille, le souffle coupé. <br>
                                Là-bas, la silhouette se détache de l'ombre de la voiture et revient vers la maison.
                                Dans
                                sa main, une flamme jaillit. Louise a juste le temps d'entrevoir un visage à demi
                                dissimulé
                                sous un chapeau... L'homme joue avec un briquet. <br>
                                Alors une grande onde calme l'envahit et elle entend la voix de Pierre. <br>
                                Ne te crispe pas. Amène l'arme contre ton épaule...Doucement...Prends ton
                                temps...Cest bien...Respire..." Cest ainsi qu'il lui apprenait à tirer sur une cible de
                                paille, au
                                fond du jardin. Il disait en riant que l'élève avait rapidement dépassé le maître! <br>
                                La sombre silhouette s'arrête, regarde la maison en continuant à balancer le briquet. La
                                main de Louise ne tremble pas. Pierre est là, près d'elle. <br>
                                Le juron et les flammes jaillissent en même temps. Lhomme tourne sur lui-même, se
                                frappe les flancs. Louise suit ses mouvements désordonnés avec le canon de la carabine
                                et
                                réalise soudain ce qui a dû se passer: en arrosant d'essence les murs de la maison, il
                                en a
                                répandu sur ses vêtements et ses chaussures. Pourvu qu'il n'ait pas le temps de
                                s'approcher! <br>
                                Mais il chancelle, fait trois pas vers sa voiture et tombe à genoux avec un épouvantable
                                hurlement de douleur. Doucement, Louise laisse retomber l'arme contre sa hanche. Voila,
                                c'est fini. Les premiers volets claquent, les habitants, tirés de leur sommeil, se
                                penchent aux
                                fenêtres. Elle en profite pour se glisser jusqu'au bureau et replacer la carabine à sa
                                place. <br>
                                Lorsqu'elle ressort, nouant la ceinture de sa robe de chambre, sirènes et gyrophares ont
                                envahi la rue, les curieux accourent et un voisin effaré fait constater aux gendarmes
                                les
                                longues traînées d'essence qui imprègnent les murs, les volets et le sol le long de la
                                maison.
                                Les pompiers s'affairent autour d'une masse noire recroquevillée sur le trottoir. Des
                                regards
                                apitoyés se tournent vers Louise qui lève les yeux vers la fenêtre de la chambre du
                                premier étage.
                                "Si elles pouvaient ne pas se réveiller maintenant, supplie-t-elle, attendre que le
                                matin
                                ait emporté ces images de cauchemar! "
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal21 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Un dimanche dans la vie de Louise</h3>
                <h4 class="auteurnouvelle">par Martine Charre</h4>
                <p>Le calendrier est posé sur la petite table. Il y reste en permanence. Un calendrier
                    banal,
                    douze feuilles agrafées sur un rectangle de carton, cadeau d'un commerçant. <br>
                    Cette année, elle
                    a choisi celui du pharmacien: une clématite d'un bleu profond sur un fond vert pâle.
                    C'est là, face au jardin, que Louise s'installe chaque soir pour l'annoter
                    scrupuleusement. En face de chaque jour, une croix, en face des dimanches, une barre dressée
                    comme...</p>
                <div class="tourbutton">
                    <button @click="showModal21 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal22 }">
                    <div class="modal-background" @click="showModal22 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Matins de juin</h3>
                            <p>Moïsette lève les yeux vers le ciel sans faille en quittant son appartement.
                                Elle a le cœur aussi léger que le tissu de sa jolie robe fleurie, c'est
                                son dernier jour de travail. Quelques centaines de mètres la séparent du
                                centre d'examen où elle est convoquée pour surveiller les épreuves écrites
                                du baccalauréat de français. Dix ans qu'elle s'y rend, chaque fin d'année
                                scolaire, pour endosser le rôle de surveillante. Une sorte de rituel accompli
                                de bonne grâce car il fleure bon les vacances. <br>
                                Elle avance d'un pas' chaloupé. Pas la moindre once de vulgarité dans
                                sa démarche, juste une grâce naturelle dont peu de femmes peuvent s'enor-
                                gueillir. Les premiers rayons du soleil caressent sa peau déjà cuivrée et les
                                fragrances fleuries qui s'échappent des jardins s'infiltrent délicieusement
                                dans ses narines. Cadeaux exquis d'un doux matin de juin. Promesse d'une
                                belle journée. <br>
                                Autour d'elle, la rue s'anime peu à peu. Les volets claquent ou s'en-
                                roulent, les toutous frétillent au bout de leur laisse et des grappes d'ado-
                                lescents, oreillette écouteur bien en place, s'acheminent vers leur établis-
                                sement scolaire. Un matin comme tous les autres dont Moïsette apprécie de
                                cueillir les premiers instants. Les uns s'éveillent ou dorment encore, les au-
                                tres s'apprêtent à partir au travail, comme ces deux ouvriers, une glacière
                                posée à leurs pieds et qui, au passage de la jeune femme, lui sourient d'un
                                air entendu. Comment pourrait-elle les laisser indifférents ? Sa beauté
                                flamboyante embrase la gent masculine sur-le-champ tout en la pétrifiant
                                sur place. « Trop sublime pour être accessible », a-t-elle l'habitude de pen-
                                ser avec un pincement au coeur. <br>
                                Sans hésitation, l'enseignante rend la pareille aux deux hommes, une
                                phrase de l'Abbé Pierre en tête : « Un sourire coûte moins cher que l'élec-
                                tricité, mais donne autant de lumière. » Le sien va-t-il contribuer à agré-
                                menter leur journée ? Elle le souhaite. En tout cas, ce dont elle est certaine,
                                c'est qu' ils la suivront du regard jusqu'à ce que sa silhouette élancée dispa-
                                raisse de leur vue. <br>
                                Des ouvriers, Moïsette ne retiendra que ce sourire ; elle n'a même
                                pas remarqué les muscles saillants sous la chemisette du plus jeune et le
                                regard de braise du grand brun car une seule personne accapare son être
                                tout entier : Romain qu'elle a épousé deux mois plus tôt. Avant d'en arriver
                                là, le jeune homme, comme tous les autres, avait estimé ses chances de sé-
                                duire la beauté fatale quasi nulles et n'avait donc pas daigné manifester son
                                attirance. Alors Moïsette avait pris les devants. <br>
                                Car Romain, tout nouveau bibliothécaire dans la médiathèque
                                qu'elle fréquente assidûment, l'avait attirée d'emblée par son air débon-
                                naire et son physique d'adolescent attardé. Ce côté touchant qui émanait de
                                sa personne la transportait dès qu'elle le voyait et, chacune de ses visites,
                                elle passait son temps à l'observer à la dérobée entre deux rayonnages de
                                livres. Pas de doute, elle était belle et bien éprise de cet homme et elle allait
                                faire en sorte qu'il le comprenne rapidement. Pas question qu'une autre le
                                lui soume ! <br>
                                Un soir, elle s'était attardée jusqu' la fermeture pour mettre son plan
                                à exécution. Plus personne autour d'eux, c'était parfait ! Le dernier livre de
                                Philippe Besson en main, elle s'était dirigée vers sa proie pour lui deman-
                                der son avis sur l'auteur. Il avait approuvé son choix. Alors, sautant sur
                                l'aubaine, Moïsette l'avait invité à prendre un pot en ville pour en discuter.
                                Le visage du jeune homme s'était légèrement empourpré. Il avait cependant
                                accepté la proposition avec un sourire aux lèvres qui accentuait sa fossette
                                au menton. La suite avait donné raison à Moisette d'avoir été entrepre-
                                nante. Ils avaient passé un moment délicieux et s'étaient revus dès le len-
                                demain. Leur histoire d'amour avait alors débuté pour se conclure par un
                                mariage un an plus tard. <br>
                                C'est à Romain qu'elle songe en franchissant la grille du lycée. Dans
                                une semaine leur couple roucoulera Rome. La ville éternelle abritera leur
                                amour et le sublimera. Elle en frémit déjà de bonheur ! <br>
                                Moisette traverse la cour, s' arrête quelques instants pour encourager
                                deux de ses élèves puis tourne gauche pour s'engouffrer à l'intérieur du
                                bâtiment. Devant la salle E7 patientent déjà quelques candidats la mine
                                inquiète. L'enseignante leur adresse un sourire compatissant. L'image de la
                                lycéenne qu'elle était se fixe un instant sur sa rétine ; le temps a passé, elle
                                a basculé de l'autre côté mais elle se sent encore tellement proche de ces
                                jeunes gens... <br>
                                Dans la grande pièce longiligne, son collègue est déjà à pied
                                d'œuvre ; il arpente les trois rangées de tables parfaitement alignées pour
                                distribuer les copies blanches. Après l'avoir salué, Moisette s'empare de
                                deux paquets de papier de brouillon : un rose et un jaune - en alternant les
                                couleurs, la fraude risque moins de passer inaperçue - et dépose quelques
                                feuilles à chaque emplacement, Une fois la tâche accomplie, elle invite les
                                élèves à entrer. <br>
                                Quelques minutes seront nécessaires avant que chacun d'eux repère
                                son nom sur la pastille collée sur la table. Vient ensuite l'étape de la vérifi-
                                cation des identités et des convocations, complétée par une signature sur un
                                listing. Un seul candidat manque à l'appel. <br>
                                Les sacs sont remisés loin devant. Des provisions diverses fleurissent
                                sur les tables : jus de fruit, gâteaux, bonbons... De quoi affronter quatre
                                heures de labeur. <br>
                                Après la lecture des instructions, visant à rappeler aux bacheliers en
                                devenir la conduite à tenir durant l'épreuve, voici l'arrivée de l'enveloppe
                                des sujets. Il faudra encore patienter quelques minutes avant de découvrir
                                son contenu. <br>
                                Alors que les regards se font de plus en plus anxieux et que les pieds
                                s'agitent sous les tables, un jeune homme, allure dégingandée et tignasse
                                hirsute, fait irruption. « Eh bien, il était temps ! » lui assène le collègue de
                                Moïsette d'un air réprobateur. L'intéressé prend place sans rien dire, la
                                mine renfrognée. « Encore un qui ne doit pas être très motivé », murmure
                                le responsable de salle en levant les yeux vers le ciel. <br>
                                Ça y est, l'épreuve a débuté, comme le confirme l'inscription au ta-
                                bleau blanc : Début : 8 H ; Fin : 12 H. Et pas une minute de plus ! N'en dé-
                                plaise aux plus lents. <br>
                                Moïsette s'est installée au fond de la salle. Dans cet immense espace
                                habituellement réservé à l'étude, les tables ont étrangement été placées dos
                                au bureau occupé par son collègue, c'est donc elle qui fait face aux élèves.
                                La jeune femme vient d'entamer une grille de mots croisés tandis que
                                l'autre surveillant a entrepris de travailler sur son ordinateur portable.
                                A peine l'enseignante a-t-elle noirci quelques cases, son regard se
                                porte vers le dernier arrivé car son comportement l'intrigue. Pourquoi dia-
                                ble ne se concentre-t-il pas sur le sujet comme tous les autres ? Au lieu de
                                cela, il a le nez en l'air, une attitude pour le moins désinvolte qui ne laisse
                                nullement présager un travail de qualité ! Et qui va se poursuivre une bonne
                                vingtaine de minutes durant lesquelles le regard frondeur du candidat croi-
                                sera plusieurs fois celui de Moisette et l'indisposera. « Il y a quelque chose
                                d'Arthur Rimbaud dans ses yeux-là a-t-elle subitement pensé, la fois
                                fascinée et effrayée par leur limpidité. « Et puis cet air rebelle qui s'affiche
                                avec un naturel déconcertant... » Une bonne dose d'insolence qui ne par-
                                vient pas à masquer un côté ingénu qui émeut l'enseignante. Néanmoins,
                                mise à part cette belle petite gueule d'ange, elle est convaincue que le jeune
                                homme n'a rien d'autre en commun avec le poète de génie ! Vraisembla-
                                blement pas de « bateau ivre » flottant dans la copie, sans doute juste une
                                flopée de fautes d'orthographe et d'erreurs de syntaxe comme le veut la
                                tendance ! D'ailleurs, elle s'imagine bien l'énergumène se lever, une fois
                                l'heure d'astreinte écoulée, remettre la feuille avec un sourire narquois et
                                sortir la tête haute. <br>
                                Mais la deuxième heure est déjà entamée et tout le monde reste en
                                place. Moisette a presque achevé ses mots croisés. Elle est maintenant
                                plongée dans la lecture d'un roman quand soudain, alors qu'elle lève de
                                nouveau les yeux vers son Rimbaud imaginaire, ne voilà-t-il pas qu'il lui
                                adresse un clin d'œil appuyé ! <br>
                                Déstabilisée par cette attitude pour le moins cavalière, la surveillante
                                se crispe, perd la page de son livre et sent monter en elle un fort sentiment
                                d'exaspération. « Non, mais, il est malade celui-là !!! » Et pourtant, elle n'a
                                pas rêvé. Il l'a bien fait ! Il a osé !!! « Mais qu'est-ce qu'il lui prend à ce
                                petit blanc bec ? Il ne manque vraiment pas d'air ! » peste-t-elle en son for
                                intérieur alors que l'indélicat mâchouille le capuchon de son stylo, l'air de rien <br>
                                Soucieuse d'échapper à un éventuel nouvel assaut oculaire, Moïsette
                                se lève brusquement. Jamais encore elle n'a connu une telle situation !
                                Néanmoins, afin de ne pas perturber la bonne marche de l'épreuve, elle
                                préfère éviter toute remontrance. La voilà qui déambule maintenant dans
                                les travées, histoire de se calmer les nerfs. Elle se tient à bonne distance de
                                son « agresseur » et peut l'observer de dos à sa guise. « Dieu merci, il ne
                                lâche plus son crayon ! », se dit-elle avec soulagement. Et continuant de
                                ruminer : « Eh bien, il en aura mis du temps avant de s'y mettre ! A pré-
                                sent, on dirait que plus rien ne peut l'arrêter ! Drôle de garçon ! » <br>
                                De nouveau sereine, elle se décide à regagner sa place. Elle reprend
                                ensuite le fil de son roman tout en relevant régulièrement la tête pour ba-
                                layer la salle du regard et jeter un œil furtif en direction du jeune impudent.
                                Les va-et-vient de la main du lycéen sont devenus incessants et sa concen-
                                tration extrême. Une frénésie d'écriture semble s'être emparée de tout son
                                être. Et ce revirement inattendu suscite la curiosité de l'enseignante. Qui se
                                cache vraiment demière ce visage ? ne peut-elle s'empêcher de se demander
                                face à une telle métamorphose. <br>
                                Il reste encore une heure de surveillance. Rimbaud vient de lever la
                                main... le cœur de Moïsette s'emballe. « Pourrais-je avoir une autre copte
                                s'il vous plait ? » Alors que l'enseignante lui apporte la feuille de papier
                                demandée, il lui adresse un sourire ensorceleur avant de se remettre aussitôt
                                à l'ouvrage. La jeune femme accuse à nouveau le coup, se rassoit, ouvre un
                                guide sur Rome et s'efforce de ne plus penser qu'à son futur voyage. Elle
                                ne va tout de même pas se laisser perturber par un adolescent ! <br>
                                « Plus que cinq minutes », indique le collègue de Moïsette aux trois
                                candidats qui planchent encore. Rimbaud est toujours là... <br>
                                « C'est terminé, vous rendez vos copies s'il vous plait <br>
                                Rimbaud se lève, remet le fruit de son travail à la surveillante en
                                plantant une dernière fois le bleu de ses jolies mirettes dans les siens puis
                                se détoume et sort incontinent. Moïsette n'a pas jugé utile de revenir sur
                                son attitude déplacée. Ah quoi bon ! L'épreuve est terminée et elle n'a plus
                                qu'une seule envie : rentrer chez elle et profiter dès à présent de son temps
                                libre. Malgré tout, elle ne peut s'empêcher de parcourir les premières lignes
                                de la copie qu'elle a entre mes mains, puis les suivantes... <br>
                                Stupéfiée, elle a retenu sa respiration durant toute la lecture. Impos-
                                sible de ne pas aller jusqu'au bout. Tant pis si son collègue manifestait des
                                signes ostensibles d'impatience. Car le contenu de la dissertation ne
                                s'apparente en rien au niveau habituel d'un élève de Première : l'écriture
                                élancée qui court Sur le papier révèle un style époustouflant, à faire pâlir
                                d'envie l'écrivain le plus chevronné. Les mots s'enchainent avec bonheur,
                                laissant entrevoir une insoupçonnable maturité et une sensibilité à fleur de
                                peau qui bouleversent l'enseignante. <br>
                                Mais, une autre surprise, encore plus déroutante, attendait Moï-
                                sette... Quelques mots qui lui étaient adressés, sur une feuille de papier de
                                brouillon rose : <br>
                                A vous, ma jolie surveillante,
                                Un jour, vous serez mienne, j 'en ai l'intime conviction <br>
                                Deux ans plus tard matin de juin
                                Le cœur débordant d'amour. Moisette contemple son époux en train
                                de leur fils. L'enfant vient tout juste de naitre et il a le même su-
                                blime regard bleu que son père, La jeune femme est au comble du bonheur.
                                Défilent alors dans sa tète les images des deux dernières années de sa vie
                                la mort tragique de Romain, victime d'un AVC au cours de leur séjour
                                Rome, le trou noir dans lequel elle s'était enfoncée par la suite et puis Ju-
                                lien, son Rimbaud d'un jour, qui s'était retrouvé sur sa route un an après
                                leur première rencontre alors qu'elle surveillait de nouveau les épreuves du
                                baccalauréat. Julien qui avait osé la défier du regard et de ses mots ; Julien
                                qui, malgré son jeune âge, a déjà publié un premier livre encensé par tout le
                                milieu littéraire et avec qui elle s'est mariée peu de temps auparavant, n'en
                                déplaisent aux oiseaux de mauvais augure. <br>
                                Elle repense à l'instant qui a fait basculer sa vie. Le jeune hornme
                                l'avait abordée à la sortie du lycée. Pourquoi, alors qu'elle était encore en-
                                sevelie sous le poids du chagrin lié à la perte de Romain, n'avait-elle pas
                                refusé son invitation au café du coin ? pourquoi n'avait-elle même pas
                                cherché à fuir ses lèvres lorsqu'il les avait approchées des siennes ce jour-
                                là ? Mystère de la vie qui suit son cours, matins de juin qui se répètent et ne
                                se ressemblent pas... </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal22 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Matins de juin</h3>
                <h4 class="auteurnouvelle">Par l'auteur de la nouvelle</h4>
                <p>Moïsette lève les yeux vers le ciel sans faille en quittant son appartement.
                    Elle a le cœur aussi léger que le tissu de sa jolie robe fleurie, c'est
                    son dernier jour de travail. Quelques centaines de mètres la séparent du
                    centre d'examen où elle est convoquée pour surveiller les épreuves écrites
                    du baccalauréat de français. Dix ans qu'elle s'y rend, chaque fin d'année
                    scolaire...</p>
                <div class="tourbutton">
                    <button @click="showModal22 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2008 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal23 }">
                    <div class="modal-background" @click="showModal23 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">L'arc en ciel</h3>
                            <p>«Bonjour, bon réveil et bonne journée ! Le soleil se lève sur la France. Il est 7h30 et
                                vous êtes a
                                l'écoute sur...» <br>
                                Mes doigts, à force de tâtonner, trouvèrent enfin le bouton d'arrêt de l'alarme, et la
                                radio
                                cessa tout vacarme. J'avais positionné la molette du volume au maximum, pas question de
                                subir
                                une nouvelle panne de réveil et de rater mon rendez-vous. La conséquence stupide de ce
                                geste fut
                                un sursaut de panique, le temps que je comprenne la raison qui m'avait éjecté ainsi de
                                mon
                                sommeil. Pourquoi les gens de la radio s'évertuaient- ils à créer des génériques aussi
                                bruyants ?
                                En revanche, la conséquence heureuse de ce réveil forcé était une pleine conscience et
                                non l'état
                                de demi-sommeil dans lequel j'avais coutume d'entamer mes journées. Je crois que
                                l'excitation et
                                l'impatience que me procurait l'idée de mon rendez-vous, contribuaient grandement à ma
                                bonne
                                humeur. En un geste, la couette se regrouva au pied du lit. Quart de tour vers la
                                droite, relever le
                                buste, atterrissage des pieds sur la,moquette moelleuse. Je me lève. Rien ne m'empêchera
                                d'être
                                heureux aujourd'hui, j'en suis convaincu. Et je me lançai. « Un... deux... trois...»
                                Dans ma tête, je
                                comptais mes pas, Ou plutôt, j'en faisais le décompte, Car à chaque nouvelle enjambée,
                                c'était un
                                pas de moins à faire jusqu'au lieu de mon rendez-vous ! Ainsi, comme une vraie trotteuse
                                de
                                montre, je cadençais mon trajet de façon régulière. Je délaissais la douceur de la
                                moquette pour
                                retrouver la froideur du carrelage de la salle de bain, qui me fit frissonner des pieds
                                à la tête.
                                Yaccueillais cette fraicheur matinale les dents serrées et un sourire crispé sur les
                                lèvres. Je
                                tâtonnais de la main pour attraper la lunette des toilettes et la relever. Le plus
                                urgent étant fait,
                                j'organisais mes pensées afin d'établir un plan d'attaque pour la préparation de ma
                                petite
                                personne. Petit déjeuner, douche, choix vestimentaire, et je serais paré à passer la
                                porte pour filer
                                droit vers ce qui rendrait cette journée si spéciale. Je continuais mon petit rituel de
                                comptage.
                                C'était devenu une habitude. Si bien que je devais connaître les dimensions de
                                l'appartement par
                                coeur et la longueur de chaque trajet imaginable entre tel et tel meuble ! Peut-être que
                                dans
                                d'autres circonstances, j'aurais pu être architecte Ou géomètre. Mais voila, un
                                architecte aveugle,
                                ça aurait été une première mondiale ! <br>
                                Je revenais mouillé, le corps ruisselant de l'eau chaude de la douche. J'adorais sentir
                                les
                                gouttelettes d'eau me chatouiller, quand elles dévalaient les pentes de mon corps depuis
                                la racines
                                de mes cheveux jusqu'à la pointe de mes pieds. C'était pour cela que je n'utilisais
                                jamais de
                                serviette de bain pour me sécher. De toute façon, la température de l'appartement était
                                assez
                                élevée pour que je sois sec avant d'avoir fini de choisir mes vêtements. Mes doigts
                                farfouillaient
                                dans ma penderie, à la recherche de mon costume préféré. Je ne savais pas comment les
                                autres
                                aveugles faisaient pour élire leur costume favori... pour moi, c'était celui dont la
                                texture, à
                                l'extérieur, était la plus agréable à caresser, et la texture, à l'intérieur, la plus
                                agréable à porter.
                                Cependant, il ne fallait pas faire trop formel, ce n'était pas un rendez-vous d'affaires
                                non plus.
                                J'optais alors pour un costume de flanelle, accompagné d'un t-shirt moulant, dans
                                lesquels je me
                                sentirais fort à mon aise, question de contact. Et voilà, classe mais décontracté, c'est
                                le secret ! Du
                                bout des doigts, j'ajustais une dernière fois ma coiffure, puis tout droit, direction la
                                (porte de)
                                sortie. Alors que ma main gauche saisissait la poignée de la porte, ma main droite se
                                refermait sur
                                ma canne d'aveugle, posée contre un coin de mur à côté de la porte d'entrée, prête à
                                assurer son
                                devoir. Il lui restait quelques minutes de répit, jusqu'à ce que je quitte l'immeuble,
                                avant de
                                fouler, de nouveau, le bitume des trottoirs. Elle était, pour moi, l'exploratrice des
                                temps
                                modernes. D'ailleurs, rien ne pouvait m'empêcher de m'imaginer dans une jungle exotique,
                                ou
                                alors dans une plaine dont I 'herbe serait balayée par le vent d'Est. En fait, si : le
                                bruit des
                                voitures
                                et l'odeur des pots d'échappement, le brouhaha des Conversations et l'odeur générale de
                                la ville.
                                Tout quoi... Je poussai la porte de l'immeuble et me retrouvai dans la jungle : la
                                jungle urbaine.
                                Le nombre de prédateurs qu'on y trouve, lorsqu'on est aveugle, est décuplé. Chaque
                                personne
                                rencontrée est potentiellement un obstacle. La canne, qui établit sa mission de
                                reconnaissance, est
                                comme un badge disant "attention aveugle". Bien sûr, je ne demandais pas aux gens de
                                s'écarter
                                de mon chemin et de dérouler le tapis rouge, bien sûr que non. Je ne me prends pas pour
                                le
                                nombril du monde. Mais comme il est gênant de frapper les gens de ma canne pour me
                                frayer un
                                passage, ceux par exemple qui attendent le métro sur le quai. «Excusezrmoi», «pardon.».
                                Et à chaque fois, ça ne manquait pas, je me retrouvais culpabiliser d'avoir manqué
                                percuter
                                quelquun, mais comment aurais-je pu l'éviter ? Et neuf fois sur dix, j'entendais, avant
                                de
                                continuer mon chemin, un soupir, comme un reproche cruel envers ma cécité. Enfin... <br>
                                Soudain, quelqu'un se colla moi, glissant précipitamment son bras sous le mien.
                                «Ah tu es la ! Alors tu te sens comment »<br>
                                Cétait la voix d'Arnaud, mon meilleur ami, déjà survolté. Mais aujourd'hui, nos
                                enthousiasmes
                                respectifs se faisaient une rude compétition pour remporter la coupe de la bonne humeur.
                                «Je trépigne d'impatience, penses-tu», lui dis-je.
                                Je l'avais invité à m'accompagner, en ce jour important. Et, en sa qualité d'ami, il
                                avait tout de
                                suite accepté. Je ne le lui disais rien mais mon sourire lui exprimait toute ma
                                reconnaissance. Au
                                cours du trajet, je fus questionné en long et en large sur mes impressions, mes
                                attentes, mon
                                sentiment sur ce qu'allait m'apporter cet entretien. A vrai dire, j'étais ailleurs, déjà
                                là-bas. Je
                                n'arrivais pas à me concentrer suffisamment sur ces questions pour y répondre
                                correctement. Il
                                s'en rendit rapidement compte et cela déclencha chez lui un rire franc. Il me tapota
                                l'épaule et,
                                conscient de mon impatience, cessa de me torturer l'esprit avec des questions parasites.
                                Grâce
                                lui, la fin du trajet se passa sans encombre. C'est pratique de pouvoir se faire guider
                                dans un
                                endroit inconnu. <br>
                                <br>
                                Cela faisait une dizaine de minutes que nous attendions à l'accueil. Pour moi, cela dura
                                des heures !
                                « - Monsieur ?... Monsieur ? <br>
                                - Hein, oui ? <br>
                                - Nous allons vous recevoir, venez avec moi, je vous prie. <br>
                                - Oui...» <br>
                                Je m'éveillai d'un seul coup de la torpeur qui m'avait envahie, et me rendis compte que
                                le moment
                                était venu. Mon ami me prit le bras et me fit suivre cet homme. Je me risquai à une
                                question :
                                «Vous m'emmenez lui ?» <br>
                                Ma voix était remplie d'émotion et d'impatience, ce qui fit rire l'homme qui devait être
                                habitué à
                                ce genre de réaction chez ses clients. «Oui ! Il nous attend sagement. D'ailleurs, nous
                                sommes
                                arrivés.», me dit-il d'un ton rassurant. <br>
                                J'entendis le bruit du verrou puis une porte s'ouvrir sous l'action de la poignée. Nous
                                pénétrâmes
                                dans une pièce. Bien sûr, je ne savais pas à quoi elle ressemblait, pas plus que toutes
                                celles que
                                nous avions traversées auparavant. Mais je savais que dans celle-ci se trouvait la
                                raison de ma
                                venue et cela suffisait à lui conférer une atmosphère particulière. J'en tremblais. Une
                                peur
                                soudaine me noua l'estomac et assécha ma gorge. J'étais intimidé l'idée de le
                                rencontrer.
                                «-Où... où est il ?
                                - Il estjuste là. Viens voir, Jazz.» <br>
                                Aussitôt, j'entendis ses quatre petites pattes de chien se mettre à trottiner, faisant
                                cliqueter ses
                                griffes sur le carrelage. Je l'entendais se rapprocher de moi, la respiration haletante.
                                Il s'arrêta
                                alors aux pieds de son dresseur, tout près de moi. «Jazz, voici ton nouveau maitre,
                                dis-lui
                                bonjour.» <br>
                                Je tendais ma main tremblotante, cherchant à tâtons, à demi paralysé. Et c'est lui qui
                                fit le premier
                                pas, qui vint renifler la paume de main, curieux de connaitre mon odeur. Le contact de
                                sa truffe
                                humide guida ma main jusqu'à son museau. Nous procédâmes à une découverte mutuelle, un
                                bref
                                apprentissage de l'autre. Il humait mon odeur autant que je dessinais son visage de mes
                                doigts. Je
                                passai ma main sur sa tête et descendis le long de l'encolure. Les poils se faisaient
                                plus longs. Je
                                glissais mes doigts dans Son pelage, doux et épais. Je me penchai vers lui, posant un
                                genou à
                                terre, et j'enlaçai mon chien. Oui, maintenant, c'était mon chien. Il serait bien plus
                                qu'un guide
                                pour moi. Je l'aimais déjà, mon chien... <br>
                                Le dresseur s'évertuait à me donner ses recommandations et j'acquiesçais à chacune
                                d'entre elles,
                                alors que je n'assimilais même pas les quelques bribes de mots que je parvenais à
                                capter, trop
                                occupé à caresser Jazz. Puis, soudain, une question me vint à l'esprit, tellement
                                spontanément que
                                j'en interrompis le dresseur pendant son discours, sans me soucier de l'importance du
                                conseil qu'il
                                me donnait alors. <br>
                                «De quelle couleur est-il lui» demandai-je.
                                Un silence gêné répondit à ma question. Je sentais le dresseur et mon ami perplexes,
                                alors que
                                Jazz, lui, me faisait toujours la fête.
                                «Je pourrais vous dire sa couleur, mais son nom n'évoquerait rien pour vous»,
                                répondit-il de
                                façon timide, de peur d'être irrespectueux.
                                Moi-même, je me demandais pourquoi cette question m'était venue à l'esprit... Je voulais
                                découvrir mon chien et, peut-être, un instant, j'ai cru parvenir à le visualiser, avec
                                sa couleur.
                                Finalement, ce fut moi le plus gêné de nous trois et, à nouveau, j'interrompais le
                                dresseur qui,
                                avec gentillesse, s'apprêtait à répondre à ma stupide question.
                                «Vous avez raison. Oubliez ça. Je ne sais pas à quoi je pensais. L'émotion sans doute.»
                                «Oui sans doute.», répondit-il.
                                L'ambiance devint plus pesante, embarrassés comme nous l'étions. Mon ami et moi partîmes
                                rapidement. Je m'en voulais de les avoir mis dans cette situation, lui et le dresseur.
                                C'était
                                fréquent. Lorsqu'on se présente avec un handicap, les gens savent rarement comment
                                réagir et
                                deviennent rapidement muets. Muet, mon ami l'était resté pendant tout le retour, jusqu'à
                                ce que
                                nous arrivions chez moi. <br>
                                <br>
                                «De quelle couleur est-il ?» <br>
                                Ni le dresseur ni moi n'avions osé répondre à sa question. Et alors, à peine avait-il
                                rencontré son
                                chien Jazz, qu'il se confrontait déjà à une déception. C'était la première fois depuis
                                que je le
                                connaissais, qu'il s'interrogeait sur la couleur de quelque chose. Et à vrai dire, avant
                                cet instant
                                précis, je ne m'étais jamais posé la question de savoir comment il interprétait les
                                couleurs. La
                                vérité, c'est qu'il ne le pouvait pas, ce qui n'avait pas manqué d'embarrasser le
                                dresseur. Quel
                                dommage c'était : non seulement il était privé des formes et contours des objets et des
                                personnes
                                qui l'entouraient, mais en plus des teintes. C'était d'une telle évidence que ça ne
                                m'avait pas
                                frappé, et je comprenais maintenant le malheur de ce handicap. Jamais il ne pourrait
                                admirer un
                                coucher de soleil aux mille teintes flamboyantes, ni un ciel de pleine lune, parsemé
                                d'une myriade
                                d'étoiles, ni une forêt au printemps, dont les rayons du soleil viendraient transpercer
                                le feuillage
                                d'un vert tendre, ni cette même forêt, en automne, dans son linceul annuel d'or et de
                                roux. Il
                                n'avait pas conscience de perdre tout cela évidemment, mais... c'était du gâchis que de
                                ne pas
                                connaître les beautés colorées du monde qui nous entoure. Me vint alors à l'idée, ce
                                défi saugrenu
                                de lui apprendre les couleurs. Et pendant tout le trajet du retour, je m'attelais à
                                cette tâche :
                                essayer de trouver un moyen efficace pour lui faire découvrir I 'arc-en-ciel. Ma
                                première idée était
                                d'associer une couleur à un objet qu'il connaissait. Une orange c'est orange, une banane
                                c'est
                                Jaune, une rose c'est rouge... <br>
                                J'avais la conviction de l'avoir trouvé ce moyen efficace de lui apprendre les couleurs,
                                mais je
                                retombai tout à coup dans la triste réalité des choses, me rendant compte de l'absolue
                                inutilité de
                                cette idée. A quoi lui servirait-il de savoir qu'une orange est orange, ne voyant pas le
                                fruit en
                                question. L'association d'idées ne se ferait pas, et il ne serait pas plus avancé. Je
                                dois avouer qu'à
                                cet instant, je ne me sentais pas brillant, mais je ne baisserais pas les bras ! En
                                fait, je restais
                                même convaincu que, quelque part, les associations d'idées se basaient sur des
                                fondements
                                logiques. Mais il fallait associer chaque couleur à quelque chose qu'il pouvait
                                percevoir. Mais oui
                                ! La perception bien sûr. C'est là-dessus qu'il fallait moduler l'idée. Il n'avait
                                jamais possédé la
                                vue, mais il lui restait quatre autres sens : l'ouïe, l'odorat, le toucher et le goût,
                                sans oublier son
                                esprit imaginatif. C'est à ces quatre sens-là que je devais associer les couleurs !
                                Et voilà que nous arrivions déjà clvz lui, moi qui étais maintenant en train de me
                                demander
                                comment aborder le sujet... Il me suffirait juste de me lancer, me dis-je. A peine
                                avais-je refermé
                                la porte que j' entamais donc mon enseignement. <br>
                                «Le ROUGE. Le rouge, c'est la chaleur ! Cest un bain trop chaud qui te noie dans la
                                torpeur, c'est
                                un feu trop proche qui te brûle la peau.» <br>
                                Décontenancé, c'est le mot qui colla le mieux à l'expression de son visage. [l n'osa pas
                                m'interroger et resta bouche bée. Me prenait-il pour un fou ? Il semblait perdu,
                                cherchant quelle
                                réaction adopter, puis, après d'interminables secondes, il sourit.
                                «Continue...», me murmura-t-il, avec une pointe de curiosité et d'impatience dans la
                                voix.
                                Je répondis à son sourire, sans qu'il puisse le voir. J'étais si heureux de susciter son
                                intérêt ! Mon
                                idée fonctionnait. <br>
                                «LORANGE. C'est tiède ! Cest la soupe qui te réchauffe de l'intérieur, c'est ta couette,
                                le matin
                                en hiver, dans laquelle tu te blottis.» <br>
                                «Le JAUNE. Cest le duvet du poussin. C'est ta peau qui se chauffe sous le soleil à la
                                plage.» <br>
                                «Le VERT. C'est l'odeur du gazon fraichement tondu. C'est une prairie dans laquelle tu
                                t'allonges
                                et tu te roules» <br>
                                Au fur et à mesure, son visage rayonnait et, à chaque nouvelle couleur, je poursuivais
                                avec encore
                                plus d'enthousiasme.
                                «L'INDIGO. Cest la mer ou l'océan dans lesquels tu te baignes, le bruit des vagues,
                                l'humidité
                                de la marée, le parfum de l'iode, le sel marin.»
                                «Le BLEU. Cest le froid, le vent des matins dhiver qui te fait frissonner, une pluie
                                fraiche et
                                agréable qui te mouille et ruisselle sur ta peau.» <br>
                                «Le VIOLET. C'est le parfum de la lavande ! Cest l'odeur et le goût des bonbons à la
                                violette, ce
                                sont des guimauves moelleuses et sucrées <br>
                                «Voila, tu connais les couleurs de l'arc-en-ciel !
                                - Et le marron ?», me demanda-t-il. <br>
                                Je ne m'attendais pas à ce qu'il soit gourmand de couleurs à ce point. Je n'étais même
                                pas sûr que
                                cela marche et voilà que je me retrouvais désanné, n'ayant pas imaginé d'autres
                                associations. Le
                                mieux était encore de me laisser aller et d'improviser. <br>
                                "Le MARRON... voyons... Cest dur et rugueux, comme l'écorce d'un arbre !»<br>
                                Et à chaque évocation de couleur se peignait sur son visage une expression tantôt de
                                joie, tantôt
                                de tristesse, selon l'association. Il reproduisait le concept de la couleur avec son
                                visage. <br>
                                - Et le beige ? <br>
                                - Le BEIGE, c'est crémeux ! C'est l'onctuosité d'un yaourt, c'est le lait qu'on te passe
                                dans le
                                dos, pour un massage ! <br>
                                - Le rose ? <br>
                                - Le ROSE. C'est un rouge plus clair, donc plus doux. Cest une chaleur tendre, c'est une
                                femme qui te prend dans ses bras et colle son corps contre le tien. <br>
                                - Le gris ? <br>
                                - Le GRIS, c'est un Orage qui éclate, un silence pesant que le tonnerre déchire, une
                                forte
                                pluie, un vent glacé. <br>
                                - Et le blanc ? <br>
                                - Le BIANC, c'est la pureté. Cest le rire d'un enfant, ce sont les larmes de joie d'une
                                femme,
                                c'est le goût de la neige ! Le blanc ne s'évoque pas sans le noir. Le NOIR, c'est...
                                - Non, arrête, m'interrompit-il. Celui-là, je le connais bien. Le noir, c'est le néant,
                                le chaos,
                                l'absence de tout. Le noir, c'est ce que je vois depuis ma naissance.», continua-t-il
                                d'un ton
                                las. <br>
                                Il se blottit contre Jazz et le cajola. Il l'aimait déjà énormément. Il serait sans
                                doute un grand ami
                                pour lui. Je m'approchai d'eux et donnai moi-même une caresse à Jazz. Puis je me penchai
                                vers
                                son maitre. <br>
                                «Non, tu as tort. Le NOIR, c'est la couleur de ton chien.» <br>
                                Il tourna la tête vers moi, comme s'il avait voulu me regarder droit dans les yeux, les
                                siens
                                s'humidifiant au fur et à mesure qu'il réalisait que le noir ne serait jamais plus, pour
                                lui, la couleur
                                de sa cécité, mais celle de ce cadeau qu'il avait aujourdhui reçu. <br>
                                La première larme coula lorsqu'il me dit avec la voix la plus sincère au monde : «Merci»
                                bra
                                Jazz interrompit la discussion d'un coup de langue sur la joue de son maitre en réponse
                                aux
                                caresses affectueuses que celui-ci lui donnait allègrement depuis tout à l'heure. Il
                                serra le chien
                                dans ses bras. <br>
                                «Faites connaissance tous les deux, je repasserai plus tard.» br
                                Et alors que je m'apprêtai à sortir, je I 'entendis me rappeler pour me dire :
                                «Je ne suis pas encore un expert en couleur. Mais l'OR, c'est la couleur de notre
                                amitié.» <br>
                                Et cette fois-ci, nous fûmes deux à verser des larmes. <br>
                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal23 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : L'Arc en Ciel</h3>
                <h4 class="auteurnouvelle">par Mathieu Guibé</h4>
                <p>«Bonjour, bon réveil et bonne journée ! Le soleil se lève sur la France. Il est 7h30 et
                    vous êtes a
                    l'écoute sur...» <br>
                    Mes doigts, à force de tâtonner, trouvèrent enfin le bouton d'arrêt de l'alarme, et la radio
                    cessa tout vacarme. J'avais positionné la molette du volume au maximum, pas question de subir
                    une nouvelle panne de réveil et de rater mon rendez-vous. La conséquence stupide de ce geste fut
                    un...</p>
                <div class="tourbutton">
                    <button @click="showModal23 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal24 }">
                    <div class="modal-background" @click="showModal24 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Le grain de sable</h3>
                            <p>Bien qu'il s'y soit rendu à peine deux semaines auparavant, Jacques reprend la route de
                                la Franche-Comté en ce samedi matin de la fin mars. L'Autoroute du Soleil. L'Yonne. La
                                Côte
                                d'Or pittoresque. Des filaments de brume accrochent çà et là une haie, escaladent une
                                butte
                                verdoyante, avec, quand le tracé le permet, la perspective lointaine d'évasions
                                passionnées. Le
                                jour se lève. Les taches plus sombres de bois touffus se démarquent. On imagine tout un
                                monde en train de s'éveiller, sinon de s'assoupir après la traque nocturne pour la
                                subsistance.
                                Mais cet environnement qui parle à l'œil et qu'on aimerait toucher, humer, stationne
                                hors
                                de portée. hors de ce ruban de chaussée monotone où l'on empile kilomètres sur
                                kilomètres, Où
                                des bandes désespérément parallèles vous encadrent avant de se fondre dans l'infini.
                                Qu'à
                                suivre ; méme pas conduire la voiture, ou tout juste. Lui, Jacques, ne sort pas avant
                                Beaune.
                                Cela ménage tout le temps à la réflexion. Tout le temps aux pensées subtiles,
                                insidieuses,
                                taraudantes ou virales de s'instiller dans le mental au sujet d'un rien, ne plus
                                l'alléger d'un carat.
                                <br>
                                Pouilly en Auxois... Le doute se glisse dans la moindre lézarde, sournois, vicieux, le
                                doute
                                griffu : "Sûrement. il l'est Impossible autrement Et aujourdhui encore...". Il n'a
                                jamais détenu
                                de preuves formelles, des indices seulement qui, jusque-là, n'avaient fait que le
                                survoler. Et que
                                penser de certains sourires vite réprimés dans sa direction quand l'un ou l'autre des
                                collègues
                                lance une gaudriole dont le sujet se rapporte à l'infidélité féminine ? "Nul doute : il
                                l'est !" <br>
                                Curieux qu'il faille ce trajet insipide dans la circulation clairsemée pour que tout
                                s'éclaire
                                d'un jour nouveau. Pourquoi lui, spécialement, échapperait-il à cet enchainement sans
                                origi-
                                nalité ? Et comme le trompé finit toujours bon dernier dans la course à la révélation...
                                <br>
                                Beaune... "Attention à prendre la bonne route après les échangeurs ! Vers Verdun sur le
                                Doubs". Au moins une diversion pour chasser le virus traumatisant, ventiler.. D'autant
                                que
                                d'autres préoccupations devraient l'accaparer. Le but de son voyage vers ces pays à la
                                douceur
                                de vivre proverbiale ne présente rien d'agréable : sa mère veuve dont la santé se délite
                                sérieusement... Ambulant des PTT, il sait qu'il ne reverra probablement qu'une fois
                                celle qui lui
                                a donné le jour. Si tant est qu'il arrive à temps. Quand on lui a téléphoné,
                                l'avant-veille, depuis
                                l'hospice des Petites Sœurs des Pauvres, une ex-abbaye d'un village près de Bletterans,
                                la
                                malade déclinait de plus en plus. Atteinte d'une leucémie implacable, elle n'avait pas
                                voulu
                                quitter la province. Elle s'y éteint doucement, entourée des soins dévoués des nonnes.
                                "Dire
                                que ce matin sa femme Lucille a refusé de l'accompagner ! " Et cela — il s'en rend
                                compte
                                davantage à chaque tour de roue de la Laguna — constitue une conduite inqualifiable.
                                "Pour le
                                moins Car, à part quelques divergences de vues inévitables. la mourante ne s'est jamais
                                montrée une belle-mère dérangeante. <br>
                                Mervans... "On approche... Non, Lucille a invoqué leur récente visite d'il y a juste
                                quinze
                                jours, prétexté du ménage en retard... Du ménage ? Si encore leurs enfants en bas âge se
                                trouvaient à la maison. Même pas Y" Les grands-parents maternels les ont kidnappés et
                                ils
                                s'ébattent là-haut, dans le Nord, jusqu'à Pâques. Bien entendu, elle lui reproche d'être
                                trop
                                autoritaire, pas tellement tendre... "Et alors ? Pas de sa faute s'il est bâti ainsi
                                Pourtant, il
                                l'adore, sa Lucille Plus qu'elle ne le supposera jamais ! Évidemment, il n'a pas la
                                manière de le
                                lui dire, pas l'art du mot qui câline, de la formule qui caresse mais indubitablement,
                                il l'aime. Il
                                se le répète, l'affirmerait à la face du monde, la tête sur le billot. Hélas, à elle, de
                                telles
                                expressions il ne sait pas en confier le dixième. "Bon Dieu, oui, qu'il est accro, comme
                                disent les
                                jeunes. Et plutôt qu'un autre l'étreigne, il n'imagine pas à quelle extrémité il serait
                                capable de se
                                livrer Et l'incertitude ? Savoir... Les voir ensemble et... Non, il vaut mieux penser à
                                autre
                                chose... Tiens Les charolaises en sont déjà à brouter ! De bonnes usinières sans
                                problèmes, à
                                élaborer du lait pour leur veau. Pas de complexes... C'est vrai aussi que des disputes
                                ont
                                émaillé leur vie de couple. Mais qui n'en rencontre pas ? " <br>
                                Saint Germain du Bois... "Bletterans, le Jura, c'est pour bientôt... Bien sûr qu'il
                                s'est
                                laissé aller à la frapper. " Récemment. Après des jours et des jours de tension, de
                                regards peu
                                amènes, de bouderie. elle a enfin daigné lui pardonner mais elle lui en veut sûrement...
                                "De là à.. "
                                Et zut ! Il convient de chasser la pensée térébrante. Tout est faux : il se nourrit de
                                phantasmes et il doit se secouer, voilà "Si seulement aujourd'hui elle avait eu un motif
                                sérieux de rester... Elle a argué d'un rendez-vous de dentiste impossible à remettre.
                                Comme
                                elle travaille également, il n'y a que samedi qui convienne..." Mais le praticien aurait
                                été
                                compréhensif, il lui aurait trouvé un autre créneau le soir... <br>
                                <br>
                                Quand Jacques y parvient en milieu de matinée, Bletterans, un gros canton enserré dans
                                les bras de la Seille, vit au rythme de la quinzaine commerciale qui débute. Des
                                détaillants, des
                                artisans finissent d'installer des étalages sur les trottoirs des rues principales, de
                                décorer des
                                boutiques Partout des haut-parleurs diffusent des disques et des flashes publicitaires
                                Chaque
                                lampadaire s'enguirlande de feuillage. Des affiches énumèrent les activités programmées.
                                Bletterans tente de secouer la platitude de sa vie provinciale. Et la loterie ! Tirage
                                tous les jours.
                                Un cochon, des jambons à gagner. Une superbe cormtoise. <br>
                                Jacques arrête la voiture auprès de "l'Hôtel de Franche-Comté"... Bien sous tous
                                rapports ;
                                pas hérissé d'étoiles mais correct. Il y est descendu la dernière fois avec Lucille. A
                                d'autres
                                occasions également. Des repas dignes des solides estomacs comme des fines gueules des
                                autochtones, des prix très honnêtes. Néanmoins un défaut : sa situation non loin d'un
                                carrefour
                                de deux routes de moyenne importance, avec des stops sur l'une d'elles. Donc :
                                démarrages
                                répétés de véhicules et freinages de poids lourds. Il souhaite être logé sur l'arrière,
                                plus calme.
                                <br>
                                Il leur faut bien nicher quelque part lorsqu'ils visitent la malade... La maison des
                                parents,
                                de son enfance à lui, a été vendue à la mort du père et l'argent partagé avec sa soeur
                                du
                                Canada. Cela leur a aidé, à Lucille et lui, à bâtir leur pavillon de Melun, au-delà de
                                la gare. "I.Jn
                                pavillon guére éloigné de son travail où, entre parenthèses. elle se plait. Alors, elle
                                pourrait
                                afficher un peu plus d'égards pour la mourante, Aucun doute, il faut bien le mobile
                                qu'il évoque
                                depuis son départ pour... En y songeant, Lucille lui a déjà joué la pièce de ne pas
                                l'accom-
                                pagner avec des motifs guére plus étoffés." Jusque-là, il a gobé sans imaginer plus
                                avant. <br>
                                Jacques s'informe. L'état de la grabataire demeure stationnaire mats critique. Trop
                                tard,
                                avant déjeuner, pour embrasser sa mère, à dix kilomètres. Alors. malgré les flonflons du
                                dehors,
                                assis au bar devant un apéritif qu'il considère sans même le voir, la pensée
                                destructrice de son
                                malheur conjugal le reprend. Elle le cerne, remprisonne dans un carcan de fer. s'ancre
                                dans
                                une cervelle taillée à coups de serpe. Cest un autre homme qui s'installe dans la salle
                                de
                                restaurant et qui, entre chaque plat, rumine de plus en plus fort des idées de
                                meurtre... La
                                bouteille de rosé d'Arbois qu'il a commandée lui échauffe la bile. Ah Lucille ! Un super
                                brin de
                                femme et de six ans moins âgée. Il s'est souvent demandé comment lui. carré de visage
                                comme
                                de tempérament. avait plu à la toute jeune manutentionnaire rencontrée dans une
                                discothèque,
                                il y a de cela treize ans. "Peut-être afin que le mariage lui procure sa liberté ? Vrai,
                                il n'avait
                                pas
                                songé à ça " Ils n'ont pas conçu d'enfant tout de suite : Lucille invoquait la pureté de
                                sa ligne à
                                préserver... Son corps. Là non plus, il n'était pas le premier. Mais l'esprit tout
                                permis Mai 68
                                avait soufflé avant Plus tard. il a tenté de la convaincre côté descendance. Toujours
                                des refus. <br>
                                — Du café ? propose la serveuse. <br>
                                Il en prendra Et pourquoi pas un marc de Bourgogne ? La porte à côté et celui de l'hôtel
                                est fameux, Même un second... Si seulement ça pouvait l'empêcher de tant gamberger...
                                Plutôt
                                le Pourquoi. ces dernières années, lui a-t-elle annoncé un jour, tout à trac, étre
                                enceinte Quinze mois plus tard, dito Sur l'instant, il en a été heureux. Mais
                                aujourd'hui, il
                                s'interroge... "Non, pas ça, tout de même Ses deux gros garçons ne seraient pas de lui
                                Sûr,
                                ils ne sont pas de lui ! Comment a-t-il pu se montrer aussi aveugle ? Lui, brun ; elle,
                                brune. Et
                                les deux bébés, tirent ferme sur le blond Une peau comme aucun deux autres ne possède.
                                <br>
                                Même durant la visite chez les petites Sœurs, l'idée perfuse, gagne davantage de
                                terrain.
                                L'allongée ne passera pas la semaine, lui confie-t-on. Il en éprouve de la tristesse.
                                Pourtant, la
                                pensée que Lucille, sa superbe Lucille qu'il était si fier de montrer — en fait les
                                grossesses ne
                                l'ont pas abimée — s'épanouit dans les bras d'un amant, lui mord le cœur, occulte d'un
                                voile
                                rouge son esprit. "Dans les bras d'autres. aussi bien, ce qui expliquerait les sourires
                                moqueurs.
                                Qui sait, des collègues à lui, de repos pendant qu'il s'active dans un wagon postal ? Ou
                                des
                                amis à elle ? Elle ne doit pas forcer pour les ramasser à la pelle Quand Jacques quitte
                                la
                                patiente qui l'a à peine reconnu, Sur la promesse de revenir le lendemain, ils sont
                                légion à défiler
                                dans son mental en ébullition. à abuser des charmes de sa brune épouse. <br>
                                positivement, Lucille lui sabote la vie, Le point de non-retour est arrivé. "Impossible
                                de
                                continuer ainsi Bafoué, certes il l'est, mais surtout, il n'admet plus qu'elle se donne
                                aux autres.
                                L'idée s'avère insupportable, Il ne faut pas moins de deux bières coup sur coup dans le
                                premier
                                bar pour étancher feu intense qui le dévore par l'intérieur, une soif atroce de
                                "En ce moment même, peut-être qu'elle fricote en galante compagnie Elle n'attendait
                                que son départ.... Ou alors, ce soir Ils s'en resserviront à s'en raire crier... Parce
                                qu'avec lui,
                                au lit, elle se montre plutôt discrète, réservée. Elle rejoindra l'autre, celui du
                                moment, ramant de
                                Service_ Ou bien, Car il y a le risque que lui-même lui téléphone tard le soir, c'est le
                                vandale, le
                                briseur de foyer, qui se glissera au domicile, dans 10 coquet pavillon qu'elle
                                affectionne. Pour-
                                quoi pas ? Ils ont tout le temps de s'envoyer en l'air. Quand le chat.. <br>
                                L'évocation dernière le torture davantage encore. "Cocu dans le lit conjugal Trop C'est
                                trop fort Invivable plus avant Que n'a-t-il compris plus tôt ? Avant que ne surviennent
                                ces
                                deux petits êtres pas responsables ? Mais i/ n'a guère cure d'eux, à présent. Des
                                étrangers.
                                Alors. dans ce terrain surchauffé pointe la notion aigue que le moment est vraiment venu
                                de supprimer Lucille... Avant qu'un troisième fruit de l'adultère ne s'annonce, "Le
                                moment ?
                                Aujourd'hui, _ _ et pourquoi pas tout de suite ? Non, de préférence cette nuit car il
                                faut le temps
                                matériel. La supprimer et l'autre aussi s'il l'a attendu Un massacre ! Tiens Par la même
                                Occasion, il se cambriolera Une bonne idée, non ? Le voleur qui pense ne trouver
                                personne et
                                qui, surpris, fait usage de son arme... Ni vu ni connu, Jacques. Pour les joumaux : un
                                drame
                                banal, du quotidien éculé...Oui mqis quelle arme ? " La question, banale elle aussi,
                                bloque un
                                long moment le processus enclenché. Jacques n'est pas féru d'armes à feu. En
                                posséderait-il
                                qu'il serait mal venu de les utiliser. Elles ne feraient que l'accuser à plus Ou moins
                                brève
                                échéance. Les expertises balistiques y donneraient du corps. <br>
                                Jacques rentre à l'Hôtel de Franche-Comté. Passant sur le palier du premier étage, un
                                détail Se rappelle à lui qu'il s'empresse de contrôler. Vers le haut de l'escalier, une
                                porte donne
                                vers l'extérieur. Par un balcon, elle dessert des locaux privés. Un perron permet la
                                descente
                                dans une cour I jardin débouchant dans une rue latérale par un passage non clos. Jacques
                                a
                                lui-même autrefois utilisé cette disposition, indiquée d'ailleurs par la patronne.
                                Devant partir de
                                très bonne heure ce matin-là, elle lui avait proposé de régler le soir même puis de s'en
                                aller
                                quand il l'entendrait, ajoutant que cette porte, en partie dissimulée et ne servant
                                qu'aux
                                hôteliers, ne se voyait presque jamais fermée. Belle occasion pour les fieffés de la
                                grivèlerie ou
                                les rats d'hôtel mais on ne semble craindre ni les uns ni les autres en cet îlot de
                                grâce. <br>
                                Dans la cour s'ouvrent les cuisines, toujours accessibles, et là se tient l'arme. Même
                                le
                                choix : une série de longs couteaux effilés. Il a vu, "En emprunter un et le replacer au
                                retour... "
                                Il calcule : par l'autoroute. 318 Km jusqu'à l'entrée de Melun où il demeure et autant
                                pour
                                en revenir. Cela représente sept heures au grand maximum avec la Laguna, en y incluant
                                le
                                forfait. Et il ne devra pas rouler à tombeau ouvert au risque de se faire piéger par un
                                radar
                                malintentionné ou d'attirer l'attention d'une patrouille nocturne. Inopportun. Même
                                fâcheux
                                L'opération ébauchée, modelée puis encastrée dans son esprit étriqué, n'en sortira plus.
                                Quoi qu'il arrive, il ne déviera pas. <br>
                                Melun__. Dimanche matin, 7H00.
                                La porte d'entrée du pavillon bâille, constate avec plaisir la voisine. Lucille est donc
                                levée.
                                Elle lui empruntera de la farine pour sa blanquette du midi. Ainsi, inutile de chercher
                                une
                                épicerie ouverte en ville, shabiller tout de suite, ôter ces bigoudis... Elle a beau
                                appeler, la
                                jeune femme ne répond pas. Pourtant, elle la sait là, n'ayant pas accompagné son époux.
                                Elle
                                lui en a fait part la veille au soir. Étonnant aussi qu'elle soit déjà debout plutôt une
                                adepte de la
                                grasse matinée les jours de repos quand les bébés ne réclament pas. Il lui faut voir...
                                <br>
                                Toutes les communications intérieures sont ouvertes. Fatalement, dans la chambre au
                                premier, entre la porte et le lit, elle se heurte au corps de Lucille dont le sang
                                imprègne la
                                moquette. <br>
                                Police-Secours... Plus rien à tenter pour elle. Le lieutenant de police de permanence
                                débarque sur les lieux. Outre que la victime porte une plaie en pleine poitrine, il note
                                le désordre
                                du lieu. Dans l'armoire mal refermée, on a bouleversé des piles de linge. Au pied. des
                                vête-
                                ments repassés jonchent le sol. Éventré, le matelas libère ses ressorts. Le poignard. ou
                                le
                                couteau, qui a servi au crime semble avoir été utilisé : des traces de sang se
                                remarquent le long
                                de l'estafilade. Sur le chevet, la lampe avec son abat-jour rose brûle toujours. Dans le
                                séjour,
                                des tiroirs gisent à terre, leur contenu dispersé. Le ou les malfaiteurs cherchaient de
                                l'argent ou
                                des bijoux car d'autres objets de valeur demeurent. Tout milite pour un cambriolage
                                connexe,
                                d'autant qu'en examinant la porte d'entrée il ne fait aucun doute qu'elle a été forcée :
                                des
                                marques de levier et la gâche du verrou tordue, balancée sur le carrelage du hall. <br>
                                À la permanence du Parquet, on délivre aussitôt une commission rogatoire télégraphique
                                à l'intention de la Gendarmerie de Bletterans. Comme il a été établi que le mari devrait
                                se
                                trouver dans cette commune, ce service vérifiera et l'avisera. Malgré tous les indices
                                présumant
                                un meurtre par un cambrioleur dérangé par la présence inattendue de la victime — il a pu
                                repérer
                                la voiture partant la veille et supposer la maison vide - ils devront néanmoins enquêter
                                plus à
                                fond. Car un élément place Jacques parmi les éventuels suspects. Le lieutenant de police
                                s'est
                                souvenu que la jolie brune en déshabillé parme a été reçue par lui, en son bureau, un
                                mois
                                auparavant. Évidemment pas dans cette tenue. <br>
                                Elle souhaitait un conseil. plutôt que de déposer plainte. au sujet de son ménage,
                                "Jacques est un être entier. massif du dedans comme du dehors, tout juste dégrossi à la
                                Cognée. Les années passant. elle finit par en avoir peur '0. Plusieurs fois, au cours de
                                disputes,
                                des paroles dures. mais peut-être irraisonnées, avait-elle concédé. des quasi-menaces de
                                mort.
                                avaient franchi sa bouche. La veille. surtout, il était allé jusqu'à lui administrer une
                                série de
                                gifles... Le motif des différends ? Rien de formel des raisons futiles mais des soupçons
                                tous
                                azimuts. En fait, avait confié Lucille avec un regard alangui, il semblerait que son
                                mari
                                suspectât, sans exactement en faire une fixation, sa fidélité conjugale. Et pourtant
                                affirmait-elle,
                                un peu mollement aux yeux de l'inspecteur, il n'y avait pas matière... Aussi
                                craignait-elle de voir
                                un jour ces actes de violence subits se renouveler et en technicolor. <br>
                                Le policier avait tempéré, minimisé. Si cela devait se reproduire, et même si elle était
                                fautive, avait-il susurré, elle ne devrait pas hésiter à lui en parler. Ils verraient
                                alors.. <br>
                                10H20 à Bletterans... <br>
                                Alors qu'il s'apprête à rendre une nouvelle visite à sa mère, l'ultime estime-t-il,
                                Jacques
                                est à son hôtel. Les gendarrnes s'y sont rendus directement la routine. Tandis qtE run
                                d'eux
                                examine le véhicule garé un peu plus loin devant la boulangerie de la Rue Grande, on
                                questionne
                                le postier ambulant sur son emploi du temps de la journée du samedi ainsi que la nuit
                                écoulée. <br>
                                Ce qu'il a fait ? répète-t-il après un temps de prostration qui peut passer pour de
                                l'accablement suite à la nouvelle de la mort de son épouse. Après déjeuner, il s'est
                                rendu à
                                l'hospice des chroniques. Au retour, en fin d'après-midi, il a demandé qu'on effectue la
                                vidange
                                moteur et le plein de la Laguna au Garage Blonde, de Bletterans. Ensuite, il a réintégré
                                l'hôtel et
                                y a diné, Ressorti pour rouler à travers la campagne, s'aérer de tout, il est revenu en
                                ville,
                                laissant l'auto à l'emplacement qu'elle occupe encore. Il expose qu'il a alors marché
                                une demi-
                                heure par les rues, contemplant les boutiques décorées, encore éclairées en raison de la
                                quinzaine. Il a bu un alcool dans un débit... Le Bar de l'Est Peut-être. Des jeux vidéo
                                occupent la partie droite. L'établissement regorgeait de jeunes, certains assez
                                échauffés.
                                D'aucuns se disputaient, dont un grand blond frisé, le nez un peu de travers... Les
                                gendarmes
                                "voient". Il se demande même si quelqu'un a prêté attention à lui, y compris la barmaid
                                qui l'a
                                machinalement servi. Alors, il est rentré à l'hôtel par le côté bar. Il pouvait être
                                22H00. <br>
                                La patronne n'est pas en mesure de conforter son témoignage. Pourtant, c'est elle qui
                                s'occupe du café après le diner et elle ne l'a pas vu L'air ennuyé, le postier reconnait
                                qu'effectivement quand il est passé dans la salle, elle ne s'y trouvait pas. Par contre,
                                quatre
                                consommateurs étaient vissés au comptoir en pleine discussion sur la chasse, encore au
                                pastis
                                à cette heure incongrue. Même l'un d'eux avait un chien ! De chasse, évidemment. <br>
                                L'hôtelière confirme. Et celui à l'épagneul, ami des trois autres, n'est arrivé qu'un
                                peu
                                avant dix heures du soir. Jusqu'à la fermeture, vers minuit, elle n'a pas vu ressortir
                                son hôte. Lui,
                                déclare s'être couché après sa toilette et, n'ayant rien de mieux à faire, être resté au
                                lit jusqu'à
                                8H00. La patronne se souvient-elle au moins lui avoir servi son petit-déjeuner une
                                demi-heure
                                plus tard ? Ah ! quand même ! <br>
                                Un point malgré tout : n'a-t-il pas été réveillé en pleine nuit par une demi-douzaine de
                                personnes qui avaient retenu trois chambres et rentraient se coucher après le mariage
                                d'un
                                parent dans la contrée ? Un autre locataire s'est plaint. <br>
                                Il admet qu'il a vaguement entendu du bruit, sans précision d'heure. mais étant logé à
                                côté des W.C. de l'étage, il n'a pas relevé. Courant dans l'hôtellerie de papa. <br>
                                Dans la réalité. il est parti aussitôt après 22H00, par l'arrière. Il a subtilisé le
                                couteau dans
                                la cuisine désertée. un effilé bien tranchant, et a effectué le trajet vers la région
                                melunaise
                                comme programmé par son moi criminel Muni des gants de peau qu'il porte pour conduire.
                                il
                                s'est présenté au pavillon, a ouvert avec sa clef personnelle et a pénétré. dans le
                                noir.
                                bousculant au passage la potiche du hall.. <br>
                                Personne au rez-de-chaussée. Il s'en assure car il sait le canapé transformable du
                                séjour
                                particulièrement douillet. Le siège préféré de Lucille. Leur chambre se tient au
                                premier, "C'est là
                                que... qu'ils..." Car depuis qu'il a franchi la porte de son chez lui, l'idée
                                bouillonne, enfle.
                                démesurée. colossale "ils sont là et elle le trompe Ah La tuer Les tuer ' La délivrance
                                <br>
                                En haut de l'escalier. par la porte entr'ouverte de la chambre, filtre de la lumière...
                                Il en
                                était sûr Alors il se rue et se heurte à sa femme. debout. Il comrnence à l'injurier
                                mais, dès les
                                tout premiers mots, avant même qu'elle n'ait manifesté une quelconque réaction, la
                                pulsion
                                s'avère la plus forte : il plonge la longue lame acérée en plein cœur. <br>
                                Un instant hébété par son acte. il ne songe même pas à frapper une seconde fois du
                                couteau qu'il a retiré, regardant sa Lucille dont les seins fiers se découpent au
                                travers du
                                vaporeux tissu, s'effondrer en portant la main à sa blessure. <br>
                                C'est alors qu'il réalise, qu'il voudrait revenir en arrière, reprendre à la case
                                départ. Si la
                                lumière est allumée. c'est qu'il a, lui, réveillé son épouse en renversant le guéridon
                                de l'entrée.
                                La fenêtre est close. De même les volets. L'amant n'a donc pu s'enfuir. Quant à
                                l'armoire,
                                contrairement aux vaudevilles, elle ne saurait dissimuler quiconque. <br>
                                Et le lit ? Le lit conjugal Encore bordé de l'autre côté. Une seule tête marquée dans un
                                seul des oreillers... Un seul creux sur le bord. "Son bord à elle Elle est tiède et
                                parfumée de
                                sa féminité cette cavité évocatrice d'agréables moments. De si rares mais ineffables
                                moments... <br>
                                "Ah ! L 'imbécile ! L'imbécile Et maintenant elle est morte, là auprès... Morte ! Morte
                                <br>
                                C'est de rage délirante qu'il arrache ce drap plein de diffuses senteurs à elle et qu'il
                                étripe
                                le matelas innocent. Non pour faire croire à la recherche d'un possible magot.
                                Longtemps,
                                agenouillé. il demeure prostré sur cette couche, la tête bourdonnant de pensées
                                contradic-
                                toires. Un moment, il songe à se constituer prisonnier. Puis, quelque chose de mal
                                défini le remet
                                en selle, le fait se relever et l'oblige à entreprendre la mise en scène prévue à son
                                planning. <br>
                                Au fur et à mesure qu'il vide des tiroirs ou bouscule le linge, il s'aperçoit qu'il est
                                incomplètement délivré de sa hantise première. Le doute qui se réinstalle lui permet de
                                continuer, de se défendre si on raccusait... "Oui, parfaire le travail." <br>
                                Après avoir refermé depuis le dehors au seul verrou, il force la porte au moyen d'une
                                pince monseigneur qui lui sert d'arrache-clous et qu'il a récupérée dans son coin
                                atelier. Il la
                                jettera dans un fossé à Fontainebleau. <br>
                                Le retour s'effectue sans encombre, hormis ce que lui considère tout à coup comme le
                                gros pépin. À la radio de la Laguna, au cours du bulletin d'informations, le journaliste
                                rappelle
                                que cette nuit, celle du changement d'heure annuel, impose d'avancer les pendules. <br>
                                Présentement, il roule du côté de Beaune. Déjà qu'il ne se situe plus dans les temps
                                escomptés. Il en a perdu pas mal à balancer sur la conduite à tenir. Il s'imaginait de
                                retour à
                                Bletterans pour 5H00, l'heure calme par excellence. Et du fait de ce contretemps, il
                                sera peut-
                                être 6H20 ou 6H30 quand il y arrivera, le moment où des gens commencent à s'éveiller,
                                ouvrir
                                des contrevents, se hâter dans les rues vers une tâche matinale. "Pas beaucoup, certes,
                                surtout
                                un dimanche, mais il suffit d'un lève-tôt ! " <br>
                                Alors, malgré la pluie qui se met de la partie, il accélère, prend des virages tangents,
                                traverse villes et villages en trombe... Bletterans, enfin. 6H12 à l'heure nouvelle !
                                Pourtant,
                                quand il gare la voiture dans le créneau encore vacant, personne ni rien de Ce qu'il a
                                appréhendé ne se manifeste. Quelqu'un, cependant, s'active à son travail non loin : le
                                boulanger. Une suave odeur de pain en cours de cuisson zigzague vers ses narines,
                                réveillant
                                de vieux souvenirs... Mais le fournil donne sur l'arrière. <br>
                                Au passage, il replace dans son râtelier le couteau homicide qu'il a nettoyé en route
                                dans
                                l'herbe mouillée. <br>
                                Il n'empêche que la hantise du grain de sable de Vheure d'été qui s'est glissé dans sa
                                machine pourtant huilée, le poursuit encore quand il se fourre sous les draps. <br>
                                L'enquête s'oriente sur la voiture. Le gendarme qui officie auprès s'accroche. On doit
                                Commettre assez peu de crimes par là. Et pour une fois qu'on en tient peut-être un...
                                <br>
                                Le garagiste Blonde qui a fait le plein et la vidange, enfin son commis, a collé une
                                étiquette avec la date. le kilométrage et les opérations effectuées le Samedi tantôt. On
                                y lit un
                                nombre : "25.737". Jacques avait remarqué la vignette et songé à rarracher- Puis il a
                                pensé
                                qu'elle pouvait servir ses desseins. Au moment ou il a été établi. le papier portait :
                                "25.137".
                                Avec un bic de la couleur de celui de l'arpète. il lui a été aisé de transformer le "1 "
                                en avec
                                celui final comme modèle. se donnant ainsi un battement de six cents kilomètres pour
                                "effacer"
                                son équipée à venir. Ouand le gendarme "examine. le compteur de la Laguna indique
                                "25.772". Soit 35 kilomètres de plus explicables par la promenade nocturne invoquée par
                                le
                                postier et qu'il n'a jamais effectuée. Il avait besoin de rouler, comme ça, leur
                                avoue-t-il. Aussi de
                                réfléchir aux conséquences de la mort inéluctable de sa mère. Sans ravoir spécialement
                                cherché, il s'est retrouvé du côté de Sellières. tout près de son village natal... Dans
                                désarroi, une sorte de pèlerinage. Un coup d'œil sur la carte justifie la différence. De
                                plus,
                                réservoir est pratiquement plein et la seule pompe ouverte gite à plus de 30 kilornètres
                                Contacté, le responsable est formel : il n'a pas servi de Laguna bleue ni personne
                                exceptionnellement il a fermé ce soir-là. Plus rien qui colle : Jacques ressort
                                innocent. <br>
                                Pour le plein du retour — il lui fallait bien en effectuer un — il avait d'abord songé
                                ravitailler sur l'autoroute. Plus anonyme. Mais même une dernière station du côté de
                                Beaune se
                                situerait encore trop loin de Bletterans pour expliquer le manque en réservoir dans le
                                cas.
                                où l'on vérifierait. Sans préjudice du risque d'étre reconnu par un pompiste moins
                                endormi qt.*
                                d'autres. La solution existe, très simple. Chez lui, afin de n'être pas pris au dépourvu
                                en raisor
                                de son service à commencer ou à quitter à des heures impossibles, il dispose de deux
                                de réserve. Pour le voyage. il estime sa consommation, s'il n'a pas le pied trop lourd
                                su
                                l'autoroute. à moins de cinquante litres. Bien pleins, les deux récipients lui en
                                foumiror
                                quarante-cinq. Avant de fracasser sa porte. à Melun, il a chargé les bidons. Dans un
                                bois,
                                de Mervans, il en a complété le réservoir avant de les dissimuler dans un fourré. <br>
                                Et personne aux abords de l'hôtel ou de la boulangerie n'a remarqué le démarrage puis I
                                retour de la Renault. À cause des stops du carrefour, chacun depuis longtemps dans
                                parages ne prête plus d'attention à ces bruits. <br>
                                Ainsi, tout concourt à innocenter l'ambulant. Tout... s'il avait le grain de sable. Le
                                pas l'heure bricolée par les services officiels ni le boulanger occupé à enfourner mais
                                son gam
                                La veille au soir, avant d'aller au lit après sa séance de télé, peu avant 22H00, il a
                                Yait
                                comédie", raconte par la suite la mère, pour récupérer sa balle mousse abandonnée dans
                                la
                                Une méchante balle de rien, un prétexte qui en vaut un autre qu'il a trouvé pour ne pas
                                coucher tout de suite. Comme bon nombre d'enfants. Mais il en a été pour ses simagrées
                                le caniveau, il n'a jamais pu la décoincer de sous la roue arrière droite de la Laguna
                                bleue. <br>
                                Il est là, ce matin, Antoine, 7 ans, à tourner autour du gendarme qu'il connaît bien,
                                inspecte la voiture. Il lui confie le "grand événement" de sa balle immobilisée. <br>
                                — Va jouer plus loin, Tonin ! Je suis occupé. Et touche à rien à cause des indices !
                                Il est perplexe, le militaire au képi. Il subodore qu'il y a quelque chose à tirer du
                                véh•
                                quelque chose qui apporterait une amorce de solution à son embarras. Mais quoi ?
                                vraiment le moment de se laisser embobiner ou distraire par le petit Antoine ! Des
                                doutes,
                                en est venu à la vue de la berline à son arrivée. Il a beaucoup plu en fin de nuit et
                                elle lus
                                apparue quasiment sèche alors que les autres. auprès, luisaient encore de gouttelettes.
                                F
                                être une question de qualité, de grain de peinture... Impossible de fonder une accusatio
                                dessus De même, à l'emplacement de la Laguna, le goudron paraissait plus mouillé que
                                les autres. Une illusion ? Difficile à définir. Dautant que depuis qu'il s'affaire comme
                                un
                                sortant d'hibernation autour d'une ruche, un franc soleil s'évertue à tout remettre en
                                cause. <br>
                                Mais il y a Antoine, le gamin tenace, qui ignore ce que sont des indices et qui insiste
                                — Tu sais, Monsieur Gendarme, la balle, je l'ai eue quand même !
                                Il l'a récupérée ce matin. Parce qu'elle se trouvait décoincée...
                                — Même que la voiture elle était tournée dans l'autre sens Alors...
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal24 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Le grain de sable</h3>
                <h4 class="auteurnouvelle">par Roger Martini</h4>
                <p>LBien qu'il s'y soit rendu à peine deux semaines auparavant, Jacques reprend la route
                    de
                    la Franche-Comté en ce samedi matin de la fin mars. L'Autoroute du Soleil. L'Yonne. La Côte
                    d'Or pittoresque. Des filaments de brume accrochent çà et là une haie, escaladent une butte
                    verdoyante, avec, quand le tracé le permet, la perspective lointaine d'évasions passionnées. Le
                    jour se lève. Les taches plus sombres de bois touffus se démarquent. On imagine tout un
                    monde...</p>
                <div class="tourbutton">
                    <button @click="showModal24 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2007 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal25 }">
                    <div class="modal-background" @click="showModal25 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">L'effet Mer</h3>
                            <p><i>Marie-Jeanne s'ennuie. Elle n'a jamais aimé la mer trop plate trop uniforme. Il la lui
                                    faut déchaînée, en tempête, en fureur, quand les vagues explosent sur les rocs
                                    qu'elles
                                    façonnent avec la rage et la patience d'un sculpteur. <br>
                                    Jean s'ennuie. Elle a toujours aimé la mer. Celle qui se retire au loin et qui
                                    revient au
                                    galop, Celle qui mugit entre les falaises, celle qui grimpe à l'assaut des phares.
                                    Mais
                                    cette plage ! mortellement lassante pour ses presque dix-huit ans, pour ses humeurs
                                    marines, pour son désir d'un goût de sel sur la bouche... </i> <br>
                                <br>
                                Cette pauvre vieille qui déambule dans les couloirs de la maison de retraite me ferait
                                presque pitié. Comment a-t-on pu la laisser s'enfuir de l'unité spécialisée où elle
                                était
                                bouclée à double tour avec ses semblables, tous et toutes — essentiellement toutes —
                                atteints de la maladie d'Alzheimer, ou de « troubles apparentés », pour parler comme
                                les gériatres ? <br>
                                Il faudra que j'en touche un mot à la directrice. Ça n'est pas parce qu'on est à l'aide
                                sociale qu'on n'a pas droit à sa tranquillité ! <br>
                                Pourquoi s'est-elle arrêtée devant moi tout à l'heure, pourquoi m'a-t-elle regardé
                                comme une bête curieuse, sans rien dire, jusqu'à ce que l'aide-soignante vienne la
                                chercher ? Gentiment du reste, il paraît qu'il ne faut pas les brusquer. Le personnel
                                sait
                                y faire. Allons, Madame Fromentin, venez, vous allez attraper froid. Il est vrai qu'elle
                                était en chemise de nuit. Oh, ces jambes, ces bras décharnés ! <br>
                                Elle s'est laissé emmener, docile. Heureusement. J'étais à deux doigts de lui demander
                                si elle voulait mon portrait. Peut-être de la claquer. De lui filer un coup de fauteuil
                                dans les tibias. <br>
                                J'aurais voulu la repousser, prendre la fuite. Mais ça a beau avoir l'air de ne pas
                                tenir
                                sur ses jambes, ces vieilles, c'est plus solide qu'on ne croit, c'est peut-être moi qui
                                serais tombé. <br>
                                Pas drôle d'être paraplégique. Mais à tout prendre, j'aime mieux avoir les jambes en
                                panne que la tête. <br>
                                <i>Ces vacances, sous un soleil imperturbable, avec ses baignades inévitables et son
                                    sable omniprésent, commencent à tirer à Marie-Jeanne des soupirs et des
                                    bâillements. Ses presque dix-sept ans supportent mal les séances répétées de volley-
                                    ball, alternant avec des baignades dans une eau toujours bleue, toujours calme,
                                    toujours claire. Et ce maillot ridicule ! <br>
                                    Jean songe à cette année passée, à la passion qu'il s'est soudain découverte pour la
                                    sculpture sur bois, où il s'est jeté à corps perdu, faisant voler le copeau de chêne
                                    ou
                                    d'orme blond pour que s'y réveille la forme endormie. Pour qu'en de vagues formes se
                                    forme la vague.</i><br>
                                Elle est revenue hier. Cette unité spécialisée est décidément une passoire.
                                Pourquoi m'a-t-elle regardé avec autant d'insistance ? Que cherchait-elle au fond de
                                mes yeux ? Qu'y avait-il au fond des siens, quelle douleur qui ne se décidait pas à
                                crever à la surface ? Quel secret porte-t-elle derrière son masque ? <br>
                                Irs yeux ont un langage que je connais mal. Quand la parole se retire et qu'il ne reste
                                que la dilatation de la pupille pour se faire comprendre, quand on a tant de chpses à
                                dire qui s'arrêtent au bord des cils, quand les phrases s'écoulent avec les larmes quel
                                dictionnaire me permettrait de comprendre ce qu'elle veut peut-être me dire ? Et que
                                je n'ai pas envie d'entendre déjà bien assez MES problèmes. <br>
                                Il faudra décidément que j'en parle à la directrice. Elle a beau être un peu braque,
                                elle
                                m'a à la bonne, depuis que je lui ai fait cadeau d'une petite vierge sculptée dans un
                                morceau de bois, qui parait-il est d'un très bel effet dans la chapelle. Elle dit ça
                                pour
                                me faire plaisir, je ne sais pas, moi je ne pousse jamais mes roues par là. Le bon Dieu
                                ne m'a fait de cadeau, je ne lui dois aucun merci. <br>
                                <i>Quel jour sommes-nous ? Pas une algue ne vient s'enrouler autour de jambes,
                                    pas une seule de ces délicieuses frayeurs que provoque ce contact visqueux et
                                    enrobant qui vous met le frisson jusqu'au bas du Plus la moindre petite
                                    tempête, si ce n'est les dérisoires disputes de quelques vieilles pour un coin de
                                    serviette, un coin de table. Un morceau de tarte. <br>
                                    Quel jour sommes-nous ? Aujourdhui ou hier, qu'est-ce que ça change ? Pas le plus
                                    petit naufrage en perspective, si ce celui de ces semaines qui traînent en
                                    longueur et en mélancolie — et pas même un flirt à esquisser dans œt environnement
                                    de vieilles trop ridées et de ventres trop flasques. L'océan va peut-être mourir ?
                                </i><br>
                                <br>
                                Elle est revenue, plus décharnée, plus égarée que jamais. Sa chemise de nuit baille un
                                peu plus sur une peau par le flux et le reflux des ans. La bretelle tombe et
                                révèle une épaule sur laquelle <br>
                                Sur laquelle... <br>
                                <i>Marie-Jeanne, oh ma Marie-Jeanne, ma douce, mon petit enfant, mon
                                    amour dim été, je me souviens, ta chair offerte telle un abricot chauffé par le
                                    soleil, cette tache lie-de-vin si légère, dont j'aimais tant suivre les contours
                                    sur ton épaule nue, lorsque tombait la bretelle de ton maillot, je mordais
                                    dans ta chair et tu criais si joliment de douleur. <br>
                                    Marie, ma Jeanne, regarde-moi, est-ce que tu te souviens, c'est moi Jean ton
                                    Jean, mais de quoi peut-on se souvenir quand tout fout le camp, qu'est-ce que
                                    tu cherches au fond de mes yeux, Marie ma Jeanne, ta bouche se souvient-
                                    elle encore de nos baisers, ton ventre se souvient-il de la rencontre de nos
                                    ventres comme s'en souvient mon corps, mon corps paraplégique, ma Marie,
                                    ma Jeanne, à quoi pourrions-nous nous raccrocher tous les deux pour
                                    arrêter ce fil qui file et se défile, dis-moi, qu'est-ce qui reste de nous dans ton
                                    souvenir ravagé, est-ce que toute notre histoire s'est réfugiée dans tes seuls
                                    yeux, Jeanne ma Marie tu pleures ou est-ce parce que la lumière est
                                    douloureuse à tes yeux éteints, comment est-ce que je pourrais te faire
                                    revenir, Marie-Jeanne tu faisais vibrer cette plage où sévissaient les vieilles
                                    avachies en villégiadictature <br></i>
                                Tu sais, j'ai continué à sculpter, j'ai donné une statuette à la directrice, elle a
                                l'air de lui plaire, si elle savait qu'en creusant le bois je pense encore à nos
                                caresses échangées, d'ailleurs je n'ai jamais vraiment su le don qui était dans mes
                                mains que le jour où elles ont suivi la courbe de tes reins de tes fait
                                des vierges rien que des vierges comme toi comme la pureté de ton amour
                                donné, j'ai sculpté, rapp:lle-toi mes dessins sur le sable Marie-Jeanne Marie-
                                Jeanne je mimivre encore de ton nom, ma vieille si laide si belle, ma petite
                                enfant, tu me regardes, tu comrnemxzs à te souvenir, tu commences n'est-ce
                                pas ? <br>
                                Rapelle toi, la mer montait lentement à l'assaut du sable. Tu étais assise un
                                peu à l'écart sur un rocher. Tu venais de te baigner et les perles ruisselaient
                                sur ton corps. <br>
                                Le soleil faisait brûler notre ennui, je ne te connaissais pas, tu ne me
                                connaissais pas, j'avais dix-huit ans toi dix-sept. J'avais creusé le sable
                                humide et sculpté la forme d'une femme nue aux pieds de laquelle se tordait
                                un dragon dans les affres de l'agonie. Tu as regardé avec un semblant
                                d'intérêt — il faut dire que tout ce qui pouvait casser cette routine où tout
                                s'engluait — tu t'es levée et mes yeux t'ont aimée aussitôt, tu t'es approchée et
                                mon corps entier t'a désirée, tu t'es penchée et ton geste m'a enchaîné. <br>
                                La mer montait et déjà les vaques se tordaient autour du dragon dont elles
                                suivaient les volutes en déposant leur écume. Tu as écrit dans le sable avec
                                ton index gauche car tu es gauchère, je n'ai oublié, « le triomphe de l'effet
                                mer ». Tu as ri, j'ai ri, nos rires se sont envolés nos deux rires ont réveillé
                                tous œs vieux toutes ces vieilles, il y avait déjà là tant de tendre complicité,
                                l'éphémère, la mer, le triomphe de la mer, les mots se mêlent aux Lugues aux
                                rires aux algues aux larmes, oh ton rire Marie ma Jeanne ma femme dun
                                Tu es revenue me voir mon amour crucifié ? de si loin de si longtemps ?
                                Viens, écris sur ma main ces mots que naguère tu as tracés dans le sable. Tes
                                doigts suivent le contour du serpent, la vague dépose de vieux morceaux de
                                bois usés par la mer ce sont tes doigts, tes doigts si fins sur ma peau, tes
                                doigts sauvnt-ils encore caresser, je Loudrais tant que tes doigts ta peau ta
                                bouche reviennent comme avant si seulement. <br>
                                Regarde, Marie-Jeanne, c'est moi ton Jean de l'été, ce sont mes doigts qui
                                dessinent sur ta joue parcheminée ridée, tu les sens, j'écris sur tes joues
                                feuilles mortes, sur tes mains branches sèches, j'écris « le triomphe de
                                l'éphémère le triomphe de la txague qui mange le sable, du temps qui
                                mange nos corps, si vite et il ne reste à la fin qu'un peu d'écume. <br>
                                Essaie de te rappeler ma Jeanne ma Marie, je veux voir briller une étincelle
                                dans tes yeux, qu'ils me disent que tu te souviens, un peu beaucoup de notre
                                passion, de notre été, juillet fini tu es repartie je ne t'ai jamais revue... <br>
                                Tu as écrit sur le sable et tu m'as regardé et tu as ri et ton rire était l'aurore.
                                Qu'est-ce qui ma pris je ne sais pas, j'ai pris cette main dorée par le soleil
                                dorée par le sable et j'ai écrit dans ta paume je vous aime, donne-moi ta
                                main, sens mon index dans ta paume, j'écris je t'aime. Nos mains ont
                                tremblé, ici et là-bas elles ont tremblé, n'est-ce pas le même tremblement, à
                                soixante-dix années d'intervalle, soixante-dix... <br>
                                Souviens-toi nous nous sommes revus tous les jours suivants le soleil ne
                                décollait pas de son zénith, nous nous sommes embrassés derrière un parasol
                                abri dérisoire, laisse-moi toucher tes lèvres est-ce qu'elles ont encore leur
                                goût de sel ? Je n'ose plus, il n 'y a plus de parasol, plus d'indulgence pour nos
                                vieilles adolescences, les vieux ont-ils le droit de s'embrasser, de se retrouver,
                                sur le pont entre nos âges on danse plus, je suis paraplégique de toutes
                                façons à quoi ça servirait, nos lèvres sont des feuilles mortes, à quoi ça
                                servirait ? <br>
                                Souviens-toi, un jour où tes parents étaient partis pour une excursion en te
                                laissant seule après tout tu avais dix-sept ans, ils te faisaient confiance. Je
                                t'avais dit la confiance est le plus beau cadeau que des parents puissent faire
                                à une fille lorsqu'elle est en âge de voler de ses propres ailes. Tu m'as
                                répondu et les garçons de leur propre zèle ? Nous nous sommes retrouvés
                                dans ta chambre, dans ton lit, dans notre hâte ma vague s'est précipitée à
                                l'assaut de ta plage, mes mains à la rencontre de ton corps, nous avons roulé
                                l'un dans l'autre cvrnme des galets emportés par la mer. <br>
                                Nos silences se sont emmêlés algues complices, le silence Marie-Jeanne qui
                                nous unissait, qui aujourdhui t'éloigne, se peut-il que les choses aient tant
                                changé ? Est-ce que ça compte les corps quand on s'aime, est-ce que ça
                                compte les années, est-ce que ça compte, ces chairs fripées qui nous
                                détournent d'être encore amants, ces règlements qui nous séparent, ces
                                verrous qui renferment, quand je t'ai retrouvée, quand il me sufit de fermer
                                les yeux, ta peau si douce, il sufit de fermer les yeux. <br>
                                Souviens-toi, ce fut si bref et pourtant si fort, une seule après-midi, une si
                                longue après-midi qui dure depuis soixante et dix années, qui ne s'est jamais
                                vraiment achevée, le soleil est resté à son zénith et te revoilà Marie ma
                                Jeanne, ton regard oh ton regard, tes yeux bleus, est-ce que ça compte
                                soixante dix ans quand le soleil s'est arrêté dans le ciel, quand on est resté
                                accroché au même jour au même après-midi, au même soleil blotti au creux
                                de nos ventres réunis ? <br>
                                Suis-moi Marie-Jeanne, plonge tes yeux dans les miens, accroche ton regard
                                à mon regard, tu vas voir je vais te sortir de là, te sortir de ton oubli, te
                                ramener chez toi, rappelle-toi comme ton lit sentait la lavande, comme il s'est
                                imprégné de l'odeur de notre amour, parfum d'iomme et de femme emmêlés,
                                les odeurs, pense aux odeurs, aux parfums qui éveillent la mémoire, ne sens-
                                tu pas leur vague monter à l'assaut de cette maison de retraite, de ses murs
                                imprégnés d'efluves de cuisine, de corps usés, incontinents. Un continent <br>
                                Oui, il y a en nous de l'inexploré, je suis encore explorateur, explorateur avec
                                les mots puisque nos corps nous trahissent, tu es encore voyageuse,
                                voyageuse avec tes yeux puisque la tête te manque. <br>
                                oi à mes mots Marie-Jeanne. Accroche-toi à mon espoir, accroche-
                                toi à ce passé que je creuse avec mes mots, tiens-moi fort, je t'emmène plus
                                vite que la lumière, plus vite que la pensée, plus haut que le soleil.
                                Souviens-toi, on s'était promis juré qu'on se reverrait, on avait échangé nos
                                photos, est-ce que tu as gardé ma photo Marie-Jeanne, moi j'ai toujours la
                                tienne, elle est là regarde-la, tu es si belle, si belle sur la photo, si belle en
                                vrai, elle est un peu jaunie, un peu passée, un peu estompée, mais est-ce que
                                nos vies nos peaux nos corps ne sont pas aussi un peu jaunis un peu passés
                                un peu estompés, les photos nous sont restées fidèles comme ma mémoire est
                                restée fidèle ; et toi qu'as-tu fait de ma photo, est-ce qu'il earrive encore de la
                                regarder comme je regarde la tienne, est-ce qu'il t'arrive de pleurer comme je
                                pleure en pensant aux barrières d'oubli de distance de honte trop tôt érigées
                                entre nos amours ? <br>
                                Oui, de honte. Je ne t'ai jamais revue, Marieaeanne, malgré ma promesse. Il
                                y a eu l'accident de moto, c'était une Gnome et Rhône. J'ai perdu l'usage de
                                mes jambes. Jamais plus je n'ai connu de femme. Je n'ai jamais trouvé le
                                courage de me montrer à toi dans ce fauteuil roulant. <br>
                                Tu es mon seul souvenir et tu scintilles en moi comme un diamant solitaire. <br>
                                Qu'est-ce qui nous reste Marie-Jeanne si tu n'as plus de mémoire, si je n'ai
                                plus de désir ? qu'est-ce qui nous reste sinon les mots, les phrases que je te
                                ressasse, que je te murmure, réveille-toi Marie-Jeanne c'est l'heure d'aller
                                enfin à la plage rejoindre ton amoureux d'un été, il t'attend depuis si
                                longtemps réveille-toi. <br>
                                Que nous reste-t-il sans mémoire sans désir, hormis les mots les phrases ? <br>
                                Je crois, oh je crois qu'avec mes mots, les mots dont je nous saoule dont je
                                nous remplis qui se bousculent qui oublient points et virgules avec la marée
                                montante des mots, la tempête des mots je pourrai faire resurgir le passé,
                                non ta mémoire n'est pas morte, elle est un coquillage enfoui dans le sable à
                                marée basse. Je vais faire monter la mer, les vagues des phrases, enccyre et
                                encore, et ta mémoire va sortir en sentant le parfum du sel, la fraîcheur de
                                l'eau, le triomphe de l'effet mer. <br>
                                <i>Qu'est-ce que vous faites là, Madame Fromentin, encore à embêter ce pauvre
                                    Monsieur
                                    Loderlot. Excusez-la, Monsieur lordelot, elle n'a plus sa tête, vous savez, je
                                    ne sais pas
                                    ce qu'elle a après vous, il va falloir qu'on l'enferme, elle n'arrête pas de
                                    crier, elle nous
                                    rendra folles, on ne peut quand même pas lui donner tout le temps des calmants,
                                    on
                                    dirait qu'elle vous cherche, comme si elle vous connaissait. Allons, Madame
                                    Fromentin, soyez raisonnable et rentrez chez vous, en plus c'est l'heure de vous
                                    coucher, heureusement elle n'est pas méchante, j'espère qu'elle ne vous ennuie
                                    pas
                                    Monsieur Lordelot, on dirait qu'elle vous connaît. Vous la connaissez ? <br>
                                    <i>Ne m'enlevez pas Marie-Jeanne, s'il vous plaît mademoiselle vous ne
                                        pouvez pas savoir. Dites-moi qu'elle reviendra, non elle ne me dérange
                                        pas, au contraire, ses yeux disent qu'elle me comprend, et vous
                                        mademoiselle vous comprenez ? Nous ne sommes que deux pauvres
                                        vieux qui essaient de réparer un pont de lianes, au-dessus (toute une vie qui
                                        les a séparés. Juste deux pauvres vieux, laissez-nous s'il vous plaît
                                        mademoiselle. Je vOUs en supplie... </i> <br>
                                    Qu'est-ce que vous voulez, Madame Fromentin ? Qu'est-ce que vous essayez de me
                                    montrer ? Vous voulez votre sac à main ? Qu'est-ce que vous voulez en faire ?
                                    Allons,
                                    ne criez pas ne vous agitez pas, le voilà votre sac ! Cest cette photo que vous
                                    voulez, je
                                    ne l'ai jamais vue, c'est vrai qu'il n'y a que huit jours que vous êtes chez
                                    nous, et déjà à
                                    vous sauver à tout bout de champ. <br>
                                    C'est votre fils, là ? non votre petit-fils ? Un bel adolescent, quel âge
                                    a-t-il, dix-huit,
                                    dix-neuf ans ? Votre petit-fils non, vous dites non ce n'est pas votre
                                    petit-fils ? Mais
                                    non, je suis bête, la photo est trop vieille, du noir et blanc on n'en fait
                                    plus, et ce format
                                    et cette bordure, et cette teinte sépcia, ça a au moins cinquante ans cette
                                    affaire-là, non
                                    peut-être bien que ça date d'avant la guerre. C'est ça, Madame Fromentin ?
                                    Alors, qui
                                    est-ce ? Non, bien sûr, vous ne pouvez pas me le dire, vous ne savez plus ? Il a
                                    des airs
                                    de Monsieur lordelot. La même bouche, les mêmes yeux rieurs, c'est vrai qu'on ne
                                    distingue pas trop bien mais on dirait bien que c'est lui. Qu'est-ce que vous
                                    dites,
                                    Madame Fromentin, vous voulez me dire quelque chose ? Jean, est ça, vous avez
                                    dit
                                    Jean ? Fst-ce que ce n'est pas le prénom de Monsieur Lordelot ? <br>
                                    Vous souriez, Madame Fromentin ? Je rêve, ou vous souriez ? Dirait-on pas que
                                    vous
                                    voilà en conversation avec les anges ? <br>
                                    Allons bon, vous pleurez maintenant ? C'est bien Monsieur lordelot, sur la
                                    photo,
                                    non ? A mieux regarder, je suis sûre que c'est lui. Mais non, arrêtez de
                                    pleurer,
                                    Madame Fromentin, vous permettez que je vous appelle Marie-Jeanne, demain je
                                    vous
                                    conduirai, on retournera voir Monsieur Lordelot. Mais oui, vous pourrez rester
                                    aussi
                                    longtemps que vous voudrez Je ne sais pas quels sont les souvenirs que vous avez
                                    à
                                    partager, mais c'est sûrement très important. Vous souriez maintenant, j'aime
                                    mieux
                                    quand vous souriez, vous avez un très beau sourire, Marie-Jeanne, on a déjà dû
                                    vous le
                                    Qu'est-ce que vous voulez me montrer, Marie-Jeanne ? Oui, il y a quelque chose
                                    d'écrit
                                    au dos de la photo. Je vois bien, mais c'est tout petit, presque effacé, et de
                                    cette
                                    écriture qu'on trouve sur les vieilles cartes postales, comment voulez-vous que
                                    je lise
                                    ça ? Attendez que je mette mes lunettes, eh oui, j'en ai déjà besoin, nous non
                                    plus on ne
                                    rajeunit pas. <br>
                                    "Je voudrais tant que nous gardions, toi et moi éternellement jeunes, afin de
                                    pouvoir
                                    nous retrouver le jour venus, tels que nous étions la première fois, inchangés"
                                    <br>
                                    C'est très beau, Marie-Jeanne, très beau. Il devait rudement vous aimer celui
                                    qui a
                                    écrit cela. Peut-être même qu'il vous aime encore ? Peut-être qu'il vous attend
                                    quelque
                                    part, juste à côté. passé n'est jamais aussi loin qu'on le croit, Marie-Jeanne.
                                    Mais
                                    n'allez pas pourtant vous perdre dans les couloirs. Venez, je vais vous
                                    accompagner,
                                    donnez-moi votre bras.</i>
                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal25 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : L'effet Mer</h3>
                <h4 class="auteurnouvelle">par Jean-Claude Guillaume</h4>
                <p>Marie-Jeanne s'ennuie. Elle n'a jamais aimé la mer trop plate trop uniforme. Il la lui
                    faut déchaînée, en tempête, en fureur, quand les vagues explosent sur les rocs qu'elles
                    façonnent avec la rage et la patience d'un sculpteur.
                    Jean s'ennuie. n a toujours aimé la mer. Celle qui se retire au loin et qui revient au
                    galop, Celle qui mugit entre les falaises, celle qui grimpe à l'assaut des phares. Mais
                    cette...
                </p>
                <div class="tourbutton">
                    <button @click="showModal25 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal26 }">
                    <div class="modal-background" @click="showModal26 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Un pont a bascule</h3>
                            <p>La lumière du jour baissait insensiblement et l'obscurité enveloppait la chaussée.
                                Claquement de portière. Une fine silhouette sortit de l'ombre. Mini-jupe de gaz laiteuse
                                sur des
                                jambes interminables lacées de cuissardes blanches, la frêle créature faisait ruisseler
                                ses cheveux
                                roux sur un trench jaune pâle. Sous le bras droit, un carton à dessin qu'elle serrait
                                sous l'aisselle et un sac en bandoulière l'épaule gauche. Elle regarda rapidement autour
                                d'elle,
                                ajusta
                                son col par une sorte de réflexe naturel et suivit l'avenue Ledru Rollin où des villas
                                des années
                                vingt en
                                pierres jaunes dissimulaient leurs bow-windows derrière de petits murets surmontés de
                                grilles en
                                fer forgé. D'épaisses haies de troènes camouflaient le rez-de-chaussée de ces demeures
                                bourgeoises et des ombres furtives glissaient parfois derrière des baies vitrés. <br>

                                Diane avait garé sa Twingo bleue dans une ruelle adjacente mal éclairé en disant
                                qu'ellee
                                n'aimerait habiter dans cette venelle sombre où la ville semblait économiser l'éclairage
                                public alors que les grandes avenues étaient illuminées à profusion. Arrivée à hauteur
                                d'un
                                piétons, elle ralentit le pas. Elle savait qu'il fallait appuyer sur un gros bouton
                                rouge
                                pour immobiliser le flot des véhicules avant de traverser. Elle préféra cependant
                                attendre
                                en disant que la meute des automobiles lancées à toute allure finirait bien
                                s'interrompre et
                                qu'elle profiterait de cette accalmie pour aller de l'autre côté. Mais une jeune femme,
                                sans doute
                                un peu plus pressée actionna le mécanistne si bien qu'elle profita de l'accalmie pour
                                rejoindre
                                le trottoir opposé. Elle fit encore quelques mètres et nota que dans la villa du
                                profesœur
                                toutes les lumières étaient éteintes. <br>
                                Diane étudiait les Beaux-Arts et il lui arrivait d' être invitée venir à venir passer
                                une soirée chez
                                cet
                                homme d'une cinquantaine d'années en compagnie de plusieurs autres étudiants. C'étaient
                                alors
                                des discussions interminables à pmpos de des tableaux chez Vermeer ou de
                                l'aspect moelleux, presque onctueux des tissus Fints par le grand maitre de Delft.
                                D'autres fois, on
                                évoquait l'influence des ready-mades de Marcel Duchamp sur l'œuvre de Rauschenberg ou la
                                fascination du trivial dans le Pop Art. Le professeur Spet écoutait tous les avis et
                                s'efforçait
                                ensuite de faire la synthèse des idées énoncées. Il s'exprimait de façon sentencieuse et
                                semblait
                                retrouver le ton doctoral un peu compassé qu'il adoptait dans l'amphithéâtre où il
                                dispensait des
                                cours d'Histoire de l'Art. Ce ton solennel et empathique étonnait toujours un peu Diane
                                car, en
                                aparté, le même homme savait se montrer simple et chaleureux- A côté de ses fonctions
                                d'enseignant, il exerçait également celles de conseiller auprès d'un marchand de
                                tableaux et, à
                                ce titre, il était entré en possession d'une huile de Vincent Van Gogh, « Le pont
                                Langlois à
                                Arles ». Personne ne savait comment il avait réussi à l' acquérir, car il restait
                                toujours vague dans
                                ses explications lorsqu'un hôte un peu plus curieux désirait en savoir davantage.<br>
                                « Je puis vous affirmer qu'il s'agit d'un original ! » déclarait-il alors. <br>
                                L'œuvre trônait au salon, au-dessus d'un guéridon Louis Philippe. <br>
                                Diane rêvait devant cette peinture. Elle avait lu dans un ouvrage de vulgarisation que
                                « dans le traitement du motif du pont, Van Gogh avait poursuivi sa recherche sur la
                                transparence
                                du ciel, de l'eau... » Le mot transparence la faisait sourire quand elle songeait à
                                l'opacité des
                                pratiques qui régnaient dans les transactions entre marchand d'art L'article précisait
                                également
                                « qu'il existait quatre versions du pont de Langlois... » C'est donc à quatre reprises
                                quee Sujet
                                avait séduit l'artiste et lui avait permis de traduire les jeux de lumière à différents
                                moments la
                                journée.
                                <br>
                                Les cote des de Vincent Van Gog la laissait songeuse. Elle déduisit qu'à l'époque
                                actuelle la circulation des oeuvres d'art générait des bénéfices substantiels pour un
                                trop petit
                                nombre d'individus... Pour quelle raison ne tirerait-elle pas profit son tour de ce
                                marché
                                lucratif ? Ne fallait-il en quelque sorte accélérer la rotation des toiles célèbres dans
                                le milieu
                                fermé des fortunes et, au passage, prélever sa quote-part ? Délester les uns pour
                                approvisionner les autres lui semblait une devise acceptable.
                                <br>
                                Diane connaissait les habitudes du professeur Spet et savait que le jeudi il se rendait
                                régulièrement à l'opéra, accompagné de son épouse. Elle n'ignorait pas moins quE
                                l'employée de
                                maison était en congé ce jour-là. Quant aux voisins, ils allaient et venaient sans se
                                souvier de
                                leur entourage. <br>
                                Elle connaissait parfaitement l'emplacement de la centrale d'alarme. Dans une niche du
                                couloir
                                d'entrée, derrière une sorte de moucharabieh. Elle savait aussi comment la déconnecter.
                                Remplacer ensuite l'œuvre originale par une copie lui paraissait une opération sans
                                difficulté
                                majeure. La disposition des pièces ne lui était pas inconnue et elle aurait été capable
                                de s'y
                                déplacer dans le noir.
                                <br>
                                Le profeseur Sirt était certes un grand amateur d'art, mais à diverses reprises, il
                                avait avoué
                                ne pas etre un spécialiste de Van Gogh. Dieu merci, le temps jouerait pour elle ! Diane
                                était
                                persuadée qu'il ne s'aperceverait pas immédiatement de la substitution, cela lui
                                laissait une marge
                                suffisante pour placer la marchandise. Autre avantage, il existait quatre versions du «
                                pont de
                                Langlois » ! Voilà de quoi compliquer un peu plus les recherches. Après la découverte de
                                la
                                supercherie, la police mènerait son enquête, les experts procéderaient à de multiples
                                vérifications,
                                établiraient des rapports sophistiqués et repousseraient sans cesse les délais pour
                                remettre leurs
                                conclusions. Des semaines s'écouleraient, des mois peut-être... On aurait largement le
                                temps
                                d'oublier l'épisode du vol. <br>
                                Pour ouvrir le portillon, il suffisait à Diane de passer le bras entre les barreaux afin
                                d'appuyer
                                sur un bouton situé à l'arrière du pilier. Elle effectua ce geste en s'assurant tout de
                                même de
                                l'absence de tout témoin. Le carton à dessin faillit lui échapper tandis qu'elle se
                                contorsionnait,
                                mais elle le rattrapa au demier moment. Elle entra, referma derrière elle la petite
                                porte qui émit
                                un grincement strident et fit le tour de la demeure pout s'assurer de l'absence de tout
                                occupant.
                                Le gravier crissait sous ses pas. Surpris par le bruit, un chat en maraude s'enfuit
                                presque entre
                                ses jambes, ce qui lui causa une vive frayeur. Elle s'arrêta un instant avant de monter
                                les marches
                                du perron, et après un bref regard circulaire, composa le code sur le portier
                                électronique comme
                                elle l'avait vu faire par le professeur un soir qu'elle était venue à son domicile en
                                même temps
                                que lui. Elle appuya sur la poignée, la porte s'ouvrit. Elle disposait de trente
                                secondes de
                                temporisation avant que la sirene ne se déclenche. Se précipitant vers la centrale, elle
                                réussit à la
                                neutraliser en tournant d'un quart de tour la clé située sur le dessus du boitier.
                                Normalement
                                celle-ci aurait dû être retirée, mais le professeur Spet lui avait dit qu'il la laissait
                                toujours en
                                place
                                de peur de l'égarer. A présent, le plus dur était fait. Elle était dans le vestibule et
                                tout
                                fonctionnait à merveille. <br>
                                Bientôt elle serait en possession d'un tableau de grande valeur pour lequel elle avait
                                déjà un
                                acquéreur : Thomas. Ce dernier fréquentait également l'Ecole des Beaux-Arts et on le
                                disait issu
                                d' une famille fortunée. Ses ascendants s'étaient enrichis dans le négoce des vins de
                                Bordeaux et
                                leur rejeton ne roulait qu'en coupé de luxe. Même s'il aimait un peu frimer, il savait
                                se montrer
                                serviable avec son entourage et lançait volontiers des plaisanteries, mais jamais aux
                                dépens
                                d'autres étudiants. C'était l'institution scolaire ou leur professeur de gravure qu'il
                                aimait
                                tourner en dérision, mettant ainsi les rieurs de son côté. <br>
                                Elle lui avait parlé de ce tableau trois mois auparavant et Thomas avait tout de suite
                                manifesté
                                un vif intéret pour cette œuvœ.
                                « Ah oui, avait-il dit, mes parents cherchent justement quelque d'un grand peintre. Un
                                paysage de préférence. Je suis sûr qu'ils voudront l'avoir. Faudra voir. Je vais leur en
                                toucher un
                                mot. » <br>
                                Un mois après, il confirma à Diane que son père se portait acquéreur de l'œuvre, mais
                                pour un
                                montant inférieur d'un tiers à celui de la cote officielle. Diane accepta, en disant que
                                la somme
                                était suffisamment rondelette pour qu'elle n'ait trop à faire la fine bouche. <br>
                                Ce qui l'étonna un peu c'est que Thomas ne lui posait aucune question sur la provenance
                                de
                                cette huile, sur son propriétaire éventuel ou sur son authenticité. Elle ne se formalisa
                                pas outre
                                mesure de ce manque d'intérêt, se disant simplement que c'était là la marque d'un
                                insouciant. « Et puis, songea-t-elle, dans leur milieu on regarde sûrement pas à la
                                dépense
                                comme moi je suis obligée de la faire chaque fois que je désire m'acheter des vêtements
                                neufs ou
                                que j 'ai envie d'aller diner dans un bon restaurant. » <br>
                                -Pour le Van Gogh, je te fais entièœ confiance, lui avait-il simplement dit le jour où «
                                il
                                avait donné confirmation de la commande». Puis il avait demandé elle aurait la
                                «chromo» <br>
                                - Vendredi prochain, car j'ai rendez-vous avec mon fournisseur jeudi soir, avait répondu
                                la
                                jeune fille. <br>
                                Pour Diane, il était à présent essentiel de noter si les portes sur son parcours dans la
                                maison étaient ouvertes ou fermées. Si improbable que cela fût, un détail infime
                                pourrait éveiller
                                des soupçons chez les occupants au moment où ils rentreraient du spectacle. Ensuite, il
                                ne fallait
                                en aucun cas allumer une lampe dont la lumière aurait signalé inutilement sa présence.
                                Diane
                                avait pensé à tous ces petits riens et s'était munie d'une torche électrique. Précaution
                                extrême,
                                elle avait collé un filtre de celluloïd rouge sur le verre de la lampe afin d'atténuer
                                l'intensité de
                                la
                                source lumineuse. Elle s'avança en dirigeant le faisceau de lumière vers le sol, referma
                                la porte
                                vitrée qui séparait le vestibule du couloir d'accès aux différentes pièces, poussa la
                                deuxième porte
                                à gauche qui ouvrait sur le salon et fut assaillie par une forte odeur de fleurs
                                coupées. Des lys, lui
                                sembla-t-il. Elle rangea la lampe de poche dans son sac à main, car à travers les volets
                                restés
                                ouverts, la lumière des réverbères lui permettait de voir très nettement le canapé
                                d'angle, la table
                                basse avec les fleurs, les fauteuils et le guéridon au-dessus duquel était accroché
                                l'objet tant
                                convoité. Elle décrocha le tableau, le posa sur la table, sortit l'original du cadre, le
                                remplaça par
                                la copie et raccrocha le tout en un tournemain. C'était plus facile qu'elle ne l'avait
                                imaginé. Là-
                                dessus, elle repartit en prenant bien soin de rebrancher l'alarme. <br>
                                Dix minutes plus tard, c'est avec mille précautions qu'elle posa le Van Gogh sur la
                                banquette
                                arrière de sa Twingo. Au moment de démarrer, elle s'aperçut, qu'elle avait laissé le
                                carton à dessin sur la table du salon. Elle s'en voulut beaucoup, mais elle n'avait pas
                                le choix : il
                                fallait retourner à la maison du professeur Spet et le même trajet. Elle sut rester
                                extrêmement concentrée pendant le second prériple et ne commit aucune erreur si bien
                                qu'elle
                                rejoignit son véhicule au bout d'un bon quart d'heure, un peu tremblante, mais soulagée.
                                « Ça n'a
                                pas été tout seul, se dit-elle, mais là je crois que c'est bon ! C'est comme si un grand
                                poids était
                                de mes épaules.» Elle savoura quelques instants le succès de l'opération qui n'était pas
                                dépourvue de risques et pensa aux toilettes qu'elle pourrait s'acheter à présent et aux
                                folles
                                dépenses à effectuer. <br>
                                Quand elle voulut mettre le Van Gogh dans le carton, elle vit qu'il n'était plus là où
                                elle l'avait
                                posé, sur la banquette arrière de sa Twingo. Elle crut tout d'abord qu'elle s'était
                                trompée de
                                voiture. mais non c'était bien la sienne. Elle ouvrit le coffre, croyant qu'elle l'y
                                avait déposé,
                                mais, las, celui-ci était désespérément vide ! Des sueurs froides la gagnèrent, elle se
                                sentit
                                défaillir
                                et resta un bon moment les avant-bras posés sur le volant, désemparée, anéantie... <br>
                                Pendant que Diane vivait un véritable cauchemar, le professeur Spet trinquait avec sa
                                femme au
                                buffet de l'opéra durant l'entracte. II tenait son verre à champagne à la main et
                                souriait de façon
                                énigmatique. Pour lui, cela n'avait pas été très difficile de faire marcher Thomas dans
                                sa combine
                                et de le faire passer pour un « mécène Tous les deux avaient prévu de se partager la
                                somme que
                                l'assurance rembourserait pour le tableau prétendument volé. Il ne doutait pas que
                                Thomas avait
                                récupéré la toile et que les images enregistrées par nouvelle caméra installée récemment
                                dans le
                                salon lui permettraient de confondre la voleuse.
                                Et qui la croira lorsqu'elle affirmera qu'elle a été volée elle-même ?
                                <br>
                                Comme la vie se plaît parfois à vous jouer des tours et comme tout peut basculer d'un
                                instant à
                                l'autre ! <br>
                                Le Pont de Langlois était justement un pont à bascule...
                            </p>
                            <!-- Your content -->
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal26 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Un pont a bascule</h3>
                <h4 class="auteurnouvelle">par Roger Lang</h4>
                <p>La lumière du jour baissait insensiblement et l'obscurité enveloppait la chaussée.
                    Claquement de portière. Une fine silhouette sortit de l'ombre. Mini-jupe de gaz laiteuse sur des
                    jambes interminables lacées de cuissardes blanches, la frêle créature faisait ruisseler ses cheveux
                    roux sur un trench jaune pâle. Sous le bras droit, un carton à dessin qu'elle serrait
                    sous l'aisselle et un sac en bandoulière l'épaule gauche. Elle regarda rapidement autour d'elle,
                    ajusta
                    son...</p>
                <div class="tourbutton">
                    <button @click="showModal26 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2006 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal27 }">
                    <div class="modal-background" @click="showModal27 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Se souvenir de Valentin</h3>
                            <p>Il aimait les matins. Surtout les matins d'été. Il se levait tôt, poussait sans bruit ses
                                volets et
                                s'accoudait à sa fenêtre pour surprendre de timides lueurs encore drapées de leur voile
                                de nuit. Il
                                accueillait l'enfantement solaire d'un regard attendri et emplissait ses poumons du jour
                                naissant. <br>
                                <br>
                                Mais ce matin-là, cette douce quiétude s'acheva sur une grande cape bleue auréolée de
                                long
                                cheveux blonds. Elle était accompagnée d'une horrible valise jaune et d'une toile bien
                                empaquetée. Elle le héla joyeusement : <br>
                                " J'ai facilement reconnu votre maison ! On ne peut pas vous rater ! <br>

                                La dernière maison au bout du sentier, parée des bleus les plus inatterxius : toit
                                bleu ciel, volets turquoise, porte pervenche et portail lavande !" <br>

                                Il soupira et s'avança à sa rencontre. Encore une artiste en herbe qui allait lui gâcher
                                sa
                                matinée... <br>
                                Cependant, il se ferait un honneur de la recevoir, il les accueillait tous, étudiait
                                leurs œuvres,
                                préconisait plus de blanc, prônait la pureté des bleus, peut—être une touche de gris...
                                Puis il
                                donnait quelques adresses utiles avant de retrouver l'apaisement de ses propres toiles.
                                <br>
                                En s'approchant d'elle, il la trouva lointaine et étrangement familière. Elle lui
                                souriait
                                gentiment. Elle le dévisageait d'un regard indéfinissable, peut-être gris ou sans doute
                                vert. Elle
                                murmura : <br>
                                " Excusez-moi de vous déranger aussi tôt... C'est mon père qui m'envoie... "<br>
                                Discours classique du jeune qui cherchait timidement à engager la conversation. <br>
                                " Il pensait que vous pourriez... Que vous auriez.„ Il m'a tellernent parlé de vous ! "
                                Des compliments, des formules de courtoisie ! Lui, il avait hâte de découvrir ses
                                couleurs et de la
                                voir repartir ! Mais elle continuait maladroitement : <br>
                                " C'est un peu comme si je vous connaissais... Il a bercé mon enfance de vos fabuleuses
                                légendes <br>
                                Là, elle exagérait un peu ! Cette façon de l'aborder ne lui convenait guère. Les
                                flateries ne lui
                                seyaient pas du tout. <br>
                                " Mon père m'endormait toujours avec l'une des merveilleuses aventures de Valentinr.
                                <br><br>

                                Il blêmit et dut s'appuyer contre son portail en bois peint; ses pensées refluèrent, son
                                cerveau
                                se trouva à marée basse, son souffle s'immergea dans des profondeurs océanes. <br>
                                " Ca ne va pas ? ", demanda la voix claire en le soutenant par les épaules. <br>
                                Il se reprit lentement et la dévisagea plus attentivement. Pourquoi cette jeune femme
                                lui semblait-
                                elle aussi familière ? Il savait pourtant qu' il ne l'avait jamais rencontrée <br>
                                " Vous devez faire erreur, Mademoiselle. <br>
                                Je m'appelle Claude. Claude Chagrin. Je suis peintre. Artiste-peintre„."
                                Cette remarque était ridicule; elle le savait bien puisqu'elle était là.
                                Quel vieil homme stupide il devenait ! <br>
                                " Mais, je ne me suis même pas présentée ! Je suis Véronique Mars, la fille de
                                Joseph..." <br>
                                Cette fois-ci, son corps fut emporté par la marée déferlante, noyé, ravagé, asphyxié...
                                <br>
                                Quand il retrouva ses esprits, elle l'avait installé sur son fauteuil pers... Elle
                                souriait toujours :
                                <br>
                                " Mon père vous considérait comme un artiste génial. Il était très attaché à vos
                                peintures... <br>
                                - Etait ? <br>
                                - Papa est décédé il y a deux ans, le 30 septembre... Comme cela, subitement... <br>
                                Tout se remettait en place, tranquillement. Son énergie envahissait à nouveau ses vieux
                                os et il
                                redevenait homme, chaleureux et accueillant. <br>
                                Voilà pourquoi elle ne lui semblait pas tout à fait inconnue : <br>
                                " Vous ressemblez beaucoup à votre mère. <br>
                                Elle le savait, son père le lui répétait souvent ! <br>
                                <br>
                                Il lui proposa un café et ils s'installèrent autour d'une nappe en tissu outremer. Il
                                avait
                                l'impression de retrouver la même jeune femme, longtemps après. Lui, il avait vieilli,
                                s'était
                                flétri, ridé, tassé... Elle, elle s'était figée dans une éternelle jeunesse. <br>
                                Revenant à la réalité, il lui demanda de lui raconter son père, leur existence à tous
                                les deux„.
                                Elle souriait en ressuscitant ce père exceptionnel qui l'avait élevée seul après le
                                décès de sa
                                mère. Ils n'étaient jamais restés plus de deux ans au même endroit, Joseph avait besoin
                                d'espace,
                                d'amplitude, de liberté. Il demandait sans cesse de nouvelles mutations. Ils avaient
                                voyagé, lui, le grand professeur aux cheveux blancs, au regard gris, à la grande
                                silhouette
                                démesurée et elle, le petit bagage tout blond, vif et espiègle, adorant cet homme
                                d'exception, à la
                                fois si tendre et si savant. <br>
                                Il enseignait la médecine dans toutes les universités qui faisaient appel à lui;
                                certains soirs, il
                                aimait recevoir des étudiants avides de savoir pour développer avec eux ses théories
                                avant-
                                gardistes. <br>
                                C'était un père merveilleux: malgré ses journées surchargées, c'est toujours lui qui
                                emmenait sa
                                fille à l'école, qui venait la rechercher et la guidait dans ses devoirs. Il veillait
                                discrètement sur
                                elle, l'écoutait patiemment et l'accompagnait dans ses choix. <br>
                                Depuis sa plus tendre enfance, chaque soir, il venait la border dans son lit; il
                                s'asseyait à ses côtés
                                et lui contait les fabuleuses histoires du Mage aux tonalités enchantées. Elle
                                s'endormait bercée
                                par la voix grave, flottant dans l'univers ouaté de Valentin. <br>
                                <br>
                                Claude sursauta à l'énoncé de ce prénom. Cette fois-ci, il se ressaisit rapidement. <br>
                                Devenue adolescente, Véronique avait partagé les voyages déconcertant de son père, de
                                l'Afrique
                                à la Norvège, de l'Inde à l'Australie. Ils étaient très complices, partageaient leurs
                                espoirs, leurs
                                bonheurs quotidiens, ces petits riens qui illuminent chaque instant. <br>
                                Les soirs, où lors de leurs balades au bord de l'océan, ils poursuivaient ensemble les
                                aventures de
                                Valentin, cet Enchanteur capable de teinter de félicité chaque épreuve de la vie. <br>
                                Joseph et Véronique composaient à deux voix ce personnage féerique, lui inventaient sans
                                cesse
                                de nouvelles péripéties, de prodigieuses odyssées, d'incroyables destinées... Ils le
                                voyaient vêtu
                                d'une vaste cape bleue, coiffé d'un chapeau en feutre ciel, accompagné de son éternel
                                bâton orné
                                de lapis-lazuli. II effaçait toute peine, toute souffrance, toute tristesse. Il
                                apparaissait dès qu'on
                                l'appelait et enluminait de sonorités lumineuses les vies les plus ternes. Il souriait
                                aux prisonniers
                                et peignait d 'azur leurs barreaux gris... <br>
                                <br>
                                Lorsque Véronique demandait à son père pourquoi, au hasard de leurs déménagements, tout
                                demeurait bleu chez eux, des tapisseries aux tapis chinois, des armoires aux fauteuils,
                                des rideaux
                                aux nappes brodées, il répondait dans un doux sourire: Demande donc à Valentin ! " <br>
                                Même la véronique était une petite fleur bleue ! <br>
                                Quelques temps avant sa mort, Joseph avait longuement parlé à sa fille. Il se confiait
                                rarement
                                et, plus elle grandissait, plus elle réalisait qu'elle ne gavait rien de lui: elle ne
                                connaissait pas sa
                                jeunesse, sa vie d'avant, les détails des cinquante années qu'il avait vécues sans
                                elle... <br>
                                " Ecoute-moi bien, Véronique... Je n'ai jamais eu le courage de te parler vraiment, de
                                te
                                raconter... Ma jeunesse, notre famille, mon frère Elie, mes compagnons de misère, ta
                                mère... Et
                                toutes ces... Non, ma chérie. Je ne trouve pas les mots... Je n'ai pas la force„.
                                Comment pourrais-
                                je t'expliquer pourquoi tout ce bleu chez nous, pourquoi j'ai tout quitté... <br>
                                Tu ne me croiras jamais, mais Valentin a vraiment existé ! Enfin,... <br>
                                Tu te souviens de cette exposition à Budapest, celle où nous sommes restés si longtemps.
                                Tu étais
                                encore une petite fille et tu voulais quitter la salle; mais moi, je n'arrivais pas à
                                m'éloigner de ces
                                toiles. Je t'ai expliqué que ce génie des bleus, ce peintre talentueux avait été mon
                                ami, il y avait
                                bien longtemps, lorsque j'étais médecin en France. Il s'appelait Claude Chagrin, mais il
                                signait
                                ses oeuvres du seul mot Chagrin. Nous l'avions raté de peu, il venait de reprendre
                                l'avion... <br>
                                Et bien, Valentin, c'était un peu lui ! <br>
                                S'il m'arrive quelque chose, va le trouver. Peut-être qu'il saura te raconter d'où
                                viennent tous ces
                                bleus qui agrémentent notre vie. Promets-moi d'aller à sa rencontre !" <br>
                                <br>
                                Et voilà ! Elle avait passé son doctorat d'histoire à Paris et maintenant qu'elle avait
                                un peu de
                                temps, elle avait souhaité le rencontrer et le questionner sur cette toile qui avait
                                orné chacune de
                                leurs demeures, au fil de leurs voyages. Elle portait la signature de l'artiste :
                                <i>Chagrin</i> <br>
                                Claude hésitait entre rire et larmes. Joseph était mort. Mais sa fille était là, assise
                                en face de
                                lui ! <br>
                                Maintenant, la matinée rayonnait ses douceurs estivales sur les teintes bleutées de la
                                maisonnée. <br>
                                Comment pourrait-il narrer leur douloureuse histoire à cette belle jeune femme blonde?
                                <br>
                                Joseph était mort avec ce fardeau, mais Claude, lui, en vieillissant, pensait souvent à
                                se délester
                                du poids terrible de sa jeunesse outragée. <br>
                                Véronique interrompit le fil de ses pensées : <br>
                                " Pourquoi tous ces bleus chez vous et chez nous ? Pourquoi Papa m'habillait-il toujours
                                en bleu
                                ? Il disait que c'était pour me protéger... Mais pour me protéger de quoi exactement ?
                                <br>
                                Je vais vous poser une question à laquelle vous ne répondez jamais lors de vos
                                entretiens avec les
                                journalistes, que vous éludez d'une boutade ou d'un simple éclat de rire : <br>
                                <i>Pourquoi tout ce bleu dans les toiles qui font de vous un artiste génial ?</i>"<br>
                                C'était étonnant, elle avait les mêmes yeux que sa mère. Joseph avait épousé une très
                                jeune
                                femme au regard gris teinté de vert. Comme Véronique lui ressemblait ! <br>
                                Il déglutit péniblement. Il ne savait comment répondre. Que pouvait-il lui dire ? <br>
                                Elle le considérait gentiment et c'est ce regard encore enfantin, empli de confiance,
                                qui le décida.
                                "Vous savez, Véronique, personne.„ Personne ne peut imaginer ce qui nous est arrivé.
                                J'ai
                                parfois l'impression que tout cela ne s'est pas vraiment passé, que mon imagination me
                                joue des
                                tours. <br>
                                <br>
                                Venez. nous allons marcher un peu dans la foret derrière la maison. Ce plus facile moi
                                de vous raconter... La fille de Joseph ! Quel bonheur ! <br>
                                <br>
                                Ils s'enfoncèrent lentement entre les arbres silencieux, disposés à recevoir les
                                confidences
                                endeuillées du peintre talentueux qui aimait tant les parer de tonalités perses ou
                                turquoise. Et, tout
                                en marchant, il Sut trouver les mots justes pour raconter l'inexprimable... <br>
                                <br>
                                " Joseph et moi habitions la même bourgade, mais nous nous connaissions à peine. Il
                                avait quinze
                                ans de plus que moi et était déjà un médecin très réputé dans notre région. En revanche,
                                j'étais
                                très ami avec son jeune frère Elie, mon camarade de classe, compagnon de jeux, tendre
                                complice
                                de mes mille facéties. Au début de la guerre, nous étions tous deux apprentis chez Paul
                                Delval, le
                                maçon; nous sifflions à tue-tête du haut de nos échafaudages, nous aimions les mêmes
                                filles et
                                nous recevions les pires nouvelles avec une horrible insouciance. <br>
                                C'est Joseph qui, agacé par la légèreté d'Elie, le mit face aux réalités. Il le présenta
                                aux résistants
                                qui commençaient à s'organiser dans notre région. En quelques semaines, Elie prit
                                conscience de
                                la situation dramatique de notre pays. Il devint sombre et ne supportait plus mes rires
                                décalés. Il
                                m 'emmena avec lui à diverses rencontres.„ Alors, je compris... Moi aussi ! <br>
                                Comme nous étions encore très jeunes, on nous mit chacun sous la tutelle d'un plus
                                ancien. C' est
                                ainsi que je fus guidé par ton père; nous travaillions ensemble sous le même pseudonyme:
                                Valentin. Notre mouvement prit de plus en plus d'amplitude, nous étions chaque jour plus
                                nombreux; nous multipliions nos actions contre l'occupant et nos "actes de vandalisme"
                                nous
                                rendirent vite "hors la loi". Nous étions discrètement soutenus par la population et
                                nous
                                devenions d'une efficacité impressionnante ! <br>
                                Après neuf mois de succès multiples, notre réseau fut démantelé: nous avions été
                                dénoncés.
                                Nous étions une trentaine; certains furent fusillés sur Elie et quelques compagnons
                                d'infortune moururent sous la torture. Une poignée d'entre nous fut déportée... Ton père
                                et moi
                                étions de ceux-là... Tu le savais sans doute... <br>
                                <br>
                                L'horreur des camps, l'esclavagisme, les travaux forcés jusqu'à la mort, la faim, le
                                froid, les
                                plaies, les coups... Je te ferai grâce des détails... <br>
                                Etrangement, Joseph et moi, nous nous retrouvâmes dans le même baraquement... Ton père
                                était brisé, accablé de culpabilité: il se croyait responsable de la mort atroce de son
                                frère.
                                Lui qui n'était guère bavard devint tacitume. Il travaillait dur, ne se rebiffait
                                jamais, n'émettait
                                aucune plainte. Chaque soir, il faisait le tour de la chambrée; il pansait, soignait,
                                apaisait avec les
                                moyens du bord. Il réconfortait sans parole, encourageait d'un regard, soulageait de ses
                                mains
                                expertes. Il veillait particulièrement sur moi... <br>
                                Puis l'hiver arriva et la vie au camp devint insupportable. Les gens mouraient de froid,
                                d'épuisement... L'un de nos camarades fut ramené dans notre baraquement, le corps gelé,
                                bleui
                                de froid. C'était Eugène, un brave garçon de mon âge, toujours souriant, généreux,
                                attentif à tous.
                                C'est cette mort qui fit réagir ton père. Il se rebella enfin. Il retrouva la parole et
                                ne la perdit
                                plus jusqu'à notre libération. C'est lui qui insuffla vie à Valentin. Il était penché
                                sur le corps
                                d'Eugène : <br>
                                " Oh Valentin ! Comme tu as bien choisi ! Tu as préféré le meilleur d'entre nous. Tu
                                l'as couvert
                                de ta couleur préférée : le bleu océan... " <br>
                                Interloqués, nous nous regardions tous avec inquiétude. Je crus que ton père avait perdu
                                la raison. <br>
                                <br>
                                Mais, ce soir-là, il nous présenta Valentin, le mage des couleurs. Pour lui, chaque
                                teinte avait
                                une signification bien précise. Mais le bleu ! Oh, le bleu! C'était la couleur libre:
                                cieux, mers,
                                océans, pierres sacrées. Nous étions assis autour de lui, comme des enfants buvant ses
                                paroles. <br>
                                <br>
                                Après la mort d'Eugène, nous nous réunîmes chaque soir, dès l'extinction des feux, pour
                                recevoir notre invité d'honneur, Valentin l'Enchanteur. <br>
                                Chacun prenait la parole à tour de rôle pour évoquer les merveilles de l'hôte
                                imaginaire: univers
                                bleuté, profondeurs océanes, villes célestes, peuplades antiques parées de bleus
                                lumineux,
                                temples céruléens, étranges animaux aux reflets turquoise... <br>
                                Les murs gris s'éloignaient et la pièce glaciale s'irradiait d'une liberté chimérique,
                                peuplée de
                                moutons bleus, d'astres indigo, de femmes aux boucles perses, d'enfants aux rires bleu
                                marine.„
                                Valentin, vaporeux à ses débuts, affirma ses contours; il prit une carrure de plus en
                                plus
                                imposante, son charisme amenait chacun à la tête. Nous l'imaginions coiffé d'un
                                chapeau en feutre turquin, vêtu d'une large cape bleu pâle, rayonnant d'un regard
                                pervenche
                                lumineux. <br>
                                Ce personnage, né de l'esprit de ton père, s'étoffait d'une consistance incroyable. Nous
                                pensions à lui toute la journée et nous cherchions sans cesse de nouvelles aventures à
                                lui
                                proposer. Il nous accornpagnait dans nos labeurs inhumains, nous escortait dans le
                                froid, occupait
                                nos pensées, détournait nos souffrances, nous protégeait du désespoir. <br>
                                Valentin ! Il repeignait notre monde, repoussait nos limites; nous oubliions les
                                barbelés, nous
                                n'écoutions plus les ordres hurlés... Nous étions marins perdus sur l'océan, aigles
                                flottant au-
                                dessus des cimes, enfants aux rêves émerveillés. La mort frappait moins souvent dans
                                notre
                                chambrée. Valentin nous rendait presque invulnérables. <br>
                                C 'est lui qui nous accompagna jusqu 'à notre délivrance.
                                Lorsque les barbelés s'effacèrent, offrant à nos libérateurs effarés la vision de nos
                                corps
                                décharnés, Joseph nous cria joyeusement: <br>
                                " Souvenez-vous de Valentin ! Emmenez-le partout avec vous ! " <br>
                                <br>
                                La vie reprit... parmi les survivants. <br>
                                Je renonçai au métier de maçon: je ne pouvais plus bâtir de murs, enfermer des clore des
                                jardins... J'étais obsédé par le bleu: lui seul me rassurait; il me rendait invincible.
                                C'est ainsi que
                                je devins Chagrin, le célèbre artiste peintre. Ton père, lui, réaménagea son cabinet
                                médical. <br>
                                Nous passions de longues soirées ensemble, silencieux, rêveurs, lointains. Parfois, nous
                                sentions
                                la présence légère et rieuse de Valentin. <br>
                                De longues années plus tard, ton père rencontra la jeune Marie, nommée institutrice dans
                                notre
                                village. Ce vieux sauvage fut immédiatement séduit par sa fraîcheur et par son étrange
                                regard gris
                                vert. Elle, elle succomba à son charme, sa délicatesse, ses silences éloquents. <br>
                                Un soir, fous de joie, ils vinrent m'annoncer ta venue parmi nous. Joseph rajeunissait
                                de jour en
                                jour, Marie s'arrondissait. <br>
                                Et puis, une nuit, ton père fut appelé au chevet d'Angèle, une vieille femme en fin de
                                vie. <br>
                                Quelques années auparavant, la pauvre avait vécu un drame affreux: son fils et son mari
                                s'étaient
                                mystérieusement entretués à la chasse. Elle ne s'en était jamais remise. Joseph passa la
                                nuit à ses
                                côtés, essayant de la soulager et de la réconforter. Au petit matin, il lui ferma les
                                yeux... <br>
                                Lorsqu'il rentra chez lui, tu étais là, vagissant faiblement... Ta maman était...
                                morte... Elle avait
                                accouché seule, sans lui, sans son secours, et elle avait été victime d'une
                                hémorragie... <br>
                                <br>
                                Joseph perdit pied, il ferma son cabinet, s' isola avec toi, n'ouvrit plus sa porte. Ce
                                fut terrible !
                                Le seul moyen qui me permit de l'atteindre fut une toile aux sonorités bleutées: "Se
                                souvenir de
                                Valentin ". Puis il quitta notre bourgade, devint professeur... Tu connais la suite...
                                <br>
                                <br>
                                Véronique repartit le lendemain, très émue, avec "Se souvenir de Valentin "... <br>
                                Claude se remit à sa dernière toile, "Afrique bleutée". <br>
                                Il restait songeur. Il avait bien fait de ne pas lui raconter. <br>.
                                Lui, il s'était ressaisi après ses années de captivité; la peinture l'avait aidé à
                                accepter ses
                                monstrueux souvenirs. Mais Joseph était resté meurtri, amer, traumatisé. Il voulait
                                savoir, il lui
                                fallait des noms. Il avait enquêté dans l'ombre, pris tout son temps, surpris des
                                secrets de la
                                bouche de ses patients. <br>
                                Puis, patiemment, il avait attendu des preuves, des certitudes. Il savait que le moment
                                viendrait.
                                Un jour de chasse, il avait ligoté Henri, le mari d'Angèle, puis son fils Marc. Il leur
                                avait
                                raconté, longuement, les horreurs de la déportation, la peur, la mort tout autour... Il
                                était entré
                                dans les détails... Ensuite, il les avait abattus, chacun avec l'arme de l'autre avant
                                de les détacher.
                                L'enquête n'avait pas abouti... On ne fit jamais le lien entre ce double meurtre et la
                                dénonciation, longtemps auparavant, d'un réseau de résistants, morts fusillés, torturés
                                ou en
                                déportation... <br>
                                <br>
                                Véronique n'avait pas besoin de savoir. <br>
                                <i>Il sufisait qu 'elle aussi se souvienne de Valentin..
                                </i>
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal27 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : Se souvenir de Valentin</h3>
                <h4 class="auteurnouvelle">par Pascale Corde Fayolle</h4>
                <p>Il aimait les matins. Surtout les matins d'été. Il se levait tôt, poussait sans bruit
                    ses volets et
                    s'accoudait à sa fenêtre pour surprendre de timides lueurs encore dravées de leur voile de nuit. Il
                    accueillait l'enfantement solaire d'un regard attendri et emplissait ses yeux du jour naissant. <br>
                    Mais ce matin-là, cette douce quiétude s'acheva sur une grande cape bleue auréolée de long
                    cheveux blonds. Elle était accompagnée d'une horrible valise jaune et d'une toile bien
                    empaquetée...</p>
                <div class="tourbutton">
                    <button @click="showModal27 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal28 }">
                    <div class="modal-background" @click="showModal28 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Un deux trois... Soleil</h3>
                            <p><i>« La route serpente, longue, blanche, vers les lointains bleus, vers les horizons
                                    attirants. Sous le soleil... »</i> <br>
                                <br>
                                Domi lit à voix haute, en détachant soigneusement les syllabes ; un débit lent et
                                régulier auquel on a recours lorsque l'on veut s'assurer d'être compris. Domi lit avec
                                l'application d'une mère racontant une histoire à son enfant avant qu'il ne s'endorme,
                                pour le
                                plonger dans un monde merveilleux où il pourra évoluer sans peur et sans cauchemars.
                                Serein.
                                Une maman dont la voix est une caresse, les mots un baume sur les écorchures. Domi
                                accumule les mots, les empile comme on bâtit des murs pour se protéger. Entre ces murs,
                                un
                                refuge pour elles deux, un univers familier qu'elles seules partagent. Domi et petite
                                mère. Une
                                intimité dans laquelle personne ne s'immiscer ; ne doit s'insinuer. Une relation d'amour
                                entre une mère et sa fille, dont chacune ne vit que par l'autre. Une sorte d'osmose où
                                les rôles
                                sont interchangeables. <br>
                                Petite mère s'assoupit, frêle Silhouette nichée au creux du grand fauteuil. Le
                                rythme de sa respiration ralentit. Ses yeux clignent Domi referme délicatement l'ouvrage
                                et le
                                dépose sans heurt sur la table basse encombrée de bibelots. Elle se cale contre le
                                dossier de la
                                chaise paillée. Surtout, de claquement sec, pas de gestes brusques pouvant être
                                assimilés à
                                une agression Ne plus Retenir son souffle. Conserver une totale immobilité. Se figer.
                                Calmer les cognements fous du cœur qui rappellent le danger toujours croissant, toujours
                                plus
                                proche. Une menace qui la laisse impuissante, Sans remède effcace. Une seule parade :
                                simplement être là, aimante et dévouée. Assumer la veillée comme elle le fait depuis
                                plusieurs
                                mois, en sortant du travail, après avoir préparé et servi le repas. Refuser la fatalité.
                                Repousser
                                le terme et le constat d'échec. <br>
                                Petite mère sursaute. Son regard se perd sur quelque objectif lointain, invisible.
                                Ses traits se décrispent. Un sourire enfantin illumine son visage. Sa tète dodeline,
                                effleurant le
                                col Claudine de sa jolie robe rose. Elle chantonne d'une voix fluette. Quelques petites
                                notes
                                qui s'égrènent dans l'aigu, aussi déchirantes qu'un accord raté de violon. <br>
                                À peine une page de lecture et déjà petite mère est partie. Ailleurs. Là où on ne
                                peut plus l'atteindre. Au cœur d'un espace peuplé d'ombres. Pourtant, Domi a pris soin
                                de
                                choisir ce soir Chemineau, d'Isabelle Eberhardt, figurant dans <i>Ecrits sable</i>, un
                                de ses
                                recueils préférés parmi les ouvrages amassés sur les rayonnages de l'immense
                                bibliothèque par
                                celle qui fut une lectrice avertie ct cultivée. Un symbole pour celle qui se voulait
                                libre, au-
                                delà de la morale, et se passionnait pour les écrits audacieux. Une femme capable de
                                vivre en
                                harmonie avec ses principes. Une mère exhibant crânement face aux bourgeoises
                                scandalisées
                                sept enfants dont seulement deux du même père. <br>
                                Qu'est-ce qu'ils font, là, mainœnant, ces frères et Sœurs qui se déchargent sur
                                Domi de leurs responsabilités depuis la mort de Bernard, le dernier compagnon ? Certes,
                                la
                                cadette ne peut être d'aucune utilité, elle est partie s'installer en Angleterre :
                                besoin de
                                respirer, loin de la famille. Mais les autres, ceux dont petite mère est si fière parce
                                qu'ils ont
                                réussi leur carrière professionnelle et leur existence personelle ? L'ainé de la
                                fratrie, que
                                Domi a à peine connu, fonctionnaire au cursus irréprochable. La grande sœur, jonglant
                                entre
                                son métier d'enseignante et ses trois enfants. Françoise, l'artiste — la préférée — à
                                l'ego
                                hypertrophié. Pierre et Patrick, si inséparable qu'on les appelait les faux jumeaux,
                                l'un
                                informaticien, l'autre clerc de notaire. Englués dans leur quotidien, bien installés
                                dans leur
                                confort, concentrés sur leur foyer. Persuadés que l'avant-dernière n'a rien de mieux à
                                faire
                                que prendre en charge une génitrice devenux encombrante. Une homo ça n'a vraiment de
                                vie privée, n'est-ce pas ? Et si peu de vie sociale... surtout avec un médiocre niveau
                                d'instruction ! C'est ce que Pierre Patrick lui ont dit, la méprisant ouverternent pour
                                des penchantd
                                qu'ils qualifient de déviants. Son dévouement auprès de la mère ? L'occasion
                                d'expier sa "faute". Le propos l'a blessée à l'époque. <br>
                                Maintenant, Domi s'en moque. Elle accomplira Son devoir jusqu'au bout Une
                                charge ? Non, un plaisir. Un sacerdoce. Mieux, elle a conscience de jouir d'une
                                situation
                                privilégiée. N'a-t-elle l'occasion inespérée de renouer avec une mère trop distante
                                durant
                                l'enfance ? Tenter de lui plaire enfin, elle qui n'a jamais brillé dans les études. Même
                                s'il est
                                usant d'être présente, de rassurer, surveiller ses gestes, veiller sa mère comme une
                                enfant
                                fragile. Même si les perspectives ne sont guère réjouissantes : pratiquement aucun
                                espoir
                                d'amélioration ou de rémission ; au mieux un ralentissement de la dégénérescence. Mais
                                jamais — elle le lui a promis — Domi n'enverra petite mère en structure médicalisée.
                                Celle-ci
                                restera dans sa maison ; son cocon. À l'abri de ce qui pourrait lui faire mal. Et si une
                                surveillance constante s'avère nécessaire, Domi quittera son boulot et viendra
                                s'installer dans
                                la demeure. Rien qu'elle et petite mère. <br>
                                — Allez, tu me racontes une histoire ? Une belle histoire. <br>
                                Petite mère la fixe d'un regard suppliant ; son ton trop aigu lui communique la
                                chair de poule. Domi n'a plus face à elle une vieille femme affaiblie par la maladie
                                mais une
                                fillette quêtant un peu d'affection et qui a besoin d'elle. <br>
                                <i>« Les premiers rayons du soleil tiède, filtrant à travers les voiles humides des
                                    eucalyptus..."</i>
                                <br> La voix de Domi se brise quand elle entend préciser :
                                — Non, pas ça. Une histoire, une vraie, je t'ai dit. Une à toi. <br>
                                Domi rechigne, elle n'a suffisamment d'imagination pour construire une
                                fiction. Mais l'autre trépigne, s'énerve. Alors, Domi parle, toujours plus haut,
                                toujours plus
                                fort, pour faire taire cette voix de fausset. Elle dit, raconte, invente, s'étourdit
                                dans un récit
                                improvisé pour ne plus s'entendre penser. Domi échafaude des murs de mots qui les
                                abritent
                                Une bulle de mots pour les protèger du silence. Insupportable silence, plein de non-dits
                                qui
                                déglinguent <br>
                                Petite mère écoute quelques minutes et se lasse. Elle tape dans les mains puis se
                                lève, le bas de sa robe retroussée sur des jambes maigres. Sautille grotesquement
                                jusqu'au
                                mur, yeux à demi fermés, mains en avant. Petite mère chantonne. un sourire malicieux aux
                                lèvres. <br>
                                — Un, deux, trois... Soleil <br>
                                Instinctivement, Domi a stoppé net son geste quand l'autre s'est retournée. Elle Se
                                récite mécaniquement les consignes : on ne bouge plus, on se fige dans une position au
                                mot
                                « soleil » sinon, on est pris Et là, on trinque Non, pas ça. <br>
                                Le cri jaillit, empli de la rancœur trop longtemps accumulée.
                                — Non, maman... Tais-toi ! Maman, s'il te plaît.. <br>
                                <br>
                                Un jour d'été comme les autres et cette touffeur de l'atmosphère qui pénètre les
                                corps. Cette chaleur qui semble vouloir couler en soi. Les garçons, torse nu, allongés
                                sur le
                                carré d'herbe brûlée, derrière la maison ; les bras en croix comme pour mieux sentir
                                s'infiltrer
                                les rayons. Elle, la morsure du soleil sur les jambes et les bras nus ; qui court
                                dans les veines. Elle tourne autour des aînés comme une mouche taquine, faisant
                                virevolter sa
                                jupe. « On joue ? » D'abord un agacement prévisible. Puis, surprise : Patrick se lève
                                d'un
                                bond, puis Pierre. « OK. À quoi ? » La réponse jaillit, spontanée : « Un, deux. trois...
                                Soleil ! » Quelques minutes de pur bonheur, de connivence avec des frères si distants
                                d'ordinaire. Des regards appuyés, des sourires ; l'impression d'exister enfin à leurs
                                yeux.
                                Puis... Non. trop dur Le film en accéléré pour se figer sur la scène d'une petite
                                chose sale et larmoyante prostrée dans le jardin. <br>
                                Quand la mère rentre, elle fulmine en voyant la tenue négligée de la gamine. La
                                gifle part toute seule. Domi voudrait lui expliquer, mais comment on dit ces choses-là ?
                                Elle
                                n'a les mots qu'il faut. Tout ce qu'elle sait dire, c'est qu'elle a joué avec les grands
                                et
                                qu'ils lui ont fait mal. <br>
                                — Arrête un de pleurnicher, tu n'es jamais contente. Ça devrait te faire plaisir
                                que tes frères jouent avec toi ; pour une fois qu'ils quittent leurs cahiers et leurs
                                livres ! Quelle
                                souillon ! Je vais encore devoir laver. Tu as vu l'état de ta jupe ? <br>
                                Elle est partie sans attendre la réponse. <br>
                                Ils n'ont plus joué à Un, deux, trois, soleil. L'autre "jeu", lui, a continué durant
                                six ans. Ensuite, les deux grands frères sont allés répandre leur semence dans le ventre
                                de leur
                                légitime. Petite mère n'a rien su. Etrange de la voir mimer une telle scène dans un
                                accès de déraison.
                                <br>
                                <br>
                                Le cri de Domi l'a secouée. Hébétée, petite mère regarde autour d'elle comme
                                pour se réapproprier son environnement. Ses traits se détendent à la vue de la jeune
                                femme.
                                — Ah, Françoise... C'est gentil d'être venue me voir. Ça faisait si longtemps ! Tu
                                me manquais, tu sais. Mais Où tu étais ? <br>
                                — Non, maman, Françoise. Dominique. C'est moi qui viens tous les jours.
                                Françoise, elle est à ... <br>
                                Domi renonce. À quoi ça servirait ? Un impératif : éviter toute souffrance. La
                                protéger. S'oublier pour cette femme si vulnérable. Et quelle importance si la vérité
                                est
                                quelque aménagée ? <br>
                                Petite mère se rassoit dans le fauteuil Où elle passe désormais l'essentiel de son
                                temps. Un siège usé, marqué de l'empreinte de son corps. Son regard s'accroche à Domis.
                                — Dis, tu me lis encore <strong>Écrit Sur le sable ?</strong> J'aime bien. Je ne sais
                                pas pourquoi?
                                ça me ma jeunesse. J'ai eu bons moments, tu sais ! <br>
                                Un miracle auquel Domi à croire. Un éclair de lucidité. Un sursaut de
                                conscience. Une rémission inespérée. Petite mère est à nouveau près d'elle. Force de
                                l'amour, capable de réaliser des prodiges... <br>
                                La jeune femme sourit. Exulte. Elle Saisit le recueil qu'elle ouvre au hasard. Fait
                                chanter les mots, valser les phrases. Elle joue son texte. Toute cette vie qui anime les
                                personnages, elle l'offre à petite mère pour lui permettre d'exister encore. Elle le
                                savait.
                                Domi, que sa tendresse finirait triompher du mal.
                                ElIe a lu avec une telle avidité, une si grande gourmandise, qu'elle doit
                                s'interrompre pour reprendre son souffle. Déjà vingt heures trente Elle profite de la
                                pause
                                pour administrer à la vieille femme les médicaments à ingurgiter le soir. Petite mère la
                                fixe
                                affectueusement, gonflée de reconnaissance. Puis grimace en regardant autour d'elle.
                                <br>
                                — Dis-moi, Françoise, tu sais où elle est passée, Dominique ? Je ne la vois pas.
                                Toujours où il ne faut pas Je n'en ferai jamais rien de celle-là ! <br>
                                Une douche froide. Une blessure qui se rouvre. La tentation est grande de
                                renoncer et rentrer chez elle. Elle se ressaisit. Elle n'agit pas souci de
                                reconnaissance mais
                                dans le seul intérêt de sa mère. Pas d'autre alternative que jouer le jeu. Tant pis pour
                                l'amour-
                                propre. Une idée obsessionnelle : protéger petite mère. lui éviter toute contrariété.
                                <br>
                                — Elle est sortie un moment Elle va bientôt rentrer <br>
                                — Encore avec Patrick et Pierre, je suppose ? <br>
                                - Peut-être. <br>
                                — Elle croit je sais rien, mais je ne suis aveugle !
                                — Qu'est-ce que tu veux dire, maman ?
                                — Comme si tu ne le savais pas.. Tout le monde ici est au courant de leur
                                jeu à ces trois-là ! Pas plus tard qu'hier, on en a encore parlé avec Bernard et toi, Tu
                                ne te
                                souviens ? <br>
                                — Non, j'ai oublié. <br>
                                — Tu perds la tête, Françoise ! Remarque, je préfère qu'elle fasse ça avec ses
                                frères, entre gosses. Au début, je croyais que c'était avec Bernard. <br>
                                — Au début ? Qu'est-ce que tu veux dire ? <br>
                                — Tu sais bien, le jour où ils ont joué tous les trois à ce jeu stupide... Comment
                                ça s'apelle déjà ? <br>
                                — Um deux, trois, soleil ?
                                — Oui, c'est ça. Un, deux, trois. Soleil ! Tu vois que tu te souviens ! Une mère,
                                ça comprend ces choses-là. Je vois bien que ça continue. Toujours à trois, je ne sais
                                où. Et les
                                regards fuyants quand on se retrouve tous à table... <br>
                                Ces cris, en elle. C'est pas vrai, maman, tu n'étais pas au courant ? Personne ne
                                savait, hein ? Ou du moins, tu n'étais pas sûre. Sinon, pourquoi tu n'as rien fait pour
                                empêcher ça ? Pourquoi tu ne m'as même pas parlé ? Pourquoi tu ne m'as pas dit que tu
                                savais et que ce n'était pas ma faute ? Ou simplement que tu m'aimais — rien que ça : je
                                t'aime ? Maman, je t'en prie, réponds ! Dis-moi que tu ne savais pas. <br>
                                Ces mots, Domi ne les dit pas. Le regard de rEtite mère est devenu fixe. Elle fuit
                                de nouveau. Inaccessible. Elle a réintégré son univers d'ombres évoluant sous un soleil
                                noir.
                                Toujours plus loin dans la folie. Elle fredonne inlassablement cinq petites notes. Domi
                                n'aura
                                pas de réponse aux interrogations qui la rongent ni maintenant, ni plus tard. Pas sûr
                                qu'elle le
                                souhaite vraiment. <br>
                                Non, petite mère n'ira en HP ou en structure médicalisée. Jamais. Elle finira
                                ses jours dans le vieux fauteuil usé, face à la bibliothèque, comme elle l'a toujours
                                désiré.
                                Personne ne connaitra sa déchéance. Elle restera la mère exemplaire, attentive aux
                                siens. Pure
                                et aimante. Ce qu'elle vient de dire ? Des propos décousus comme on en tient sous
                                l'effet du
                                délire. Une sorte d'intuition dictée par son subconscient. Rien d'autre. Tout va bien?
                                Il ne s'est
                                rien passé. Il ne se passera plus rien. C'est fini. <br>
                                La main de Domi tremble quand elle saisit la boîte. Sa voix manque d'assurance.
                                Tiens, maman, prends tes médicaments du soir. On a failli les oublier. Tu vas
                                dormir maintenant. Tu verras, tu seras bien. Tranquille. Comme le Chemineau. <strong> En
                                    une
                                    harmonie simple avec la mélancolie des choses.</strong>
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal28 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de Prix : Un deux trois... Soleil</h3>
                <h4 class="auteurnouvelle">par Andrée Dewiere</h4>
                <p><i>« La route serpente, longue, blanche, vers les lointains bleus, vers les horizons
                        attirants. Sous le soleil... »</i> <br>
                    <br>
                    Domi lit à voix haute, en détachant soigneusement les syllabes ; un débit lent et
                    régulier auquel on a recours lorsque l'on veut s'assurer d'être compris. Domi lit avec
                    l'application d'une mère racontant une histoire à son enfant avant qu'il ne s'endorme, pour le
                    plonger dans un monde merveilleux où il pourra évoluer sans peur et sans cauchemars. Serein.
                    Une...
                </p>
                <div class="tourbutton">
                    <button @click="showModal28 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
        <div class="deuxnouv">
            <h2 class="titresection">2005 :</h2>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal29 }">
                    <div class="modal-background" @click="showModal29 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">La fin de la guerre : </h3>
                            <p>La guerre tirait à sa fin. Le père était revenu le premier du front. Puis les trois
                                filles et la mère qui avaient passé des hivers entiers dans les forêts, sur les routes
                                mitraillées, à marcher derrière leur charrette tirée par un cheval blessé. Dans leur
                                maison, à Saint-Avold, plus aucun des meubles familiers ne les attendait ; les soldats
                                allemands en avaient fait du petit bois pour se chauffer. Du tas de fumier du
                                poulailler, Rose et Bernard avaient extirpé la seule photo rescapée de ces cinq années
                                de guerre, le seul souvenir de trente années de leur vie : la photo de leur mariage. Le
                                chien, hirsute et squelettique, qui avait attendu leur retour d'exode était mort le jour
                                de leur arrivée ; il les avait entendus tourner au coin de la rue, il avait fourni son
                                dernier effort pour les accueillir, gémissant de souffrance, de joie et de bonheur. Il
                                était mort le soir même, son grand corps couvert de blessures purulentes et de caresses.
                                La voisine revenue quelques mois plus tôt leur raconta qu'il montrait les crocs aux
                                derniers Allemands qui forçaient la maison. Ils le chassaient en lui tirant dessus. Mais
                                ce jour d'automne 44, le chien avait eu sa récompense : les petites filles qu'il avait
                                vues partir dans un tintamarre de cloches affolées, il y avait déjà trois ans, Pélagie,
                                Delphine et Ginette, étaient revenues. Ginette marchait tout juste quand le curé avait
                                couru jusqu'à ce quartier reculé de Saint-Avold pour les informer qu'un train attendait
                                à la gare et qu'il fallait fuir tout de suite. Rose la portait sur le bras en plus de la
                                valise et les deux autres petites agrippaient chacune un coin de sa longue jupe grise.
                                Le chien les avait regardées s'éloigner de derrière la barrière de la cour, sans même
                                s'occuper des lapins que Rose avait relâchés.
                                Il les avait instantanément reconnues, plus maigres, grandies, plus graves et elles
                                aussi le reconnaissaient, retrouvaient l'odeur de son pelage, le pressaient en
                                sanglotant dans leurs jeunes bras fatigués. C'était lui qui les avait conduites jusqu'à
                                la maison. Ils en avaient tellement vu, tous, que le trou d'obus dans la cour à la place
                                du noyer, les fenêtres sans vitre, les tapisseries déchirées et les pièces vides, les
                                planchers arrachés, les murs troués d'impacts de balle, rien de toute cette dévastation
                                de leur vie passée ne les avait assommés. La maison était encore debout, et eux étaient
                                vivants, le père, Bernard, la mère, Rose, et les trois filles. Beaucoup de leurs amis,
                                d'oncles, de tantes, de cousins, de Saint-Naboriens n'avaient pas eu cette chance.
                                Il ne restait même plus un jouet. Juste jetés dans un coin, les mouchoirs que la Oma
                                avait brodés au nom de Delphine. Déchiquetés, ils avaient servi à nettoyer le canon des
                                fusils. Ils se mirent au travail dès le lendemain, comme tous leurs voisins, dans cette
                                Lorraine dévastée. Jour après jour, heure après heure, ils s 'attelèrent à remettre en
                                état d'abord le jardin parce qu'il fallait manger, puis le poulailler, les cages à
                                lapin, la cuisine. Heureusement, des cousins leur avaient procuré un poêle pour se
                                chauffer et cuisiner. L'hiver 44 était arrivé, plus froid que les autres hivers,
                                pourtant déjà difficiles à passer en Lorraine.
                                A l'hiver 45, la maison avait déjà retrouvé son rythme d'avant-guerre. Une fois les
                                devoirs faits, les premiers devoirs dans ce français auquel les petites ne comprenaient
                                pas grand-chose, elles montaient se coucher au premier, une bouillotte serrée sur le
                                ventre et elles se glissaient, jambes et bras étroitement repliés, entre dans des draps
                                raides de froid. Les parents montaient plus tard dans la grande chambre à côQé, guidés
                                par la lampe à pétrole pour économiser l'électricité réinstallée, mais aussi pour ne pas
                                se signaler, dans ce quartier écarté. Ils avaient tous gardé au ventre la peur des
                                alertes et il arrivait encore que quelque prisonnier hagard échappé du fond d'un camp,
                                errant depuis des mois dans les forêts, ne sachant même pas que la guerre était finie,
                                vienne s'écrouler devant une maison où jouaient des enfants. Ou que de pauvres gens, de
                                retour d'exode, à bout de forces, des mères taraudées par l'angoisse et l'espoir insensé
                                de trouver une trace de leur fils de vingt ans frappent à la porte pour quémander un
                                renseignement, déverser sans la miséricorde d'une rémission leurs chagrins et leurs
                                souvenirs.
                                Ce soir de décembre 1945, la neige s'était mise à descendre comme un rideau, lentement
                                mais sans cesse. En deux heures, elle avait tout enseveli, estompé les formes, absorbé
                                les quelques bruits qui passaient dans cette rue isolée. Les filles étaient montées se
                                coucher un peu plus tôt que d'habitude, déroutées par l'engourdissement de la nature. Le
                                père avait vérifié les portes et volets, la mère avait donné un dernier coup d'oeil au
                                feu pour qu'il tienne jusqu'au lendemain et après leur chapelet marmonné en commun sous
                                l'édredon, ils avaient baissé la mèche de la lampe et étaient prêts à s'endormir. II n'y
                                avait plus un bruit, plus un soupir, c'était comme si on s'évanouissait dans un rêve
                                calme et cotonneux.
                                Le bonnet enfoncé sur les oreilles, Bernard savourait ce silence, si différent des
                                hurlements, du tapage insensé du front, tous ces éclats de fer et de feu fichés dans de
                                la chair torturée qui le prenaient dès qu'il n'avait plus la tête occupée. La
                                respiration de Rose était déjà celle de l'endormissement. Leurs journées étaient si
                                harassantes. Il soupira et ferma les yeux à son tour.
                                A cet instant précis, la sonnette de la porte d' entrée retentit, transperçant
                                désagréablement le silence et les pémices du sommeil.
                                « Ah, non, pas la mère Gorny ! grogna Bernard, furieux. Ne réponds pas, Rose. Elle n'a
                                qu'à revenir dernain. » C'était la voisine la plus proche, à cent mètres. Veuve, elle
                                venait plusieurs fois par semaine apaiser ses peurs et ses mauvais souvenirs dans la
                                cuisine de la famille. Ses bavardages futiles cassaient les oreilles du et perturbaient
                                les filles occupées à leurs devoirs. Agacé, Bernard soutenait qu'elle venait se chauffer
                                gratuitement dans leur cuisine.
                                Réveillée, Rose était pourtant inquiète pour la veuve : « si elle est venue par ce
                                temps, c'est que quelque chose ne va pas. Va donc voir.
                                - Laisse-la sonner. Si c'est vraiment important, elle insistera ou elle appellera. Sinon
                                elle verra bien qu'il n'y a plus de lumière et elle repartira chez elle. »
                                Ni Bernard, ni Rose n'avaient de toute façon envie de ressortir un pied de dessous
                                l'édredon qui avait commencé à leur restituer la chaleur de leurs corps.
                                Du reste, il n'y eut plus de coup de sonnette.
                                - Elle a compris, tu vois, grommela Bernard en remontant l'édredon jusqu'au nez. Elle
                                reparûe. Dors. Bizarre quand même qu'elle soit venue par ce temps » ajouta-t-il pour
                                lui-même. Les petites n'avaient rien entendu. Rose se détendit et se mit à penser aux
                                plantes qui n'avaient pas été paillées et protégées du froid, dans le jardin. Le sommeil
                                commença à l'envahir, la préparant à la journée laborieuse qui l'attendait. Du jardin,
                                elle s'enfonça en quelques instants dans un rêve opaque et vague, vaincue par la
                                fatigue. Un coup de sonnette agressivement long la redressa brutalement sur ses
                                oreillers, le coeur battant. Puis un autre suivit tout de suite, moins insistant. Dans
                                l''obscurité, elle ne voyait pas son mari mais savait qu'il était réveillé, rien qu 'à
                                entendre son souffle devenu court. De la guerre, ils avaient pris l'habitude de ne pas
                                se manifester, de réfléchir vite, en silence, devant un événement inattendu. Ils ne
                                dirent rien, leurs sens en alerte, pendant deux ou trois minutes. Puis la sonnerie,
                                grêle et stridente, déchira de nouveau le silence de la maison. Les petites remuèrent
                                dans la pièce à côté.
                                « Qui ça peut être ? finit par chuchoter Rose. Pas la mère Gorny, cette fois... Un
                                soldat, tu crois ? Un blessé ?
                                - Quelqu'un qui a attendu qu'on s'endorme pour sonner de nouveau. Et ça n'a rien pour me
                                plaire, je t'assure. »
                                La stridulaüon de la sonnette ricocha trois fois de suite sur les murs nus et glacés du
                                corridor. Cette fois, les filles étaient réveillées. Elles appelèrent timidement. « Ne
                                vous inquiétez pas, je vais voir qui c'est ! répondit Bernard, et plus bas, à l'adresse
                                de sa femme : si c'est la mère Gorny qui s'amuse à ça,je te préviens qu'elle ne remettra
                                plus les pieds ici.
                                - Oh, Bernard, elle a peut-être un gros problème, plaida Rose. Ou c'est peut-être
                                quelqu'un de perdu. Ne juge pas avant de savoir. Va voir par la fenêtre de la chambre de
                                devant. »
                                Elle n'avait pas besoin de lui recommander la prudence, ni aux filles qui ne pipèrent
                                mot tandis qu'il traversait leur chambre et qu 'il tournait très lentement la clenche de
                                la fenêtre qu'il entrouvrit ; il passa l'index par la fente, souleva sans bruit le
                                loquet du volet et le poussa de deux centimètres. Il patienta quelques minutes, puis
                                colla brièvement l'oeil à la fente. Il saisit dans son champ de vision que tout était
                                blanc à l'extérieur et que la neige continuait à tomber. Il balaya d'un regard incisif
                                et systématique, comme par la lorgnette de son fusil, l'allée qui menait à la porte
                                d'entrée, puis la large descente vers la cour, enfin le dessus du petit toit en verre
                                qui surplombait la porte. Dans tout ce blanc phosphorescent, il était impossible qu'une
                                silhouette passe inaperçue.
                                Mais il n'y avait personne ; le mauvais plaisant était repartit pendant qu'il se levait.
                                Maussade, il rabattit le volet bruyamment cette fois et enclencha le loquet. II avait
                                encore la main sur la poignée de la fenêtre, lorsque la sonnette retentit clairement,
                                moqueuse cette fois, lui sembla-t-il.
                                Pris au dépourvu, il jura, rouvrit brutalement fenêtre et volet, au mépris de toute
                                prudence et se pencha rapidement. Cet imbécile devait être collé contre la porte pour
                                qu'il ne l'ait pas vu la première fois. II en aurait le coeur net.
                                « Rose, apporte-moi le fusil ! Et vous, montrez-vous et dites-nous ce que vous voulez !
                                »
                                Sa femme était déjà arrivée dans son dos et lui tendait le fusil, tout en le tirant en
                                arrière par sa chemine de nuit. Agacé, il lui arracha presque l'arme des mains, écarta
                                avec le canon le volet et le pointa vers le petit toit, Dans la nuit, le bruit qu'il fit
                                en armant le fusil de guerre claqua comme un choc de métal contre le silence.
                                Il n'y eu pas de réponse. devinait Rose qui tremblait dans son dos, de peur et de froid,
                                mais elle intima à voix basse aux filles de ne pas sortir de leur lit et se pencha
                                furtivement à son tour pour vérifier si autour de la maison il n'y avait pas quelqu'un
                                que son mari ne voyait pas, tendu qu'il était vers la porte, Mais elle non plus
                                n'aperçut personne. Elle se pencha rapidement encore un peu plus, tentant d'apercevoir
                                les pieds qui devaient forcément dépasser de dessous le petit toit. Non, on ne voyait
                                rien, rien de rien. Elle recula d'un pas dans la chambre, surprise. ElIe sentait que
                                quelque chose lui échappait.
                                Des pieds... De la neige immaculée... Et la sonnette qui se remettait à sonner. Des
                                larmes amères d'angoisse lui brûlèrent d'un coup les yeux. Il y avait quelque chose de
                                terrible là-dehors, qu'ils n'avaient remarqué tout de suite. Et cette sonnette qui
                                continuait à sonner !
                                Il y avait que la neige tombait depuis des heures maintenant, réfléchit Rose en
                                repassant mentalement devant ses yeux le paysage qu'elle avait vu, et il y avait aussi
                                que dans toute cette blancheur, n'apparaissait aucune trace de pas.
                                « Bernard, it n'y a pas de traces... » chuchota-t-elle. devait avoir compris en même
                                temps, car il se retira de la fenêtre les sourcils froncés, le doigt en suspens sur la
                                détente- Pélagie appela d'une voix inquiète, mais Rose, accaparée par son idée, ne
                                l'entendit pas. « C'est peut—être un blessé, il est collé contre la porte depuis tout ce
                                temps, peut-être qu'il ne peut sonner que par moments. Tiens, ça sonne, encore une fois.
                                Bernard, il est doit être mourant, il faut aller voir. »<br>
                                Il avait pris son parti : il balaya lentement de la pointe du fusil l'espace autour de
                                la porte. "Qui que vous soyez, déclara-t-il d 'une voix ferme, vous êtes ici dans une
                                maison accueillante. Si vous avez besoin montrez-vous. Nous comprenons aussi l'allemand.
                                Nous ne vous ferons pas de mal. Mais je vous préviens que nous sommes plusieurs et armés
                                et que si vous... » s'interrompit, stupéfait. La sonnette sonnait sans discontinuer,
                                couvrant sa voix.<br>
                                « Voilà quelqu'un qui peut sonner, effrontément, mais ni se montrer, ni parler ? Ca
                                alors ! Rose. va chercher le revolver. Tu restes à la fenêtre. Et pas de sentiments,
                                cette fois. Tu tires.<br>
                                - Pélagie, Delphine, Ginrtte vous affolez pas. Il y a quelqu'un qui nous prend pour des
                                imbéciles, je vais voir qui c'est. Ginette: file dans le petit grenier, avec l'édredon.
                                Tu ne sortiras que si je viens te chercher, ou maman. tu dors, tu attends qu'il fasse
                                jour et tu ne sortiras que si tu reconnais des voix. Compris ?<br>
                                La petite n'avait pas cinq ans, mais elle savait ce que cela voulait dire. Elle ravala
                                ses larmes et courut sur la pointe des pieds vers le grenier, le coeur battant à tout
                                rompre lorsqu'elle traversa le palier obscur et hostile, traînant l'édredon sur son dos.
                                « Pélagie, prends le tisonnier. Tu sais que si tu tapes sur la tête, tu peux faire très
                                mal. Tu peux aussi taper sur l'arme et la faire tomber. Mais il faut faire d'un coup,
                                très vite et sans hésiter. Compris ? Delphine, tu descend avec nous. Je vais avoir
                                besoin de toi. » Il avait pas une parole en trop et on aurait presque pu entendre
                                chuinter la neige. La sonnette rompit tout ce silence et donna le signal. père sortit de
                                la chambre, Pélagie et Delphine collées à ses talons et descendit l'escalier, à toute
                                vitesse et sans bruit, comme un chat. Il tenait son fusil pointé vers le centre de la
                                porte.<br>
                                La sonnette ponctua leur descente silencieuse.<br>
                                A côté de la porte d'entrée, il y avait les toilettes, éclairées par une minuscule
                                lucarne en hauteur. Pélagie se posta sur un signe de son père à l'angle du mur des
                                toilettes ; il réfléchit rapidement, lui prit le lourd tisonnier des mains, le posa
                                contre le mur, à sa propre portée et lui mit le fusil à l'épaule. Puis il expliqua par
                                gestes à Delphine, la plus agile, qu'il allait la hisser jusqu'à la lucarne. Elle lui
                                fit signe qu' elle avait compris son plan.<br>
                                Dès que son père croisa ses mains, elle y cala son pied nu. La seconde d'après, ses yeux
                                arrivaient au ras de la lucarne ; la neige ne l'opacifiait pas, car elle était protégée
                                par le petit toit de la porte d 'entrée. Mais Delphine n eut pas le temps de regarder.
                                La sonnette sonna brusquement, tout près de son oreille, comme un avertissement. La
                                petite se baissa aussitôt, dans un réflexe de survie et ne bougea plus. Elle était à
                                quelques centimètres de la tête de quelqu'un d'autre. Bernard attendit que la frayeur
                                lui passe et imprima un petit mouvement au buste de sa fille. Elle approcha de nouveau
                                sa tête de la surface glacée et se força à ne pas fermer les yeux. D'abord, dans la
                                première fraction de seconde, elle perçut le lointain, d'un blanc opaque. Tout était
                                pareil. Il nb' avait pas forme humaine dans cette uniformité fantomatique. Dès qu' elle
                                eut enregistré cette information, elle ramena immédiatement son regard contre la maison.
                                Cela pouvait être une question de vie ou de mort. Dans son champ de vision entra d'un
                                coup au moins la moitié de la porte.<br>
                                - Papa, descends-moi, il n'y a personne.<br>
                                Elle le suppliait à voix très basse.<br>
                                - Ce n'est pas possible, Delphine, gronda-t-il sourdement. Tu n'as pas regardé. Ca sonne
                                ! Regarde vite ! » n avait honte de la brusquer, mais il le fallait- Il pensait à sa
                                femme avec son revolver là-haut, à sa petite terrée dans le grenier avec comme seule
                                protection un édredon, à son aînée qui à quatorze ans tenait un fusil en main, prête à
                                tuer. Il fallait en finir.<br>
                                - Allez, regarde ! Ca sonne !<br>
                                Cette fois, la petite écrasa son nez contre la lucarne, dans le coin qui lui permettait
                                de voir le plus grand pan possible de la porte.<br>
                                - Papa, il n'y a personne ! Je te le jure ! Laisse-moi descendre !<br>
                                Elle se débattit. Mais pendant que la sonnette sonnait à nouveau, il ne lui lâchait pas
                                les jambes.<br>
                                - Delphine, tu vas ouvrir la lucarne, chuchota-t-il impérieusement. Pélagie ? Tu
                                m'entends ? Quand je donnerai un petit coup dans le mur, tu taperas avec le fusil contre
                                la porte. Prépare-toi.<br>
                                Delphine, vas-y ! Ouvre, regarde ! »<br>
                                Pélagie seule contre son mur dans le corridor glacé était transie de terreur. Elle donna
                                un coup mal mesuré qui ébranla la porte.<br>
                                Sa soeur ouvrit à ce moment précis la lucarne. Elle voyait tout maintenant, très bien.
                                La neige éclairait la nuit.<br>
                                Il fallut que son père la tirât brutalement en arrière pour qu'elle réagisse.<br>
                                « Alors ?<br>
                                - Je ne vois personne.<br>
                                ElIe avait parlé d'une voix normale. II la regarda droit dans les yeux, songeur un
                                instant, puis la hissa à nouveau.<br>
                                - Ecoute-moi bien : regarde s'il y a des traces dans la neige. »
                                La petite repassa le bout de son nez par la lucarne, mais elle répondait déjà en même
                                temps, parce qu'elle avait bien gravé dans son cerveau tout ce qu'elle avait vu. La
                                neige étal t impeccable et demain matin ce serait elle qui Impnmeralt la première belle
                                trace avec ses galoches.<br>
                                Et pendant qu'elle contemplait cette belle neige qui tant de plaisirs à l'enfant qu'elle
                                était encore, la sonnette sonna à quelques centimètres de sa tête. Elle voyait
                                parfaitement le petit rectangle noir avec son bouton crème. Personne n'appuyait dessus,
                                ni blessé, ni mère Gorny, et pourtant elle sonnait.<br>
                                La petite n'avait plus vraiment peur, parce que la guerre lui avait appris que c'était
                                des humains qu'il fallait avoir le plus peur. Et là, il n'y avait pas d'humain. Mais sa
                                Oma croyait à tout ce qu'on ne voyait pas. Et sa mère avait tellement prié devant une
                                image de la Vierge, sous les bombes, et la Vierge l'avait entendue, tout de même...
                                Delphine regardait la sonnette en pensant à tout cela. La neige tombait plus dru. monde
                                était très étrange, décidément. Un flocon lui passa devant le nez et se posa sur la
                                sonnette. Toute contente de ce compagnon imprévu et éphémère, la sonnette tinta.
                                Delphine eut un petit sourire. Elle attendit. Un deuxième flocon arriva, un peu de
                                travers. Il se posa sur la sonnette, et la coquine sonna.<br>
                                Papa, c'est les flocons... Je crois qu'il y a un court-circuit. »<br>
                                C'était vraiment la fin de la guerre, la fin des grandes frayeurs.<br>
                                Il ne fallait plus y penser. Seulement aux éclats de rire demain pendant la bataille de
                                boules de neige.<br>
                                - Dieu soit loué ! » s'exclama Bernard.</p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal29 = false"></button>
                </div>
                <h3 class="titrenouvelle">Lauréat : La fin de la guerre</h3>
                <h4 class="auteurnouvelle">par Sylviane Chaumont-Gorius</h4>
                <p>La guerre tirait à sa fin. Le père était revenu le premier du front. Puis les trois filles et la mère
                    qui avaient passé des hivers entiers dans les forêts, sur les routes mitraillées, à marcher derrière
                    leur charrette tirée par un cheval blessé. Dans leur maison, à Saint-Avold, plus aucun des meubles
                    familiers ne les attendait ; les soldats allemands en avaient fait du petit bois pour se chauffer.
                    Du tas de fumier du poulailler...</p>
                <div class="tourbutton">
                    <button @click="showModal29 = true" id="button">
                        En lire plus...
                    </button>
                </div>

            </div>
            <div class="unenouv">
                <div v-bind:class="{ modal: true, 'is-active': showModal30 }">
                    <div class="modal-background" @click="showModal30 = false"></div>
                    <div class="modal-content">
                        <div class="box">
                            <h3 class="titrenouvelle">Une vengeance posthume</h3>
                            <p>Au volant de sa petite voiture, Hélène se dirigeait vers la clinique où se trouvait
                                Simon.
                                Littéralement paniquée, elle allait assister à I'événement... <br>
                                Il y avait un peu plus d'un mois, Hélène et Simon, jeune couple de parisiens, avaient
                                été
                                invités en ce début d'été 2012 au mariage d'un cousin en grande banlieue. Beaucoup de
                                monde Au buffet qui avait suivi la cérémonie, Hélène s'était rendu compte qu'un couple
                                s'intéressait de près à Simon. Surtout la femme ! Elle le dévorait des yeux ! Très
                                provocante,
                                habillée d'une robe commençant tard et finissant tôt. Elle se faisait volontairement
                                rernarqtrr
                                par sa de s'exprimer Ainsi elle disait à propos de tout et de n'importe quoi .
                                Oh ! C'est trop tendance !<br>
                                Quant au mari, très homme d'affaires moderne, pour lui tout était 'top grave'. Simon
                                flatté n' essayait pas de s'esquiver vers des personnages moins voyants. Au grand
                                agacement
                                d'Hélène, jalouse sans l'avouer. Elle parvint néanmoins à l'entraîner vers les jeunes
                                mariés.
                                Tout le reste de lajournée la jeune femme se débrouilla pour ne plus tomber dans les
                                mailles
                                des deux autres, malgré pensait-elle les efforts de ces derniers surtout la femme les
                                Bref, tout a une fin et il fallu reprendre la direction de la capitale l'autoroute. Dans
                                la
                                voiture de Simon, pour des raisons professionnelles chacun avait la sienne, Hélène enfin
                                détendue se moqua de monsieur et madame 'tendance top grave' ! Simon trouva I
                                'expression
                                très adaptée à la réalité et s'en amusa fort. N'empêche, pensa-t-il in petto, elle est
                                rudement
                                bien roulée la snobinarde. Ridicule. Mais bien roulée. <br>
                                Pratiquement à l'entrée de Paris Porte d'Orléans, se fut le drame. Un conducteur le
                                contrôle de son véhicule se mit en travers et tous ceux qui suivaient s'encastrèrent les
                                uns
                                dans les autres. Bruits de tôles froissées, cris. pleurs, explosions, appels à l'aide !
                                police, pompiers, SAMU, les secours furent vite sur place. Hélène s'en tirait avec des
                                contusions heureusement sans gravité. Ce fut une autre histoire pour Simon Malgré sa
                                ceinture de sécurité bouclée sa tête avait été projetée dans le pare brise.
                                Des urgences de la Pitié-Salpétrière il avait été transféré sans connaissance vers une
                                clinique srÉcialisée dans la chirurgie réparatrice. Bien sûr avec l'accord de sa femme
                                qui
                                devait rester en observanon quelques heures. <br>
                                Dès que cela tût possible Hélène se rendit au chevet de Simon. Il était toujours en
                                réanimation plongé dans un coma artificiel. La tête complètement enveloppée dans
                                pansements et bandes. Seuls les yeux n'étaient pas cachés. par bonheur ils n'avaient été
                                touchés. Pour le moment, ils étaient évidemment fermés. Hélène ne put le voir qu'à
                                travers
                                une vitre. IR chirurgien responsable de la clinique la reçut dans son bureau. D'abord il
                                la
                                rassura sur l'état de santé générale de Simon. pour autant il ne lui cacha pas la
                                gravité des
                                blessures au visage. Son mari resterait probablement défiguré, malgré de nombreuses
                                et des séjours non moins nombreux à la clinique... <br>
                                -A moins que.. ajouta-t-il en plongeant son regard dans celui de la jeune femme,
                                -A moins que je lui transplante un autre visage. Mais c'est à vous deux de décider
                                Hélène la proposition comme un coup de poing et resta sonnée quelques Instants.
                                -Ca veut dire quoi un autre visage ? demanda-t-elle d' une voix blanche. <br>
                                -Ca veut dire que nous allons appliquer sur les os et les muscles faciaux votre mari
                                un épiderme venant d' un donneur décédé. Anonyme bien sûr, C'est intervention
                                de micro chirurgie très délicate mise au point par un praticien anglais il y a déjà une
                                dizaine d'années. J'en ai moi-même réalisé plusieurs sans aucun problème. Mais dans
                                la discrétion la plus absolue. <br>
                                -Parce que ce n'est pas légal ? <br>
                                -Bien sûr que si ! Pour autant il n'est pas souhaitable pour la tranquillité des
                                familles
                                du receveur et du donneur de faire trop de bruit autour de ce type d' intervention. <br>
                                Admettons que nous acceptions. Mon mari risque d'avoir une autre tête ? <br>
                                -Pas vraiment. En gros ce sera toujours sa tète. Néanmoins son visage ne sera plus tout
                                fait le même. Mais c'est mille fois mieux qu' un visage couvert de cicatrices même
                                les moins voyantes possibles. <br>
                                -Je dois réfléchir, docteur. De plus il me faut absolument son avis à lui. <br>
                                -Tout à fait d'accord, madame. Nous allons d'abord soigner ses fractures et son
                                traumatisme crânien. Puis quand il sera en mesure de comprendre la situation nous
                                vous laisserons prendre ensemble la déctsion de votre choix. <br>
                                Hélène était rentrée chez elle très perturbée. Décidément les hommes de sa vie n'avaient
                                pas de chance. Bien sûr cette fois-ci elle n'y était pour rien ! Quoique ce qui était
                                arrivé à
                                Jérôme, le grand amour de ses 16 ans, n'était pas vraiment de sa faute à elle. S'il ne
                                l'avait
                                pas trompé rien ne serait arrivé Mais avec des si... <br>
                                Deux semaines plus tard Simon, la tête toujours enveloppée de bandes, fut en mesure de
                                réaliser la situation. En présence d'Hélène le chirurgien lui présenta les deux options
                                possibles. La première : une série d'interventions sur son visage. Ce serait long. Il
                                lui faudrait
                                faire des séjours nombreux la clinique. De plus malgré tous les soins apportés à ces
                                interventions son visage en porterait définitivement les séquelles. Deuxième option :
                                une
                                greffe complète du visage. D'où une seule mais très longue opération Avec résultat un
                                visage légèrement transformé mais sans affreuses cicatrices ! <br>
                                Le chirurgien termina par ses mots <br>
                                A vous et à votre femme de décider. Je vous laisse. Ne soyez trop long. J'ai un
                                donneur. Comme vous pouvez l'imaginer, il n'est pas possible de lE faire attendre
                                trop longtemps ! <br>
                                Le lendemain quand Hélène revint, elle apprit à Simon que les jeunes mariés s'étaient
                                inquiétés de sa santé. Ils viendraient le voir dès que ce serait Ils avaient ajouté que
                                deux autres couples avaient été victimes du terrible carambolage.
                                Puis après avoir parlé de tout et de rien Simon et Hélène prirent leur décision. Ca
                                serait la
                                deuxième option : la greffe. <br>
                                Alors le chirurgien fixa la date. Composa son équipe. Le corps du donneur leur fut
                                amené. Et tout alla très vite. On prépara Simon et le jour dit très tôt tout commença.
                                C'est vers 2 1 h30 que le téléphone sonna chez Hélène à qui on avait fortement
                                déconseillé
                                d'attendre à la clinique. Elle décrocha- C'était le chirurgie <br>
                                -Tout c'est très bien passé. Votre mari est maintenant en chambre stérile. Il ne
                                reprendra connaissance que demain dans la journée. <br>
                                -Je pourrai le voir avec son nouveau visage ? <br>
                                -Vous pourrez le voir, mais il sera toujours sous bandage. Il faudra attendre le début
                                de
                                la semaine prochaine pour que nous les lui retirons. Ne vous inquiétez pas. Je vous le
                                répète tout va très bien. <br>
                                Hélène heureuse, rassurée raccrocha. Et elle alluma la radio sur une station
                                d'informations continue qu 'elle écoutait en se préparant un semblant de dîner. Soudain
                                une
                                phrase du journaliste lui glaça le sang. Il disait que courrait la rumeur qu'une
                                transplantation
                                très importante s'était déroulée dans une clinique spécialisée en microchirurgie.
                                L'homme
                                ajoutait que ce n'était certes pas la première fois, mais que l'originalité venait du
                                donneur
                                un condamné à perpétuité pour viol qui venait de se suicider en prison après avoir fait
                                le don
                                de ses organes à la médecine. Atterrée Hélène n'écoutait plus la radio. Elle se
                                demandait si
                                cette rumeur ne concernait pas la clinique Où se trouvait Simon et s'il n 'était
                                question de
                                son opération à lui. Le donneur un homme condamné pour viol ! Etait-ce son passé qui lui
                                revenait comme un boomerang. <br>
                                Quelques années avant son mariage, Hélène qui vivait dans une ville du centre de la
                                France était tombée follement amoureuse d'un garçon un peu plus âgé qu'elle. Elle 16
                                ans,
                                lui 22. Tous les deux membres de la chorale locale. Leur aventure avait commencée lors
                                d'un
                                déplacement dans une ville voisine. La très jeune fille avait nettement montré au garçon
                                qui
                                Jérôme qu'il lui plaisait. Elle était très jolie et Jérôme profita de sa chance. Ce fut
                                d'abord un flirt très sage, puis de plus en plus chaud... Mais tout le monde le savait,
                                le cher
                                Jérôme très sensible au charme féminin avait un cœur volage. LI tomba amoureux d'une
                                autre
                                fille Laura. Lui, deux en même temps, cela ne le gênait pas. Mais ses deux conquêtes
                                quand
                                elles s'en rendirent compte ne l'entendirent pas de cette oreille. Surtout Hélène qui se
                                voyait
                                déjà mariée. Les deux filles décidèrent de se venger en le ridiculisant. Un piège fut
                                monté.
                                Pendant qu'Hélène s' arrangerait pour être seule avec le jeune homme dans la salle de la
                                chorale et ferait semblant d'aller le plus loin possible avec Jérôme, Laura amènerait
                                comme
                                par hasard quelques copains et copines dans cette salle. Alors au moment où ces témoins
                                involontaires y entreraient Hélène hurlerait, se débattrait en faisant croire à une
                                tentative de
                                viol. <br>
                                Tout se déroula comme prévu. Toutefois un grain de sable peut détraquer les plus belles
                                mécaniques. Là ce ne fut pas un grain mais un gros tas de sable. Ce qui ne devait être
                                qu'une
                                comédie tourna à la tragédie. Hélène et Laura n'avaient pas tenu compte des parents.
                                Ceux
                                d'Hélène prirent très mal la chose et portèrent plainte contre Jérôme. Les deux copines
                                n'osèrent pas se désavouer et expliquer ce qu'elles avaient tramer. La suite est facile
                                à
                                imaginer. Jérôme fut arrêté, mis en examen tentative de viol sur mineure et incarcéré
                                jusqu'au procès en Cours d'assise. Là encore devant les magistrats et les jurés, Hélène
                                ne
                                revint pas sur ses dépositions. Les témoignages des autres jeunes furent accablants. Sa
                                réputation de coureur de jupons faisant le reste, le pauvre Jérôme malgré ses
                                dénégations et la
                                plaidoirie de son avocat fut condamné à la prison à perpétuité avec une peine
                                incompressible
                                de vingt ans. A I 'époque la sévérité du verdict ne surprit pas. Plusieurs sordides
                                affaires de
                                « tournantes » avaient scandalisé à juste titre l'opinion publique. <br>
                                Maintenant dix ans plus tard Hélène avait encore en souvenir le regard désespéré que
                                Jérôme lui avait lancé avant de quitter le Tribunal entre deux gendarmes.
                                A sa majorité, Hélène ne supportant plus de vivre dans la ville de son mensonge, décida
                                de la quitter. Elle venait d'apprendre que Jérôme avait fait une tentative de suicide...
                                Malgré
                                l'opposition de ses parents elle monta à Paris Où elle trouva rapidement du travail dans
                                un
                                grand magasin. Dans l'anonymat de la Capitale, elle retrouva peu à peu la joie de vivre
                                et le
                                souvenir du drame s'estompa Puis elle rencontra Simon ingénieur informaticien dont le
                                regard la subjugua Elle ne lui cacha pas ce qui était arrivé. mais elle s'en tint la
                                version
                                officielle. Simon la plaignit et l'entoura de tout son amour. Les deux jeunes gens lui
                                grand
                                brun aux yeux bletLS et elle jolie blonde menue se marièrent, dénichèrent un 1Etit
                                dans le I arrondissement pres de la Nation. Ils vivaient là heureux jusqu'à
                                ce maudit accident. <br>
                                Donc ce soir là pour Hélène il n'y avait pas de doute possible, information qu'elle
                                venait d'entendre les concernait. Jérôme avait finalement réussi à se suicider et
                                c'était son
                                visage qui allait être greffé sur la face de Simon. Ainsi toute sa vie elle aurait sous
                                les yeux
                                une partie de la figure de sa victime. Vengeance posthume, certes involontaire, mais
                                elle se
                                l'avouait bien méritée. <br>
                                Le jour suivant à la clinique, elle vit Simon derrière la vitre de la chambre stérile.
                                Elle
                                put encore l'approcher et lui parler. Il tourna la tête vers elle et la hocha lentement
                                plusieurs fois. chirurgien lui expliqua qu'il était toujours sous l'effet de
                                l'anesthésie et
                                qu' il ne en faire plus. Il lui conseilla de revenir le lendemain Simon serait en
                                mesure de dire quelques mots. <br>
                                Avant de quitter la clinique Hélène se demanda avec anxiété quelle tête était sous les
                                bandages. Celle de Simon ? Celle de Jérôme ? Un mélange des deux ? Pas de réponse bien
                                sûr et à qui se confier ! Elle passa tous les après-midi des quelques jours qui
                                suivirent près de son mari. Il avait quitté la chambre stérile. Il parlait Elle parlait
                                Anxieux
                                pour des raisons différentes ils attendaient. Enfin le chirurgien leur dit :
                                -C'est pour demain 10 heures. <br>
                                Dans le de la clinique, elle resta prostrée un moment dans la voiture. Elle
                                aurait voulu que tout s'arrête... Bien sûr pensait-elle une chose est certaine c'est son
                                regard,
                                donc ce sont ses yeux... Alors elle quitta la voiture elle se dirigea vers l' ascenseur.
                                Là nouvel
                                arrêt, la tète lui tournait Elle ne voulait plus savoir ! T'as pas le choix sembla lui
                                dire sa
                                conscience. Tu dois assumer Comme une automate elle pris l'ascenseur, monta à l'étage et
                                se dirigea vers la chambre de Simon. Là encore elle s'arrêta quelques secondes, respira
                                un
                                grand coup et entra <br>
                                Le chirurgien était là. Elle apperçut que cet homme était avec eux et devinait leur
                                anxiété.
                                Et encore il ne savait pas tout. <br>
                                Nous allons retirer bandages et pansements. Mais il faudra encore quelques jours de
                                traitement ici à la clinique, dit-il. Puis assisté d'une infirmière et entouré de ses
                                collaborateurs prêts à toute éventualité, il entreprit de découvrir le visage de Simon.
                                L'opération parue durer des siècles à la jeune femme. Enfin le visage de Simon apparu...
                                Hélène frappée de stupeur ouvrit la bouche, mais ne put prononcer une seule parole.
                                Simon qui regardait sa femme devina qu'il y avait quelque chose. Il arracha la glace que
                                lui
                                tendait l'infirmière et se regarda. De surprise la glace lui échappa... Il ne pu dire
                                que
                                quelques mots <br>
                                monsieur Tendance top grave ! <br>
                                vous connaissiez le donneur ? demanda le chirurgien décontenancé. <br>
                                Quelques heures plus tard tout était clair. Le couple 'Tendance top grave', parisien
                                aussi,
                                ayant quitté la noce presque derrière Simon et Hélène avait été victime du carambolage.
                                La
                                femme s'en était sortie, mais pas l'homme. Les deux avaient dans leurs papiers une
                                lettre
                                acceptant le don d'organes en cas d'accident. Le chirurgien ignorant la rencontre entre
                                les
                                deux couples avait choisi le malheureux pour sa santé apparente. Oui il était au courant
                                de la
                                rumeur à propos du condamné suicidé mais ça ne pouvait faire l'affaire. Le sujet étant
                                un
                                vieil homme ancienne personnalité dans son département et condamné après les
                                faits. <br>
                                Le soir chez elle Hélène encore seule fit le point. De toute façon et c'était
                                l'essentiel
                                l'opération avait parfaitement réussi , pensait-elle. Dans quelques jours Simon serait
                                rentré Il
                                lui plaisait toujours autant ! Son visage était un peu différent, mais c'était
                                supportable ! Et il
                                avait toujours son magnifique regard ! Il n'y avait pas eu de vengeance posthume et la
                                vie
                                reprendrait comme avant <br>
                                Comme avant c'était vite dit ! Rien ne va jamais comme on le souhaite. Eh oui le grain
                                de sable <br>
                                -Le téléphone sonna_ Hélène presque joyeuse décrocha <br>
                                Hélène ? vous vous souvenez certainement de moi. Nous nous sommes rencontrés
                                lors du mariage d'un cousin commun. Mon mari et moi avons été victimes du même
                                carambolage que vous. Moi je m'en suis tirée. Mais lui est mort. Je viens d'apprendre
                                qu'on a greffé son visage sur la figure de Simon. Me permettez vous d'aller le voir ?
                            </p>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="showModal30 = false"></button>
                </div>
                <h3 class="titrenouvelle">Mention de prix : Une vengeance posthume</h3>
                <h4 class="auteurnouvelle">par Gérard Chatron</h4>
                <p>Le résumé de la nouvelle</p>
                <div class="tourbutton">
                    <button @click="showModal30 = true" id="button">
                        En lire plus...
                    </button>
                </div>
            </div>
        </div>
    </div>
    <FooterCompo />
</template>

<script>

import NavbarCompo from '@/components/NavbarCompo.vue';
import FooterCompo from '@/components/FooterCompo.vue';
export default {
    name: 'NouvellesVue',
    data() {
        return {
            showModal1: false,
            showModal2: false,
            showModal3: false,
            showModal4: false,
            showModal5: false,
            showModal6: false,
            showModal7: false,
            showModal8: false,
            showModal9: false,
            showModal10: false,
            showModal11: false,
            showModal12: false,
            showModal13: false,
            showModal14: false,
            showModal15: false,
            showModal16: false,
            showModal17: false,
            showModal18: false,
            showModal19: false,
            showModal20: false,
            showModal21: false,
            showModal22: false,
            showModal23: false,
            showModal24: false,
            showModal25: false,
            showModal26: false,
            showModal27: false,
            showModal28: false,
            showModal29: false,
            showModal30: false,

        };
    },
    methods: {
        closeAllModals() {
            this.showModal1 = false;
            this.showModal2 = false;
            this.showModal3 = false;
            this.showModal4 = false;
            this.showModal5 = false;
            this.showModal6 = false;
            this.showModal7 = false;
            this.showModal8 = false;
            this.showModal9 = false;
            this.showModal10 = false;
            this.showModal11 = false;
            this.showModal12 = false;
            this.showModal12 = false;
            this.showModal14 = false;
            this.showModal15 = false;
            this.showModal16 = false;
            this.showModal17 = false;
            this.showModal18 = false;
            this.showModal19 = false;
            this.showModal20 = false;
            this.showModal21 = false;
            this.showModal22 = false;
            this.showModal23 = false;
            this.showModal24 = false;
            this.showModal25 = false;
            this.showModal26 = false;
            this.showModal27 = false;
            this.showModal28 = false;
            this.showModal29 = false;
            this.showModal30 = false;

        },
    },
    mounted() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this.closeAllModals();
            }
        });
    },
    components: {
        NavbarCompo,
        FooterCompo,
    },
};
</script>

<style scoped>
.titrepage {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    margin-bottom: 0.5em;

}

#button {
    background-color: #319093;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 1em;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
}

.container {
    margin-left: 0.5em;
}

.deuxnouv {
    margin-left: 1em;
}

.unenouv {
    border: 2px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    width: 95vw;
    margin-bottom: 2em;
    padding: 1em;
}

.titrenouvelle {
    font-family: 'Amatic SC', serif;

    font-size: 2em;
}

.auteurnouvelle {
    font-family: 'Dancing Script', cursive;
    font-size: 1.5em;
    color: grey;
}

.tourbutton {
    display: flex;
    justify-content: end;

}
</style>