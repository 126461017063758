<template>
    <NavbarCompo />

    <div class="container">
        <h2 class="titrepagepart">Participer : </h2>
        <div class="consignes">
            <h3 class="titresectionpart">Consignes : </h3>
            <p>Vous pouvez candidater au prix Odette Massfleder directement depuis ce site <b>Passion Nouvelle</b>. <br>
                ATTENTION, pour ce faire, vous devrez avoir renseigner toute la partie <span
                    style="font-family: 'Dancing Script', cursive; font-size: 1.5em;">Participer</span> . De plus, votre
                nouvelle devra être enregistrée au format pdf et ne devra pas excèder 2GO.
                Une fois ces conditions remplies, cliquez sur le bouton choisir un fichier (ci-dessous), et explorez
                votre répertoire
                pour trouver votre nouvelle. Puis double-cliquez dessus. Vous pourrez ensuite appuyer sur le bouton
                envoyer.
            </p><br>
            <p> Attention, même si vous participez via le site vous devez vous acquitter des frais d'inscription (cf <a
                    id="inline" href="/reglement">règlement - article 2</a>).</p>
        </div>
        <h3 class="titresectionpart">Participer : </h3>
        <form @submit.prevent="submitForm">
            <div class="field">
                <label class="titresoussection">Nom</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Dupond" v-model="form.lastName" required>
                </div>
            </div>

            <div class="field">
                <label class="titresoussection">Prénom</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Antoine" v-model="form.firstName" required>
                </div>
            </div>

            <div class="field">
                <label class="titresoussection">Email</label>
                <div class="control">
                    <input class="input" type="email" placeholder="antoinedupond@mail.com" v-model="form.email"
                        required>
                </div>
            </div>
            <div class="field">
                <input id="file" @change="handleFileChange" type="file" accept=".pdf" required style="display: none">
                <label for="file" class="button">Choisir un fichier</label>
                <span v-if="fileName" class="labelinputfile">Vous avez choisi {{ fileName }}</span>
                <!-- Ajoutez cette ligne -->
            </div>
            <div class="field">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" required>
                        J'ai pris connaissance du <router-link to="/reglement" id="inline">règlement</router-link>
                    </label>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" required>
                        J'ai conscience que je dois m'acquitter des frais de participation, même si je candidate par
                        mail.
                    </label>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <label class="checkbox" required>
                        <input type="checkbox">
                        J'ai bien ajouté à ma nouvelle une page de garde détaillant mon nom, mon prénom
                        et
                        mon adresse mail.
                    </label>
                </div>
            </div>


            <div class="field is-grouped">
                <div class="control">
                    <button id="button">Valider la candidature</button>
                </div>
            </div>
        </form>
        <p>Télécharger <a id="inline" href="RIB.pdf" download="RIB.pdf"> le rib de l'association </a> pour s'acquitter
            des frais de règlements</p>
    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '@/components/NavbarCompo.vue'
import FooterCompo from '@/components/FooterCompo.vue'
import axios from 'axios';
export default {
    name: 'ParticiperVue',
    // Your component's logic goes here
    components: {
        NavbarCompo,
        FooterCompo

    },
    data() {
        return {
            form: {
                firstName: '',
                lastName: '',
                email: '',
                file: null
            },
            fileName: ''
        }
    },
    methods: {
        handleFileChange(e) {
            this.form.file = e.target.files[0];
            this.fileName = e.target.files[0].name;
        },
        submitForm() {
            let formData = new FormData();
            formData.append('firstName', this.form.firstName);
            formData.append('lastName', this.form.lastName);
            formData.append('email', this.form.email);
            formData.append('file', this.form.file);

            // Afficher l'alerte "chargement"
            alert('Chargement...');

            axios.post('https://tasteful-bramble-slicer.glitch.me/submit-form', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Fermer l'alerte "chargement" et afficher l'alerte de succès
                alert('Formulaire soumis avec succès');
            }).catch(() => {
                // Fermer l'alerte "chargement" et afficher l'alerte d'erreur personnalisée
                alert('Erreur lors de la soumission du formulaire, merci de contacter Lucas via lucas.maujean@gmail.com');
            });
        }
    },


}
</script>

<style scoped>
/* Your component's styles go here */
.labelinputfile {
    margin-left: 1em;
    text-align: center;
    margin-top: 0.5em;
    display: inline-block;

}

.titrepagepart {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    margin-bottom: 0.5em;
    margin-left: -0.5em;



}

.titresectionpart {
    font-family: 'Dancing Script', cursive;
    font-size: 2.5em;
    margin-left: -0.4em;


}

.titresoussection {
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
    color: #319093;

}

#button {
    background-color: #319093;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
}

#inline {
    color: #319093;
}

.consignes {
    margin-bottom: 2em;
}
</style>