<template>
    <footer class="footer has-text-black">
        <div class="tourseparator">
            <div class="separator"></div>
        </div>
        <div class="content">
            <div class="hautfooter">
                <router-link to="/contact" class="lienfooter">Contact</router-link>
                <router-link to="/mentionlegale" class="lienfooter">Mentions Légales</router-link>
                <router-link to="/apropos" class="lienfooter">À propos</router-link>
                <router-link to="/calendrier" class="lienfooter">Calendrier</router-link>
                <router-link to="/actu" class="lienfooter">Actu</router-link>
                <a href="https://www.facebook.com" target="_blank">
                    <img src="../../public/logofacebook.png" alt="Facebook" />
                </a>
            </div>
            <div class="basfooter">
                <img src="../../public/logo.png" class="logo" alt="App Logo" />
                <p class="copyright"> © 2022 Association Passion Nouvelle</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterCompo',
}
</script>

<style scoped>
.footer {
    padding: 2rem 1.5rem;
}

.footer a,
.footer .router-link {
    color: inherit;
}

.logo {
    width: 60px;
}

.basfooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5em;
}

.lienfooter {
    color: black;
    font-size: 2em;
    font-weight: bold;
    font-family: 'Dancing Script', cursive;
}

.tourseparator {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.separator {
    width: 100vw;
    border: solid 1px lightgray;
}

.copyright {
    color: black;
    font-size: 1.5em;
    font-family: 'Dancing Script', cursive;
}
.hautfooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}
@media (max-width: 1024px) {
    .hautfooter {
        flex-direction: column;
    }
}
footer {
    background-color: #f5f5f5;
}
</style>