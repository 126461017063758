<template>
    <NavbarCompo />

    <div class="attention">
        <h2 class="texteattention">Nous rappelons à nos candidats que leurs nouvelles doivent contenir une page de garde
            mentionnant leur nom, leur prénom et leur adresse mail !</h2>
    </div>
    <div class="containeracc">
        <h2 class="titrepage">Bienvenue sur la page d'accueil du site </h2>
        <div class="sectionacc">
            <h3 class="titresection">Le prix en quelques mots :</h3>
            <p> Le prix Odette Massfelder est un prix littéraire organisé par l'association <b>Passion Nouvelle</b>. Le
                prix
                est ouvert à tous, sans distinction d’âge, de nationalité ou de résidence. Les participants écrivent une
                nouvelle dont le sujet est libre. Le consensus du comité de lecture honore et récompence le lauréat et
                la
                mention de prix..</p>
            <div class="tourbouton">
                <router-link to="/presentation" class="button">En savoir plus</router-link>
            </div>
        </div>
        <div class="sectionacc">
            <h3 class="titresection">Extrait de Règlement :</h3>
            <h5 class="soustitresect">Article 3 : Présentation des textes :</h5>
            <p>Il s’agit pour les candidats de présenter conformément au présent règlement une
                nouvelle ;
                Le choix du sujet est libre ;
                Chaque texte présenté, sera rédigé en français, dactylographié en police de 12 avec un
                interligne simple, il ne devra pas excéder six pages, être anonyme et sera expédié en trois
                exemplaires ;
                Les pages devront être numérotées ;
                Le texte aura toujours un titre ;
                Dans une enveloppe devront être portées les coordonnées de l’auteur(e) (nom, adresse,
                téléphone, adresse mail et titre de la nouvelle) ; Les insertions de photos dans les textes
                sont proscrites.</p>
            <div class="tourbouton">
                <router-link to="/reglement" class="button">En voir plus</router-link>
            </div>
        </div>
        <h2 class="titrepage">Nous découvrir :</h2>
        <CarousselCompo />
        <h2 class="titrepage">Intéressé(e) ?</h2>
        <div class="sectionacc">
            <div class="columns">
                <div class="column" style="display: flex; align-items: center;">
                    <p>Vous voulez participer au prix littéraire ? Envoyez votre nouvelle directement depuis ce site en
                        vous rendant
                        sur la page participer.</p>
                </div>
                <div class="column is-one-quarter">
                    <div class="tourbouton">
                        <router-link to="/participer" class="button">S'y rendre</router-link>
                    </div>
                </div>
            </div>
        </div>

        </div>
        <FooterCompo />
</template>

<script>
import axios from 'axios';
import NavbarCompo from '../components/NavbarCompo.vue';
import CarousselCompo from "../components/CarousselCompo";
import FooterCompo from '../components/FooterCompo.vue'
export default {
    name: 'AccueilVue',
    components: {
        NavbarCompo,
        CarousselCompo,
        FooterCompo
    },
    mounted() {
        axios.get('https://tasteful-bramble-slicer.glitch.me/wakeup')
      .then(() => console.log('Server awakened'))
      .catch(err => console.error('Error waking up server:', err));
    }
    // Your component's logic goes here
}
</script>

<style scoped>
.attention {
    background-color: #319093;
    min-height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;

}

.texteattention {
    color: white;
    text-align: center;
    font-size: 1em;
    font-family: 'Roboto Slab', serif;
}

.titrepage {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    margin-bottom: 0.5em;
    margin-left: 0.5em;

}

.sectionacc {
    display: inline-block;
    border: 3px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    background: #ffffff;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    margin-bottom: 1em;
    width: 95vw;
}



.titresection {
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;
}

.tourbouton {
    display: flex;
    justify-content: end;
    margin-top: 1em;
    margin-bottom: 1em;
}

.button {
    background-color: #319093;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
}

.soustitresect {
    font-family: 'Amatic SC', cursive;
    font-size: 1.75em;

}

.containeracc {
    margin-left: 1em;
}
</style>