<template>
    <NavbarCompo />
    <div class="container">
        <h1 class="titrepage">Règlement : </h1>
        <h2 class="titresection">Préambule :</h2>
        <p>Le prix littéraire est placé sous la responsabilité de l’Association PASSION NOUVELLE ;
            Un comité de lecture présidé par madame Jocelyne Bour est chargé de l’organisation du prix et de
            l’adoption du règlement qui suit :</p>
        <ul>
            <li>
                <h4 class="titresection">1. Intitulé :</h4>
                <p class="contenusect">Prix littéraire Odette Massfelder – Ecriture d’une nouvelle.</p>
            </li>
            <li>
                <h4 class="titresection">2. Conditions d’inscription :</h4>
                <p class="contenusect">Le prix est ouvert à tous, sans distinction d’âge, de nationalité ou de
                    résidence.
                    Les membres du comité de lecture ne peuvent participer au prix.
                    Le droit de participation est de 10 euros (chèque libellé à l’ordre de l’association
                    PASSION NOUVELLE – 20 rue de l'église 57340 MORHANGE).
                    Le lauréat et la mention de prix ne pourront concourir l’année suivante.</p>
            </li>
            <li>
                <h4 class="titresection">3. Présentation des textes :</h4>
                <p class="contenusect">Il s’agit pour les candidats de présenter conformément au présent règlement une nouvelle ; Le choix du sujet est libre ; Chaque texte présenté, sera rédigé en français, dactylographié en police de 12 avec un interligne simple, il ne devra pas excéder six pages, être anonyme et sauf s'il est pas déposé sur le site, sera expédié en trois exemplaires  ; Les pages devront être numérotées ; Le texte aura toujours un titre ; Dans une enveloppe devront être portées les coordonnées de l’auteur(e) (nom, adresse, téléphone, adresse mail et titre de la nouvelle) ; Les insertions de photos dans les textes sont proscrites.
                    <br>
                    Si vous candidatez via le site vous devrez ajouter à votre fichier une page de garde sur laquelle
                    seront
                    renseignés votre nom, votre prénom et une adresse mail.
                    <br>
                    Dans le cadre d'un envoi postal, le texte devra être expédié en trois exemplaires avec dans une
                    enveloppe les
                    coordonnées de l’auteur(e) (nom, adresse,
                    téléphone, adresse mail et titre de la nouvelle).
                </p>
            </li>
            <li>
                <h4 class="titresection">4. Modalités d’envois :</h4>
                <p class="contenusect">Par courrier :
                    le texte en trois exemplaires, une enveloppe contenant les coordonnées de l’auteur(e), le titre de
                    sa
                    nouvelle, ainsi que les frais d'inscription de dix euros nécessaires à l'enregistrement de votre
                    participation. Les envois devront être adressés, à madame la Présidente Jocelyne Bour au 11, rue de
                    la
                    fauvette – 57340 MORHANGE. <br><strong> Ce règlement peut aussi être effectué via le RIB disponible
                        sur la
                        page "Participer". </strong>

                </p>
                <p class="contenusect">Sur notre site <b>Passion Nouvelle</b> :
                    vous rendre sur la page "Participer", déposez votre nouvelle au format pdf. Vous devrez quand même
                    vous
                    acquitter des frais d'inscription comme précisé ci-dessus.
                    <br>

                </p>
            </li>
            <li>
                <h4 class="titresection">5. Récompense :</h4>
                <p class="contenusect">Le 1er prix est d’un montant de 500 euros (CINQ CENTS EUROS), le 2ème prix est
                    d’un
                    montant
                    de 250 euros (DEUX CENT CINQUANTE EUROS). La remise des prix se fera en fin d'année.</p>
            </li>
            <li>
                <h4 class="titresection">6. Date limite d’envoi :</h4>
                <p class="contenusect"> Pour la session 2023/2024, les participants pourront envoyer leurs écrits
                    jusqu'au 15
                    octobre 2024 !</p>
            </li>
            <li>
                <h4 class="titresection">7. Communication :</h4>
                <p class="contenusect">Chaque participant s’engage à accorder aux organisateurs la liberté de diffuser
                    leur
                    texte sur notre site internet et sur notre page Facebook. En cas de désaccord, l’auteur devra
                    joindre à son
                    envoi une lettre manuscrite précisant son refus. Ainsi, les textes primés pourront être consultés
                    sur notre
                    site <a href="/nouvelles" id="inline">passionnouvelle.fr</a> (page Nouvelles) et sur notre page
                    Facebook. Toutes correspondances pourront se faire
                    via : <a href="mailto:associationpassionnouvelle@gmail.com"
                        id="inline">associationpassionnouvelle@gmail.com</a></p>
            </li>

        </ul>

    </div>
    <FooterCompo />
</template>

<script>
import NavbarCompo from '../components/NavbarCompo.vue';
import FooterCompo from '../components/FooterCompo.vue'
export default {
    name: 'ReglementVue',
    // Your component's logic goes here
    data() {
        return {
            // Your data properties go here
        };
    },
    methods: {
        // Your component's methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    components: {
        NavbarCompo,
        FooterCompo
    }
};
</script>

<style scoped>
.titrepage {
    margin-top: 0.5em;
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    margin-bottom: 0.5em;
}
#inline{
    color: #319093;
}
</style>